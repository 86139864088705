import React, { useEffect, useState } from "react";

const Filter = (props) => {
  const [locationModal, setModal] = useState(false);
  const showModal = () => {
    setModal(true);
  };
  const hideModal = () => {
    setModal(false);
  };
  //  for primary skill
  const [skillModal, setSkillModal] = useState(false);
  const showSkillModal = () => {
    setSkillModal(true);
  };
  const hideSkillModal = () => {
    setSkillModal(false);
  };
  // for secondry skill
  const [primarySkillModal, setPrimarySkillModal] = useState(false);
  const showPrimarySkillModal = () => {
    setPrimarySkillModal(true);
  };
  const hidePrimarySkillModal = () => {
    setPrimarySkillModal(false);
  };
  // domain
  const [domainModal, setDomainModal] = useState(false);
  const showDomainModal = () => {
    setDomainModal(true);
  };
  const hideDomainModal = () => {
    setDomainModal(false);
  };
  // education
  const [educationModal, setEducationModal] = useState(false);
  const showEducationModal = () => {
    setEducationModal(true);
  };
  const hideEducationModal = () => {
    setEducationModal(false);
  };

  // certificate
  const [certificateModal, setCertificateModal] = useState(false);
  const showCertificateModal = () => {
    setCertificateModal(true);
  };
  const hideCertificateModal = () => {
    setCertificateModal(false);
  };

  const filterToggleShow = () => {
    document.getElementById("sidebaricon").style.pointerEvents = "auto";
    document.getElementsByClassName(
      "filtertogglebutton"
    )[0].style.pointerEvents = "auto";
    const hide = document.getElementsByClassName("hide")[0].style.display;
    if (hide === "none") {
      const sidebar = document.getElementsByClassName("sidebar")[0];
      sidebar.style.cssText = "min-width:100px !important;padding-left:0px";
      const companylogo = document.getElementsByClassName("companylogo")[0];
      companylogo.style.cssText =
        "width:200px;height:auto;position:relative;right:50px";
      const container = document.getElementsByClassName("stick")[0];
      container.style.cssText =
        "margin-left:100px;background-color:#F9F9F9;border-radius:38px 0px 0px 0px";
    } else {
    }
  };
  const [location, setLocation] = useState([]);
  const [primary, setPrimary] = useState([]);
  const [secondary, setSecondary] = useState([]);
  const [domain, setDomain] = useState([]);
  const [education, setEducation] = useState([]);
  const [certificate, setCertificate] = useState([]);

  //list of location
  useEffect(() => {
    try {
      if (props.location.length !== 0) {
        setLocation(props.location);
      }
    } catch {}
  }, [props.location]);

  //list of skill
  useEffect(() => {
    try {
      if (props.primary.length !== 0) {
        setPrimary(props.primary);
      }
    } catch {}
  }, [props.primary]);

  //list of secondary skill
  useEffect(() => {
    try {
      if (props.secondary.length !== 0) {
        setSecondary(props.secondary);
      }
    } catch {}
  }, [props.secondary]);

  //list of domain
  useEffect(() => {
    try {
      if (props.domain.length !== 0) {
        setDomain(props.domain);
      }
    } catch {}
  }, [props.domain]);

  //list of education
  useEffect(() => {
    try {
      if (props.education.length !== 0) {
        setEducation(props.education);
      }
    } catch {}
  }, [props.education]);

  //list of certificate
  useEffect(() => {
    try {
      if (props.certificate.length !== 0) {
        setCertificate(props.certificate);
      }
    } catch {}
  }, [props.certificate]);

  //list of rateCards
  const workAuthorizationData = [
    { workAuth: "US_CITIZEN" },
    { workAuth: "GREEN_CARD" },
    { workAuth: "GC_EAD" },
    { workAuth: "H1B" },
    { workAuth: "H4_EAD" },
    { workAuth: "OPT" },
    { workAuth: "OPT-STEM" },
    { workAuth: "CPT" },
    { workAuth: "TN_VISA" },
    { workAuth: "TN_EAD" },
    { workAuth: "E3" },
    { workAuth: "APPROVED_I_140" },
  ];
  const employmentData = [
    { type: "FULL_TIME" },
    { type: "C2C" },
    { type: "C2H" },
    { type: "W2" },
    { type: "W2_HOURLY" },
    { type: "1099" },
  ];
  const securityCategory = [
    { name: "SCI" },
    { name: "SAPS" },
    { name: "RD" },
    { name: "FRD" },
    { name: "NSI" },
  ];
  const handleInformation = props.handleInformation;
  return (
    <div
      className={"modal  right"}
      id={"clientmodalfilter"}
      role={"dialog"}
      data-backdrop="false"
      aria-labelledby="myModalLabel2"
    >
      <div className={"modal-dialog modal-lg "}>
        <div className={"modal-content pr-2"}>
          <div className={"scrollmodal"}>
            <div className="row">
              <div className="col-md-1">
                <button
                  className="clearbtn"
                  data-dismiss={"modal"}
                  onClick={filterToggleShow}
                >
                  &times;
                </button>
              </div>
              <div className={"col-md-1"}>
                <label className="ml-2 mt-2">FILTERS</label>
              </div>
              <div className={"col-md"}>
                <label
                  className={"float-right pr-2 headingfont mt-2"}
                  style={{ color: "red", fontSize: "12px" }}
                >
                  CLEAR ALL
                </label>
              </div>
            </div>
          </div>
          <div
            className={"modal-body"}
            style={{ backgroundColor: "#F9F9F9", borderRadius: "20px" }}
          >
            <form>
              <div className="row mx-1">
                <input
                  type="radio"
                  name="pay"
                  id="rad1"
                  className="modalradio"
                  value={"HOURLY"}
                  onClick={props.handleRateFilter}
                />
                <label htmlFor="rad1" className="skill pl-4">
                  Hourly
                </label>
                <br />
              </div>
              <div className="row mx-1">
                <input
                  type="radio"
                  name="pay"
                  id="rad1"
                  className="modalradio"
                  value={"YEARLY"}
                  onClick={props.handleRateFilter}
                />
                <label htmlFor="rad1" className="skill pl-4">
                  Yearly
                </label>
                <br />
              </div>
              <hr className={"divider"} />
              {/* for location */}
              <div className={"dropdownfilter"}>
                <h4>Location</h4>
                {location.slice(0, 5).map((location, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    {" "}
                    <input
                      type="checkbox"
                      id={location.city}
                      className="modalcheckbox ml-2"
                      value={location.city}
                      onClick={props.handleLocationFilter}
                    />
                    <label
                      htmlFor={location.city}
                      className="pl-4 modaltext mt-1"
                    >
                      {location.city}
                    </label>
                  </li>
                ))}
                <button
                  type="button"
                  className="btnshow pl-5"
                  onClick={showModal}
                >
                  + show more
                </button>

                <div
                  className={`modal popupsecondbox fade ${
                    locationModal ? "show" : ""
                  }`}
                  aria-labelledby="myLargeModalLabel"
                  style={{
                    display: `${locationModal ? "block" : "none"}`,
                  }}
                  tabIndex="-1"
                  role="dialog"
                  id={"modalLocation"}
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              id="form1"
                              className="form-control"
                              placeholder="Search Location"
                              name={"locationData"}
                              onChange={handleInformation}
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="close"
                          onClick={hideModal}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <ul className={"filterdirectory"}>
                          {location.slice(5).map((location, index) => (
                            <li
                              key={index}
                              className="filterDirectorylisttitle"
                            >
                              {" "}
                              <input
                                type="checkbox"
                                id={location.city}
                                name={""}
                                className="modalcheckbox ml-2 locationcheck"
                                value={location.city}
                                onClick={props.handleLocationFilter}
                              />
                              <label
                                htmlFor={location.city}
                                className="pl-4 modaltext mt-1"
                              >
                                {location.city}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {primary.length !== 0 && <hr className={"divider"} />}
              {/* lolcation end here */}
              {/* for primary skill */}
              <div className={"dropdownfilter"}>
                {primary.length !== 0 && <h4>Primary Skill</h4>}
                {primary.slice(0, 5).map((primary, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    {" "}
                    <input
                      type="checkbox"
                      id={primary.skill}
                      className="modalcheckbox ml-2"
                      value={primary.skill}
                      onClick={props.handlePrimarySkillFilter}
                    />
                    <label
                      htmlFor={primary.skill}
                      className="pl-4 modaltext mt-1"
                    >
                      {primary.skill}
                    </label>
                  </li>
                ))}
                {primary.length > 5 ? (
                  <button
                    type="button"
                    className="btnshow pl-5"
                    onClick={showSkillModal}
                  >
                    + show more
                  </button>
                ) : (
                  ""
                )}
                <div
                  className={`modal fade popupsecondbox newmodelbox skill-example-modal-lg ${
                    skillModal ? "show" : ""
                  }`}
                  aria-labelledby="myLargeModalLabel"
                  style={{
                    display: `${skillModal ? "block" : "none"}`,
                  }}
                  tabIndex="-1"
                  role="dialog"
                  id={"modalLocation"}
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              id="form1"
                              className="form-control"
                              placeholder="Search Location"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="close"
                          onClick={hideSkillModal}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <ul className={"filterdirectory"}>
                          {primary.slice(5).map((primary, index) => (
                            <li
                              key={index}
                              className="filterDirectorylisttitle"
                            >
                              {" "}
                              <input
                                type="checkbox"
                                id={primary.skill}
                                className="modalcheckbox ml-2"
                                value={primary.skill}
                                onClick={props.handlePrimarySkillFilter}
                              />
                              <label
                                htmlFor={primary.skill}
                                className="pl-4 modaltext mt-1"
                              >
                                {primary.skill}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {secondary.length !== 0 && <hr className={"divider"} />}
              {/*primary skill end */}
              {/* for secondary skill */}
              <div className={"dropdownfilter"}>
                {secondary.length !== 0 && <h4>Secondary Skill</h4>}
                {secondary.slice(0, 5).map((secondary, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    {" "}
                    <input
                      type="checkbox"
                      id={secondary.skill}
                      className="modalcheckbox ml-2"
                      value={secondary.skill}
                      onClick={props.handleSecondarySkillFilter}
                    />
                    <label
                      htmlFor={secondary.skill}
                      className="pl-4 modaltext mt-1"
                    >
                      {secondary.skill}
                    </label>
                  </li>
                ))}
                {secondary.length > 5 ? (
                  <button
                    type="button"
                    className="btnshow pl-5"
                    onClick={showPrimarySkillModal}
                  >
                    + show more
                  </button>
                ) : (
                  ""
                )}
                <div
                  className={`modal fade popupsecondbox newmodelbox skill-example-modal-lg ${
                    primarySkillModal ? "show" : ""
                  }`}
                  aria-labelledby="myLargeModalLabel"
                  style={{
                    display: `${primarySkillModal ? "block" : "none"}`,
                  }}
                  tabIndex="-1"
                  role="dialog"
                  id={"modalLocation"}
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              id="form1"
                              className="form-control"
                              placeholder="Search Location"
                            />
                          </div>
                        </div>

                        <button
                          type="button"
                          className="close"
                          onClick={hidePrimarySkillModal}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <ul className={"filterdirectory"}>
                          {secondary.slice(5).map((secondary, index) => (
                            <li
                              key={index}
                              className="filterDirectorylisttitle"
                            >
                              {" "}
                              <input
                                type="checkbox"
                                id={secondary.skill}
                                className="modalcheckbox ml-2"
                                value={secondary.skill}
                                onClick={props.handleSecondarySkillFilter}
                              />
                              <label
                                htmlFor={secondary.skill}
                                className="pl-4 modaltext mt-1"
                              >
                                {secondary.skill}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {props.domainLength !== null && <hr className={"divider"} />}
              {/*secondry skill end */}
              {/* domain start */}
              <div className={"dropdownfilter"}>
                {props.domainLength !== null && <h4>Domain</h4>}
                {domain.slice(0, 5).map((domain, index) => (
                  <div key={index}>
                    {domain.Candidate.CandidateExperiences[0].domain !==
                      null && (
                      <li className="filterDirectorylisttitle">
                        {" "}
                        <input
                          type="checkbox"
                          id={domain.Candidate.CandidateExperiences[0].domain}
                          className="modalcheckbox ml-2"
                          value={
                            domain.Candidate.CandidateExperiences[0].domain
                          }
                          onClick={props.handleDomainFilter}
                        />
                        <label
                          htmlFor={
                            domain.Candidate.CandidateExperiences[0].domain
                          }
                          className="pl-4 modaltext mt-1"
                        >
                          {domain.Candidate.CandidateExperiences[0].domain}
                        </label>
                      </li>
                    )}
                  </div>
                ))}
                {domain.length > 5 ? (
                  <button
                    type="button"
                    className="btnshow pl-5"
                    onClick={showDomainModal}
                  >
                    + show more
                  </button>
                ) : (
                  ""
                )}
                <div
                  className={`modal fade popupsecondbox newmodelbox domain-example-modal-lg ${
                    domainModal ? "show" : ""
                  }`}
                  aria-labelledby="myLargeModalLabel"
                  style={{
                    display: `${domainModal ? "block" : "none"}`,
                  }}
                  tabIndex="-1"
                  role="dialog"
                  id={"modalLocation"}
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              id="form1"
                              className="form-control"
                              placeholder="Search Location"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="close"
                          onClick={hideDomainModal}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <ul className={"filterdirectory"}>
                          {domain.slice(5).map((domain, index) => (
                            <li
                              key={index}
                              className="filterDirectorylisttitle"
                            >
                              {" "}
                              <input
                                type="checkbox"
                                id={
                                  domain.Candidate.CandidateExperiences[0]
                                    .domain
                                }
                                className="modalcheckbox ml-2"
                                value={
                                  domain.Candidate.CandidateExperiences[0]
                                    .domain
                                }
                                onClick={props.handleDomainFilter}
                              />
                              <label
                                htmlFor={
                                  domain.Candidate.CandidateExperiences[0]
                                    .domain
                                }
                                className="pl-4 modaltext mt-1"
                              >
                                {
                                  domain.Candidate.CandidateExperiences[0]
                                    .domain
                                }
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* domain end */}

              <hr className={"divider"} />
              {/* education */}
              <div className={"dropdownfilter"}>
                <h4>Education</h4>
                {education.slice(0, 5).map((education, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    <input
                      type="checkbox"
                      id={
                        education.Candidate.CandidateEducations[0]
                          .academicQualification
                      }
                      className="modalcheckbox ml-2"
                      value={
                        education.Candidate.CandidateEducations[0]
                          .academicQualification
                      }
                      onClick={props.handleEducationFilter}
                    />
                    <label
                      htmlFor={
                        education.Candidate.CandidateEducations[0]
                          .academicQualification
                      }
                      className="pl-4 modaltext mt-1"
                    >
                      {
                        education.Candidate.CandidateEducations[0]
                          .academicQualification
                      }
                    </label>
                  </li>
                ))}
                {education.length > 5 ? (
                  <button
                    type="button"
                    className="btnshow pl-5"
                    onClick={showEducationModal}
                  >
                    + show more
                  </button>
                ) : (
                  ""
                )}
                <div
                  className={`modal fade popupsecondbox newmodelbox education-example-modal-lg ${
                    educationModal ? "show" : ""
                  }`}
                  aria-labelledby="myLargeModalLabel"
                  style={{
                    display: `${educationModal ? "block" : "none"}`,
                  }}
                  tabIndex="-1"
                  role="dialog"
                  id={"modalLocation"}
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              id="form1"
                              className="form-control"
                              placeholder="Search Location"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="close"
                          onClick={hideEducationModal}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <ul className={"filterdirectory"}>
                          {education.slice(5).map((education, index) => (
                            <li
                              key={index}
                              className="filterDirectorylisttitle"
                            >
                              <input
                                type="checkbox"
                                id={
                                  education.Candidate.CandidateEducations[0]
                                    .academicQualification
                                }
                                className="modalcheckbox ml-2"
                                value={
                                  education.Candidate.CandidateEducations[0]
                                    .academicQualification
                                }
                                onClick={props.handleEducationFilter}
                              />
                              <label
                                htmlFor={
                                  education.Candidate.CandidateEducations[0]
                                    .academicQualification
                                }
                                className="pl-4 modaltext mt-1"
                              >
                                {
                                  education.Candidate.CandidateEducations[0]
                                    .academicQualification
                                }
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* education end */}
              <hr className={"divider"} />
              {/* work authorization */}
              <div className={"dropdownfilter"}>
                <h4>Work Authorization</h4>
                {workAuthorizationData.map((data, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    {" "}
                    <input
                      type="checkbox"
                      id={data.workAuth}
                      className="modalcheckbox ml-2"
                      value={data.workAuth}
                      onClick={props.handleWorkAuthFilter}
                    />
                    <label
                      htmlFor={data.workAuth}
                      className="pl-4 modaltext mt-1"
                    >
                      {data.workAuth}
                    </label>
                  </li>
                ))}
              </div>
              {/* end work auth */}
              <hr className={"divider"} />
              {/* employe type */}
              <div className={"dropdownfilter"}>
                <h4>Employment Type</h4>
                {employmentData.map((data, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    {" "}
                    <input
                      type="checkbox"
                      id={data.type}
                      className="modalcheckbox ml-2"
                      value={data.type}
                      onClick={props.handleEmploymentFilter}
                    />
                    <label htmlFor={data.type} className="pl-4 modaltext mt-1">
                      {data.type}
                    </label>
                  </li>
                ))}
              </div>
              {/* end employee type */}
              <hr className={"divider"} />
              {/* us degree */}
              <div className={"dropdownfilter"}>
                <h4>US Degree</h4>
                <div className="d-flex mx-1">
                  <input
                    type="radio"
                    name="group"
                    id="rad1"
                    className="modalradio"
                    value={"yes"}
                    onClick={props.handleUSDegreeFilter}
                  />
                  <label htmlFor="rad1" className="skill pl-4">
                    YES
                  </label>
                  <br />
                </div>
                <div className="d-flex mx-1">
                  <input
                    type="radio"
                    name="group"
                    id="rad2"
                    className="modalradio"
                    value={"no"}
                    onClick={props.handleUSDegreeFilter}
                  />
                  <label htmlFor="rad2" className="skill pl-4">
                    NO
                  </label>
                </div>
              </div>

              {/* us degree end */}
              <hr className={"divider"} />
              {/* security  */}
              <div className={"dropdownfilter"}>
                <h4>Security Clearence</h4>
                {securityCategory.map((data, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    {" "}
                    <input
                      type="checkbox"
                      id={data.name}
                      className="modalcheckbox ml-2"
                      value={data.name}
                      onClick={props.handleSecurityFilter}
                    />
                    <label htmlFor={data.name} className="pl-4 modaltext mt-1">
                      {data.name}
                    </label>
                  </li>
                ))}
              </div>
              {/*security end  */}
              {certificate.length !== 0 && <hr className={"divider"} />}
              {/* certification */}
              <div className={"dropdownfilter"}>
                {certificate.length !== 0 && <h4>Certification</h4>}
                {certificate.slice(0, 5).map((certificate, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    <input
                      type="checkbox"
                      id={certificate.certificateName}
                      className="modalcheckbox ml-2"
                      value={certificate.certificateName}
                      onClick={props.handleCertificateFilter}
                    />
                    <label
                      htmlFor={certificate.certificateName}
                      className="pl-4 modaltext mt-1"
                    >
                      {certificate.certificateName}
                    </label>
                  </li>
                ))}
                {certificate.length > 2 && (
                  <button
                    type="button"
                    className="btnshow pl-5"
                    onClick={showCertificateModal}
                  >
                    + show more
                  </button>
                )}
                <div
                  className={`modal fade popupsecondbox newmodelbox experience-example-modal-lg ${
                    certificateModal ? "show" : ""
                  }`}
                  aria-labelledby="myLargeModalLabel"
                  style={{
                    display: `${certificateModal ? "block" : "none"}`,
                  }}
                  tabIndex="-1"
                  role="dialog"
                  id={"modalLocation"}
                >
                  <div className="modal-dialog modal-lg modelposition">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              id="form1"
                              className="form-control"
                              placeholder="Search Location"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="close"
                          onClick={hideCertificateModal}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {certificate.slice(5).map((certificate, index) => (
                          <li key={index} className="filterDirectorylisttitle">
                            <input
                              type="checkbox"
                              id={certificate.certificateName}
                              className="modalcheckbox ml-2"
                              value={certificate.certificateName}
                              onClick={props.handleCertificateFilter}
                            />
                            <label
                              htmlFor={certificate.certificateName}
                              className="pl-4 modaltext mt-1"
                            >
                              {certificate.certificateName}
                            </label>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* certification end */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Filter);
