import React, { useState, useEffect } from "react";
import GoToTop from "../common/GoToTop";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRequirementInfo } from "../../api/client/getRequirementInfo";
import { freegetRequirementInfo } from "../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { recgetRequirementInfo } from "../../api/recruiter/client/BuildRequirement/getRequirementInfo";

const JobPreview = () => {
  // return job apply main page
  const param = useParams();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const [match, setMatch] = useState();
  try {
    document.getElementById("replacetitleimg").innerText =
      "Job Posting Preview";
    document.getElementById("postjobid").style.display = "none";
  } catch {}

  useEffect(() => {
    async function callUserDetails() {
     try {
        const response = isrecruiterCandidate
          ? await recgetRequirementInfo(token,param.reqID)
          : isFreelance
          ? await freegetRequirementInfo(token,param.reqID)
          : await getRequirementInfo(token,param.reqID);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.requirement;
          if(payload){
            setMatch(payload);
          }
        }
     } catch {}
    }
    callUserDetails();
  }, []);

  return (
    <div className={"col mx-5"}>
      <div className={"row pt-5"}>
        <div
          className={"container pl-4 pb-4"}
          style={{ background: "#FFFFFF", borderRadius: "24px" }}
        >
          {/*first row*/}
          <div className={"row mt-4"}>
            <div className={"col-md-8"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#515151",
                }}
              >
                Requirement ID - {match ? match.ClientRequirementId : ""}
              </label>
            </div>
          </div>
          <div className={"row align-items-center pt-1 mt-4"}>
            <div className={"col-md-3"}>
              <font
                className={"globalfontrule"}
                style={{ fontSize: "10px", color: "#3F9AE0" }}
              >
                {match ? match.employementType : ""}
              </font>
              <br />
              <font
                className={"globalfontrule"}
                style={{ fontSize: "16px", color: "#313131" }}
              >
                {match ? match.jobTitle : ""}
              </font>
              <br />
              <div className={"row"}>
                <div className={"col"}>
                  <font
                    className={"globalfontrule"}
                    style={{
                      fontWeight: "normal",
                      fontSize: "12px",
                      color: "#B2B0AF",
                    }}
                  >
                    {match ? match.location : ""}
                  </font>
                </div>
              </div>
            </div>
            <div className={"col-md-2 pl-4"}>
              <font
                className={"globalfontrule"}
                style={{ fontSize: "12px", color: "#313131" }}
              >
                ${match ? match.rate : ""}/{match ? match.rateType : ""}
              </font>
              <br />
            </div>
            <div className={"col-md"}>
              <div
                className={"globalfontrule btn starbtn"}
                style={{ fontSize: "12px", color: "#FF8A00" }}
              >
                4.3
              </div>
            </div>
            {/* <div className={"col-md"}>
                    <Link to={"/client/jobapply"} className={"btn sharejobbtn"}>Share job</Link>
                </div> */}
          </div>
          {/*second row*/}
          <div className={"row  pt-5"}>
            <div className={"col-md"}>
              <div
                className={"btn globalfontrule"}
                style={{
                  fontSize: "12px",
                  color: "#3F9AE0",
                  backgroundColor: "rgba(121,57,203,0.16)",
                  borderRadius: "6px",
                }}
              >
                Job Description
              </div>
            </div>
          </div>
          <div className={"row pt-2"}>
            <div className={"col-md"}>
              <font className={"parafont"} style={{ fontWeight: "normal" }}>
                {match ? match.description : ""}
              </font>
            </div>
          </div>
          <div className={"row pt-5"}>
            <div className={"col-md"}>
              <img
                src="../../icon/candidate/applications/correction.png"
                alt={"correction icon"}
                className={"img-fluid pr-3"}
              />
              <font className={"parafont"} style={{ fontWeight: "bold" }}>
                Primary Skill
              </font>
            </div>
          </div>
          {match
            ? match.ClientPrimarySkills.map((obj, index) => {
            return (
              <div className={"row ml-2"} key={index}>
                <div className={"col-md parafont ml-3"}>
                  <font style={{ fontWeight: "normal" }}>
                    {obj.ClientPrimarySkill}
                  </font>
                </div>
              </div>
            );
          })
        : ""}
             {match && match.ClientSecondarySkills.length!==0 ? (
        <div className={"row pt-2"}>
          <div className={"col-md"}>
            <img
              src="../../icon/candidate/applications/correction.png"
              alt={"correction icon"}
              className={"img-fluid pr-3"}
            />
            <font className={"parafont"}>Secondary Skill</font>
          </div>
        </div>
      ) : (
        ""
      )}
      {match
        ? match.ClientSecondarySkills.map((obj, index) => {
            return (
              <div className={"row ml-2"} key={index}>
                <div className={"col-md parafont ml-3"}>
                  <font style={{ fontWeight: "normal" }}>
                    {obj.ClientSecondarySkill}
                  </font>
                </div>
              </div>
            );
          })
        : ""}
        {match && match.AcademicCertificates.length!==0 ?
         <div className={"row pt-2"}>
         <div className={"col-md"}>
           <img
             src="../../icon/candidate/applications/correction.png"
             alt={"correction icon"}
             className={"img-fluid pr-3"}
           />
           <font className={"parafont"}>Academics Qualification</font>
         </div>
       </div>
        :""
        }
        {match
        ? match.AcademicCertificates.map((obj, index) => {
            return (
              <div className={"row pt-1 ml-2"} key={index}>
                <div className={"col-md parafont ml-3"}>
                  <font style={{ fontWeight: "normal" }}>
                    {obj.academicsQualification} - {obj.specialization}
                  </font>
                </div>
              </div>
            );
          })
        : ""}
        {match && match.securityClearanceRequired === "YES" ? (
        <>
          <div className={"row pt-2"}>
            <div className={"col-md parafont"}>
              <img
                src="../../icon/candidate/applications/correction.png"
                alt={"correction icon"}
                className={"img-fluid pr-3"}
              />
              <font className={"parafont"}>Security Clearance</font>
            </div>
          </div>
          <div className={"row pt-2 ml-3"}>
            <div className={"col-md"}>
              <font style={{ fontWeight: "normal" }} className={"parafont"}>
                {match ? match.securityClearanceClassification : ""}
              </font>
              <br />
              <font style={{ fontWeight: "normal" }} className={"parafont"}>
                {match ? match.securityClearanceCategory : ""}
              </font>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
        </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(JobPreview);
