import { AUTHENTICATION_URL } from "../ENVIRONMENT";

async function createUser(userdetails) {
  // api for register new user
  const response = await fetch(AUTHENTICATION_URL + "register", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userdetails),
  });
  return response;
}
export { createUser };
