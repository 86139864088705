import { FREELANCEBUILDEXP } from "../../../ENVIRONMENT";
// api for freelance candidate experience 
async function freepostExperience(token, data , id) {
  const response = await fetch(FREELANCEBUILDEXP + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
// api for freelance candidate experience  details
async function freegetExperience(token,id) {
  const response = await fetch(FREELANCEBUILDEXP + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { freepostExperience, freegetExperience };
