import { MATCHINGJOBS } from "../ENVIRONMENT";

async function getMatchingJob(token, id) {
  // api for candidate matching job list
  let url = MATCHINGJOBS;
  if (id) {
    url = url + "/" + id;
  }
  return await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { getMatchingJob };
