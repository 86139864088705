import "../../styles/app.scss";
import React, { useEffect, useState } from "react";
import { Data, clearFilter } from "../../function/candidate/Application";
import Filter from "../../common-component/Filter";
import {
  locationList,
  locationListSearch,
} from "../../api/common/locationList";
import { useDispatch, useSelector } from "react-redux";
import { primaryList } from "../../api/candidate/primaryList";
import { secondaryList } from "../../api/candidate/secondaryList";
import { applicationSorting } from "../../api/candidate/applicationSorting";
import ReactPaginate from "react-paginate";
import { applicationFilter } from "../../api/candidate/applicationFilter";
import { applicationSearch } from "../../api/candidate/applicationSearch";
import { domainList } from "../../api/candidate/domainList";
import { educationList } from "../../api/candidate/educationList";
import { certificateList } from "../../api/candidate/certificateList";
import { recapplicationSearch } from "../../api/recruiter/candidate/applicationSearch";
import { freeapplicationSearch } from "../../api/freelance/candidate/applicationSearch";
import { recapplicationFilter } from "../../api/recruiter/candidate/applicationFilter";
import { freeapplicationFilter } from "../../api/freelance/candidate/applicationFilter";
import { recapplicationSorting } from "../../api/recruiter/candidate/applicationSorting";
import { freeapplicationSorting } from "../../api/freelance/candidate/applicationSorting";
import { recprimaryList } from "../../api/recruiter/candidate/primaryList";
import { freeprimaryList } from "../../api/freelance/candidate/primaryList";
import { recdomainList } from "../../api/recruiter/candidate/domainList";
import { recsecondaryList } from "../../api/recruiter/candidate/secondaryList";
import { freesecondaryList } from "../../api/freelance/candidate/secondaryList";
import { freedomainList } from "../../api/freelance/candidate/domainList";
import { receducationList } from "../../api/recruiter/candidate/educationList";
import { freeeducationList } from "../../api/freelance/candidate/educationList";
import { reccertificateList } from "../../api/recruiter/candidate/certificateList";
import { freecertificateList } from "../../api/freelance/candidate/certificateList";

const Applications = () => {
  // return application page
  try {
    document.getElementById("replacetitleimg").innerText = "Applications";
  } catch {}
  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  const token = useSelector((state) => state.token);
  const isFreelance = useSelector((state) => state.isFreelance);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);

  const [information, setInformation] = useState("");
  const [item, setItem] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState();
  const [totalLength, setTotalLength] = useState();
  const [idOrder, setIdOrder] = useState(true);
  const [order, setOrder] = useState(true);
  const [matching, setMatching] = useState(true);
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();

  //search
  async function handleSearchData() {
    try {
      const response = isrecruiterCandidate
        ? await recapplicationSearch(token, information, 1)
        : isFreelance
        ? await freeapplicationSearch(token, information, 1)
        : await applicationSearch(token, information, 1);
      if (response.status === 200) {
        let payload = await response.json();
        payload = payload.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setTotalLength(totalRecord);
        setItem(payload.slice(0, -1));
        setPageCount(Math.ceil(payload[6].totalRecord / 6));
      }
    } catch {}
  }
  useEffect(() => {
    async function callUserDetails() {
      try {
        if (information.length === 0) {
          const response = isrecruiterCandidate
            ? await recapplicationSearch(token, information, 1)
            : isFreelance
            ? await freeapplicationSearch(token, information, 1)
            : await applicationSearch(token, information, 1);
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setTotalLength(totalRecord);
            setItem(payload.slice(0, -1));
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        }
      } catch {}
    }
    callUserDetails();
  }, [information]);

  //pagination
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPage(data.selected);
    let location = "location";
    let primarySkill = "primarySkill";
    let secondarySkill = "secondarySkill";
    let workAuth = "workAuthorization";
    let security = "securityClearanceCategory";
    let employment = "employementType";
    let domain = "domain";
    let certificate = "certificate";
    let education = "academicsQualification";
    let USADegree = "usaDegree";
    let rateType = "rateType";
    setLoading(true);
    const response = isrecruiterCandidate
      ? await recapplicationFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          currentPage
        )
      : isFreelance
      ? await freeapplicationFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          currentPage
        )
      : await applicationFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          currentPage
        );

    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      const totalRecord = payload[payload.length - 1].totalRecord;
      setRecord(totalRecord);
      setTotalLength(totalRecord);
      setItem(payload.slice(0, -1));
      setLoading(false);
    }
  };

  //percentage sorting
  const handleMatchingSorting = async () => {
    let rate = "percentage";
    let high = "high";
    let low = "low";
    setPage(0);
    if (matching) {
      const response = isrecruiterCandidate
        ? await recapplicationSorting(token, 1, rate, high)
        : isFreelance
        ? await freeapplicationSorting(token, 1, rate, high)
        : await applicationSorting(token, 1, rate, high);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else {
      const response = isrecruiterCandidate
        ? await recapplicationSorting(token, 1, rate, low)
        : isFreelance
        ? await freeapplicationSorting(token, 1, rate, low)
        : await applicationSorting(token, 1, rate, low);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    }
  };

  //rate sorting
  const handleRateSorting = async () => {
    let rate = "rate";
    let high = "high";
    let low = "low";
    setPage(0);
    if (order) {
      const response = isrecruiterCandidate
        ? await recapplicationSorting(token, 1, rate, high)
        : isFreelance
        ? await freeapplicationSorting(token, 1, rate, high)
        : await applicationSorting(token, 1, rate, high);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else {
      const response = isrecruiterCandidate
        ? await recapplicationSorting(token, 1, rate, low)
        : isFreelance
        ? await freeapplicationSorting(token, 1, rate, low)
        : await applicationSorting(token, 1, rate, low);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    }
  };

  //id sorting
  const handleIdSorting = async () => {
    let rate = "id";
    let high = "high";
    let low = "low";
    setPage(0);
    if (idOrder) {
      const response = isrecruiterCandidate
        ? await recapplicationSorting(token, 1, rate, high)
        : isFreelance
        ? await freeapplicationSorting(token, 1, rate, high)
        : await applicationSorting(token, 1, rate, high);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else {
      const response = isrecruiterCandidate
        ? await recapplicationSorting(token, 1, rate, low)
        : isFreelance
        ? await freeapplicationSorting(token, 1, rate, low)
        : await applicationSorting(token, 1, rate, low);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    }
  };

  // for modal
  const filterToggleHide = () => {
    document.getElementById("sidebaricon").style.pointerEvents = "none";
    document.getElementsByClassName(
      "filtertogglebutton"
    )[0].style.pointerEvents = "none";
    const sidebar = document.getElementsByClassName("sidebar")[0];
    sidebar.style.cssText = "min-width:280px";
    const companylogo = document.getElementsByClassName("companylogo")[0];
    companylogo.style.cssText = "position:relative;right:50px";
    const container = document.getElementsByClassName("stick")[0];
    container.style.cssText =
      "margin-left:260px;background-color:#F9F9F9;border-radius:38px 0px 0px 0px";
  };

  //location list
  const [location, setLocation] = useState([]);
  const [outsideLocation, setOutsideLocation] = useState([]);
  //primary skill list
  const [skill, setSkill] = useState([]);
  //seconday
  const [secondary, setSecondary] = useState([]);
  //domain
  const [domain, setDomain] = useState([]);
  const [domainLength, setDomainLength] = useState();
  //education
  const [education, setEducation] = useState([]);
  //certificate
  const [certificate, setCertificate] = useState([]);

  useEffect(() => {
    async function callLocationDetails() {
      try {
        locationList(token)
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setOutsideLocation(payload);
            setLocation(payload);
          });
      } catch {}
      try {
        const primRes = isrecruiterCandidate
          ? recprimaryList(token)
          : isFreelance
          ? freeprimaryList(token)
          : primaryList(token);
        primRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setSkill(payload);
          });
      } catch {}
      try {
        const secRes = isrecruiterCandidate
          ? recsecondaryList(token)
          : isFreelance
          ? freesecondaryList(token)
          : secondaryList(token);
        secRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setSecondary(payload);
          });
      } catch {}
      try {
        const domainRes = isrecruiterCandidate
          ? recdomainList(token)
          : isFreelance
          ? freedomainList(token)
          : domainList(token);
        domainRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            
            setDomain(payload);
            let domainCount = 0;
            payload.map((obj) => {
              domainCount += obj.Candidate.CandidateExperiences[0].domain;
            });
            setDomainLength(domainCount);
          });
      } catch {}
      try {
        const academicRes = isrecruiterCandidate
          ? receducationList(token)
          : isFreelance
          ? freeeducationList(token)
          : educationList(token);
        academicRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            if (payload.length !== 0) {
             setEducation(payload);
            }
          });
      } catch {}
      try {
        const certificateRes = isrecruiterCandidate
          ? reccertificateList(token)
          : isFreelance
          ? freecertificateList(token)
          : certificateList(token);
        certificateRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            if (payload.length !== 0) {
              setCertificate(payload);
            }
          });
      } catch {}
    }
    callLocationDetails();
  }, []);

  //filter location
  const [checked, setChecked] = useState([]);

  const handleLocationFilter = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter primary skill
  const [checkedPrimary, setCheckedPrimary] = useState([]);

  const handlePrimarySkillFilter = (event) => {
    var updatedList = [...checkedPrimary];
    if (event.target.checked) {
      updatedList = [...checkedPrimary, event.target.value];
    } else {
      updatedList.splice(checkedPrimary.indexOf(event.target.value), 1);
    }
    setCheckedPrimary(updatedList);
  };
  const checkedPrimaryItems = checkedPrimary.length
    ? checkedPrimary.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter secondary skill
  const [checkedSecondary, setCheckedSecondary] = useState([]);

  const handleSecondarySkillFilter = (event) => {
    var updatedList = [...checkedSecondary];
    if (event.target.checked) {
      updatedList = [...checkedSecondary, event.target.value];
    } else {
      updatedList.splice(checkedSecondary.indexOf(event.target.value), 1);
    }
    setCheckedSecondary(updatedList);
  };
  const checkedSecondaryItems = checkedSecondary.length
    ? checkedSecondary.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter workAuthorization
  const [checkedAuth, setCheckedAuth] = useState([]);

  const handleWorkAuthFilter = (event) => {
    var updatedList = [...checkedAuth];
    if (event.target.checked) {
      updatedList = [...checkedAuth, event.target.value];
    } else {
      updatedList.splice(checkedAuth.indexOf(event.target.value), 1);
    }
    setCheckedAuth(updatedList);
  };
  const checkedAuthItems = checkedAuth.length
    ? checkedAuth.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter employmentType
  const [checkedEmploy, setCheckedEmploy] = useState([]);

  const handleEmploymentFilter = (event) => {
    var updatedList = [...checkedEmploy];
    if (event.target.checked) {
      updatedList = [...checkedEmploy, event.target.value];
    } else {
      updatedList.splice(checkedEmploy.indexOf(event.target.value), 1);
    }
    setCheckedEmploy(updatedList);
  };
  const checkedEmployItems = checkedEmploy.length
    ? checkedEmploy.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter securityCategory
  const [checkedSecurity, setCheckedSecurity] = useState([]);

  const handleSecurityFilter = (event) => {
    var updatedList = [...checkedSecurity];
    if (event.target.checked) {
      updatedList = [...checkedSecurity, event.target.value];
    } else {
      updatedList.splice(checkedSecurity.indexOf(event.target.value), 1);
    }
    setCheckedSecurity(updatedList);
  };
  const checkedSecurityItems = checkedSecurity.length
    ? checkedSecurity.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter USADegree
  const [checkedDegree, setCheckedDegree] = useState("");

  const handleUSDegreeFilter = async (event) => {
    setCheckedDegree(event.target.value);
  };
  //filterRatetype
  const [checkedRate, setCheckedRate] = useState("");

  const handleRateFilter = async (event) => {
    setCheckedRate(event.target.value);
  };

  //filter domain
  const [checkedDomain, setCheckedDomain] = useState([]);

  const handleDomainFilter = (event) => {
    var updatedList = [...checkedDomain];
    if (event.target.checked) {
      updatedList = [...checkedDomain, event.target.value];
    } else {
      updatedList.splice(checkedDomain.indexOf(event.target.value), 1);
    }
    setCheckedDomain(updatedList);
  };
  const checkedDomainItems = checkedDomain.length
    ? checkedDomain.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter certificates
  const [checkedCertificate, setCheckedCertificate] = useState([]);

  const handleCertificateFilter = (event) => {
    var updatedList = [...checkedCertificate];
    if (event.target.checked) {
      updatedList = [...checkedCertificate, event.target.value];
    } else {
      updatedList.splice(checkedCertificate.indexOf(event.target.value), 1);
    }
    setCheckedCertificate(updatedList);
  };
  const checkedCertificateItems = checkedCertificate.length
    ? checkedCertificate.reduce((total, item) => {
        return total + "," + item;
      })
    : "";
  //filter education
  const [checkedEducation, setCheckedEducation] = useState([]);

  const handleEducationFilter = (event) => {
    var updatedList = [...checkedEducation];
    if (event.target.checked) {
      updatedList = [...checkedEducation, event.target.value];
    } else {
      updatedList.splice(checkedEducation.indexOf(event.target.value), 1);
    }
    setCheckedEducation(updatedList);
  };
  const checkedEducationItems = checkedEducation.length
    ? checkedEducation.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  useEffect(async () => {
    let location = "location";
    let primarySkill = "primarySkill";
    let secondarySkill = "secondarySkill";
    let workAuth = "workAuthorization";
    let security = "securityClearanceCategory";
    let employment = "employementType";
    let domain = "domain";
    let certificate = "certificate";
    let education = "academicsQualification";
    let USADegree = "usaDegree";
    let rateType = "rateType";
    setLoading(true);
    try {
      const response = isrecruiterCandidate
        ? await recapplicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1
          )
        : isFreelance
        ? await freeapplicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1
          )
        : await applicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setTotalLength(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setPageCount(Math.ceil(payload[6].totalRecord / 6));
      }
    } catch {}
  }, [
    checked,
    checkedPrimary,
    checkedSecondary,
    checkedAuth,
    checkedSecurity,
    checkedEmploy,
    checkedDomain,
    checkedCertificate,
    checkedEducation,
    checkedRate,
    checkedDegree,
  ]);

  //location search
  const [informationData, setInformationData] = useState({ locationData: "" });

  function handleInformation(event, setInformationData) {
    let { name, value } = event.currentTarget;
    setInformationData((state) => {
      return { ...state, [name]: value };
    });
  }
  function callMethod(event) {
    handleInformation(event, setInformationData);
  }
  async function handleLocationSearch() {
    let data = informationData.locationData;
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setLocation(payload);
    }
  }

  useEffect(() => {
    handleLocationSearch();
  }, [informationData.locationData]);

  const handleClearAll = () => {
    var aa = document.getElementsByTagName("input");
    for (var i = 0; i < aa.length; i++) {
      if (aa[i].type == "checkbox") {
        if (aa[i].checked) {
          setChecked([]);
          setCheckedPrimary([]);
          setCheckedSecondary([]);
          setCheckedAuth([]);
          setCheckedSecurity([]);
          setCheckedEmploy([]);
          setCheckedDomain([]);
          setCheckedCertificate([]);
          setCheckedEducation([]);
          aa[i].checked = false;
        }
      }
      if (aa[i].type == "radio") {
        if (aa[i].checked) {
          setCheckedRate("");
          setCheckedDegree("");
          aa[i].checked = false;
        }
      }
    }
  };

  return (
    <div className={"col mx-4"}>
      {/*this is first box*/}
      <div
        className={"row mt-3 align-items-center"}
        style={{
          borderRadius: "20px",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col-8 py-3"}>
          <div className={"row align-items-center"}>
            <div className={"col ml-2"}>
              <input
                type={"search"}
                className={
                  "form-control p-4 border-0 placetext searchbxfocusbdr"
                }
                placeholder={"Search by Title, Company or any jobs keyword..."}
                value={information}
                name={"searchData"}
                onChange={(event) => setInformation(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={"col"}>
          <button
            className={"findbtn float-right mr-3"}
            onClick={handleSearchData}
          >
            FIND
          </button>
          <button
            className={"filterbtn mx-4 float-right filtertogglebutton"}
            onClick={filterToggleHide}
            data-toggle={"modal"}
            data-target={"#modalfilter"}
          >
            FILTER
          </button>
        </div>
      </div>
      <Filter
        location={location}
        outsideLocation={outsideLocation}
        checked={checked}
        primary={skill}
        secondary={secondary}
        domain={domain}
        domainLength={domainLength}
        education={education}
        certificate={certificate}
        informationData={informationData}
        handleInformation={callMethod}
        handleLocationFilter={handleLocationFilter}
        handlePrimarySkillFilter={handlePrimarySkillFilter}
        handleSecondarySkillFilter={handleSecondarySkillFilter}
        handleWorkAuthFilter={handleWorkAuthFilter}
        handleEmploymentFilter={handleEmploymentFilter}
        handleSecurityFilter={handleSecurityFilter}
        handleUSDegreeFilter={handleUSDegreeFilter}
        handleRateFilter={handleRateFilter}
        handleDomainFilter={handleDomainFilter}
        handleEducationFilter={handleEducationFilter}
        handleCertificateFilter={handleCertificateFilter}
        handleClearAll={handleClearAll}
      />
      {/*toggle box of filter button*/}
      <div
        className={"row mt-3 py-2 align-items-center collapse"}
        id={"filter"}
      >
        <div className={"col-9 filterfont pl-4 ml-2"}>
          Filter Applied
          <button
            className={"filterbttn ml-5"}
            style={{ width: "120px", height: "30px" }}
          >
            hourly
          </button>
          <button
            className={"filterbttn mx-2"}
            style={{ width: "120px", height: "30px" }}
          >
            Califonia
          </button>
          <button
            className={"filterbttn mx-2"}
            style={{ width: "120px", height: "30px" }}
          >
            Telephonic
          </button>
          <button
            className={"filterbttn mx-3"}
            style={{ width: "120px", height: "30px" }}
          >
            java
          </button>
          <button
            className={"filterbttn mx-2 my-2"}
            style={{ width: "120px", height: "30px" }}
          >
            8 Year
          </button>
        </div>
        <div className={"col"}>
          <button
            className={
              "filterfontright float-right mr-4 border-0 bg-transparent"
            }
            onClick={clearFilter}
          >
            Clear filter
          </button>
        </div>
      </div>
      {/*this is second box of data heading-----changed*/}
      <div
        className={"row mt-3 align-items-center"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row pl-3"}>
            <div className={"col-1 pr-0 pl-0"}>
              <font className={"headingfont pl-5 pr-1"}>ID</font>
              <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                  {
                    handleIdSorting();
                    setIdOrder(!idOrder);
                  }
                }}
              />
            </div>
            <div className={"col-2 pl-5"}>
              <div className="pl-5">
                <font className={"headingfont"}>Position</font>
              </div>
            </div>
            <div className={"col-2"}>
              <div className={"pl-5"}>
                <font className={"headingfont"}>Matching</font>
                <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  onClick={() => {
                    {
                      handleMatchingSorting();
                      setMatching(!matching);
                    }
                  }}
                />
              </div>
            </div>
            <div className={"col-1 pr-0"}>
              <div className={"pl-1"}>
                <font className={"headingfont"}>SourcedBy</font>
              </div>
            </div>
            <div className={"col-1 pl-4 pr-0"}>
              <font className={"headingfont"}>Rate</font>
              <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                  {
                    handleRateSorting();
                    setOrder(!order);
                  }
                }}
              />
            </div>
            <div className={"col-2 pl-3 pr-0"}>
              <font className={"headingfont"}>Interview Type</font>
            </div>
            <div className={"col-1 pl-0 pr-0"}>
              <font className={"headingfont"}>Location</font>
            </div>
            <div className={"col-1 pl-5"}>
              <font className={"headingfont"}>Status</font>
            </div>
          </div>
        </div>
      </div>
      {/*this is second box of heading data*/}
      <Data item={item} record={record} />
      {/*last box of data of heading*/}
      {loading ? (
        ""
      ) : (
        <>
          {record !== 0 && (
            <div
              className={"row align-items-center"}
              style={{
                borderRadius: "0 0 20px 20px",
                boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                backgroundColor: "white",
                marginTop: "1px",
              }}
            >
              <div className={"col py-2"}></div>
            </div>
          )}
        </>
      )}
      <div className={"row align-items-center pb-5"}>
        <div className={"col-md-6 pl-0 pb-5"}></div>
        <div className={""}>
          {totalLength > 6 && (
            <ReactPaginate
              previousLabel={"<< Previous"}
              nextLabel={"Next >>"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={3}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={page}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default React.memo(Applications);
