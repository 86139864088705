import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dashboardjobcount } from "../api/candidate/dashboardJobCount";
import { freelancejobcount } from "../api/freelance/candidate/freelanceJobCount";
import { recruiterjobcount } from "../api/recruiter/candidate/recruiterJobCount";
import { candidateRedirect } from "../function/candidate/candidateRedirect";
import { viewMore } from "../function/common/viewmore";

const CandidateDashcard = () => {
  // return dashboard card group which generated by data variable
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const dispatch = useDispatch();
  const [count, setCount] = useState({
    matchingJobs: "",
    interviewSchedule: "",
    jobOffer: "",
    shortlistedJobs: "",
    applicationSent: "",
    jobsInPipeline: "",
  });

  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isrecruiterCandidate
          ? await recruiterjobcount(token)
          : isFreelance
          ? await freelancejobcount(token)
          : await dashboardjobcount(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setCount({
            ...count,
            matchingJobs: payload.matchingJobs,
            interviewSchedule: payload.interviewSchedule,
            jobOffer: payload.jobOffer,
            shortlistedJobs: payload.shortlistedJobs,
            applicationSent: payload.applicationSent,
            jobsInPipeline: payload.jobsInPipeline,
          });
        }
      } catch {}
    }
    callUserDetails();
  }, []);

  const data = [
    {
      count: count.matchingJobs,
      countColor: "#2BC155",
      progressColor: "#2BC155",
      icon: "/icon/common/suitcase.png",
      iconColor: "#E9FFEF",
      message: "Matching Jobs",
    },
    {
      count: count.interviewSchedule,
      countColor: "#3F9AE0",
      progressColor: "#3F9AE0",
      icon: "/icon/common/calendar.png",
      iconColor: "#E0F2FF",
      message: "Interview Schedule",
    },
    {
      count: count.jobOffer,
      countColor: "#FF9B52",
      progressColor: "#FF9B52",
      icon: "/icon/common/person.png",
      iconColor: "#FFF0EB",
      message: "Job Offer",
    },
    {
      count: count.shortlistedJobs,
      countColor: "#8743DF",
      progressColor: "#8743DF",
      icon: "/icon/common/suitcase1.png",
      iconColor: "rgba(135, 67, 223, 0.37)",
      message: "Shortlisted Jobs",
    },
    {
      count: count.applicationSent,
      countColor: "#3F9AE0",
      progressColor: "#3F9AE0",
      icon: "/icon/common/calendar.png",
      iconColor: "#E0F2FF",
      message: "Application sent",
    },
    {
      count: count.jobsInPipeline,
      countColor: "#FF9B52",
      progressColor: "#FF9B52",
      icon: "/icon/common/person.png",
      iconColor: "#FFF0EB",
      message: "Jobs in Pipeline",
    },
  ];

  return data.map((data, index) => (
    <div key={index} className={"col-lg-4 pt-4"}>
      {data.message === "Matching Jobs" ? (
        <Link to={"/candidate/matchjobs"} onClick={() => viewMore(dispatch)}>
          <div
            className={"pt-3 pb-0 viewcontent"}
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              borderRadius: "20px",
              fontFamily: "poppins",
            }}
          >
            <div className={"row mx-3"}>
              <div className={"col-lg"}>
                <label
                  style={{
                    fontWeight: "600",
                    fontSize: "50px",
                    color: data.countColor,
                  }}
                >
                  {data.count}
                </label>
                <br />
              </div>
              <div className={"col-lg my-4"}>
                <div className={"pl-5"}>
                  <span
                    className={"pt-3 pb-3 pl-2 pr-2"}
                    style={{ background: data.iconColor, borderRadius: "15px" }}
                  >
                    <img src={data.icon} alt={"case"} className={"img-fluid"} />
                  </span>
                </div>
              </div>
            </div>
            <div className={"row mx-3"}>
              <div className={"col-lg pb-1"}>
                <Link
                  to={"/candidate/matchjobs"}
                  onClick={() => viewMore(dispatch)}
                >
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                      color: "rgba(15, 15, 15, 0.7)",
                    }}
                  >
                    {data.message}
                  </label>
                </Link>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-lg"}>
                <div
                  style={{
                    background: data.progressColor,
                    borderRadius: "20px",
                    width: "90%",
                    height: "4px",
                    marginLeft: "5%",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <>
          {data.message === "Application sent" ? (
            <Link
              to={"/candidate/applications"}
              onClick={() => candidateRedirect(dispatch)}
            >
              <div
                className={"pt-3 pb-0 viewcontent"}
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
                  borderRadius: "20px",
                  fontFamily: "poppins",
                }}
              >
                <div className={"row mx-3"}>
                  <div className={"col-lg"}>
                    <label
                      style={{
                        fontWeight: "600",
                        fontSize: "50px",
                        color: data.countColor,
                      }}
                    >
                      {data.count}
                    </label>
                    <br />
                  </div>
                  <div className={"col-lg my-4"}>
                    <div className={"pl-5"}>
                      <span
                        className={"pt-3 pb-3 pl-2 pr-2"}
                        style={{
                          background: data.iconColor,
                          borderRadius: "15px",
                        }}
                      >
                        <img
                          src={data.icon}
                          alt={"case"}
                          className={"img-fluid"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className={"row mx-3"}>
                  <div className={"col-lg pb-1"}>
                    <Link
                      to={"/candidate/applications"}
                      onClick={() => viewMore(dispatch)}
                    >
                      <label
                        style={{
                          fontWeight: "normal",
                          fontSize: "16px",
                          color: "rgba(15, 15, 15, 0.7)",
                        }}
                      >
                        {data.message}
                      </label>
                    </Link>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-lg"}>
                    <div
                      style={{
                        background: data.progressColor,
                        borderRadius: "20px",
                        width: "90%",
                        height: "4px",
                        marginLeft: "5%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          ) : (
            <Link
              to={`/candidate/matchjobs?title=${data.message
                .split(" ")[0]
                .toLowerCase()}`}
              onClick={() => viewMore(dispatch)}
            >
              <div
                className={"pt-3 pb-0 viewcontent"}
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
                  borderRadius: "20px",
                  fontFamily: "poppins",
                }}
              >
                <div className={"row mx-3"}>
                  <div className={"col-lg"}>
                    <label
                      style={{
                        fontWeight: "600",
                        fontSize: "50px",
                        color: data.countColor,
                      }}
                    >
                      {data.count}
                    </label>
                    <br />
                  </div>
                  <div className={"col-lg my-4"}>
                    <div className={"pl-5"}>
                      <span
                        className={"pt-3 pb-3 pl-2 pr-2"}
                        style={{
                          background: data.iconColor,
                          borderRadius: "15px",
                        }}
                      >
                        <img
                          src={data.icon}
                          alt={"case"}
                          className={"img-fluid"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className={"row mx-3"}>
                  <div className={"col-lg pb-1"}>
                    <Link
                      to={`/candidate/matchjobs?title=${data.message
                        .split(" ")[0]
                        .toLowerCase()}`}
                      onClick={() => viewMore(dispatch)}
                    >
                      <label
                        style={{
                          fontWeight: "normal",
                          fontSize: "16px",
                          color: "rgba(15, 15, 15, 0.7)",
                        }}
                      >
                        {data.message}
                      </label>
                    </Link>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-lg"}>
                    <div
                      style={{
                        background: data.progressColor,
                        borderRadius: "20px",
                        width: "90%",
                        height: "4px",
                        marginLeft: "5%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </>
      )}
    </div>
  ));
};
export default CandidateDashcard;
