import React from "react";
import reactdom from "react-dom";
import { data } from "../../function/client/rateCardData";

const WorkAuthorization = ({ workAuthorization }) => {
  // return data of ratecard dropdown button
  const tmp = workAuthorization;
  const workAuthData = data
    .map((value, index) => {
      value = Object.keys(value)[0];
      if (workAuthorization && index === 0) {
        return tmp;
      }
      if (value !== workAuthorization) return value;
    })
    .filter((data) => (data ? true : false))
    .map((data, index) => {
      return (
        <option key={index} value={data}>
          {data}
        </option>
      );
    });
  return workAuthData;
};

// function TaxTerms(select_value, preferredEmployment) {
//   // change subdata of ratecard dropdown button
//   let select_index;
//   for (let i = 0; i < data.length; i++) {
//     if (Object.keys(data[i])[0] === select_value) select_index = i;
//   }
//   reactdom.render(
//     Object.values(data[select_index])[0].map((value, index) => (
//       <option key={index} value={value}>
//         {value}
//       </option>
//     )),
//     document.getElementById("taxtermsid")
//   );
// }

function rateCardValidate(event) {
  const employement = event.target.form.taxterm.value.toLowerCase()
  const duration = event.target.form.duration.value.toLowerCase();
  const rate = event.target.form.rate.value.length;

  if (employement === "select" || duration === "select" || rate < 1)
    document.getElementById("ratecardid").style.display = "none";
  else document.getElementById("ratecardid").style.display = "";
}

export { WorkAuthorization, rateCardValidate };
