import { PRIMARYSKILL } from "../ENVIRONMENT";

function primaryList(token) {
  // api for candidate primaryliast details
  const response = fetch(PRIMARYSKILL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
export { primaryList };
