import { APPLICATIONDETAILS } from "../ENVIRONMENT";
// api for candidate application filter
async function applicationFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page
) {
  const response = await fetch(
    APPLICATIONDETAILS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
export { applicationFilter };
