import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import "../../styles/app.scss";
import { Route, Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import { loginUser } from "../../api/authentication/login";
import { verifyEmail } from "../../api/authentication/verify";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { getUserInfoForRegisterPage } from "../../api/common/userInfo";
import { getDetails } from "../../api/client/getDetails";
import { freegetDetails } from "../../api/freelance/getDetails";
import { recgetDetails } from "../../api/recruiter/getDetails";
import decode from "jwt-decode";
import io from "socket.io-client";
import { SOCKET_URL } from "../../api/ENVIRONMENT";
import { linkedinToken } from "../../api/authentication/linkedinToken";

const Login = () => {
  // return login page
  const dispatch = useDispatch();
  const history = useHistory();
  try {
    dispatch({ type: "newusertrue" });
    dispatch({ type: "buildresumefalse" });
    dispatch({ type: "recruiterfalse" });
    dispatch({ type: "recruiterCandidatefalse" });
    dispatch({ type: "freelancefalse" });
  } catch {}
  var count = 0;

  const { linkedInLogin } = useLinkedIn({
    clientId: "77bx0x727z9w6f",
    redirectUri: "https://app.dollarstaffing.com/linkedin",
    //redirectUri: "http://dollar-staffing.s3-website.ap-south-1.amazonaws.com/linkedin",
    scope: "r_liteprofile,r_emailaddress",
    onSuccess: async (code) => {
      let data = {
        authCode: code,
      };
      const response = await linkedinToken(data);
      if (response.status === 200) {
        let data = await response.json();
        data = data.payload;
        const token = data.token;
        const socket = io.connect(SOCKET_URL, {
          auth: {
            userId: decode(token).uuid,
          },
        });
        sessionStorage.setItem("token", token);
        dispatch({ type: "settoken", value: token });
        dispatch({ type: "setsocket", value: socket });
        try {
          const response = await getUserInfoForRegisterPage(token);
          if (response.status === 200) {
            const data = await response.json(token);
            if (data.payload.isRegistered) {
              dispatch({ type: "newuserfalse" });
              history.push("/candidate/dashboardview");
            } else {
              history.push("/candidate/dashboard/register");
            }
          }
        } catch {}
      }
    },
    onError: (error) => {},
  });

  const LoginHome = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [loginrole, setLoginRole] = useState();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const param = useParams();
    const formRef = useRef();
    let btnRef = useRef();

    const togglePassword = (e) => {
      // toggle the password view to hide or show
      e.preventDefault();
      setPasswordShown(!passwordShown);
    };

    const candidateChecked = document.getElementById("customtadio");
    const clientChecked = document.getElementById("customtadio0");
    const recChecked = document.getElementById("customtadio1");
    const freeChecked = document.getElementById("customtadio2");

    const changebgtxt = (event) => {
      // this function is used for change bg and txt of role type of login page
      const defaultrole = document.getElementsByClassName("defaultrole")[0];
      //defaultrole.checked = false;
      defaultrole.nextSibling.style.cssText = "";
      defaultrole.parentNode.style.cssText = "";

      count += 1;
      if (count === 1) {
        const defaultrole = document.getElementsByClassName("defaultrole")[0];
        defaultrole.nextSibling.style.cssText = "";
        defaultrole.parentNode.style.cssText = "";
      }
      if (event.target.nodeName === "INPUT") {
        if (event.target.nextSibling.innerHTML !== "Candidate") {
          document.getElementById("linkedln").style.cssText = "display:none";
          document.getElementById("btwlabel1").style.cssText = "display:none";
        } else {
          document.getElementById("linkedln").style.cssText = "display:block";
          document.getElementById("btwlabel1").style.cssText = "display:block";
        }
        let background;
        if (event.target.value === "client")
          background = event.target.parentNode;
        else background = event.currentTarget.children[0];
        background.style.cssText = "background-color:#3F9AE0";
        event.target.nextSibling.style.cssText = "color:#FFFFFF";
        setLoginRole({ bg: background, txt: event.target.nextSibling });
      } else if (event.target.nodeName === "SPAN") {
        event.target.children[0].checked = true;
        if (event.target.innerText !== "Candidate") {
          document.getElementById("linkedln").style.cssText = "display:none";
          document.getElementById("btwlabel1").style.cssText = "display:none";
        } else {
          document.getElementById("linkedln").style.cssText = "display:block";
          document.getElementById("btwlabel1").style.cssText = "display:block";
        }

        event.target.style.cssText = "background-color:#3F9AE0";
        event.target.children[1].style.cssText = "color:#FFFFFF";
        setLoginRole({ bg: event.target, txt: event.target.children[1] });
      } else if (event.target.nodeName === "LABEL") {
        event.target.previousSibling.checked = true;
        if (event.target.textContent !== "Candidate") {
          document.getElementById("linkedln").style.cssText = "display:none";
          document.getElementById("btwlabel1").style.cssText = "display:none";
        } else {
          document.getElementById("linkedln").style.cssText = "display:block";
          document.getElementById("btwlabel1").style.cssText = "display:block";
        }

        event.target.parentNode.style.cssText = "background-color:#3F9AE0";
        event.target.style.cssText = "color:#FFFFFF";
        setLoginRole({ bg: event.target.parentNode, txt: event.target });
      }
      if (loginrole) {
        loginrole.bg.style.cssText = "";
        loginrole.txt.style.cssText = "";
      }
    };

    const selectdefaultrole = async (event) => {
      const defaultrole = document.getElementsByClassName("defaultrole")[0];
      defaultrole.checked = true;
      defaultrole.nextSibling.style.cssText = "color:#FFFFFF";
      defaultrole.parentNode.style.cssText = "background-color:#3F9AE0";

      // verifyemail start
      const errmsg = document.getElementsByClassName("errormsg")[0];
      const id = param.id;
      if (id) {
        const response = await verifyEmail(id);
        const status = response.status;
        if (status === 200) {
          const msg = await response.json();
          errmsg.innerText = msg.message;
          errmsg.style.cssText = "display:inline;color:green";
        }
      }
      // verifyemail ends
    };

    async function submitUser(userData) {
      // submit form for user creation if all validation meet requirement

      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
      }

      const form = formRef.current;
      let opt = form.opt.value;
      let role =
        opt.toUpperCase() === "FREELANCE"
          ? "FREELANCE_RECRUITER"
          : opt.toUpperCase();
      try {
        document.getElementById("loader").style.display = "";
        const response = await loginUser({
          role: role,
          email: form.email.value,
          password: form.password.value,
        });

        const status = response.status;

        try {
          sessionStorage.setItem("token", "");
          sessionStorage.setItem("clientnewuser", "");
          dispatch({ type: "settoken", value: "" });

          if (status === 200) {
            let data = await response.json();
            data = data.payload;
            const token = data.token;
            const socket = io.connect(SOCKET_URL, {
              auth: {
                userId: decode(token).uuid,
              },
            });
            sessionStorage.setItem("token", token);
            dispatch({ type: "settoken", value: token });
            dispatch({ type: "setsocket", value: socket });

            try {
              const response = await getUserInfoForRegisterPage(token);
              if (response.status === 200) {
                const data = await response.json(token);
                if (data.payload.isRegistered) {
                  dispatch({ type: "newuserfalse" });
                  history.push("/candidate/dashboardview");
                } else {
                  history.push("/candidate/dashboard/register");
                }
              }
            } catch {}
            try {
              const response = await getDetails(token);
              const status = response.status;
              let data = await response.json();
              data = data.payload;
              if (status === 200) {
                if (data.isRegistered) {
                  dispatch({ type: "newuserfalse" });
                  history.push("/client/dashboardview");
                } else {
                  history.push("/client/dashboard/register");
                }
              }
            } catch {}
            try {
              const response = await recgetDetails(token);
              const status = response.status;
              let data = await response.json();
              data = data.payload;
              if (status === 200) {
                if (data.isRegistered) {
                  dispatch({ type: "newuserfalse" });
                  dispatch({ type: "recruiterCandidatetrue" });
                  dispatch({ type: "recruitertrue" });
                  history.push("/candidate/dashboardview");
                } else {
                  history.push("/recruiter/dashboard/register");
                }
              }
            } catch {}
            try {
              const response = await freegetDetails(token);
              const status = response.status;
              let data = await response.json();
              data = data.payload;
              if (status === 200) {
                if (data.isRegistered) {
                  dispatch({ type: "newuserfalse" });
                  dispatch({ type: "freelancetrue" });
                  dispatch({ type: "recruitertrue" });
                  history.push("/candidate/dashboardview");
                } else {
                  history.push("/freelance/dashboard/register");
                }
              }
            } catch {}
          }
        } catch {}
        document.getElementById("loader").style.display = "none";
        btnRef.current.removeAttribute("disabled");
        const data = await response.json();
        const errmsg = document.getElementsByClassName("errormsg")[0];
        errmsg.innerText = data.message;
        errmsg.style.cssText = "display:inline";
      } catch {}
    }

    return (
      <div
        className="main-container"
        style={{ height: "100vh" }}
        onLoad={selectdefaultrole}
      >
        <div className="col-md-12 h-100">
          <div className="row h-100">
            <div className="col-md-7" style={{ background: "#F7F7F7" }}>
              <div className="row pt-5">
                <div className="col-md pt-5">
                  <div className={"pt-5 mt-3 pb-5"}>
                    <img
                      src="/icon/common/sulopa.png"
                      className="img-fluid mx-auto d-block pt-5 mt-5 pb-5"
                      alt="job-portal"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5" style={{ background: "#FFFFFF" }}>
              <form
                className="form-group"
                onSubmit={handleSubmit(submitUser)}
                ref={formRef}
                method={"POST"}
              >
                <div className={"mt-3"}>
                  <div className="row logincontent">
                    <div className="col-xl-2 loginI">
                      <label
                        style={{
                          fontWeight: "500",
                          fontSize: "26px",
                          color: "#000000",
                          fontFamily: "poppins",
                        }}
                        className={"ml-3"}
                      >
                        I am a
                      </label>
                    </div>
                    <div
                      className="col-xl-4 radiowrapper1 signupcontentchild"
                      onClick={changebgtxt}
                      style={{
                        pointerEvents: `${
                          candidateChecked !== null && candidateChecked.checked
                            ? "none"
                            : "auto"
                        }`,
                      }}
                    >
                      <span className="p-2 form-inline roletype" id="span1">
                        <input
                          type="radio"
                          className="loginradio defaultrole"
                          id="customtadio"
                          value="candidate"
                          name="opt"
                        />
                        <label
                          className="radiotext"
                          htmlFor="customRadio"
                          onClick={changebgtxt}
                        >
                          Candidate
                        </label>
                      </span>
                    </div>
                    <div
                      className="col-xl-4 signupcontentchild"
                      onClick={changebgtxt}
                      style={{
                        pointerEvents: `${
                          clientChecked !== null && clientChecked.checked
                            ? "none"
                            : "auto"
                        }`,
                      }}
                    >
                      <div className={"loginclient"}>
                        <span className="p-2  form-inline roletype">
                          <input
                            type="radio"
                            className="loginradio"
                            id="customtadio0"
                            value="client"
                            name="opt"
                          />
                          <label
                            className="radiotext"
                            htmlFor="customRadio0"
                            onClick={changebgtxt}
                          >
                            Client
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-2 pl-5 ml-5">
                  <div
                    className="col-xl-4 radiowrapper loginrecruiter"
                    onClick={changebgtxt}
                    style={{
                      pointerEvents: `${
                        recChecked !== null && recChecked.checked
                          ? "none"
                          : "auto"
                      }`,
                    }}
                  >
                    <span className="p-2 form-inline roletype">
                      <input
                        type="radio"
                        className="loginradio"
                        id="customtadio1"
                        value="recruiter"
                        name="opt"
                      />
                      <label
                        className="radiotext"
                        htmlFor="customRadio1"
                        onClick={changebgtxt}
                      >
                        Recruiter
                      </label>
                    </span>
                  </div>
                  <div
                    className="col-xl-5 radiowrapper freelance"
                    onClick={changebgtxt}
                    style={{
                      pointerEvents: `${
                        freeChecked !== null && freeChecked.checked
                          ? "none"
                          : "auto"
                      }`,
                    }}
                  >
                    <span className="p-2 form-inline roletype freelance">
                      <input
                        type="radio"
                        className="loginradio"
                        id="customtadio2"
                        value="freelance"
                        name="opt"
                      />
                      <label
                        className="radiotext recruiter"
                        htmlFor="customRadio2"
                        onClick={changebgtxt}
                      >
                        Freelance Recruiter
                      </label>
                    </span>
                  </div>
                </div>
                <div className="row mx-4">
                  <div className="col-md">
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "28px",
                        color: "#11142D",
                        marginTop: "60px",
                        fontFamily: "Mulish",
                      }}
                    >
                      Log in
                    </label>
                  </div>
                </div>
                <div className="row mx-4 my-3">
                  <div className="col-xl-10">
                    <input
                      type={"email"}
                      placeholder={"Registered Email ID"}
                      id={"email"}
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Please enter a valid email ID",
                        },
                        pattern: {
                          value:
                            /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                          message: "Please enter a valid email ID",
                        },
                        onChange: () => {
                          document.getElementsByClassName(
                            "errormsg"
                          )[0].style.cssText = "display:none";
                        },
                      })}
                      className={
                        "form-control signupforminputfield shadow-none my-2"
                      }
                      name={"email"}
                    />
                    {errors.email && (
                      <font
                        className={"dashboardforminputtext text-danger"}
                        style={{ fontWeight: "600" }}
                      >
                        {errors.email.message}
                      </font>
                    )}
                  </div>
                </div>
                <div className="row mx-4">
                  <div className="col-xl-10">
                    <div>
                      <i
                        className={
                          passwordShown ? "fas fa-eye-slash" : "fa fa-eye"
                        }
                        onClick={togglePassword}
                        style={{
                          position: "absolute",
                          right: "27px",
                          top: "20px",
                          color: "#DADADA",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      />
                      <input
                        type={passwordShown ? "text" : "password"}
                        placeholder={"Password"}
                        name={"password"}
                        {...register("password", {
                          required: {
                            value: true,
                            message: "Please enter the password",
                          },
                          // minLength: {
                          //   value: 8,
                          //   message: "Password Must Have At Least 8 Character",
                          // },
                          // pattern: {
                          //   value:
                          //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/,
                          //   message: "Password Is Invalid",
                          // },
                          onChange: () => {
                            document.getElementsByClassName(
                              "errormsg"
                            )[0].style.cssText = "display:none";
                          },
                        })}
                        className={
                          "form-control forminputfield shadow-none pr-5"
                        }
                      />
                    </div>
                    {errors.password && (
                      <font
                        className={"dashboardforminputtext text-danger"}
                        style={{ fontWeight: "600" }}
                      >
                        {errors.password.message}
                      </font>
                    )}
                  </div>
                </div>
                <div className="row mr-3">
                  <div className="col pr-5 mr-5">
                    <button className="btn float-right">
                      <Link
                        to="/user/forgot"
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: "Mulish",
                          color: "#9A9AB0",
                          textDecoration: "none",
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </button>
                  </div>
                </div>
                <div className="row pt-4 mx-4">
                  <div className="col-md-10">
                    <div className={"errormsg pr-5"}></div>
                  </div>
                </div>
                <div className="row mx-4">
                  <div className="col-xl-10">
                    <button
                      type={"submit"}
                      ref={btnRef}
                      className={"btnsign"}
                      id={"divbtndiv"}
                      onClick={handleSubmit(submitUser)}
                    >
                      <i
                        className="fa fa-refresh fa-spin"
                        aria-hidden="true"
                        id={"loader"}
                        style={{ display: "none", padding: "5px 15px" }}
                      ></i>
                      Log In
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl">
                    <label className={"text1"} id={"btwlabel1"}>
                      Or
                    </label>
                  </div>
                </div>
                <div className="row  mx-4">
                  <div className="col-xl-10">
                    <button
                      type={"button"}
                      className="btn btnlinkedln"
                      id={"linkedln"}
                      onClick={linkedInLogin}
                    >
                      Log in with Linkedln
                    </button>
                  </div>
                </div>
                <div className="row mx-5 pb-0 pt-3">
                  <div className=" pr-0 pl-4">
                    <div
                      className={"pl-5 pr-0"}
                      style={{
                        fontFamily: "Mulish",
                        fontWeight: "normal",
                        fontSize: "16px",
                        color: "#9A9AB0",
                      }}
                    >
                      Doesn't have an account?
                    </div>
                  </div>
                  <div className=" pl-0">
                    <div className={"pl-2"}>
                      <Link
                        to="/user/register"
                        style={{
                          textDecoration: "none",
                          color: "#2E2E2E",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: "Mulish",
                        }}
                      >
                        Register
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Route path={["/user/login/:id", "/user/login"]}>
        <LoginHome />
      </Route>
      <Route path={"/user/forgot"}>
        <ForgotPassword />
      </Route>
      <Route path={"/user/reset/:token"}>
        <ResetPassword />
      </Route>
      <Route path={"/user/register"}>
        <Signup />
      </Route>
      <Route path={"/user/success"}>
        <Register />
      </Route>
    </React.Fragment>
  );
};

export default React.memo(Login);
