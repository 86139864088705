import {
  CLIENTDASHBOARDREQUIREMENT,
  CLIENTACTIVEJOBS,
  ARCHIVEREQUIREMENTS,
} from "../ENVIRONMENT";
// api for client requirement sorting
async function requirementSorting(token, currentPage, name, order) {
  const response = await fetch(
    CLIENTDASHBOARDREQUIREMENT +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for client requirement card sorting
async function requirementCardSorting(token, currentPage, name, order, card) {
  const response = await fetch(
    CLIENTDASHBOARDREQUIREMENT +
      `?limit=6&page=${currentPage}&${name}=${order}&card=${card}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for client active job sorting
async function activeJobSorting(token, currentPage, name, order) {
  const response = await fetch(
    CLIENTACTIVEJOBS + `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function archiveJobSorting(token, currentPage, name, order) {
  // api for getClient details
  const response = await fetch(
    ARCHIVEREQUIREMENTS + `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  requirementSorting,
  activeJobSorting,
  archiveJobSorting,
  requirementCardSorting,
};
