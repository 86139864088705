import { CANDIDATEFINISH } from "../ENVIRONMENT";

async function snapshot(token) {
  // api for submit candidate snapshot
  return await fetch(CANDIDATEFINISH, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
       "Authorization": `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    });
}

export { snapshot };
