import { RECRUITERCANDIDATESECONDARY } from "../../ENVIRONMENT";

// api for recruiter secondary list
function recsecondaryList(token) {
  const response = fetch(RECRUITERCANDIDATESECONDARY, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { recsecondaryList };
