import { CLIENTMATCHINGJOBS } from "../ENVIRONMENT";

// api for client application filter
async function applicationFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page
) {
  const response = await fetch(
    CLIENTMATCHINGJOBS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function singleRequirementFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page,
  id
) {
  const response = await fetch(
    CLIENTMATCHINGJOBS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}&id=${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function singleRequirementStatusFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page,
  id,
  card
) {
  const response = await fetch(
    CLIENTMATCHINGJOBS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}&id=${id}&card=${card}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function statusFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page,
  card
) {
  const response = await fetch(
    CLIENTMATCHINGJOBS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}&card=${card}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  applicationFilter,
  singleRequirementFilter,
  singleRequirementStatusFilter,
  statusFilter,
};
