import { RECRUITERCANDIDATEAPPLICATION } from "../../ENVIRONMENT";

// api for recruiter application filter
async function recapplicationFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page
) {
  const response = await fetch(
    RECRUITERCANDIDATEAPPLICATION +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { recapplicationFilter };
