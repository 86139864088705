import React, { useState, useEffect, useRef } from "react";
import { toTitleCase } from "../../../../../function/common/convertToTitleCase"; 
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";


const RequirementListData = (props) => {
  const [loading, setLoading] = useState();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let cardName = query.get("title");
  const ref = useRef();

  const [match, setMatch] = useState([
    {
      ClientRequirementId: "DS21222K3351",
      createdAt: "2022-11-21T06:43:32.000Z",
      hiringType: "internal hiring",
      jobTitle: "java developer",
      minDuration: "6",
      source: "client",
      rate: "455",
      workType: "WORK_REMOTELY",
      location: "Texas",
    },
    {
        ClientRequirementId: "DS21222K3351",
        createdAt: "2022-11-21T06:43:32.000Z",
        hiringType: "internal hiring",
        jobTitle: "java developer",
        minDuration: "6",
        source: "client",
        rate: "455",
        workType: "WORK_REMOTELY",
        location: "Texas",
      },
      {
        ClientRequirementId: "DS21222K3351",
        createdAt: "2022-11-21T06:43:32.000Z",
        hiringType: "internal hiring",
        jobTitle: "java developer",
        minDuration: "6",
        source: "client",
        rate: "455",
        workType: "WORK_REMOTELY",
        location: "Texas",
      },
      {
        ClientRequirementId: "DS21222K3351",
        createdAt: "2022-11-21T06:43:32.000Z",
        hiringType: "internal hiring",
        jobTitle: "java developer",
        minDuration: "6",
        source: "client",
        rate: "455",
        workType: "WORK_REMOTELY",
        location: "Texas",
      },
      {
        ClientRequirementId: "DS21222K3351",
        createdAt: "2022-11-21T06:43:32.000Z",
        hiringType: "internal hiring",
        jobTitle: "java developer",
        minDuration: "6",
        source: "client",
        rate: "455",
        workType: "WORK_REMOTELY",
        location: "Texas",
      },
      {
        ClientRequirementId: "DS21222K3351",
        createdAt: "2022-11-21T06:43:32.000Z",
        hiringType: "internal hiring",
        jobTitle: "java developer",
        minDuration: "6",
        source: "client",
        rate: "455",
        workType: "WORK_REMOTELY",
        location: "Texas",
      }
  ]);

  //   useEffect(() => {
  //     try {
  //       setLoading(true);
  //       if (props.item.length !== 0) {
  //         setMatch(props.item);
  //         setLoading(false);
  //       }
  //       if (props.record === 0) {
  //         setLoading(false);
  //       }
  //     } catch {}
  //   }, [props.item]);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const dispatch = useDispatch();

  return (
    // return matching job table data group which generated by data variable
    <>
      {match.map((match) => (
        <div
          className={"row pl-4"}
          style={{
            boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
            backgroundColor: "white",
            marginTop: "1px",
          }}
          key={match.ClientRequirementId}
        >
          {/* <input type={"checkbox"} className={"checkbx  mr-4 mt-4"}/> */}
          <div className={"col py-3 box"}>
            {/*heading box data*/}
            <div className={"row  align-items-center pl-3"}>
              <div className={"col-2 pl-4"}>
                <Link
                  to={"/admin/client/requirementDetails"}
                  className={"matchingjobdata text-primary"}
                >
                  {match.ClientRequirementId && match.ClientRequirementId}
                </Link>
                <br />
                <font className="matchingjobdata">
                  {new Date(match.createdAt).toLocaleDateString(
                    "en-US",
                    options
                  )}
                </font>
                <br />
                <font className={"matchingjobdata"}>
                  {match.hiringType && match.hiringType.substring(0, 8)}
                </font>
              </div>
              <div className={"col-md-2"}>
                <div className="row">
                  <div
                    className="col-md"
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                      textAlign: "center",
                    }}
                  >
                    <label
                        className={"matchingjobdata text-primary"}
                        //onClick={() => clientRedirect(dispatch)}
                      >
                        {match.jobTitle}
                      </label>
                   </div>
                </div>
                {/* <div
                  className="row"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {cardName === null && (
                    <Link
                      to={`/client/filteredapplications?id=${match.uuid}`}
                      className={"matchingjobdata text-primary"}
                      onClick={() => clientRedirect(dispatch)}
                    >
                      No Of Position {match.numberOfPosition}
                    </Link>
                  )}
                  {cardName === "total" && (
                    <Link
                      to={`/client/filteredapplications?id=${match.uuid}`}
                      className={"matchingjobdata text-primary"}
                      onClick={() => clientRedirect(dispatch)}
                    >
                      No Of Position {match.numberOfPosition}
                    </Link>
                  )}
                  {cardName === "archive" && (
                    <Link
                      to={`/client/filteredapplications?id=${match.uuid}`}
                      className={"matchingjobdata text-primary"}
                      onClick={() => clientArchiveRedirect(dispatch)}
                    >
                      No Of Position {match.numberOfPosition}
                    </Link>
                  )}
                  {cardName === "active" && (
                    <Link
                      to={`/client/filteredapplications?id=${match.uuid}`}
                      className={"matchingjobdata text-primary"}
                      onClick={() => clientRedirect(dispatch)}
                    >
                      No Of Position {match.numberOfPosition}
                    </Link>
                  )}
                  {cardName === "scheduled" && (
                    <Link
                      to={`/client/filteredapplications?id=${match.uuid}&card=scheduled`}
                      className={"matchingjobdata text-primary"}
                      onClick={() => clientRedirect(dispatch)}
                    >
                      Scheduled interviews {match.scheduledCount}
                    </Link>
                  )}
                  {cardName === "shortlisted" && (
                    <Link
                      to={`/client/filteredapplications?id=${match.uuid}&card=shortlisted`}
                      className={"matchingjobdata text-primary"}
                      onClick={() => clientRedirect(dispatch)}
                    >
                      Shortlisted Candidates {match.shortlistedCount}
                    </Link>
                  )}
                  {cardName === "waiting" && (
                    <label className={"matchingjobdata text-primary"}>
                      Waiting Candidates
                    </label>
                  )}
                  {cardName === "offer" && (
                    <Link
                      to={`/client/filteredapplications?id=${match.uuid}&card=offer`}
                      className={"matchingjobdata text-primary"}
                    >
                      Offer Roleout {match.offerCount}
                    </Link>
                  )}
                </div> */}
              </div>
              <div className="col-2">
                <div className={"row pl-4"}>
                  <div className={"col ml-1"}>
                    <div className={"row"}>
                      <div className={"col-md"}>
                        <font className={"matchingjobdata"}>
                          {match.minDuration} month
                        </font>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div
                        className="col-md"
                        style={{
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <font className={"matchingjobdata"}>
                          {match.location}
                        </font>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-1 pl-1"}>
                <div
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <font className={"matchingjobdata"}>${match.rate}</font>
                </div>
              </div>
              {/* <div className={"col-1 pl-0 pr-0"}>
                <font className={"matchingjobdata"}>
                  {match.shortlistedCount}
                </font>
              </div> */}
              <div className="col-2 pl-0">
                <font className={"matchingjobdata"}>
                  {match.workType &&
                    toTitleCase(match.workType.replace(/_/g, " "))}
                </font>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export { RequirementListData };
