import { GETCLIENTBUILDREQUIREMENT } from "../ENVIRONMENT";

// api for client requirement information
async function getRequirementInfo(token, id) {
  const response = await fetch(GETCLIENTBUILDREQUIREMENT + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { getRequirementInfo };
