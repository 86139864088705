import { CLIENTMATCHINGJOBS } from "../ENVIRONMENT";

// api for client application filter
async function filterApplication(token, data, currentPage) {
  const response = await fetch(
    CLIENTMATCHINGJOBS + `?id=${data}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for client application scheduledcandidate filter
async function filterScheduledCandidates(token, data, cardName, currentPage) {
  const response = await fetch(
    CLIENTMATCHINGJOBS +
      `?id=${data}&card=${cardName}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function filterStatusDropList(token, cardName, currentPage) {
  // api for getClient details
  const response = await fetch(
    CLIENTMATCHINGJOBS + `?card=${cardName}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { filterApplication, filterScheduledCandidates, filterStatusDropList };
