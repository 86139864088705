import React, { useState,useEffect } from 'react';
import {InlineWidget} from "react-calendly";
import { CALENDLYAPITOKEN } from '../../api/ENVIRONMENT';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { interviewSchedule } from '../../api/client/interviewSchedule';
import { recinterviewSchedule } from '../../api/recruiter/client/interviewSchedule';
import { freeinterviewSchedule } from '../../api/freelance/client/interviewSchedule';

const isCalendlyScheduledEvent = (e) => {
    return e.data.event &&
           e.data.event.indexOf('calendly') === 0 &&
           e.data.event === 'calendly.event_scheduled'
  }

async function interviewInvitee(url,tokenID) {
      // api for get requiremetInfo details
      const response = await fetch(url,{
          
          headers: { 'Content-Type': 'application/json',
          'Authorization':`Bearer ${tokenID}` },
      
      })
      return response;
  }
const Interview = () =>{
const param = useParams();
const id=param.id;
const uuid = param.uuid;
const token = useSelector((state) => state.token);
const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
const isFreelance = useSelector((state) => state.isFreelance);

useEffect(() => {
    window.addEventListener(
      'message',
      async(e) => {
        if (isCalendlyScheduledEvent(e)) {
            let eventURL = e.data.payload.event.uri;
            const response = await interviewInvitee(eventURL,CALENDLYAPITOKEN)
            if(response.status===200){
                const data = await response.json();
                const payload = data.resource;
                let scheduleData={
                    matchingJobsUUID : uuid,
                    scheduleTime : payload.start_time
                }
                isrecruiterCandidate ? recinterviewSchedule(token,scheduleData)
                :
                isFreelance ? freeinterviewSchedule(token,scheduleData)
                :
                interviewSchedule(token,scheduleData)
            }
        }
      }
    )
  }, [])
    return(
        <div className={"col-md mx-5 my-3 px-5"}>
        {/* <InlineWidget url="https://calendly.com/testsulopa-1/30min"/> */}
        <InlineWidget url={`https://calendly.com/dsinterviews/30min?name=Dollar%20Staffing&email=info@dollarstaffing.com`} 
            styles={{height: '700px'}}
            />
        </div>
    )
}

export default React.memo(Interview);

