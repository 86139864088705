import { REGISTERCANDIDATE } from "../ENVIRONMENT.js";

async function getProfileDetails(token) {
  // api for candidate profile details
  const stoken = sessionStorage.getItem("token");
  const response = await fetch(REGISTERCANDIDATE, {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${stoken ? stoken : token}`,
    },
  });
  return response;
}
export { getProfileDetails };
