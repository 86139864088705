import { CLIENTDASHBOARDREQUIREMENT,CLIENTACTIVEJOBS,ARCHIVEREQUIREMENTS } from "../ENVIRONMENT";
// api for client dashboard requirement details
async function DashboardRequirment(token,currentPage) {
    const response = await fetch(CLIENTDASHBOARDREQUIREMENT+`?limit=6&page=${currentPage}`, {
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token ? token : sessionStorage.getItem('token')}`
        }
    })
    return response;
    
}
// api for client dashboardcard requirement
async function DashboardCardRequirment(token,currentPage,card) {
    const response = await fetch(CLIENTDASHBOARDREQUIREMENT+`?limit=6&page=${currentPage}&card=${card}`, {
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token ? token : sessionStorage.getItem('token')}`
        }
    })
    return response;
    
}

async function DashboardActiveJobs(token,currentPage) {
    // api for getClient details
    const response = await fetch(CLIENTACTIVEJOBS+`?limit=6&page=${currentPage}`, {
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token ? token : sessionStorage.getItem('token')}`
        }
    })
    return response;
    
}

async function ArchiveRequirements(token,currentPage) {
    // api for getClient details
    const response = await fetch(ARCHIVEREQUIREMENTS+`?limit=6&page=${currentPage}`, {
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token ? token : sessionStorage.getItem('token')}`
        }
    })
    return response;
    
}


export { DashboardRequirment,DashboardActiveJobs,ArchiveRequirements,DashboardCardRequirment };