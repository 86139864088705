import { SUPERADMINAUTHENTICATION_URL } from "../../ENVIRONMENT";

async function loginAdmin(userdetails) {
  // get user detail for login api
  const response = fetch(SUPERADMINAUTHENTICATION_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userdetails),
  });
  return response;
}

export { loginAdmin };
