import { FREELANCEBUILDREQUIREMENT } from "../../../ENVIRONMENT";

// api for freelance client snapshot
async function freesnapshot(token, id) {
  return await fetch(FREELANCEBUILDREQUIREMENT + `/${id}/finish`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { freesnapshot };
