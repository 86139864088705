import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clearFilter } from "../common/clearFilter";
import { selectAllData } from "../common/selectAllData";
import {
  handleRemoveShortlistIcon,
  handleShortlistIcon,
  SelectSingle,
} from "../common/SingleSelectMatchingJob";

var listData = [];

const Data = (props) => {
  // return matching job table data group which generated by data variable
  const [list, setList] = useState([]);
  //const [match,setMatch] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function callItSelf() {
      setLoading(true);
      try {
        if (props.item.length !== 0) {
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          setList(
            props.item.map((obj) => {
              return {
                uuid: obj.uuid,
                id: [
                  obj.jobRequirementId,
                  new Date(obj.createdAt).toLocaleDateString("en-US", options),
                ],
                jobRequirementUUID: obj.jobRequirementUUID,
                jobposted: obj.JobRequirement.interviewType,
                matching: [
                  obj.matchPercentage,
                  obj.JobRequirement.domain,
                  obj.JobRequirement.employementType,
                ],
                clientSource:obj.clientUUID,
                recruiterSource:obj.recruiterUuid,
                freelanceSource:obj.freelanceRecruiterUuid,
                rate: obj.JobRequirement.rate,
                position: obj.JobRequirement.jobTitle,
                Location: obj.JobRequirement.location,
                status:
                  obj.status === null
                    ? obj.applied
                      ? "Applied"
                      : "Apply"
                    : obj.status,
                shortlistData: obj.candidateShortlisted,
              };
            })
          );
          setLoading(false);
        }
        if (props.record === 0) {
          setLoading(false);
        }
      } catch {}
    }
    callItSelf();
    listData = list;
  }, [props.item]);

  return (
    <>
      {loading ? (
        <div
          className="row h-100"
          style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
        >
          <div className="col-sm-12 my-auto">
            <img
              src={"/icon/Gif/loader.gif"}
              className={"mx-auto d-block"}
              alt={"loader"}
            />
          </div>
        </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {list.map((data, index) => (
                <div
                  key={index}
                  className={"row pl-3"}
                  style={{
                    boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                    backgroundColor: "white",
                    marginTop: "1px",
                  }}
                >
                 <div>
                    {data.shortlistData ? (
                      <>
                        <label
                          style={{ color: "#FF8A00", fontSize: "22px" }}
                          className={"mt-3"}
                          onClick={(e) => {
                            handleShortlistIcon(e);
                            props.handleremoveSingleShortlist(data.uuid);
                          }}
                        >
                          &#9733;
                        </label>
                        <label
                          style={{
                            color: "black",
                            fontSize: "24px",
                            fontWeight: "500",
                            display: "none",
                          }}
                          className={"mt-3"}
                          onClick={(e) => {
                            handleRemoveShortlistIcon(e);
                            props.handleaddSingleShortlist(data.uuid);
                          }}
                        >
                          &#9734;
                        </label>
                      </>
                    ) : (
                      <>
                      <label
                          style={{
                            color: "#FF8A00",
                            fontSize: "22px",
                            display: "none",
                          }}
                          className={"mt-3"}
                          onClick={(e) => {
                            handleShortlistIcon(e)
                            props.handleremoveSingleShortlist(data.uuid);
                          }}
                        >
                          &#9733;
                        </label>
                        <label
                          style={{
                            color: "black",
                            fontSize: "24px",
                            fontWeight: "500",
                          }}
                          className={"mt-3"}
                          onClick={(e) => {
                            handleRemoveShortlistIcon(e);
                            props.handleaddSingleShortlist(data.uuid);
                          }}
                        >
                          &#9734;
                        </label>
                      </>
                    )}
                  </div>
                  <input
                    type={"checkbox"}
                    className={"checkbx checkbxsingle mt-4 mr-4 ml-3"}
                    onClick={(e) => {
                      {
                        SelectSingle(e);
                        props.handleShortlist(e, data.shortlistData);
                      }
                    }}
                    value={data.uuid}
                  />
                  <div className={"col py-3 box"}>
                    {/*heading box data*/}
                    <div className={"row align-items-center"}>
                      <div className={"col-1 pl-0 pr-0"}>
                        {data.status !== null && data.status !== "Apply" ? (
                          <Link
                            to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                            className={"matchingjobdata text-primary"}
                          >
                            {data.id[0]}
                          </Link>
                        ) : (
                          <Link
                            to={`/candidate/jobapply/${data.uuid}`}
                            className={"matchingjobdata text-primary"}
                          >
                            {data.id[0]}
                          </Link>
                        )}
                        <br />
                        <font className={"matchingjobdata"}>{data.id[1]}</font>
                      </div>
                      <div className={"col-md-2"}>
                        <div
                          className="ml-4"
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {data.status !== null && data.status !== "Apply" ? (
                            <Link
                              to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                              className={"matchingjobdata text-primary"}
                            >
                              {data.position}
                            </Link>
                          ) : (
                            <Link
                              to={`/candidate/jobapply/${data.uuid}`}
                              className={"matchingjobdata text-primary"}
                            >
                              {data.position}
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className={"row"}>
                          <div className="col-5">
                            <div
                              className={"badge py-3"}
                              style={{
                                backgroundColor: "#D3D3D3",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {data.matching[0]} %
                            </div>
                          </div>
                          <div className="col-7 pl-0">
                            <div className={"row"}>
                              <div
                                className="col-md"
                                style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                <font
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  {data.matching[1]}
                                </font>
                              </div>
                            </div>
                            <div className={"row"}>
                              <div className={"col-md"}>
                                <font
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                  }}
                                >
                                  {data.matching[2]}
                                </font>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-1">
                        <font className={"matchingjobdata"}>{data.clientSource ? "client" : data.freelanceSource ? "Freelance" : data.recruiterSource ? "Recruiter"  :""}</font>
                      </div>
                      <div className={"col-1 pl-4"}>
                        <font className={"matchingjobdata"}>${data.rate}</font>
                      </div>
                      <div className={"col-1"}>
                        <font className={"matchingjobdata ml-4"}>
                          {data.jobposted}
                        </font>
                      </div>
                      <div className="col-2 pl-0">
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                          className={"ml-5 pl-5"}
                        >
                          <font className={"matchingjobdata"}>
                            {data.Location}
                          </font>
                        </div>
                      </div>
                      <div className={"col-1 ml-3"}>
                        {data.status !== null && data.status !== "Apply" ? (
                          <Link
                            to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                            className={"btn bg-success"}
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              width: "100px",
                              borderRadius: "10px",
                              color: "white",
                            }}
                          >
                            {data.status}
                          </Link>
                        ) : (
                          <Link
                            to={`/candidate/jobapply/${data.uuid}`}
                            className={"btn bg-success"}
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              width: "100px",
                              borderRadius: "10px",
                              color: "white",
                            }}
                          >
                            {data.status}
                          </Link>
                        )}
                      </div>
                      {/* <div className={"col pl-5 ml-3"}>
            <div className={"row pl-4"}>
              <div className={"col-5"}>
              <div className="ml-3" style={{ wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
                <font className={"matchingjobdata"}>{data.Location}zuxcujzuihsuhxujhujxsxdijdsijksdujksd</font>
                </div>
              </div>
              <div className={"col ml-1"}>
                <div className={"row align-items-center"}>
                  {
                    data.status !== null && data.status !== "Apply"
                    ?
                    <Link
                    to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                    className={"btn bg-success"}
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      width: "100px",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    {data.status}
                  </Link>
                  :
                  <Link
                  to={`/candidate/jobapply/${data.uuid}`}
                  className={"btn bg-success"}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    width: "100px",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  {data.status}
                </Link>
                  }
                </div>
              </div>
              </div>
          </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Data Found
            </div>
          )}
        </>
      )}
    </>
  );
};
const data = listData;
export { Data, data, selectAllData, clearFilter };
