// let data = [
//     { "Select": ["Select"] },
//     { "US_CITIZEN": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly", "1099"] },
//     { "GREEN_CARD": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly", "1099"] },
//     { "GC_EAD": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly", "1099"] },
//     { "H1B": ["C2C", "C2H"] },
//     { "H4_EAD": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly", "1099"] },
//     { "OPT": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly"] },
//     { "OPT_STEM": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly"] },
//     { "CPT": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly"] },
//     { "TN_VISA": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly", "1099"] },
//     { "TN_EAD": ["Full_Time", "C2H", "C2C", "W2", "W2_Hourly", "1099"] },
//     { "E_3": ["W2", "C2C"] },
//     { "APPROVED_I_140": ["C2C", "C2H"] }
// ];
// export { data }
let data = [
    { "Select": ["Select"] },
    { "US_CITIZEN": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C","N1099With","N1099Without"] },
    { "GREEN_CARD": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C","N1099With","N1099Without"] },
    { "GC_EAD": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C","N1099With","N1099Without"] },
    { "H1B": ["C2C", "C2H"] },
    { "H4_EAD": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C","N1099With","N1099Without"] },
    { "OPT": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C"] },
    { "OPT_STEM": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C"] },
    { "CPT": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C"] },
    { "TN_VISA": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C","N1099With","N1099Without"] },
    { "TN_EAD": ["W2With","W2Without","W2HourlyWith","W2HourlyWithout","C2H","C2C","N1099With","N1099Without"] },
    { "E_3": ["W2With","W2Without", "C2C"] },
    { "APPROVED_I_140": ["C2C", "C2H"] }
];
export { data }
