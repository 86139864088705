import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import GoToTop from "../../common/GoToTop";
import { Link, useHistory } from "react-router-dom";
import {
  postBuildResumeSocial,
  getBuildResumeSocial,
} from "../../../api/recruiter/candidate/buildResume/buildResumeSocial";
import {
  freepostBuildResumeSocial,
  freegetBuildResumeSocial,
} from "../../../api/freelance/candidate/buildResume/buildResumeSocial";
import { useParams } from "react-router-dom";

const BuildResume5 = (props) => {
  const history = useHistory();
  const socialRef = useRef();
  const [loader, setLoader] = useState(false);
  const [socialLink, setSocialLink] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pintrest: "",
    youtube: "",
    skype: "",
    website: "",
  });
  const [errList, setErrList] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pintrest: "",
    youtube: "",
    skype: "",
    website: "",
  });
  const token = useSelector((state) => state.token);
  const isFreelance = useSelector((state) => state.isFreelance);
  const param = useParams();
  const id = param.id;
  async function submitData(event) {
    try {
      event.preventDefault();
      if (socialRef.current) {
        socialRef.current.setAttribute("disabled", "disabled");
      }setLoader(true);
      Object.keys(socialLink).map((key) => {
        if (!socialLink[key]) delete socialLink[key];
      });
      if (isFreelance) {
        const response = await freepostBuildResumeSocial(token, socialLink, id);
        if (response.status === 200) {
          history.push(`${props.link[6]}/${id}`);
        } else if (response.status === 422) {
          socialRef.current.removeAttribute("disabled");
          setLoader(false);
          const data = await response.json();
          const payload = data.payload;
          const error = {
            facebook: "",
            twitter: "",
            linkedin: "",
            pintrest: "",
            youtube: "",
            skype: "",
            website: "",
          };

          if (payload.hasOwnProperty("twitter")) {
            error.twitter = payload.twitter.message;
          }
          if (payload.hasOwnProperty("facebook")) {
            error.facebook = payload.facebook.message;
          }
          if (payload.hasOwnProperty("linkedin")) {
            error.linkedin = payload.linkedin.message;
          }
          if (payload.hasOwnProperty("pintrest")) {
            error.pintrest = payload.pintrest.message;
          }
          if (payload.hasOwnProperty("skype")) {
            error.skype = payload.skype.message;
          }
          if (payload.hasOwnProperty("youtube")) {
            error.youtube = payload.youtube.message;
          }
          if (payload.hasOwnProperty("website")) {
            error.website = payload.website.message;
          }
          setErrList(error);
        }
      } else {
        const response = await postBuildResumeSocial(token, socialLink, id);
        if (response.status === 200) {
          history.push(`${props.link[6]}/${id}`);
        } else if (response.status === 422) {
          socialRef.current.removeAttribute("disabled");
          setLoader(false);
          const data = await response.json();
          const payload = data.payload;
          const error = {
            facebook: "",
            twitter: "",
            linkedin: "",
            pintrest: "",
            youtube: "",
            skype: "",
            website: "",
          };

          if (payload.hasOwnProperty("twitter")) {
            error.twitter = payload.twitter.message;
          }
          if (payload.hasOwnProperty("facebook")) {
            error.facebook = payload.facebook.message;
          }
          if (payload.hasOwnProperty("linkedin")) {
            error.linkedin = payload.linkedin.message;
          }
          if (payload.hasOwnProperty("pintrest")) {
            error.pintrest = payload.pintrest.message;
          }
          if (payload.hasOwnProperty("skype")) {
            error.skype = payload.skype.message;
          }
          if (payload.hasOwnProperty("youtube")) {
            error.youtube = payload.youtube.message;
          }
          if (payload.hasOwnProperty("website")) {
            error.website = payload.website.message;
          }
          setErrList(error);
        }
      }
    } catch {}
  }

  useEffect(() => {
    async function callItSelf(){
      if (isFreelance) {
        try {
          const response = await freegetBuildResumeSocial(token, id);
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload;
            if (payload[0]) setSocialLink(payload[0]);
          }
        } catch {}
      } else {
        try {
          const response = await getBuildResumeSocial(token, id);
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload;
            if (payload[0]) setSocialLink(payload[0]);
          }
        } catch {}
      }
    }
    callItSelf()
    
  }, []);

  return (
    <div className={"col"}>
      <form onSubmit={submitData}>
        <div className={"row pl-5 ml-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5"}>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className={"row mx-5 pl-5  resumealignment"}>
          <div className={"col-lg-1"}>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[2]}/${id}`}>
              <div
                className={"pl-3 pb-2 pr-3 pt-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Experience
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[3]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 "}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Academics
                  <br />& Certificate
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[4]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Security Clearance
                </label>
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[5]}/${id}`}>
              <div
                className={"pt-2 pb-0 pl-3 pr-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <div className={"row"}>
                  <div className={"col-lg pb-2"}>
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#000000",
                        fontFamily: "poppins",
                      }}
                    >
                      Social
                    </label>
                  </div>
                </div>
                <div className={"row pl-0"}>
                  <div className={"col-lg pl-1"}>
                    <div
                      style={{
                        background: "#3F9AE0",
                        width: "110%",
                        height: "4px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/*first box*/}
        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-4 pt-4 contentholder"}>
              <div className={"row pl-4"}>
                <div className={"col-md-3"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#000000",
                    }}
                  >
                    Social Link
                  </label>
                </div>
              </div>

              {/*facebook*/}
              <div className={"row pl-4"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1"}
                    style={{ background: "#FBFBFB", borderRadius: "10px" }}
                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2"}
                          style={{
                            background: "#3D6AD6",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src="/icon/candidate/resume/facebook.png"
                            alt={"facebook"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"facebook.com/userid"}
                          value={socialLink.facebook}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              facebook: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"facebookerr text-danger dashboardforminputtext"}
                  >
                    {errList.facebook}
                  </font>
                </div>
              </div>

              {/*ball */}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1"}
                    style={{ background: "#FBFBFB", borderRadius: "10px" }}
                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2"}
                          style={{
                            background: "#03A9F4",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src="/icon/candidate/resume/ball.png"
                            alt={"ball"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"twitter.com/userid"}
                          value={socialLink.twitter}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              twitter: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"twitter text-danger dashboardforminputtext"}
                  >
                    {errList.twitter}
                  </font>
                </div>
              </div>

              {/* linkedin*/}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1"}
                    style={{ background: "#FBFBFB", borderRadius: "10px" }}
                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2"}
                          style={{
                            background: "#0073B1",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src="/icon/candidate/resume/linkedin.png"
                            alt={"linkedin"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"linkedln.com/userid"}
                          value={socialLink.linkedin}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              linkedin: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"linkedin text-danger dashboardforminputtext"}
                  >
                    {errList.linkedin}
                  </font>
                </div>
              </div>
              {/*pinterest*/}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1"}
                    style={{ background: "#FBFBFB", borderRadius: "10px" }}
                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2"}
                          style={{
                            background: "#BFBFBF",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src="/icon/candidate/resume/pin.png"
                            alt={"pinterest"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"pinterest.com/userid"}
                          value={socialLink.pintrest}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              pintrest: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"pintrest text-danger dashboardforminputtext"}
                  >
                    {errList.pintrest}
                  </font>
                </div>
              </div>
              {/* youtube*/}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1"}
                    style={{ background: "#FBFBFB", borderRadius: "10px" }}
                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2"}
                          style={{
                            background: "#FF0000",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src="/icon/candidate/resume/youtube.png"
                            alt={"youtube"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"youtube.com/userid"}
                          value={socialLink.youtube}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              youtube: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"youtube text-danger dashboardforminputtext"}
                  >
                    {errList.youtube}
                  </font>
                </div>
              </div>
              {/*skype*/}
              <div className={"row pl-3 pt-3"}>
                <div className={"col-lg-5"}>
                  <div className={"row pt-0 pb-0"}>
                    <div className="col-lg-2 pt-0 pb-0">
                      <img
                        src="/icon/candidate/resume/skype.png"
                        alt={"skype"}
                        style={{
                          transform: "scale(0.7)",
                          backgroundColor: "#F9F9F9",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          paddingRight: "30px",
                        }}
                      />
                    </div>
                    <div className={"col-lg-10 skypetext pt-2"}>
                      <input
                        type={"text"}
                        className={"form-control socialinputfield shadow-none"}
                        placeholder={"skype/userid"}
                        style={{ height: "39px" }}
                        value={socialLink.skype}
                        onChange={(event) =>
                          setSocialLink({
                            ...socialLink,
                            skype: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <font className={"skype text-danger dashboardforminputtext"}>
                    {errList.skype}
                  </font>
                </div>
              </div>
              {/*www*/}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1"}
                    style={{ background: "#FBFBFB", borderRadius: "10px" }}
                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2"}
                          style={{ borderRadius: "10px" }}
                        >
                          <img
                            src="/icon/candidate/resume/www.png"
                            alt={"www"}
                            className={""}
                            style={{ transform: "scale(0.8)" }}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"www.domainname.com"}
                          value={socialLink.website}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              website: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <font
                        className={"website text-danger dashboardforminputtext"}
                      >
                        {errList.website}
                      </font>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col pt-3"}>
            <button
              type={"submit"}
              className={"btn offset-sm-9 save"}
              ref={socialRef}
            >
              {loader ? (
                <i
                  className = "fa fa-refresh fa-spin"
                  style={{ padding: "3px 5px" }}
                ></i>
              ) : (
                ""
              )}
              Snapshot
            </button>
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};
export default React.memo(BuildResume5);
