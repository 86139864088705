import React from "react";
import { ApplicationListData } from "./ApplicationListData";

const ApplicationList = () => {
  return (
    <div className="col">
      <div
        className={"row mt-3 align-items-center"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row"}>
            <div className={"col-2"} style={{textAlign: "center"}}>
              <font className={"headingfont pl-2 pr-0"}>ID</font>
              {/* <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                    handleIdSorting();
                    setIdOrder(!idOrder);
                }}
              /> */}
            </div>
            <div className={"col-2  hidebox"} style={{textAlign: "center"}}>
              <font className={"headingfont"}>Resume title</font>
            </div>
            <div className={"col-1 hidebox"}>
                <font className={"headingfont"}>SourcedBy</font>
            </div>
            <div className={"col-1  hidebox ml-4"} style={{textAlign: "center"}}>
              <font className={"headingfont"}>Matching</font>
                {/* <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  onClick={() => {
                      handleMatchSorting();
                      setMatchOrder(!matchOrder);
                  }}
                /> */}
            </div>
            <div className={"col-1 hidebox"}>
              <font className={"headingfont"}>Experience</font>
                {/* <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  onClick={() => {
                      handleExpSorting();
                      setExpOrder(!expOrder);
                  }}
                /> */}
            </div>
            <div className={"col-2  hidebox"} style={{textAlign: "center"}}>
              <font className={"headingfont"}>Rate</font>
              {/* <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                    handleRateSorting();
                    setRateOrder(!rateOrder);
                }}
              /> */}
            </div>
            <div className={"col-1 hidebox pl-0"}>
              {/* <font className={"headingfont pl-5 ml-5"}>Status</font> */}
              <select
                  className={"form-select statusdropdown px-4 headingfont"}
                  //   onChange={handleStatusFilter}
                  //   value={statusValue}
                >
                  <option value={""}>Status</option>
                  <option value={"scheduled"}>Scheduled</option>
                  <option value={"shortlisted"}>Shortlisted</option>
                  <option value={"offer"}>Offered</option>
                  <option value={"rejected"}>Rejected</option>
                </select>
              </div>
            </div>
          </div>
      </div>
      <ApplicationListData />
      <div
        className={"row align-items-center"}
        style={{
          borderRadius: "0 0 20px 20px",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
          marginTop: "1px",
        }}
      >
        <div className={"col py-2"}></div>
      </div>
    </div>
  );
};
export default React.memo(ApplicationList);
