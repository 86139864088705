import { RECRUITERBUILDREQUIREMENT } from "../../../ENVIRONMENT";

// api for submit recruiter builrequirement security
async function recpostBuildRequirementSecurity(token, data, id) {
  return await fetch(RECRUITERBUILDREQUIREMENT + `/${id}/securityclearance`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}
export { recpostBuildRequirementSecurity };
