import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import GoToTop from "../../common/GoToTop";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";
import RateCard from "../RateCard";
import SnapshotDiv from "../../../common-component/clientSnapshotDiv";
import { Redirect, useParams } from "react-router-dom";
import {
  verifyJobRequirementInfo,
  verifyJobPostingAcademic,
  verifyJobPostingSecurity,
  verifyBuildRequirementSkill,
} from "../../../function/client/checkRequired";
import {
  jobRequirementInfo,
  jobRequirementSkill,
  jobRequirementAcademic,
  jobRequirementSecurity,
} from "../../../function/client/validateField";
import { secondarySkill } from "../../../function/candidate/validateField";
import GetNoOfPosition from "../../../common-component/GetNoOfPosition";
import { MinMaxDuration } from "../../../function/client/minMaxDuration";
import { useDispatch, useSelector } from "react-redux";
import { durationData } from "../../../function/client/getDurationData";
import Multiselect from "multiselect-react-dropdown";
import { createRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState } from "draft-js";
import { getRequirementInfo } from "../../../api/client/getRequirementInfo";
import { postBuildRequirementSkill } from "../../../api/client/buildRequirementSkill";
import { setSkillData } from "../../../function/client/setSkillData";
import { postBuildRequirementAcademic } from "../../../api/client/buildRequirementAcademic";
import { postBuildRequirementSecurity } from "../../../api/client/buildRequirementSecurityClearance";
import { postBuildRequirementDescription } from "../../../api/client/buildRequirementDescription";
import {
  CreateSignedUrlAPI,
  uploadImage,
} from "../../../api/common/ImageUpload";
import { editRequirementInfo } from "../../../api/client/requirementInfo";
import { snapshot } from "../../../api/client/snapshot";
import { recgetRequirementInfo } from "../../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { freegetRequirementInfo } from "../../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { recpostBuildRequirementSkill } from "../../../api/recruiter/client/BuildRequirement/buildRequirementSkill";
import { freepostBuildRequirementSkill } from "../../../api/freelance/client/BuildRequirement/buildRequirementSkill";
import { recpostBuildRequirementAcademic } from "../../../api/recruiter/client/BuildRequirement/buildRequirementAcademic";
import { freepostBuildRequirementAcademic } from "../../../api/freelance/client/BuildRequirement/buildRequirementAcademic";
import { recpostBuildRequirementSecurity } from "../../../api/recruiter/client/BuildRequirement/buildRequirementSecurityClearance";
import { freepostBuildRequirementSecurity } from "../../../api/freelance/client/BuildRequirement/buildRequirementSecurityClearance";
import { recpostBuildRequirementDescription } from "../../../api/recruiter/client/BuildRequirement/buildRequirementDescription";
import { freepostBuildRequirementDescription } from "../../../api/freelance/client/BuildRequirement/buildRequirementDescription";
import { receditRequirementInfo } from "../../../api/recruiter/client/BuildRequirement/requirementInfo";
import { freeditRequirementInfo } from "../../../api/freelance/client/BuildRequirement/requirementInfo";
import { recsnapshot } from "../../../api/recruiter/client/BuildRequirement/snapshot";
import { freesnapshot } from "../../../api/freelance/client/BuildRequirement/snapshot";
import { homePage } from "../../../function/common/homePage";
import { freelanceHomePage } from "../../../function/common/freelanceHomepage";
import { locationListSearch } from "../../../api/common/locationList";
import GetPrimaryMonth from "../../../common-component/GetPrimaryMonth";
import GetPrimaryYear from "../../../common-component/GetPrimaryYear";
import { recgetDetails } from "../../../api/recruiter/getDetails";
import { freegetDetails } from "../../../api/freelance/getDetails";
import { getDetails } from "../../../api/client/getDetails";

const JobRequirmentPreview = () => {
  // return jobrequirement preview page
  const [redirect, setRedirect] = useState(false);
  const [loader, setLoader] = useState(false);
  const param = useParams();
  const id = param.id;
  let snapRef = useRef();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const history = useHistory();
  const dispatch = useDispatch();
  //job requirement Info
  const [requirementInformation, setRequirementInformation] = useState({
    jobTitle: "",
    clientName: "",
    location: "",
    domain: "",
    minDuration: "",
    interviewType: "",
    no_of_position: "",
    maxDuration: "",
    rateCardValue: "",
    travelRequirement: "",
  });

  const [requirementError, setRequirementError] = useState({
    jobTitle: "",
    clientName: "",
    location: "",
    domain: "",
    minDuration: "",
    interviewType: "",
  });
  const [hiringType, setHiringType] = useState(true);
  const [workType, setWorkType] = useState(true);
  const [date, setDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(true);
  const [companyName, setCompanyName] = useState("");

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="resumeformcalendarfield w-100"
      onClick={onClick}
      ref={ref}
    >
      <label style={{ marginTop: "10px" }}>{value}</label>
      <i
        className="fas fa-calendar-alt"
        style={{ fontSize: "20px", marginTop: "10px" }}
      ></i>
    </button>
  ));

  function MaxDuration() {
    if (requirementInformation.minDuration) {
      const val = [];
      durationData.map((obj) => {
        if (Object.keys(obj)[0] === requirementInformation.minDuration)
          val.push(obj[requirementInformation.minDuration]);
      });
      const result = [requirementInformation.maxDuration];
      try {
        val[0].map((val) => {
          if (val !== requirementInformation.maxDuration) result.push(val);
        });
      } catch {}
      const option = result.map((value, index) => {
        if (index === 0)
          return (
            <option key={index} value={value} selected>
              {value} Months
            </option>
          );
        return (
          <option key={index} value={value}>
            {value} Months
          </option>
        );
      });
      return option;
    } else
      return (
        <option value={"Select"} disabled>
          Select
        </option>
      );
  }

  //Location integration
  const [citylist, setCityList] = useState([]);
  const [cityShow, setCityShow] = useState(false);

  async function handleLocationChange() {
    let data = requirementInformation.location;
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList(payload);
    } else if (response.status === 404) {
      setCityShow(false);
      setRequirementError({
        ...requirementError,
        location: "please enter valid city",
      });
    }
  }

  useEffect(() => {
    handleLocationChange();
  }, [requirementInformation.location]);

  async function selectedLocation(city) {
    setCityShow(false);
    setRequirementInformation({
      ...requirementInformation,
      location: city,
    });
    setRequirementError({
      ...requirementError,
      location: "",
    });
  }

  function locationValid() {
    let locationValue = requirementInformation.location;
    let mapped = citylist.map((data) => data.city);
    let found = mapped.includes(locationValue);
    if (requirementInformation.location.length !== 0) {
      if (found) {
        setRequirementError({
          ...requirementError,
          location: "",
        });
      } else {
        setRequirementError({
          ...requirementError,
          location: "please enter valid city",
        });
      }
    }
  }
  // for location outside click
  const jobref = React.useRef();
  useEffect(() => {
    const locHandleClickOutside = (event) => {
      if (
        cityShow &&
        jobref.current &&
        !jobref.current.contains(event.target)
      ) {
        document.getElementById("firstLocCodeDiv").style.display = "none";
        locationValid();
      }
      document.body.removeEventListener("click", locHandleClickOutside);
    };
    document.body.addEventListener("click", locHandleClickOutside);
  }, [cityShow, citylist, requirementInformation.location]);

  //get job requirementdetails
  useEffect(() => {
    async function callItSelf() {
      try {
        const profileResponse = isrecruiterCandidate
          ? await recgetDetails(token)
          : isFreelance
          ? await freegetDetails(token)
          : await getDetails(token);
        if (profileResponse.status === 200) {
          const profileData = await profileResponse.json();
          const profilePayload = profileData.payload;
          setCompanyName(profilePayload.companyName);
        }
        if (!requirementInformation.jobTitle) {
          const response = isrecruiterCandidate
            ? await recgetRequirementInfo(token, id)
            : isFreelance
            ? await freegetRequirementInfo(token, id)
            : await getRequirementInfo(token, id);
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload.requirement;
            if (payload.hiringType === "INTERNAL_HIRING") {
              setHiringType(true);
            } else {
              setHiringType(false);
            }
            if (payload.workType === "WORK_REMOTELY") {
              setWorkType(true);
            } else {
              setWorkType(false);
            }
            setRequirementInformation({
              jobTitle: payload.jobTitle,
              clientName: payload.clientName,
              no_of_position: payload.numberOfPosition,
              location: payload.location || "",
              domain: payload.domain,
              minDuration: payload.minDuration,
              maxDuration: payload.maxDuration,
              interviewType: payload.interviewType,
              travelRequirement: payload.travelRequirement,
              rate: payload.rate,
              rateType: payload.rateType,
            });
            document.getElementsByClassName("selectemploy")[0].value =
              payload.employementType;
            setDate(new Date(payload.startDate || null));
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);

  //primary skill
  const [primaryInformation, setPrimaryInformation] = useState({
    primarySkill: "",
    primarySkill0: "",
    primarySkill1: "",
    primarySkill2: "",
    primarySkill3: "",
  });
  const [primaryError, setPrimaryError] = useState({
    primarySkill: "",
    primarySkill0: "",
    primarySkill1: "",
    primarySkill2: "",
    primarySkill3: "",
  });
  const [primaryVisible, setPrimaryVisible] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
  });
  const [primeExperience, setPrimeExperience] = useState({
    month: "",
    year: "",
    month0: "",
    year0: "",
    month1: "",
    year1: "",
    month2: "",
    year2: "",
    month3: "",
    year3: "",
  });
  //add more primary skill
  function CreatePrimary() {
    setPrimaryVisible((state) => {
      if (state.one === false && state.two) return { ...state, one: true };
      else if (state.two === false && state.three)
        return { ...state, two: true };
      else if (state.three === false && state.four)
        return { ...state, three: true };
      else return { ...state, four: true };
    });
  }
  //secondary skill
  const [secondaryInfo, setSecondaryInfo] = useState({
    secondarySkill: "",
    secondarySkill0: "",
    secondarySkill1: "",
    secondarySkill2: "",
  });

  const [secondaryError, setSecondaryError] = useState({
    secondarySkill: "",
    secondarySkill0: "",
    secondarySkill1: "",
    secondarySkill2: "",
  });

  const [secondaryVisible, setSecondaryVisible] = useState({
    one: false,
    two: false,
    three: false,
  });

  const [experience, setExperience] = useState({
    secondaryMonth: "",
    secondaryYear: "",
    secondaryMonth0: "",
    secondaryYear0: "",
    secondaryMonth1: "",
    secondaryYear1: "",
  });

  //add more secondary skill
  function CreateSecondary() {
    setSecondaryVisible((state) => {
      if (!state.one) return { ...state, one: true };
      if (!state.two) return { ...state, two: true };
      if (!state.three) return { ...state, three: true };
      return state;
    });
  }
  const [mandatoryType, setMandatoryType] = useState(false);
  const [mandatoryType0, setMandatoryType0] = useState(false);
  const [mandatoryType1, setMandatoryType1] = useState(false);
  function experienceFunction(event, setExperience) {
    const { name, value } = event.currentTarget;
    setExperience((state) => {
      return { ...state, [name]: value };
    });
  }

  function primeExperienceFunction(event, setPrimeExperience) {
    const { name, value } = event.currentTarget;
    setPrimeExperience((state) => {
      return { ...state, [name]: value };
    });
  }

  useEffect(() => {
    setPrimaryInformation((state) => {
      return {
        ...state,
        primarySkill0: "",
      };
    });
    setPrimaryError((state) => {
      return {
        ...state,
        primarySkill0: "",
      };
    });
  }, [primaryVisible.one]);

  useEffect(() => {
    setPrimaryInformation((state) => {
      return {
        ...state,
        primarySkill1: "",
      };
    });
    setPrimaryError((state) => {
      return {
        ...state,
        primarySkill1: "",
      };
    });
  }, [primaryVisible.two]);

  useEffect(() => {
    setPrimaryInformation((state) => {
      return {
        ...state,
        primarySkill2: "",
      };
    });
    setPrimaryError((state) => {
      return {
        ...state,
        primarySkill2: "",
      };
    });
  }, [primaryVisible.three]);

  useEffect(() => {
    setPrimaryInformation((state) => {
      return {
        ...state,
        primarySkill3: "",
      };
    });
    setPrimaryError((state) => {
      return {
        ...state,
        primarySkill3: "",
      };
    });
  }, [primaryVisible.four]);

  useEffect(() => {
    let primData = {};
    let primExp = {};
    if (!primaryVisible.one) {
      primData = { ...primData, primarySkill0: "" };
      primExp = { ...primExp, month0: "", year0: "" };
    }
    if (!primaryVisible.two) {
      primData = { ...primData, primarySkill1: "" };
      primExp = { ...primExp, month1: "", year1: "" };
    }
    if (!primaryVisible.three) {
      primData = { ...primData, primarySkill2: "" };
      primExp = { ...primExp, month2: "", year2: "" };
    }
    if (!primaryVisible.four) {
      primData = { ...primData, primarySkill3: "" };
      primExp = { ...primExp, month3: "", year3: "" };
    }
    setPrimaryInformation({ ...primaryInformation, ...primData });
    setPrimeExperience({ ...primeExperience, ...primExp });
  }, [primaryVisible]);

  //get the skill details
  useEffect(() => {
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await recgetRequirementInfo(token, id)
          : isFreelance
          ? await freegetRequirementInfo(token, id)
          : await getRequirementInfo(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload.requirement;
          setSkillData(
            payload,
            primaryInformation,
            setPrimaryInformation,
            secondaryInfo,
            setSecondaryInfo,
            experience,
            setExperience,
            primeExperience,
            setPrimeExperience,
            primaryVisible,
            setPrimaryVisible,
            secondaryVisible,
            setSecondaryVisible,
            setMandatoryType,
            setMandatoryType0,
            setMandatoryType1
          );
        }
      } catch {}
    }
    callItSelf();
  }, []);

  //submit the skill details
  function skillHandle() {
    const primData = [];
    const secondaryData = [];

    primData.push({
      ClientPrimarySkill: primaryInformation.primarySkill,
      year: primeExperience.year ? primeExperience.year : 0,
      month: primeExperience.month ? primeExperience.month : 0,
    });
    if (primaryInformation.primarySkill0) {
      primData.push({
        ClientPrimarySkill: primaryInformation.primarySkill0,
        year: primeExperience.year0 ? primeExperience.year0 : 0,
        month: primeExperience.month0 ? primeExperience.month0 : 0,
      });
    }
    if (primaryInformation.primarySkill1) {
      primData.push({
        ClientPrimarySkill: primaryInformation.primarySkill1,
        year: primeExperience.year1 ? primeExperience.year1 : 0,
        month: primeExperience.month1 ? primeExperience.month1 : 0,
      });
    }
    if (primaryInformation.primarySkill2) {
      primData.push({
        ClientPrimarySkill: primaryInformation.primarySkill2,
        year: primeExperience.year2 ? primeExperience.year2 : 0,
        month: primeExperience.month2 ? primeExperience.month2 : 0,
      });
    }
    if (primaryInformation.primarySkill3) {
      primData.push({
        ClientPrimarySkill: primaryInformation.primarySkill3,
        year: primeExperience.year3 ? primeExperience.year3 : 0,
        month: primeExperience.month3 ? primeExperience.month3 : 0,
      });
    }
    if (secondaryInfo.secondarySkill) {
      secondaryData.push({
        ClientSecondarySkill: secondaryInfo.secondarySkill,
        year: experience.secondaryYear ? experience.secondaryYear : 0,
        month: experience.secondaryMonth ? experience.secondaryMonth : 0,
        type: mandatoryType ? "MANDATORY" : "GOOD_TO_HAVE",
      });
    }
    if (secondaryVisible.one) {
      if (secondaryInfo.secondarySkill0) {
        secondaryData.push({
          ClientSecondarySkill: secondaryInfo.secondarySkill0,
          year: experience.secondaryYear0 ? experience.secondaryYear0 : 0,
          month: experience.secondaryMonth0 ? experience.secondaryMonth0 : 0,
          type: mandatoryType0 ? "MANDATORY" : "GOOD_TO_HAVE",
        });
      }
    }
    if (secondaryVisible.two) {
      if (secondaryInfo.secondarySkill1) {
        secondaryData.push({
          ClientSecondarySkill: secondaryInfo.secondarySkill1,
          year: experience.secondaryYear1 ? experience.secondaryYear1 : 0,
          month: experience.secondaryMonth1 ? experience.secondaryMonth1 : 0,
          type: mandatoryType1 ? "MANDATORY" : "GOOD_TO_HAVE",
        });
      }
    }
    let parseData = {
      primarySkill: primData,
    };
    if (secondaryData.length > 0) {
      parseData = {
        primarySkill: primData,
        secondarySkill: secondaryData,
      };
    }
    return parseData;
  }

  //academics
  const [academicInformation, setAcademicInformation] = useState({
    qualification: "",
    specialization: "",
  });
  const [academicError, setAcademicError] = useState({
    qualification: "",
    specialization: "",
  });
  const [academicVisibility, setAcademicVisibility] = useState(true);
  const [veteranVisibility, setVeteranVisibility] = useState(true);
  //certificate
  const [certificateInfo, setCertificateInfo] = useState({
    certificateName: "",
    certificateName0: "",
    certificateName1: "",
    certificateName2: "",
  });

  const [certificateError, setCertificateError] = useState({
    certificateName: "",
    certificateName0: "",
    certificateName1: "",
    certificateName2: "",
  });

  const [certificateVisible, setCertificateVisible] = useState({
    one: false,
    two: false,
    three: false,
  });
  const [certificateMandatory, setCertificateMandatory] = useState(null);
  const [certificateMandatory0, setCertificateMandatory0] = useState(null);
  const [certificateMandatory1, setCertificateMandatory1] = useState(null);
  const [certificateMandatory2, setCertificateMandatory2] = useState(null);
  //add more certificate
  function CreateInputs() {
    setCertificateVisible((state) => {
      if (!state.one) return { ...state, one: true };
      if (!state.two) return { ...state, two: true };
      if (!state.three) return { ...state, three: true };
      return state;
    });
  }
  //get jobRequirement Academic & certificate Details
  useEffect(() => {
    async function callItSelf() {
      try {
        if (!academicInformation.qualification) {
          const response = isrecruiterCandidate
            ? await recgetRequirementInfo(token, id)
            : isFreelance
            ? await freegetRequirementInfo(token, id)
            : await getRequirementInfo(token, id);
          if (response.status === 200) {
            const data = await response.json();
            let visibleObject = {};
            let secondaryObject = {};
            const payload = data.payload.requirement;
            const academic = payload.AcademicCertificates[0];
            if (academic.clearanceRequired === "YES") {
              setAcademicVisibility(true);
            } else {
              setAcademicVisibility(false);
            }
            setVeteranVisibility(data.payload.veteran);
            setAcademicInformation({
              qualification: academic.academicsQualification,
              specialization: academic.specialization,
            });
            if (academic.AcademicCertifications[0]) {
              const firstCertificate = academic.AcademicCertifications[0];
              secondaryObject = {
                ...secondaryObject,
                certificateName: firstCertificate.certificateName,
              };
              if (firstCertificate.type === "MANDATORY") {
                setCertificateMandatory(true);
              } else {
                setCertificateMandatory(false);
              }
            }
            if (academic.AcademicCertifications[1]) {
              const secondCertificate = academic.AcademicCertifications[1];
              visibleObject = {
                ...visibleObject,
                one: true,
              };
              secondaryObject = {
                ...secondaryObject,
                certificateName0: secondCertificate.certificateName,
              };
              if (secondCertificate.type === "MANDATORY") {
                setCertificateMandatory0(true);
              } else {
                setCertificateMandatory0(false);
              }
            }
            if (academic.AcademicCertifications[2]) {
              const thirdCertificate = academic.AcademicCertifications[2];
              visibleObject = {
                ...visibleObject,
                two: true,
              };
              secondaryObject = {
                ...secondaryObject,
                certificateName1: thirdCertificate.certificateName,
              };
              if (thirdCertificate.type === "MANDATORY") {
                setCertificateMandatory1(true);
              } else {
                setCertificateMandatory1(false);
              }
            }
            if (academic.AcademicCertifications[3]) {
              const fourthCertificate = academic.AcademicCertifications[3];
              visibleObject = {
                ...visibleObject,
                three: true,
              };
              secondaryObject = {
                ...secondaryObject,
                certificateName2: fourthCertificate.certificateName,
              };
              if (fourthCertificate.type === "MANDATORY") {
                setCertificateMandatory2(true);
              } else {
                setCertificateMandatory2(false);
              }
            }
            setCertificateVisible({ ...certificateVisible, ...visibleObject });
            setCertificateInfo({ ...certificateInfo, ...secondaryObject });
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);
  //submit academic & certificate details
  function academicHandle() {
    const certificateData = [];
    if (certificateInfo.certificateName) {
      certificateData.push({
        certificateName: certificateInfo.certificateName,
        type: certificateMandatory ? "MANDATORY" : "GOOD_TO_HAVE",
      });
    }
    if (certificateVisible.one) {
      if (certificateInfo.certificateName0) {
        certificateData.push({
          certificateName: certificateInfo.certificateName0,
          type: certificateMandatory0 ? "MANDATORY" : "GOOD_TO_HAVE",
        });
      }
    }
    if (certificateVisible.two) {
      if (certificateInfo.certificateName1) {
        certificateData.push({
          certificateName: certificateInfo.certificateName1,
          type: certificateMandatory1 ? "MANDATORY" : "GOOD_TO_HAVE",
        });
      }
    }
    if (certificateVisible.three) {
      if (certificateInfo.certificateName2) {
        certificateData.push({
          certificateName: certificateInfo.certificateName2,
          type: certificateMandatory2 ? "MANDATORY" : "GOOD_TO_HAVE",
        });
      }
    }

    let registrationValue = {
      clearanceRequired: academicVisibility ? "YES" : "NO",
      academicsQualification: academicInformation.qualification,
      specialization: academicInformation.specialization,
      veteran: veteranVisibility,
    };

    if (certificateData.length > 0) {
      registrationValue = {
        ...registrationValue,
        certificates: certificateData,
      };
    }

    let filteredValue = {};
    Object.keys(registrationValue)
      .filter((key) => {
        const value = registrationValue[key];
        if (value || value === false) {
          return true;
        } else {
          return false;
        }
      })
      .map((key) => {
        filteredValue = { [key]: registrationValue[key], ...filteredValue };
      });
    return filteredValue;
  }
  //security clearance
  const [securityInformation, setSecurityInformation] = useState({
    classification: "",
    category: "",
  });
  const [securityError, setSecurityError] = useState({
    classification: "",
    category: "",
  });
  const [securityVisibility, setSecurityVisibility] = useState(true);
  const multidata = [
    { document: "Passport", id: 1 },
    { document: "Workauth Document", id: 2 },
    { document: "Driving License", id: 3 },
  ];
  const [options] = useState(multidata);
  const [multiselectData, setMultiSelectData] = useState([
    { document: "", id: 1 },
    { document: "", id: 2 },
    { document: "", id: 3 },
  ]);
  const SaveDisplay = (event) => {
    // for save button
    event.target.parentNode.lastChild.style.display = "inline";
    event.target.style.display = "none";
    var fields = document.getElementsByTagName("input");
    for (var i = 0; i < fields.length; i++) {
      fields[i].disabled = false;
    }
    var dropdowns = document.getElementsByTagName("select");
    for (var i = 0; i < dropdowns.length; i++) {
      dropdowns[i].disabled = false;
    }
  };
  const multiselectRef = createRef();
  //submit security clearance details
  function securityHandle(event) {
    event.preventDefault();
    event.target.style.display = "none";
    event.target.parentNode.firstChild.style.display = "inline";
    var fields = document.getElementsByTagName("input");
    for (var i = 0; i < fields.length; i++) {
      fields[i].disabled = true;
    }
    var dropdowns = document.getElementsByTagName("select");
    for (var i = 0; i < dropdowns.length; i++) {
      dropdowns[i].disabled = true;
    }
    const multidocument = multiselectRef.current.getSelectedItems();
    let registerData = {
      securityClearanceRequired: securityVisibility ? "YES" : "NO",
    };
    if (securityVisibility) {
      registerData = {
        securityClearanceClassification: securityInformation.classification,
        securityClearanceCategory: securityInformation.category,
        ...registerData,
      };
    }
    if (multidocument[0] && multidocument !== "null") {
      registerData = {
        mandatoryDocument: [multidocument[0].document],
        ...registerData,
      };
    }
    if (multidocument[1] && multidocument !== "null") {
      const pushData = registerData.mandatoryDocument.push(
        multidocument[1].document
      );
      registerData = {
        mandatoryDocument: pushData,
        ...registerData,
      };
    }
    if (multidocument[2] && multidocument !== "null") {
      const pushData = registerData.mandatoryDocument.push(
        multidocument[2].document
      );
      registerData = {
        mandatoryDocument: pushData,
        ...registerData,
      };
    }
    if (
      verifyJobPostingSecurity(
        securityError,
        setSecurityError,
        securityInformation,
        securityVisibility
      )
    ) {
      isrecruiterCandidate
        ? recpostBuildRequirementSecurity(token, registerData, id)
        : isFreelance
        ? freepostBuildRequirementSecurity(token, registerData, id)
        : postBuildRequirementSecurity(token, registerData, id);
    }
  }

  //get security clearance details
  useEffect(() => {
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await recgetRequirementInfo(token, id)
          : isFreelance
          ? await freegetRequirementInfo(token, id)
          : await getRequirementInfo(token, id);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.requirement;
          if (payload.securityClearanceRequired === "YES") {
            setSecurityVisibility(true);
          } else {
            setSecurityVisibility(false);
          }
          if (payload.securityClearanceRequired === "YES") {
            setSecurityInformation({
              classification: payload.securityClearanceClassification,
              category: payload.securityClearanceCategory,
            });
            if (payload.mandatoryDocument.document[0]) {
              setMultiSelectData([
                {
                  document: payload.mandatoryDocument.document[0],
                },
              ]);
            }
            if (payload.mandatoryDocument.document[1]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
              ]);
            }
            if (payload.mandatoryDocument.document[2]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
                { document: payload.mandatoryDocument.document[2] },
              ]);
            }
          } else if (payload.securityClearanceRequired === "NO") {
            if (payload.mandatoryDocument.document[0]) {
              setMultiSelectData([
                {
                  document: payload.mandatoryDocument.document[0],
                },
              ]);
            }
            if (payload.mandatoryDocument.document[1]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
              ]);
            }
            if (payload.mandatoryDocument.document[2]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
                { document: payload.mandatoryDocument.document[2] },
              ]);
            }
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);

  //job description
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [information, setInformation] = useState({ uploadskill: "" });

  const onEditorStateChange = (editorState) => {
    return setEditorState(editorState);
  };
  //file upload integration
  function handleFile() {
    const file = document.getElementById("previewskillset").files[0];
    const obj = {
      for: isrecruiterCandidate
        ? "RECRUITER"
        : isFreelance
        ? "FREELANCE_RECRUITER"
        : "CLIENT",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    CreateSignedUrlAPI(token, obj)
      .then((result) => {
        const statusCode = result.status;
        const data = result.json();
        return Promise.all([statusCode, data]).then((res) => ({
          statusCode: res[0],
          data: res[1],
        }));
      })
      .then((res) => {
        if (res.statusCode === 200) {
          const promises = res.data?.payload?.signedUrls.map((url) => {
            uploadImage(url.signedUrl, file);
            return url;
          });
          Promise.all(promises).then((data) => {
            uploadImageListHandler(data);
          });
        }
      });
  }

  function uploadImageListHandler(items) {
    setTimeout(() => {
      // setInformation(items[0].fileUrl);
      setInformation((prevState) => ({
        ...prevState,
        uploadskill: items[0].fileUrl,
      }));
      sessionStorage.setItem("fileUrl", items[0].fileUrl);
    }, 1000);
  }
  //get job description details
  useEffect(() => {
    callJobDescription();
  }, []);

  const callJobDescription = async () => {
    try {
      const response = isrecruiterCandidate
        ? await recgetRequirementInfo(token, id)
        : isFreelance
        ? await freegetRequirementInfo(token, id)
        : await getRequirementInfo(token, id);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload.requirement;
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(payload.description)
          )
        );
        const skillsetName = payload.skillSetKey.split("/");
        document.getElementById("filename").placeholder =
          skillsetName[skillsetName.length - 1];
      }
    } catch {}
  };
  //submit jobdescription details
  function descriptionHandle() {
    let registerData = {
      skillSetKey: information.uploadskill
        ? information.uploadskill
        : sessionStorage.getItem("fileUrl"),
    };
    if (editorState) {
      registerData = {
        description: editorState.getCurrentContent().getPlainText(),
        ...registerData,
      };
    }
    let filteredValue = {};
    Object.keys(registerData)
      .filter((key) => {
        const value = registerData[key];
        if (value || value === false) {
          return true;
        } else {
          return false;
        }
      })
      .map((key) => {
        filteredValue = { [key]: registerData[key], ...filteredValue };
      });
    return filteredValue;
  }
  try {
    document.getElementById("replacetitleimg").innerText =
      "Job Posting Preview";
    document.getElementById("postjobid").style.display = "none";
  } catch {}
  const isrecruiter = useSelector((state) => state.isrecruiter);
  //submit
  const rateValError = [
    "W2Witherror",
    "W2Withouterror",
    "c2herror",
    "c2cerror",
    "W2Hourlyerror",
    "W2Hourlywithouterror",
    "1099error",
    "1099Withouterror",
  ];

  const rateValCheckbox = [
    "W2WithCheck",
    "W2WithoutCheck",
    "C2HCheck",
    "C2CCheck",
    "W2HourlyWithCheck",
    "W2HourlyWithoutCheck",
    "N1099WithCheck",
    "N1099WithoutCheck",
  ];
  async function handleClick(event) {
    event.preventDefault();
    const target = event.target;
    let flag = false;
    setSecondaryError((state) => {
      if (!secondaryVisible.one) state = { ...state, secondarySkill0: "" };
      if (!secondaryVisible.two) state = { ...state, secondarySkill1: "" };
      if (!secondaryVisible.three) state = { ...state, secondarySkill1: "" };
      return state;
    });

    const result = Object.keys(secondaryError)
      .map((key) => {
        return secondaryError[key] ? true : false;
      })
      .includes(true);

    setCertificateError((state) => {
      if (!certificateVisible.one) state = { ...state, certificateName0: "" };
      if (!certificateVisible.two) state = { ...state, certificateName1: "" };
      if (!certificateVisible.three) state = { ...state, certificateName1: "" };
      return state;
    });

    const certificateResult = Object.keys(certificateError)
      .map((key) => {
        return certificateError[key] ? true : false;
      })
      .includes(true);

    if (
      !verifyJobRequirementInfo(
        requirementError,
        setRequirementError,
        requirementInformation
      )
    )
      return;
    const resultVal = Object.values(requirementError)
      .map((data) => {
        if (data) return true;
        return false;
      })
      .some((data) => {
        if (data) return true;
        return false;
      });

    if (
      !verifyJobPostingAcademic(
        academicError,
        setAcademicError,
        academicInformation,
        academicVisibility
      )
    )
      return;
    const academicVal = Object.values(academicError)
      .map((data) => {
        if (data) return true;
        return false;
      })
      .some((data) => {
        if (data) return true;
        return false;
      });
    const minDuration = target.minDuration.value;
    const no_of_position = target.no_of_position.value;
    const maxDuration = target.maxDuration.value;
    const travel = target.travelRequirement.value;
    const taxTerm = target.taxterm.value;
    const duration = target.duration.value;

    const rateError = document.getElementById("rateerror").innerHTML;
    if (taxTerm === "Select") {
      document.getElementById("employmenterror").innerHTML =
        "Please enter the employment type";
      document.getElementsByClassName("selectemploy")[0].style.cssText =
        "border: 1px solid red";
    }
    if (duration === "Select") {
      document.getElementById("rateTypeerror").innerHTML =
        "Please enter the rate type";
      document.getElementsByClassName("selectrateType")[0].style.cssText =
        "border: 1px solid red";
    }
    if (target.rate.value === "") {
      document.getElementById("rateerror").innerHTML = "Please enter the rate";
      document.getElementsByClassName("ratefield")[0].style.cssText =
        "border: 1px solid red";
    }
    const rateTableError = rateValError.map((key) => {
      const field = document.getElementsByClassName(`${key}`)[0].innerHTML;
      if (field !== "") {
        return false;
      } else {
        return true;
      }
    });
    var rateTableCheckBox = [];
    rateValCheckbox.map((key) => {
      rateTableCheckBox = [
        ...rateTableCheckBox,
        document.getElementsByName(`${key}`)[0].checked,
      ];
    });
    const TotalCheckValue = rateTableCheckBox.every((value) => value === false);
    if (TotalCheckValue && target.rate.value !== "" && rateError === "") {
      document.getElementsByClassName("totalCheckError")[0].innerHTML =
        "Select at least one checkbox";
    }

    let registerData = {
      hiringType: hiringType ? "INTERNAL_HIRING" : "EXTERNAL_HIRING",
      workType: workType ? "WORK_REMOTELY" : "ON_SITE",
      jobTitle: requirementInformation.jobTitle,
      clientName: requirementInformation.clientName,
      numberOfPosition: no_of_position,
      location: requirementInformation.location,
      domain: requirementInformation.domain,
      startDate: date.toISOString().slice(0, 10),
      minDuration: minDuration,
      maxDuration: maxDuration === "Months" ? "" : maxDuration,
      interviewType: requirementInformation.interviewType,
      travelRequirement: travel,
      employementType: taxTerm,
      rateType: duration,
      rate: target.rate.value,
    };
    if (target.W2WithCheck.checked) {
      registerData = {
        W2With: +target.W2With.value,
        ...registerData,
      };
    }
    if (target.W2WithoutCheck.checked) {
      registerData = {
        W2Without: +target.W2Without.value,
        ...registerData,
      };
    }
    if (target.W2HourlyWithCheck.checked) {
      registerData = {
        W2HourlyWith: +target.W2HourlyWith.value,
        ...registerData,
      };
    }
    if (target.W2HourlyWithoutCheck.checked) {
      registerData = {
        W2HourlyWithout: +target.W2HourlyWithout.value,
        ...registerData,
      };
    }
    if (target.C2HCheck.checked) {
      registerData = {
        C2H: +target.C2H.value,
        ...registerData,
      };
    }
    if (target.C2CCheck.checked) {
      registerData = {
        C2C: +target.C2C.value,
        ...registerData,
      };
    }
    if (target.N1099WithCheck.checked) {
      registerData = {
        N1099With: +target.N1099With.value,
        ...registerData,
      };
    }
    if (target.N1099WithoutCheck.checked) {
      registerData = {
        N1099Without: +target.N1099Without.value,
        ...registerData,
      };
    }
    let filteredValue = {};
    Object.keys(registerData)
      .filter((key) => {
        const value = registerData[key];
        if (
          registerData.workAuthorization === "Select" &&
          (key === "workAuthorization" ||
            key === "employementType" ||
            key === "rateType" ||
            key === "rate")
        )
          return false;
        else if (value || value === false) return true;
        return false;
      })
      .map((key) => {
        filteredValue = { [key]: registerData[key], ...filteredValue };
      });

    const flag1 = verifyBuildRequirementSkill(
      primaryError,
      setPrimaryError,
      primaryInformation,
      primaryVisible
    );
    const flag3 = resultVal;
    const flag4 = academicVal;
    const flag5 = verifyJobPostingSecurity(
      securityError,
      setSecurityError,
      securityInformation,
      securityVisibility
    );

    if (
      !flag3 &&
      flag1 & !result & !flag4 & !certificateResult &&
      flag5 &&
      rateError === "" &&
      !rateTableError.includes(false) &&
      !TotalCheckValue
    ) {
      if (snapRef.current) {
        snapRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      let PROMISEARRAY = isrecruiterCandidate 
      ? 
      Promise.all([
        receditRequirementInfo(token, filteredValue, id),
        recpostBuildRequirementSkill(token, skillHandle(), id),
        recpostBuildRequirementAcademic(token,academicHandle(),id),
        recpostBuildRequirementDescription(token,descriptionHandle(),id)
      ])
      :
      isFreelance
      ?
      Promise.all([
        freeditRequirementInfo(token, filteredValue, id),
        freepostBuildRequirementSkill(token, skillHandle(), id),
        freepostBuildRequirementAcademic(token,academicHandle(),id),
        freepostBuildRequirementDescription(token,descriptionHandle(),id)
      ])
      :
      Promise.all([
        editRequirementInfo(token, filteredValue, id),
        postBuildRequirementSkill(token, skillHandle(), id),
        postBuildRequirementAcademic(token,academicHandle(),id),
        postBuildRequirementDescription(token,descriptionHandle(),id)
      ])
      PROMISEARRAY.then(async () => {
        const response = isrecruiterCandidate
          ? await recsnapshot(token, id)
          : isFreelance
          ? await freesnapshot(token, id)
          : await snapshot(token, id);
        if (response.status === 200) {
          if (isrecruiterCandidate) {
            homePage(dispatch);
            enableDropdownfields();
          } else if (isFreelance) {
            freelanceHomePage(dispatch);
            enableDropdownfields();
          }
          flag = true;
        } else {
          snapRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      }).then(()=>{
        setRedirect(flag);
      })
    } else {
      flag = false;
    }
    setRedirect(flag);
  }

  const disabledFields = () => {
    var fields = document.getElementsByTagName("input");
    for (var i = 0; i < fields.length; i++) {
      fields[i].disabled = true;
    }
    var dropdowns = document.getElementsByTagName("select");
    for (var i = 0; i < dropdowns.length; i++) {
      dropdowns[i].disabled = true;
    }
    setIsOpen(true);
  };

  const enableDropdownfields = () => {
    const select = document.getElementsByTagName("select");
    for (let i = 0; i < select.length; i++) {
      select[i].disabled = false;
    }
  };

  const updateEditor = (boolVal) => {
    setIsOpen(boolVal);
  };

  // autofocus
  // function myFunction() {
  //   setHiringType(false);
  //   var x = document.getElementById("myText").select();
  // }

  return (
    <div className={"col mx-5"} onLoad={disabledFields}>
      <form className={"form-group"} onSubmit={handleClick}>
        {/*requirment info first box*/}
        <SnapshotDiv>
          <div className={"row mx-4 pl-1"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Requirement Info
              </label>
            </div>
          </div>
          <div className={"row pl-5 pt-1"}>
            <div className={"col-md form-inline"}>
              <label className={"resumeformquerytext pr-3"}>Hiring Type</label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio"
                name="hiring"
                value="INTERNAL_HIRING"
                checked={hiringType}
                onClick={() => {
                  setHiringType(true);
                  setRequirementInformation({
                    ...requirementInformation,
                    clientName: companyName,
                  });
                }}
                onChange={() => {}}
              />
              <label
                className="dashboardforminputtext pl-2 pr-3"
                htmlFor="customRadio"
              >
                Internal hiring
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio0"
                name="hiring"
                value="EXTERNAL_HIRING"
                checked={!hiringType}
                onClick={() => setHiringType(false)}
                onChange={() => {}}
              />
              <label
                className="dashboardforminputtext pl-2"
                htmlFor="customRadio0"
              >
                External hiring
              </label>
            </div>
          </div>
          <div className={"row pl-5 pt-3"}>
            <div className={"col-md form-inline"}>
              <label className={"resumeformquerytext pr-3"}>Work Type</label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio"
                name="work"
                value="WORK_REMOTELY"
                checked={workType}
                onClick={() => setWorkType(true)}
                onChange={() => {}}
              />
              <label
                className="dashboardforminputtext pl-2 pr-3"
                htmlFor="customRadio"
              >
                Work remotely
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio0"
                name="work"
                value="ON_SITE"
                checked={!workType}
                onClick={() => setWorkType(false)}
                onChange={() => {}}
              />
              <label
                className="dashboardforminputtext pl-2"
                htmlFor="customRadio0"
              >
                On site
              </label>
            </div>
          </div>
          <div className={"row pr-5 pt-3 pl-5"}>
            <div className={"col-lg-4"}>
              <label htmlFor={"Jtitle"} className={"dashboardforminputtext"}>
                Job Title<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={"text"}
                placeholder={"Type Here"}
                id={"Jtitle"}
                name={"jobTitle"}
                value={requirementInformation.jobTitle}
                style={{
                  border: `${requirementError.jobTitle ? "1px solid red" : ""}`,
                }}
                className={"form-control dashboardforminputfield shadow-none"}
                onChange={(event) =>
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  )
                }
              />
              <font className={"dashboardforminputtext text-danger"}>
                {requirementError.jobTitle}
              </font>
            </div>
            <div className={"col-lg-8"}>
              <label htmlFor={"cname"} className={"dashboardforminputtext"}>
                Client Name
              </label>
              <input
                type={"text"}
                placeholder={"Type Here"}
                id={"myText"}
                name={"clientName"}
                maxLength={50}
                disabled={hiringType ? true : false}
                value={requirementInformation.clientName}
                style={{
                  border: `${
                    requirementError.clientName ? "1px solid red" : ""
                  }`,
                }}
                className={"form-control dashboardforminputfield shadow-none"}
                onChange={(event) =>
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  )
                }
              />
              <font className={"dashboardforminputtext text-danger"}>
                {requirementError.clientName}
              </font>
            </div>
          </div>
          <div className={"row pt-3 pr-5 pl-5"}>
            <div className={"col-lg-4"}>
              <label htmlFor={"NOS"} className={"resumeforminputtext"}>
                Number Of position
              </label>
              <select
                className={
                  "form-control resumeforminputfield form-select inputgray2 shadow-none"
                }
                id={"NOS"}
                name={"no_of_position"}
                value={requirementInformation.no_of_position}
                onChange={(event) =>
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  )
                }
              >
                <option value={""}>Select</option>
                <GetNoOfPosition />
              </select>
            </div>
            <div className={"col-md"} ref={jobref}>
              <label htmlFor={"location"} className={"resumeforminputtext"}>
                Location<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={"text"}
                placeholder={"Type Here"}
                id={"location"}
                name={"location"}
                className={"form-control resumeforminputfield shadow-none"}
                value={requirementInformation.location}
                style={{
                  border: `${requirementError.location ? "1px solid red" : ""}`,
                }}
                onChange={(event) => {
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  );
                  setCityShow(true);
                }}
                autoComplete={"off"}
                onClick={() => {
                  setCityShow(!cityShow);
                  locationValid();
                }}
              />
              <font className={"dashboardforminputtext text-danger"}>
                {requirementError.location}
              </font>
              <div
                id={"firstLocCodeDiv"}
                style={{
                  display: `${
                    cityShow && requirementInformation.location.length > 0
                      ? "block"
                      : "none"
                  }`,
                }}
              >
                {citylist &&
                  citylist.length > 0 &&
                  citylist.map((data, index) => (
                    <div
                      key={index}
                      style={{ cursor: "pointer" }}
                      value={data.city}
                      onClick={() => selectedLocation(data.city)}
                    >
                      {data.city}
                    </div>
                  ))}
              </div>
            </div>
            <div className={"col-md"}>
              <label htmlFor={"domain"} className={"resumeforminputtext"}>
                Domain<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={"text"}
                placeholder={"Type Here"}
                id={"domain"}
                name={"domain"}
                className={"form-control resumeforminputfield shadow-none"}
                value={requirementInformation.domain}
                style={{
                  border: `${requirementError.domain ? "1px solid red" : ""}`,
                }}
                onChange={(event) =>
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  )
                }
              />
              <font className={"dashboardforminputtext text-danger"}>
                {requirementError.domain}
              </font>
            </div>
          </div>
          <div className={"row pt-3 pr-5 pl-5"}>
            <div className={"col-lg-4"}>
              <label htmlFor={"startdate"} className={"resumeforminputtext"}>
                Start Date
              </label>
              <DatePicker
                selected={date}
                required="true"
                onChange={(date) => setDate(date)}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                customInput={<CustomInput />}
              />
            </div>
            <div className={"col-lg-4"}>
              <label htmlFor={"min"} className={"resumeforminputtext"}>
                Min Duration<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className={
                  "form-control resumeforminputfield form-select inputgray2 shadow-none"
                }
                id={"min"}
                value={requirementInformation.minDuration}
                style={{
                  border: `${
                    requirementError.minDuration ? "1px solid red" : ""
                  }`,
                }}
                name={"minDuration"}
                onChange={(event) =>
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  )
                }
              >
                <MinMaxDuration
                  {...{ minMaxDuration: requirementInformation.minDuration }}
                />
              </select>
              <font className={"dashboardforminputtext text-danger"}>
                {requirementError.minDuration}
              </font>
            </div>
            <div className={"col-lg-4"}>
              <label htmlFor={"max"} className={"resumeforminputtext"}>
                Max Duration
              </label>
              <select
                className={
                  "form-control resumeforminputfield form-select inputgray2 shadow-none"
                }
                id={"max"}
                name={"maxDuration"}
                value={requirementInformation.maxDuration}
                onChange={(event) =>
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  )
                }
              >
                <MaxDuration />
              </select>
            </div>
          </div>
          <div className={"row pt-3 pr-5 pl-5"}>
            <div className={"col-lg-4"}>
              <label htmlFor={"Itype"} className={"resumeforminputtext"}>
                Interview type<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className={
                  "form-control resumeforminputfield form-select inputgray2 shadow-none"
                }
                id={"Itypw"}
                value={requirementInformation.interviewType}
                style={{
                  border: `${
                    requirementError.interviewType ? "1px solid red" : ""
                  }`,
                }}
                name={"interviewType"}
                onChange={(event) =>
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  )
                }
              >
                <option value={""}>Select</option>
                <option value={"VIDEO"}>Video</option>
                <option value={"TELEPHONE"}>Telephone</option>
                <option value={"IN_PERSON"}>In Person</option>
                <option value={"TELEPHONE-VIDEO"}>Telephone-Video</option>
                <option value={"TELEPHONE-IN_PERSON"}>
                  Telephone-In Person
                </option>
                <option value={"VIDEO-IN_PERSON"}>Video-In Person</option>
                <option value={"TELEPHONE-VIDEO-IN_PERSON"}>
                  Telephone,Video & In Person
                </option>
              </select>
              <font className={"dashboardforminputtext text-danger"}>
                {requirementError.interviewType}
              </font>
            </div>
            <div className={"col-lg-8"}>
              <label htmlFor={"travel"} className={"resumeforminputtext"}>
                Travel Requirement
              </label>
              <select
                className={
                  "form-control resumeforminputfield form-select inputgray2 shadow-none"
                }
                id={"travel"}
                name={"travelRequirement"}
                value={requirementInformation.travelRequirement}
                onChange={(event) =>
                  jobRequirementInfo(
                    event,
                    requirementError,
                    setRequirementError,
                    requirementInformation,
                    setRequirementInformation
                  )
                }
              >
                <option value={""}>Select</option>
                <option value={"0%"}>0%</option>
                <option value={"25%"}>25%</option>
                <option value={"50%"}>50%</option>
                <option value={"75%"}>75%</option>
                <option value={"OCCASSIONAL"}>Occassional</option>
              </select>
            </div>
          </div>
          <div className="row mx-4 pb-0 pl-2">
            <div className="col-md">
              <label
                className={"pb-0 mt-3"}
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Rate card
              </label>
            </div>
          </div>
          <div className="row mx-2 pt-0">
            <div className="col-md">
              <RateCard
                {...{
                  workAuthorization: requirementInformation.workAuthorization,
                  preferredEmployment:
                    requirementInformation.preferredEmployment,
                  rateType: requirementInformation.rateType,
                  rate: requirementInformation.rate,
                }}
              />
            </div>
          </div>
        </SnapshotDiv>
        {/*skill first box*/}
        <SnapshotDiv
          postData={
            isrecruiterCandidate
              ? recpostBuildRequirementSkill
              : isFreelance
              ? freepostBuildRequirementSkill
              : postBuildRequirementSkill
          }
          parsedData={skillHandle()}
          id={id}
        >
          <div className={"row mx-4 pl-1"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Skill
              </label>
            </div>
          </div>
          <div className={"row pr-4  pl-5"}>
            <div className={"col-lg-4"}>
              <label htmlFor={"pskill"} className={"dashboardforminputtext"}>
                Primary skill<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={"text"}
                placeholder={"Type Here"}
                id={"pskill"}
                className={"form-control dashboardforminputfield shadow-none"}
                name={"primarySkill"}
                value={primaryInformation.primarySkill}
                style={{
                  border: `${primaryError.primarySkill ? "1px solid red" : ""}`,
                }}
                onChange={(event) =>
                  jobRequirementSkill(
                    event,
                    setPrimaryInformation,
                    setPrimaryError
                  )
                }
              />
              <font className={"dashboardforminputtext text-danger"}>
                {primaryError.primarySkill}
              </font>
            </div>
            <div className={"col-lg-4"}>
              <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                Years of experience
              </label>
              <div
                className={"dropdownforminputfield"}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <select
                  className={
                    "form-control form-select dropgray shadow-none ml-0"
                  }
                  id={"pexp"}
                  name={"year"}
                  value={primeExperience.year}
                  onChange={(event) =>
                    primeExperienceFunction(event, setPrimeExperience)
                  }
                >
                  <option value={""}>Year</option>
                  <GetPrimaryYear />
                </select>
                <select
                  className={"form-control form-select dropgray1 shadow-none"}
                  id={"pexp"}
                  name={"month"}
                  value={primeExperience.month}
                  onChange={(event) =>
                    primeExperienceFunction(event, setPrimeExperience)
                  }
                >
                  <option value={""}>Month</option>
                  <GetPrimaryMonth />
                </select>
              </div>
            </div>
          </div>
          {primaryVisible.one &&
          primaryVisible.two &&
          primaryVisible.three &&
          primaryVisible.four ? (
            <div className={"row pt-3"}>
              <div className={"col-md"}></div>
            </div>
          ) : (
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn offset-sm-6 badge border-0 pl-5 newskill"}
                  style={{ color: "#3F9AE0" }}
                  onClick={CreatePrimary}
                >
                  +Add More
                </div>
              </div>
            </div>
          )}
          <div className={"row"}>
            <div className={"col-md"}>
              {/*duplicate here */}
              {primaryVisible.one && (
                <div className={"row pr-4  pl-5"}>
                  <div className={"col-lg-4"}>
                    <label
                      htmlFor={"pskill"}
                      className={"dashboardforminputtext"}
                    >
                      Primary skill<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"pskill"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"primarySkill0"}
                      value={primaryInformation.primarySkill0}
                      style={{
                        border: `${
                          primaryError.primarySkill0 ? "1px solid red" : ""
                        }`,
                      }}
                      onChange={(event) =>
                        jobRequirementSkill(
                          event,
                          setPrimaryInformation,
                          setPrimaryError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {primaryError.primarySkill0}
                    </font>
                  </div>
                  <div className={"col-lg-4"}>
                    <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                      Years of experience
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name="year0"
                        value={primeExperience.year0}
                        onChange={(event) =>
                          primeExperienceFunction(event, setPrimeExperience)
                        }
                      >
                        <option value={""}>Year</option>
                        <GetPrimaryYear />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name="month0"
                        value={primeExperience.month0}
                        onChange={(event) =>
                          primeExperienceFunction(event, setPrimeExperience)
                        }
                      >
                        <option value={""}>Month</option>
                        <GetPrimaryMonth />
                      </select>
                    </div>
                  </div>
                  <div
                    className={"btn offset-sm-7 badge border-0 cancelskill"}
                    onClick={() =>
                      setPrimaryVisible((state) => {
                        return { ...state, one: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here*/}
              {primaryVisible.two && (
                <div className={"row pr-4  pl-5"}>
                  <div className={"col-lg-4"}>
                    <label
                      htmlFor={"pskill"}
                      className={"dashboardforminputtext"}
                    >
                      Primary skill<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"pskill"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"primarySkill1"}
                      value={primaryInformation.primarySkill1}
                      style={{
                        border: `${
                          primaryError.primarySkill1 ? "1px solid red" : ""
                        }`,
                      }}
                      onChange={(event) =>
                        jobRequirementSkill(
                          event,
                          setPrimaryInformation,
                          setPrimaryError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {primaryError.primarySkill1}
                    </font>
                  </div>
                  <div className={"col-lg-4"}>
                    <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                      Years of experience
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name="year1"
                        value={primeExperience.year1}
                        onChange={(event) =>
                          primeExperienceFunction(event, setPrimeExperience)
                        }
                      >
                        <option value={""}>Year</option>
                        <GetPrimaryYear />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name="month1"
                        value={primeExperience.month1}
                        onChange={(event) =>
                          primeExperienceFunction(event, setPrimeExperience)
                        }
                      >
                        <option value={""}>Month</option>
                        <GetPrimaryMonth />
                      </select>
                    </div>
                  </div>
                  <div
                    className={"btn offset-sm-7 badge border-0 cancelskill"}
                    onClick={() =>
                      setPrimaryVisible((state) => {
                        return { ...state, two: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here */}
              {primaryVisible.three && (
                <div className={"row pr-4  pl-5"}>
                  <div className={"col-lg-4"}>
                    <label
                      htmlFor={"pskill"}
                      className={"dashboardforminputtext"}
                    >
                      Primary skill<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"pskill"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"primarySkill2"}
                      value={primaryInformation.primarySkill2}
                      style={{
                        border: `${
                          primaryError.primarySkill2 ? "1px solid red" : ""
                        }`,
                      }}
                      onChange={(event) =>
                        jobRequirementSkill(
                          event,
                          setPrimaryInformation,
                          setPrimaryError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {primaryError.primarySkill2}
                    </font>
                  </div>
                  <div className={"col-lg-4"}>
                    <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                      Years of experience
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name="year2"
                        value={primeExperience.year2}
                        onChange={(event) =>
                          primeExperienceFunction(event, setPrimeExperience)
                        }
                      >
                        <option value={""}>Year</option>
                        <GetPrimaryYear />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name="month2"
                        value={primeExperience.month2}
                        onChange={(event) =>
                          primeExperienceFunction(event, setPrimeExperience)
                        }
                      >
                        <option value={""}>Month</option>
                        <GetPrimaryMonth />
                      </select>
                    </div>
                  </div>
                  <div
                    className={"btn offset-sm-7 badge border-0 cancelskill"}
                    onClick={() =>
                      setPrimaryVisible((state) => {
                        return { ...state, three: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here */}
              {primaryVisible.four && (
                <div className={"row pr-4  pl-5"}>
                  <div className={"col-lg-4"}>
                    <label
                      htmlFor={"pskill"}
                      className={"dashboardforminputtext"}
                    >
                      Primary skill<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"pskill"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"primarySkill3"}
                      value={primaryInformation.primarySkill3}
                      style={{
                        border: `${
                          primaryError.primarySkill3 ? "1px solid red" : ""
                        }`,
                      }}
                      onChange={(event) =>
                        jobRequirementSkill(
                          event,
                          setPrimaryInformation,
                          setPrimaryError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {primaryError.primarySkill3}
                    </font>
                  </div>
                  <div className={"col-lg-4"}>
                    <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                      Years of experience
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name="year3"
                        value={primeExperience.year3}
                        onChange={(event) =>
                          primeExperienceFunction(event, setPrimeExperience)
                        }
                      >
                        <option value={""}>Year</option>
                        <GetPrimaryYear />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name="month3"
                        value={primeExperience.month3}
                        onChange={(event) =>
                          primeExperienceFunction(event, setPrimeExperience)
                        }
                      >
                        <option value={""}>Month</option>
                        <GetPrimaryMonth />
                      </select>
                    </div>
                  </div>
                  <div
                    className={"btn offset-sm-7 badge border-0 cancelskill"}
                    onClick={() =>
                      setPrimaryVisible((state) => {
                        return { ...state, four: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here*/}
            </div>
          </div>
          <div className={"row pr-4 pl-5"}>
            <div className={"col-lg-4 pl-4"}>
              <label htmlFor={"sskill"} className={"dashboardforminputtext"}>
                Secondary Skills
              </label>
              <input
                type={"text"}
                placeholder={"Type Here"}
                id={"sskill"}
                className={"form-control dashboardforminputfield shadow-none"}
                name={"secondarySkill"}
                value={secondaryInfo.secondarySkill}
                style={{
                  border: `${
                    secondaryError.secondarySkill ? "1px solid red" : ""
                  }`,
                }}
                onChange={(event) =>
                  secondarySkill(event, setSecondaryInfo, setSecondaryError)
                }
              />
              <font className={"dashboardforminputtext text-danger"}>
                {secondaryError.secondarySkill}
              </font>
            </div>
            <div className={"col-lg-4"}>
              <label htmlFor={"YOF"} className={"resumeforminputtext"}>
                Years Of Experience
              </label>
              <div
                className={"dropdownforminputfield"}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <select
                  className={
                    "form-control form-select dropgray shadow-none ml-0"
                  }
                  id={"YOF"}
                  name={"secondaryYear"}
                  value={experience.secondaryYear}
                  onChange={(event) => experienceFunction(event, setExperience)}
                >
                  <option value={""}>Year</option>
                  <GetPrimaryYear />
                </select>
                <select
                  className={"form-control form-select dropgray1 shadow-none"}
                  id={"YOF"}
                  name={"secondaryMonth"}
                  value={experience.secondaryMonth}
                  onChange={(event) => experienceFunction(event, setExperience)}
                >
                  <option value={""}>Month</option>
                  <GetPrimaryMonth />
                </select>
              </div>
            </div>
            <div className={"col-lg form-inline pt-4"}>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio"
                name="secondarystatus"
                value="mandatory"
                onClick={() => setMandatoryType(true)}
                onChange={() => {}}
                checked={mandatoryType}
              />
              <label
                className="dashboardforminputtext pl-2 pr-1"
                htmlFor="customRadio"
              >
                Mandatory
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio0"
                name="secondarystatus"
                value="good"
                onClick={() => setMandatoryType(false)}
                onChange={() => {}}
                checked={!mandatoryType}
              />
              <label
                className="dashboardforminputtext pl-2"
                htmlFor="customRadio0"
              >
                Good to have
              </label>
            </div>
          </div>
          {secondaryVisible.one && secondaryVisible.two ? (
            <div className={"row pt-3"}>
              <div className={"col-md"}></div>
            </div>
          ) : (
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn offset-sm-7 badge  newskill"}
                  onClick={CreateSecondary}
                >
                  +Add More
                </div>
              </div>
            </div>
          )}
          <div className={"row"}>
            <div className={"col-md-12"}>
              {/*duplicae here */}
              {secondaryVisible.one && (
                <div className={"row pr-4 pl-5"}>
                  <div className={"col-lg-4 pl-4"}>
                    <label
                      htmlFor={"pskill"}
                      className={"dashboardforminputtext"}
                    >
                      Secondary skills
                    </label>
                    <input
                      type={"text"}
                      id={"pskill"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"secondarySkill0"}
                      placeholder={"Type Here"}
                      value={secondaryInfo.secondarySkill0}
                      style={{
                        border: `${
                          secondaryError.secondarySkill0 ? "1px solid red" : ""
                        }`,
                      }}
                      onChange={(event) =>
                        secondarySkill(
                          event,
                          setSecondaryInfo,
                          setSecondaryError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {secondaryError.secondarySkill0}
                    </font>
                  </div>
                  <div className={"col-lg-4"}>
                    <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                      Years of experience
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name={"secondaryYear0"}
                        value={experience.secondaryYear0}
                        onChange={(event) =>
                          experienceFunction(event, setExperience)
                        }
                      >
                        <option value={""}>Year</option>
                        <GetPrimaryYear />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name={"secondaryMonth0"}
                        value={experience.secondaryMonth0}
                        onChange={(event) =>
                          experienceFunction(event, setExperience)
                        }
                      >
                        <option value={""}>Month</option>
                        <GetPrimaryMonth />
                      </select>
                    </div>
                  </div>
                  <div className={"col-lg-4 form-inline pt-4"}>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio"
                      name="secondarystatus0"
                      value="mandatory"
                      onClick={() => setMandatoryType0(true)}
                      onChange={() => {}}
                      checked={mandatoryType0}
                    />
                    <label
                      className="dashboardforminputtext pl-2 pr-1"
                      htmlFor="customRadio"
                    >
                      Mandatory
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio0"
                      name="secondarystatus0"
                      value="good"
                      onClick={() => setMandatoryType0(false)}
                      onChange={() => {}}
                      checked={!mandatoryType0}
                    />
                    <label
                      className="dashboardforminputtext pl-2"
                      htmlFor="customRadio0"
                    >
                      Good to have
                    </label>
                  </div>
                  <div
                    className={"btn offset-sm-7  badge border-0 cancelskill"}
                    onClick={() =>
                      setSecondaryVisible((state) => {
                        return { ...state, one: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here */}
              {/*duplicae here */}
              {secondaryVisible.two && (
                <div className={"row pr-4 pl-5"}>
                  <div className={"col-lg-4 pl-4"}>
                    <label
                      htmlFor={"pskill"}
                      className={"dashboardforminputtext"}
                    >
                      Secondary skills
                    </label>
                    <input
                      type={"text"}
                      id={"pskill"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"secondarySkill1"}
                      placeholder={"Type Here"}
                      value={secondaryInfo.secondarySkill1}
                      style={{
                        border: `${
                          secondaryError.secondarySkill1 ? "1px solid red" : ""
                        }`,
                      }}
                      onChange={(event) =>
                        secondarySkill(
                          event,
                          setSecondaryInfo,
                          setSecondaryError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {secondaryError.secondarySkill1}
                    </font>
                  </div>
                  <div className={"col-lg-4"}>
                    <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                      Years of experience
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name={"secondaryYear1"}
                        value={experience.secondaryYear1}
                        onChange={(event) =>
                          experienceFunction(event, setExperience)
                        }
                      >
                        <option value={""}>Year</option>
                        <GetPrimaryYear />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name={"secondaryMonth1"}
                        value={experience.secondaryMonth1}
                        onChange={(event) =>
                          experienceFunction(event, setExperience)
                        }
                      >
                        <option value={""}>Month</option>
                        <GetPrimaryMonth />
                      </select>
                    </div>
                  </div>
                  <div className={"col-lg-4 form-inline pt-4"}>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio"
                      name="secondarystatus1"
                      value="mandatory"
                      onClick={() => setMandatoryType1(true)}
                      onChange={() => {}}
                      checked={mandatoryType1}
                    />
                    <label
                      className="dashboardforminputtext pl-2 pr-1"
                      htmlFor="customRadio"
                    >
                      Mandatory
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio0"
                      name="secondarystatus1"
                      value="good"
                      onClick={() => setMandatoryType1(false)}
                      onChange={() => {}}
                      checked={!mandatoryType1}
                    />
                    <label
                      className="dashboardforminputtext pl-2"
                      htmlFor="customRadio0"
                    >
                      Good to have
                    </label>
                  </div>
                  <div
                    className={"btn offset-sm-7  badge border-0 cancelskill"}
                    onClick={() =>
                      setSecondaryVisible((state) => {
                        return { ...state, two: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here */}
            </div>
          </div>
        </SnapshotDiv>
        {/*academic*/}
        <SnapshotDiv
          postData={
            isrecruiterCandidate
              ? recpostBuildRequirementAcademic
              : isFreelance
              ? freepostBuildRequirementAcademic
              : postBuildRequirementAcademic
          }
          parsedData={academicHandle()}
          id={id}
        >
          <div className={"row mx-4 pl-1"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Education
              </label>
            </div>
          </div>
          <div className={"row pl-5 mt-1 pb-0"}>
            <div className={"col-md form-inline"}>
              <label className={"resumeformquerytext pr-3"}>
                Is US Degree Req?{" "}
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio"
                name="educationDegree"
                value="yes"
                onClick={() => setAcademicVisibility(true)}
                checked={academicVisibility}
                onChange={() => {}}
              />
              <label
                className="dashboardforminputtext pl-2 pr-3"
                htmlFor="customRadio"
              >
                Yes
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio0"
                name="educationDegree"
                value="no"
                onClick={() => setAcademicVisibility(false)}
                checked={!academicVisibility}
                onChange={() => {}}
              />
              <label
                className="dashboardforminputtext pl-2"
                htmlFor="customRadio0"
              >
                No
              </label>
            </div>
          </div>
          <div className={"row pl-5 pt-3 pb-2"}>
            <div className={"col-md form-inline"}>
              <label className={"resumeformquerytext pr-4"}>Veteran </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio"
                name="veteran"
                value="yes"
                onClick={() => setVeteranVisibility(true)}
                checked={veteranVisibility}
              />
              <label
                className="dashboardforminputtext pl-2 pr-3"
                htmlFor="customRadio"
              >
                Yes
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio0"
                name="veteran"
                value="no"
                onClick={() => setVeteranVisibility(false)}
                checked={!veteranVisibility}
              />
              <label
                className="dashboardforminputtext pl-2"
                htmlFor="customRadio0"
              >
                No
              </label>
            </div>
          </div>
          <div id={"requiredDegree"}>
            <div className={"row pt-2 pl-5"}>
              <div className={"col-lg-4"}>
                <label htmlFor={"equal"} className={"resumeforminputtext"}>
                  Academics Qualification<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className={
                    "form-control resumeforminputfield form-select inputgray shadow-none qualification"
                  }
                  value={academicInformation.qualification}
                  id={"equal"}
                  name={"qualification"}
                  style={{
                    border: `${
                      academicError.qualification ? "1px solid red" : ""
                    }`,
                  }}
                  onChange={(event) => {
                    jobRequirementAcademic(
                      event,
                      academicError,
                      setAcademicError,
                      academicInformation,
                      setAcademicInformation
                    );
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"ANY"}>Any</option>
                  <option value={"DOCTORATE"}>Doctorate</option>
                  <option value={"MASTERS"}>Masters</option>
                  <option value={"BACHELORS"}>Bachelors</option>
                  <option value={"ASSOCIATE"}>Associate</option>
                  <option value={"VOCATIONAL"}>Vocational</option>
                  <option value={"HIGH_SCHOOL"}>High School</option>
                </select>
                <font className={"dashboardforminputtext text-danger"}>
                  {academicError.qualification}
                </font>
              </div>
              <div className={"col-lg-4"}>
                <label
                  htmlFor={"specialization"}
                  className={"resumeforminputtext"}
                >
                  Specialization
                </label>
                <input
                  type={"text"}
                  placeholder={"Type Here"}
                  id={"specialization"}
                  name={"specialization"}
                  value={academicInformation.specialization}
                  style={{
                    border: `${
                      academicError.specialization ? "1px solid red" : ""
                    }`,
                  }}
                  className={"form-control resumeforminputfield shadow-none"}
                  onChange={(event) => {
                    jobRequirementAcademic(
                      event,
                      academicError,
                      setAcademicError,
                      academicInformation,
                      setAcademicInformation
                    );
                  }}
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {academicError.specialization}
                </font>
              </div>
            </div>
          </div>

          <div className={"row pt-2 pl-5"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Certificates
              </label>
            </div>
          </div>
          <div className={"row pt-0 pl-4"}>
            <div className={"col-lg-4 pl-4"}>
              <label htmlFor={"cname"} className={"resumeforminputtext pl-3"}>
                Certificate Name
              </label>
              <input
                type={"text"}
                placeholder={"Type Here"}
                id={"cname"}
                className={"form-control resumeforminputfield shadow-none ml-3"}
                name={"certificateName"}
                value={certificateInfo.certificateName}
                style={{
                  border: `${
                    certificateError.certificateName ? "1px solid red" : ""
                  }`,
                }}
                onChange={(event) =>
                  secondarySkill(event, setCertificateInfo, setCertificateError)
                }
              />
              <font className={"dashboardforminputtext text-danger ml-4"}>
                {certificateError.certificateName}
              </font>
            </div>
            <div className={"col-lg-4 form-inline pt-4 pl-4"}>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio"
                name="certificatestatus"
                value="mandatory"
                onClick={() => setCertificateMandatory(true)}
                onChange={() => {}}
                checked={certificateMandatory}
              />
              <label
                className="dashboardforminputtext pl-2 pr-3"
                htmlFor="customRadio"
              >
                Mandatory
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio0"
                name="certificatestatus"
                value="good"
                onClick={() => setCertificateMandatory(false)}
                onChange={() => {}}
                checked={!certificateMandatory}
              />
              <label
                className="dashboardforminputtext pl-2"
                htmlFor="customRadio0"
              >
                Good to have
              </label>
            </div>
          </div>
          {certificateVisible.one &&
          certificateVisible.two &&
          certificateVisible.three ? (
            <div className={"row pt-3"}>
              <div className={"col-md"}></div>
            </div>
          ) : (
            <div className={"row"}>
              <div className={"col-md pb-0"}>
                <div
                  className={"btn  offset-sm-7 border-0   newskill"}
                  style={{ color: "#3F9AE0" }}
                  onClick={CreateInputs}
                >
                  +Add More
                </div>
              </div>
            </div>
          )}
          <div className={"row pt-0"}>
            <div className={"col-md-12"}>
              {/*duplicate here */}
              {certificateVisible.one && (
                <div className={"row pt-0 pl-5"}>
                  <div className={"col-lg-4 pl-4"}>
                    <label
                      htmlFor={"cname"}
                      className={"dashboardforminputtext"}
                    >
                      Certificate Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cname"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"certificateName0"}
                      value={certificateInfo.certificateName0}
                      style={{
                        border: `${
                          certificateError.certificateName0
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onChange={(event) =>
                        secondarySkill(
                          event,
                          setCertificateInfo,
                          setCertificateError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.certificateName0}
                    </font>
                  </div>
                  <div className={"col-lg-4 form-inline pt-4 pl-3"}>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio"
                      name="certificatestatus0"
                      value="mandatory"
                      onClick={() => setCertificateMandatory0(true)}
                      onChange={() => {}}
                      checked={certificateMandatory0}
                    />
                    <label
                      className="dashboardforminputtext pl-2 pr-3"
                      htmlFor="customRadio"
                    >
                      Mandatory
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio0"
                      name="fieldstatus0"
                      value="good"
                      onClick={() => setCertificateMandatory0(false)}
                      onChange={() => {}}
                      checked={!certificateMandatory0}
                    />
                    <label
                      className="dashboardforminputtext pl-2"
                      htmlFor="customRadio0"
                    >
                      Good to have
                    </label>
                  </div>
                  <div
                    className={
                      "btn offset-sm-7 pl-3  badge border-0 cancelskill"
                    }
                    onClick={() =>
                      setCertificateVisible((state) => {
                        return { ...state, one: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here */}
              {/*duplicate here */}
              {certificateVisible.two && (
                <div className={"row pt-0 pl-5"}>
                  <div className={"col-lg-4 pl-4"}>
                    <label
                      htmlFor={"cname"}
                      className={"dashboardforminputtext"}
                    >
                      Certificate Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cname"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"certificateName1"}
                      value={certificateInfo.certificateName1}
                      style={{
                        border: `${
                          certificateError.certificateName1
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onChange={(event) =>
                        secondarySkill(
                          event,
                          setCertificateInfo,
                          setCertificateError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.certificateName1}
                    </font>
                  </div>
                  <div className={"col-lg-4 form-inline pt-4 pl-3"}>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio"
                      name="certificatestatus1"
                      value="mandatory"
                      onClick={() => setCertificateMandatory1(true)}
                      onChange={() => {}}
                      checked={certificateMandatory1}
                    />
                    <label
                      className="dashboardforminputtext pl-2 pr-3"
                      htmlFor="customRadio"
                    >
                      Mandatory
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio0"
                      name="certificatestatus1"
                      value="good"
                      onClick={() => setCertificateMandatory1(false)}
                      onChange={() => {}}
                      checked={!certificateMandatory1}
                    />
                    <label
                      className="dashboardforminputtext pl-2"
                      htmlFor="customRadio0"
                    >
                      Good to have
                    </label>
                  </div>
                  <div
                    className={
                      "btn offset-sm-7 pl-3  badge border-0 cancelskill"
                    }
                    onClick={() =>
                      setCertificateVisible((state) => {
                        return { ...state, two: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here */}
              {/*duplicate here */}
              {certificateVisible.three && (
                <div className={"row pt-0 pl-5"}>
                  <div className={"col-lg-4 pl-4"}>
                    <label
                      htmlFor={"cname"}
                      className={"dashboardforminputtext"}
                    >
                      Certificate Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cname"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      name={"certificateName2"}
                      value={certificateInfo.certificateName2}
                      style={{
                        border: `${
                          certificateError.certificateName2
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onChange={(event) =>
                        secondarySkill(
                          event,
                          setCertificateInfo,
                          setCertificateError
                        )
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.certificateName2}
                    </font>
                  </div>
                  <div className={"col-lg-4 form-inline pt-4 pl-3"}>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio"
                      name="certificatestatus2"
                      value="mandatory"
                      onClick={() => setCertificateMandatory2(true)}
                      onChange={() => {}}
                      checked={certificateMandatory2}
                    />
                    <label
                      className="dashboardforminputtext pl-2 pr-3"
                      htmlFor="customRadio"
                    >
                      Mandatory
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio0"
                      name="certificatestatus2"
                      value="good"
                      onClick={() => setCertificateMandatory2(false)}
                      onChange={() => {}}
                      checked={!certificateMandatory2}
                    />
                    <label
                      className="dashboardforminputtext pl-2"
                      htmlFor="customRadio0"
                    >
                      Good to have
                    </label>
                  </div>
                  <div
                    className={
                      "btn offset-sm-7 pl-3  badge border-0 cancelskill"
                    }
                    onClick={() =>
                      setCertificateVisible((state) => {
                        return { ...state, three: false };
                      })
                    }
                  >
                    cancel
                  </div>
                </div>
              )}
              {/*duplicate here */}
            </div>
          </div>
        </SnapshotDiv>
        {/* security */}
        <form onSubmit={securityHandle}>
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row  pr-5 mx-3 pt-3"}>
              <div
                className={"w-100 pb-4 pt-3 pr-4 contentholder"}
                style={{
                  borderRadius: "20px",
                  boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                  backgroundColor: "white",
                }}
              >
                <i
                  className="fas fa-edit  editbtn float-right"
                  style={{
                    fontWeight: "500",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  onClick={SaveDisplay}
                ></i>
                <div className={"row mx-4 pl-1"}>
                  <div className={"col-md"}>
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#000000",
                      }}
                    >
                      Securtiy Clearance
                    </label>
                  </div>
                </div>
                <div className={"row mx-4 pl-1 pt-1"}>
                  <div className={"col-md form-inline"}>
                    <label className={"resumeformquerytext pr-3"}>
                      Security Clearance required
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio"
                      name="security"
                      value="yes"
                      checked={securityVisibility}
                      onChange={() => {}}
                      onClick={() => setSecurityVisibility(true)}
                    />
                    <label
                      className="dashboardforminputtext pl-2 pr-3"
                      htmlFor="customRadio"
                    >
                      Yes
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio0"
                      name="security"
                      value="no"
                      checked={!securityVisibility}
                      onChange={() => {}}
                      onClick={() => setSecurityVisibility(false)}
                    />
                    <label
                      className="dashboardforminputtext pl-2"
                      htmlFor="customRadio0"
                    >
                      No
                    </label>
                  </div>
                </div>
                {securityVisibility && (
                  <div id={"clearance"}>
                    <div className={"row pt-3 pl-5"}>
                      <div className={"col-lg-4"}>
                        <label
                          htmlFor={"classification"}
                          className={"resumeforminputtext"}
                        >
                          Security Clearance Classification
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          value={securityInformation.classification}
                          id={"classification"}
                          name={"classification"}
                          style={{
                            border: `${
                              securityError.classification
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                          onChange={(event) => {
                            jobRequirementSecurity(
                              event,
                              securityError,
                              setSecurityError,
                              securityInformation,
                              setSecurityInformation
                            );
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"TOP_SECRET"}>Top Secret</option>
                          <option value={"SECRET"}>Secret</option>
                          <option value={"CONFIDENTIAL"}>Confidential</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {securityError.classification}
                        </font>
                      </div>
                      <div className={"col-lg-4"}>
                        <label
                          htmlFor={"category"}
                          className={"resumeforminputtext"}
                        >
                          Security Clearance category
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          value={securityInformation.category}
                          id={"category"}
                          name={"category"}
                          style={{
                            border: `${
                              securityError.category ? "1px solid red" : ""
                            }`,
                          }}
                          onChange={(event) => {
                            jobRequirementSecurity(
                              event,
                              securityError,
                              setSecurityError,
                              securityInformation,
                              setSecurityInformation
                            );
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"SCI"}>SCI</option>
                          <option value={"SAPS"}>SAPS</option>
                          <option value={"RD"}>RD</option>
                          <option value={"FRD"}>FRD</option>
                          <option value={"NSI"}>NSI</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {securityError.category}
                        </font>
                      </div>
                    </div>
                  </div>
                )}
                <div className={"row pt-0 pt-3 pl-5"}>
                  <div className={"col-lg-4"}>
                    <label
                      htmlFor={"document"}
                      className={"resumeforminputtext"}
                    >
                      Mandatory Documents required for submission
                    </label>
                    <Multiselect
                      options={options}
                      displayValue="document"
                      name={"multiselectbox"}
                      ref={multiselectRef}
                      selectedValues={
                        multiselectData[0].document ? multiselectData : ""
                      }
                      style={{
                        searchBox: {
                          backgroundColor: "#FBFBFB",
                          height: "auto",
                          border: "1px solid #C2C2C2",
                          borderRadius: "8px",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        },
                        option: {
                          fontSize: "12px",
                          color: "#000000",
                          fontWeight: "500",
                        },
                        chips: {
                          fontWeight: "500",
                        },
                      }}
                    />
                  </div>
                </div>
                <button
                  type={"button"}
                  className={"btn float-right save  mt-3 px-4 py-1"}
                  style={{ display: "none" }}
                  onClick={(e) => securityHandle(e)}
                >
                  save
                </button>
              </div>
            </div>
          </div>
        </form>
        {/*Requirment Description */}
        <SnapshotDiv
          updateEditor={updateEditor}
          postData={
            isrecruiterCandidate
              ? recpostBuildRequirementDescription
              : isFreelance
              ? freepostBuildRequirementDescription
              : postBuildRequirementDescription
          }
          parsedData={descriptionHandle()}
          id={id}
        >
          <div className={"row mx-4 pl-1"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Job Requirements Description
              </label>
            </div>
          </div>
          <div className={"row mx-4 pl-1"}>
            <div
              className={"col-md"}
              style={{ height: "400px", overflowY: "auto" }}
            >
              <Editor
                readOnly={isOpen}
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                localization={{ locale: "en" }}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "history",
                  ],
                  inline: { inDropdown: true },
                  list: { inDropdown: false },
                  link: { inDropdown: true },
                  history: { inDropdown: false },
                }}
                onEditorStateChange={(event) => onEditorStateChange(event)}
              />
            </div>
          </div>
        </SnapshotDiv>
        <SnapshotDiv
          postData={
            isrecruiterCandidate
              ? recpostBuildRequirementDescription
              : isFreelance
              ? freepostBuildRequirementDescription
              : postBuildRequirementDescription
          }
          parsedData={descriptionHandle()}
          id={id}
        >
          <div className={"row mx-4 pl-1"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Skill Matrix
              </label>
            </div>
          </div>
          <div className={"row mx-4 pl-1"}>
            <div className={"col-lg-4 pt-2"}>
              <input
                type={"text"}
                className={"form-control resumeuploadtextbox py-3 shadow-none"}
                placeholder={"Upload skillset"}
                id={"filename"}
                disabled
              />
            </div>
            <div className={"col-lg-2 pt-3 pr-0"}>
              <label className={"browsebtn btn"}>
                <input
                  type="file"
                  accept=".doc,.pdf,.docx"
                  style={{ display: "None" }}
                  id={"previewskillset"}
                  onChange={(event) => {
                    document.getElementById("filename").placeholder =
                      event.target.files[0].name;
                  }}
                />
                <span>Browse</span>
              </label>
            </div>
            <div className={"col-lg-3 pt-3 pl-0"}>
              <button
                type={"button"}
                className={"btn uploadbtn"}
                onClick={handleFile}
              >
                Upload
              </button>
            </div>
            {/* <div className={"col-lg-1 pr-5 pt-3 pl-0"}>
              <Link to={"/client/postjobpreview"} className={"cancelskill"}>
                Cancel
              </Link>
            </div> */}
          </div>
        </SnapshotDiv>
        <div className={"row pt-4"}>
          {isrecruiterCandidate ? (
            <div className={"col pr-5 mr-5"}>
              {redirect ? (
                <Redirect
                  to={"/client/dashboardview"}
                  className={"btn offset-sm-9 save"}
                />
              ) : (
                <button
                  type="submit"
                  className={"btn offset-sm-9  save"}
                  ref={snapRef}
                >
                  {loader ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ padding: "3px 10px" }}
                    ></i>
                  ) : (
                    ""
                  )}
                  Save & Continue
                </button>
              )}
            </div>
          ) : isFreelance ? (
            <div className={"col pr-5 mr-5"}>
              {redirect ? (
                <Redirect
                  to={"/client/dashboardview"}
                  className={"btn offset-sm-9 save"}
                />
              ) : (
                <button
                  type="submit"
                  className={"btn offset-sm-9  save"}
                  ref={snapRef}
                >
                  {loader ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ padding: "3px 10px" }}
                    ></i>
                  ) : (
                    ""
                  )}
                  Save & Continue
                </button>
              )}
            </div>
          ) : (
            <div className={"col pr-5 mr-5"}>
              {redirect ? (
                <Redirect to="/client/dashboardview" />
              ) : (
                <button
                  type="submit"
                  className={"btn offset-sm-9  save"}
                  ref={snapRef}
                >
                  {loader ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ padding: "3px 10px" }}
                    ></i>
                  ) : (
                    ""
                  )}
                  Save & Continue
                </button>
              )}
            </div>
          )}
        </div>
      </form>
      <GoToTop />
    </div>
  );
};

export default React.memo(JobRequirmentPreview);
