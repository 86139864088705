import { FREELANCECANDIDATECERTIFICATE } from "../../ENVIRONMENT";

// api for freelance certificatelist
function freecertificateList(token) {
  const response = fetch(FREELANCECANDIDATECERTIFICATE, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { freecertificateList };
