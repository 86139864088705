import React from "react";

const NavbarSidebarBtnForNewUser = () => (
  // return button for new user
  <div
    className={"pl-4 ml-3 sidebarBtnForNewUser"}
    style={{ background: "#F9F9F9", borderRadius: "16px" }}
  >
    <div
      className={"row align-items-center py-1"}
      style={{ background: "#F9F9F9" }}
    >
      <div
        className={"col-4 py-1 border-0 dashboardbtnpadding"}
        id={"dashboardbtn"}
      >
        <img
          src="/icon/common/Vector.png"
          alt={"dashboard"}
          className={"dashboardbtnimgpadding"}
          style={{
            boxShadow: "0px 16px 31px rgba(145, 96, 209, 0.28)",
            borderRadius: "18px",
          }}
        />
      </div>
      <div className={"col py-3 dashboardbtnpadding"}>
        <font className={"sidebarfont pl-1"} id={"dashboardfont"}>
          Home
        </font>
      </div>
    </div>
  </div>
);

export default React.memo(NavbarSidebarBtnForNewUser);
