import { FREELANCECANDIDATEPERMANENTDATA } from "../../ENVIRONMENT";

// api for freelance permanentdata dashboard
async function freegetPermanentDataDashboard(token) {
  return await fetch(FREELANCECANDIDATEPERMANENTDATA, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { freegetPermanentDataDashboard };
