import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import GoToTop from "../../common/GoToTop";
import { verifyJobPostingSecurity } from "../../../function/client/checkRequired";
import { jobRequirementSecurity } from "../../../function/client/validateField";
import Multiselect from "multiselect-react-dropdown";
import { createRef } from "react";
import { freegetRequirementInfo } from "../../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { freepostBuildRequirementSecurity } from "../../../api/freelance/client/BuildRequirement/buildRequirementSecurityClearance";

const JobRequirment4 = (props) => {
  // return job requirement four page
  const [redirect, setRedirect] = useState(false);
  const [loader, setLoader] = useState(false);
  const [securityVisibility, setSecurityVisibility] = useState(true);
  const [information, setInformation] = useState({
    classification: "",
    category: "",
  });
  const [error, setError] = useState({
    classification: "",
    category: "",
  });
  const [descriptionNavigation, setDescriptionNavigation] = useState(false);
  try {
    document.getElementById("replacetitleimg").innerText = "Job Posting";
    document.getElementById("postjobid").style.display = "none";
  } catch {}

  const multidata = [
    { document: "Passport", id: 1 },
    { document: "Workauth Document", id: 2 },
    { document: "Driving License", id: 3 },
  ];
  const [options] = useState(multidata);
  const [multiselectData, setMultiSelectData] = useState([
    { document: "", id: 1 },
    { document: "", id: 2 },
    { document: "", id: 3 },
  ]);

  const token = useSelector((state) => state.token);
  const param = useParams();
  const id = param.id;
  const refId = param.refId;
  let securityRef = useRef();
  const multiselectRef = createRef();

  function handleClick(e) {
    e.preventDefault();
    const target = e.target;
    const multidocument = multiselectRef.current.getSelectedItems();
    let registerData = {
      securityClearanceRequired: securityVisibility ? "YES" : "NO",
    };
    if (securityVisibility) {
      const classification = target.classification.value;
      const category = target.category.value;
      registerData = {
        securityClearanceClassification: classification,
        securityClearanceCategory: category,
        ...registerData,
      };
    }
    if (multidocument[0]) {
      registerData = {
        mandatoryDocument: [multidocument[0].document],
        ...registerData,
      };
    }
    if (multidocument[1]) {
      const pushData = registerData.mandatoryDocument.push(
        multidocument[1].document
      );
      registerData = {
        mandatoryDocument: pushData,
        ...registerData,
      };
    }
    if (multidocument[2]) {
      const pushData = registerData.mandatoryDocument.push(
        multidocument[2].document
      );
      registerData = {
        mandatoryDocument: pushData,
        ...registerData,
      };
    }
    if (
      verifyJobPostingSecurity(error, setError, information, securityVisibility)
    ) {
      if (securityRef.current) {
        securityRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      freepostBuildRequirementSecurity(token, registerData, id).then((res) => {
        if (res.status === 200) {
          setRedirect(true);
        } else {
          securityRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      });
    }
  }

  useEffect(() => {
    async function callItSelf() {
      try {
        const response = await freegetRequirementInfo(token, id);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.requirement;
          if (payload.securityClearanceRequired.length !== 0) {
            setDescriptionNavigation(true);
            document.getElementsByClassName("hidebtn")[0].style.display =
              "none";
          }
          if (payload.securityClearanceRequired === "NO") {
            setSecurityVisibility(false);
          } else {
            setSecurityVisibility(true);
          }
          if (payload.securityClearanceRequired === "YES") {
            setInformation({
              classification: payload.securityClearanceClassification,
              category: payload.securityClearanceCategory,
            });
            if (payload.mandatoryDocument.document[0]) {
              setMultiSelectData([
                {
                  document: payload.mandatoryDocument.document[0],
                },
              ]);
            }
            if (payload.mandatoryDocument.document[1]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
              ]);
            }
            if (payload.mandatoryDocument.document[2]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
                { document: payload.mandatoryDocument.document[2] },
              ]);
            }
          } else if (payload.securityClearanceRequired === "NO") {
            if (payload.mandatoryDocument.document[0]) {
              setMultiSelectData([
                {
                  document: payload.mandatoryDocument.document[0],
                },
              ]);
            }
            if (payload.mandatoryDocument.document[1]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
              ]);
            }
            if (payload.mandatoryDocument.document[2]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
                { document: payload.mandatoryDocument.document[2] },
              ]);
            }
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);

  function submitEnabled() {
    try {
      document.getElementsByClassName("hidebtn")[0].style.display = "block";
      setDescriptionNavigation(false);
    } catch {}
  }

  return (
    <div className={"col"} style={{ height: "100vh" }}>
      <form className={"form-group"} onSubmit={handleClick}>
        <div className={"row pl-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5 pr-5"}>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className={"row mx-5 pl-5  resumealignment"}>
          <div className={"col-lg-2 pb-3 pl-4"}>
            <Link to={`${props.link[6]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Requirement
                  <br />
                  info
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[1]}/${id}`}>
              <div
                className={"pl-3 pb-2 pr-3 pt-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Skill
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[2]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 "}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Academics
                  <br />& Certificate
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <div
              className={"pt-1 pl-2 pr-2"}
              style={{
                width: "140px",
                borderRadius: "10px",
                background: "#FFFFFF",
                textAlign: "center",
              }}
            >
              <div className={"row"}>
                <div className={"col-lg pb-0"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#000000",
                      fontFamily: "poppins",
                    }}
                  >
                    Security Clearance
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg"}>
                  <div
                    style={{
                      background: "#3F9AE0",
                      width: "100%",
                      height: "4px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {descriptionNavigation ? (
              <Link to={`${props.link[4]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2"}
                  style={{
                    width: "140px",
                    borderRadius: "10px",
                    background: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#BFBFBF",
                    }}
                  >
                    Job
                    <br />
                    Description
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#BFBFBF",
                  }}
                >
                  Job
                  <br />
                  Description
                </label>
              </div>
            )}
          </div>
        </div>
        <div className={"pl-1 pr-5 mr-5 pb-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pt-3 pb-3 pl-5 contentholder"}>
              <div className={"row"}>
                <div className={"col-md form-inline"}>
                  <label className={"resumeformquerytext pr-3"}>
                    Security Clearance required
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="degree"
                    value="yes"
                    checked={securityVisibility}
                    onChange={() => {
                      submitEnabled();
                    }}
                    onClick={() => setSecurityVisibility(true)}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="degree"
                    value="no"
                    checked={!securityVisibility}
                    onChange={() => {
                      submitEnabled();
                    }}
                    onClick={() => setSecurityVisibility(false)}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>
              {securityVisibility && (
                <div id={"clearance"}>
                  <div className={"row pt-2"}>
                    <div className={"col-lg-4"}>
                      <label
                        htmlFor={"classification"}
                        className={"resumeforminputtext"}
                      >
                        Security Clearance Classification
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        value={information.classification}
                        style={{
                          border: `${
                            error.classification ? "1px solid red" : ""
                          }`,
                        }}
                        id={"classification"}
                        name={"classification"}
                        onChange={(event) => {
                          jobRequirementSecurity(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"TOP_SECRET"}>Top Secret</option>
                        <option value={"SECRET"}>Secret</option>
                        <option value={"CONFIDENTIAL"}>Confidential</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.classification}
                      </font>
                    </div>
                    <div className={"col-lg-4"}>
                      <label
                        htmlFor={"category"}
                        className={"resumeforminputtext"}
                      >
                        Security Clearance category
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        value={information.category}
                        id={"category"}
                        name={"category"}
                        style={{
                          border: `${
                            error.category ? "1px solid red" : ""
                          }`,
                        }}
                        onChange={(event) => {
                          jobRequirementSecurity(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"SCI"}>SCI</option>
                        <option value={"SAPS"}>SAPS</option>
                        <option value={"RD"}>RD</option>
                        <option value={"FRD"}>FRD</option>
                        <option value={"NSI"}>NSI</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.category}
                      </font>
                    </div>
                  </div>
                </div>
              )}

              <div className={"row pt-0 pt-2"}>
                <div className={"col-lg-4"}>
                  <label htmlFor={"document"} className={"resumeforminputtext"}>
                    Mandatory Documents required for submission
                  </label>
                  <Multiselect
                    options={options}
                    displayValue="document"
                    name={"multiselectbox"}
                    ref={multiselectRef}
                    selectedValues={
                      multiselectData[0].document ? multiselectData : ""
                    }
                    onSelect={() => submitEnabled()}
                    onRemove={() => submitEnabled()}
                    style={{
                      searchBox: {
                        backgroundColor: "#FBFBFB",
                        height: "auto",
                        border: "1px solid #C2C2C2",
                        borderRadius: "8px",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      },
                      option: {
                        fontSize: "12px",
                        color: "#000000",
                        fontWeight: "500",
                      },
                      chips: {
                        fontWeight: "500",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"row hidebox"}>
          <div className={"col pr-5 pt-2 pb-2"}>
            {descriptionNavigation ? (
              <>
                {redirect ? (
                  <Redirect to={`${props.link[4]}/${id}`} />
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 save hidebtn"}
                    ref={securityRef}
                  >
                    {loader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 5px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  refId ? (
                    <Redirect to={`${props.link[4]}/${id}/${refId}`} />
                  ) : (
                    <Redirect to={`${props.link[4]}/${id}`} />
                  )
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 save"}
                    ref={securityRef}
                  >
                    {loader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 5px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};

export default React.memo(JobRequirment4);
