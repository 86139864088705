import { CLIENTMATCHINGJOBS } from "../ENVIRONMENT";

function rejectApplication(token,id) {
    // api for reject application
    return fetch(CLIENTMATCHINGJOBS + "/reject",{
        
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token?token:sessionStorage.getItem('token')}` },
        body: JSON.stringify(id)

    })
    
}

export { rejectApplication };

