import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clearFilter } from "../common/clearFilter";

const Data = (props) => {
  const [loading, setLoading] = useState(false);
  const [match, setMatch] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch {}
  }, [props.item]);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // return matching job table data group which generated by data variable
  return (
    <>
      {loading ? (
        <div
          className="row h-100"
          style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
        >
          <div className="col-sm-12 my-auto">
            <img
              src={"/icon/Gif/loader.gif"}
              alt={"loader"}
              className={"mx-auto d-block"}
            />
          </div>
        </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {match.map((match, index) => (
                <div
                  key={index}
                  className={"row pl-4"}
                  style={{
                    boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                    backgroundColor: "white",
                    marginTop: "1px",
                  }}
                >
                  <div className={"col py-3 box"}>
                    {/*heading box data*/}
                    <div className={"row align-items-center pl-4"}>
                      <div className={"col-1 pl-0"}>
                        <Link
                          to={`/candidate/jobapply/${match.uuid}/Applied`}
                          className={"matchingjobdata text-primary"}
                        >
                          {match.jobRequirementId && match.jobRequirementId}
                        </Link>
                        <br />
                        <font className={"matchingjobdata"}>
                          {new Date(
                            match.JobRequirement.createdAt
                          ).toLocaleDateString("en-US", options)}
                        </font>
                      </div>
                      <div className={"col-md-2 pl-5 pr-0"}>
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <Link
                            to={`/candidate/jobapply/${match.uuid}/Applied`}
                            className={"matchingjobdata text-primary"}
                          >
                            {match.JobRequirement.jobTitle}
                          </Link>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className={"row"}>
                          <div className="col-5">
                            <div
                              className={"badge py-3"}
                              style={{
                                backgroundColor: "#D3D3D3",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {match.matchPercentage} %
                            </div>
                          </div>
                          <div className="col-7 pl-0">
                            <div className={"row"}>
                              <div
                                className="col-md"
                                style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                <font
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  {match.JobRequirement.domain}
                                </font>
                              </div>
                            </div>
                            <div className={"row"}>
                              <div className={"col-md"}>
                                <font
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                  }}
                                >
                                  {match.JobRequirement.employementType}
                                </font>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-1">
                        <font className={"matchingjobdata"}>Client</font>
                      </div>
                      <div className={"col-1 pl-3"}>
                        <font className={"matchingjobdata"}>
                          ${match.JobRequirement.rate}
                        </font>
                      </div>
                      <div className={"col-1"}>
                        <font className={"matchingjobdata"}>
                          {match.JobRequirement.interviewType}
                        </font>
                      </div>
                      <div className="col-2 pl-0">
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                          className={"ml-5 pl-5"}
                        >
                          <font className={"matchingjobdata"}>
                            {match.JobRequirement.location}
                          </font>
                        </div>
                      </div>
                      <div className={"col-1 ml-4"}>
                        {match.applied && (
                          <font
                            className={"matchigjobdata"}
                            style={{
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            Applied
                          </font>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Data Found
            </div>
          )}
        </>
      )}
    </>
  );
};

export { Data, clearFilter };
