import { CLIENTREQUIREMENTINFO } from "../ENVIRONMENT";

// api for client client requirement information
async function requirementInfo(token,userdetails) {
        const response = await fetch(CLIENTREQUIREMENTINFO,{   
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token?token:sessionStorage.getItem('token')}` },
        body: JSON.stringify(userdetails)

    })
    return response;
}
// api for client edit requirementInfo
async function editRequirementInfo(token,userdetails,id) {
        const response = await fetch(CLIENTREQUIREMENTINFO+`/${id}`,{   
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token?token:sessionStorage.getItem('token')}` },
        body: JSON.stringify(userdetails)

    })
    return response;
}

export { requirementInfo,editRequirementInfo };


