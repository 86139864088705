import { RECRUITERBUILDSKILL } from "../../../ENVIRONMENT";

// api for submit recruiter candidate buildresume skills
async function postBuildResumeSkill(token,data,id) {
  return await fetch( RECRUITERBUILDSKILL + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}
// api for recruiter candidate buildresume skilld details
async function getBuildResumeSkill(token,id) {
  return await fetch( RECRUITERBUILDSKILL + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { postBuildResumeSkill, getBuildResumeSkill };
