import { APPLYSTATUS } from "../ENVIRONMENT";

async function applyJob(token,id,data) {
  // api for candidate applyjob
  const response = await fetch(APPLYSTATUS+`/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body:JSON.stringify(data),
});
  return response;
}
export {applyJob}