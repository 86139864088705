import { RECRUITERBUILDACADEMIC } from "../../../ENVIRONMENT";

// api for submit recruiter candidate buildresume academic
async function postAcademic(token, data, id) {
  const response = await fetch(RECRUITERBUILDACADEMIC + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

// api for recruiter candidate buildresume academic details
async function getAcademic(token, id) {
  const response = await fetch(RECRUITERBUILDACADEMIC + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { postAcademic, getAcademic };
