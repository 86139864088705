import { RECRUITERCANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for recruiter multiple matching filter

async function recmatchingMultipleFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page
) {
  const response = await fetch(
    RECRUITERCANDIDATEMATCHINGJOBS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter multiple matchingcard filter
async function recmatchingCardFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page,
  card
) {
  const response = await fetch(
    RECRUITERCANDIDATEMATCHINGJOBS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}&card=${card}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { recmatchingCardFilter, recmatchingMultipleFilter };
