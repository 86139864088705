import "../../../../../styles/app.scss";
import React, { useEffect, useState } from "react";
import GoToTop from "../../../../common/GoToTop";
import { useSelector } from "react-redux";
import { adminCandidateListPagination } from "../../../../../api/admin/candidatelist/candidateList";
import { Data } from "../../../../../function/admin/candidateList";
import ReactPaginate from "react-paginate";
import { candidateListSorting } from "../../../../../api/admin/candidatelist/candidateListSorting";
import { candidateListLocation } from "../../../../../api/admin/candidatelist/candidateListLocation";

const RecruiterCandidateList = () => {
  const token = useSelector((state) => state.token);
  const [item, setItem] = useState([]);
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [totalLength, setTotalLength] = useState();
  const [idOrder, setIdOrder] = useState(true);

  useEffect(() => {
    async function adminCandidateListDetails() {
      try {
        setLoading(true);
        const response = await adminCandidateListPagination(token, 1);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
          setTotalLength(payload[6].totalRecord);
        }
      } catch {}
    }
    adminCandidateListDetails();
  }, []);

  // pagination
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPage(data.selected);
    setLoading(true);
    const response = await adminCandidateListPagination(token, currentPage);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      const totalRecord = payload[payload.length - 1].totalRecord;
      setRecord(totalRecord);
      setTotalLength(totalRecord);
      setItem(payload.slice(0, -1));
      setLoading(false);
    }
  };

    return (
    <div className={"col mx-4 pt-1"}>
      <div
        className={"row mt-3 align-items-center pl-3"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row"}>
            <div className={"col-2 pr-0 pl-0"}>
              <font className={"headingfont pl-5 pr-1"}>ID</font>
            </div>
            <div className={"col-2 ml-2 hidebox"}>
              <font className={"headingfont"}>Name</font>
            </div>
            <div className={"col-2  hidebox"}>
              <font className={"headingfont pl-5"}>Email</font>
            </div>
            <div className={"col-1 pl-3  hidebox"}>
              <font className={"headingfont"}>Phone</font>
            </div>
            <div className={"col-2 ml-5 hidebox"}>
              <font className={"headingfont"}>Location</font>
            </div>
            <div className={"col-1 ml-3 hidebox"}>
              <font className={"headingfont"}>Status</font>
            </div>
          </div>
        </div>
      </div>
      <Data item={item} record={record} Link={"/admin/recruiter/candidateDetails"}/>
      {loading ? (
        ""
      ) : (
        <>
          {record !== 0 && (
            <div
              className={"row align-items-center"}
              style={{
                borderRadius: "0 0 20px 20px",
                boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                backgroundColor: "white",
                marginTop: "1px",
              }}
            >
              <div className={"col py-2"}></div>
            </div>
          )}
        </>
      )}
      <div className={"row align-items-center mt-5"}>
        <div className={"col-md-6 pl-0 pb-5"}></div>
        <div className={""}>
          {totalLength > 6 && (
            <ReactPaginate
              previousLabel={"<< Previous"}
              nextLabel={"Next >>"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={3}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={page}
            />
          )}
        </div>
      </div>
      <GoToTop />
    </div>
  );
};
export default React.memo(RecruiterCandidateList);
