import { CANDIDATEPERMANENTDATA } from "../ENVIRONMENT";

async function getPermanentDataDashboard(token) {
    // api for candidate dashboard permanentdata details
  return await fetch(CANDIDATEPERMANENTDATA, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { getPermanentDataDashboard };
