import "../styles/app.scss";
import React from "react";
import { Link } from "react-router-dom";
import NavbarSidebarBtnForNewUser from "./NavbarSidebarBtnForNewUser";

const NavbarSidebarForNewUser = (props) => {
  // return navbarsidebar button for new user of client
  return (
    <div className={"col-md-1 sidebar"}>
      <div className={"col-md-2"}>
        <Link to={props.homelocation}>
          <img
            src="/icon/common/mainlogo.png"
            alt={"companylogo"}
            className={"pb-4 companylogo"}
          />
        </Link>
      </div>
      <div className={"dashbtn"}>
        <NavbarSidebarBtnForNewUser />
      </div>
    </div>
  );
};

export default React.memo(NavbarSidebarForNewUser);
