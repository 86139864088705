import { RECRUITERBUILDREQUIREMENTINFO } from "../../../ENVIRONMENT";

// api for submit recruiter requirement information
async function recrequirementInfo(token, userdetails) {
  const response = await fetch(RECRUITERBUILDREQUIREMENTINFO, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(userdetails),
  });
  return response;
}

// api for submit recruiter requirement information
async function receditRequirementInfo(token, userdetails, id) {
  const response = await fetch(RECRUITERBUILDREQUIREMENTINFO + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(userdetails),
  });
  return response;
}

export { recrequirementInfo, receditRequirementInfo };
