import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default React.memo(function NavbarSidebar(props) {
  // return mainmenu sidebarmenu
  const state = useSelector((state) => state.btnstate);
  const dispatch = useDispatch();

  const role = props.homelocation.split("/")[1];
  
  const buttonChange = (event) => {
    // change button appearance when click if we click on another button it reset previous button
    if (state.btn) {
      state.btn.style.cssText = "";
      state.txt.style.cssText = "";
      state.bg.style.cssText = "";
    }

    if (event.currentTarget.previousSibling) {
      dispatch({
        type: "btnstate",
        value: {
          btn: event.currentTarget.previousElementSibling.children[0],
          txt: event.currentTarget.children[0],
          bg: event.currentTarget.parentNode,
        },
      });
      event.currentTarget.previousElementSibling.children[0].style.cssText =
        "box-shadow: 0px 0px 31px rgba(145,96,209,0.28);border-radius:18px";
      event.currentTarget.children[0].style.cssText =
        "color:#292929;font-weight:600";
      event.currentTarget.parentNode.style.cssText =
        "background-color:#F9F9F9;border-radius:16px 0px 0px 16px";
    } else {
      try {
        dispatch({
          type: "btnstate",
          value: {
            btn: event.target,
            txt: event.currentTarget.nextSibling.children[0],
            bg: event.currentTarget.parentNode,
          },
        });
        event.target.style.cssText =
          "box-shadow: 0px 0px 31px rgba(145,96,209,0.28);border-radius:18px";
        event.currentTarget.nextSibling.children[0].style.cssText =
          "color:#292929;font-weight:600";
        event.currentTarget.parentNode.style.cssText =
          "background-color:#F9F9F9;border-radius:16px 0px 0px 16px";
      } catch {}
    }
  };

  const location = useLocation();
  const locationPath = location.pathname.split("/")[2];

  return (
    <div className={"col-md-1 sidebar bg-white h-100"}>
      <Link to={props.homelocation}>
        <img
          src="/icon/common/mainlogo.png"
          alt={"companylogo"}
          className={"pb-3 companylogo"}
          style={{ position: "relative", right: "50px" }}
        />
      </Link>
      <div className={"dashbtn"}>
        <div className={"row align-items-center pl-5 py-2"}>
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/dashboardview`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath === "dashboardview"
              ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
              : {})}
            >
              <img
                src="/icon/common/Vector.png"
                alt={"dashboard"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/dashboardview`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                &nbsp;Dashboard
              </font>
            </Link>
          </div>
        </div>
        <div className={"row align-items-center pl-5 py-2"} id={"viewmore"}>
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/candidateList`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath.includes("candidate")
              ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
              : {})}
            >
              <img
                src="/icon/common/Vector(1).png"
                alt={"matchingjob"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/candidateList`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                Candidate
              </font>
            </Link>
          </div>
        </div>
        <div
          className={"row align-items-center pl-5 py-2"}
          id={"clientviewmore"}
        >
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/clientList`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath.includes("client")
              ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
              : {})}
            >
              <img
                src="/icon/common/Vector(2).png"
                alt={"application"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/clientList`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                Client
              </font>
            </Link>
          </div>
        </div>
        <div
          className={"row align-items-center pl-5 py-2"}
          id={"clientviewmore"}
        >
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/recruiterList`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath.includes("recruiter")
              ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
              : {})}
            >
              <img
                src="/icon/common/Vector(2).png"
                alt={"application"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/recruiterList`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                Recruiter
              </font>
            </Link>
          </div>
        </div>
        <div
          className={"row align-items-center pl-5 py-2"}
          id={"clientviewmore"}
        >
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/freelanceList`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath.includes("freelance")
              ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
              : {})}
            >
              <img
                src="/icon/common/Vector(2).png"
                alt={"application"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/freelanceList`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                Freelance
              </font>
            </Link>
          </div>
        </div>
        <div className={"row align-items-center pl-5 py-2"}>
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/user`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath === "user"
              ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
              : {})}
            >
              <img
                src="/icon/common/Vector(3).png"
                alt={"message"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/user`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                User
              </font>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});
