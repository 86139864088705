import React, { useEffect, useState } from "react";
import MessageBox from "./MessageBox";
import MessageBody from "./MessageBody";
import GoToTop from "../components/common/GoToTop";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendMessageList, singleMessageList } from "../api/common/messageList";

function Message(props) {
  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  const role = props.homelocation.split("/")[1];
  // return a message page
  try {
    document.getElementById("replacetitleimg").innerText = "Message";
  } catch {}
  const param = useParams();
  const uuid = param.id;
  const token = useSelector((state) => state.token);
  const [information, setInformation] = useState();
  const [sending, setSending] = useState(false);
  const [item, setItem] = useState([]);

  //get list of message
  useEffect(() => {
    async function callUserDetails() {
      // try {
      if (uuid) {
        const response = await singleMessageList(token, uuid);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.rows;
          const totalRecord = data.payload.count;
          if (totalRecord !== 0) {
            let dataSort = payload.sort(function compare(a, b) {
              var dateA = new Date(a.createdAt);
              var dateB = new Date(b.createdAt);
              return dateA - dateB;
            });
            setItem(dataSort);
          }
        }
      }
      // } catch {}
    }
    callUserDetails();
  }, [param.id]);
  //send message
  const handleSend = async () => {
    setSending(true);
    let data = {
      message: information,
    };
    const response = await sendMessageList(token, uuid, data);
    if (response.status === 200) {
      const response = await singleMessageList(token, uuid);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload.rows && data.payload.rows.reverse();
        const totalRecord = data.payload.count;
        setSending(false);
        if (totalRecord !== 0) {
          let dataSort = payload.sort(function compare(a, b) {
            var dateA = new Date(a.createdAt);
            var dateB = new Date(b.createdAt);
            return dateA - dateB;
          });
          setItem(dataSort);
        }
      }
      setInformation("");
    }
  };
  return (
    <div className={"row my-4 mx-4 bg-white w-100"} style={{ height: "83%" }}>
      <div className={"col-md-4"}>
        <div className={"row"}>
          <div className={"col pt-2  pb-3 rowborder"}>
            {/* <select className={"form-select primarybox py-2 px-4"}>
              <option value={"primary"}>Primary</option>
              <option value={"input"}>Input</option>
            </select> */}
          </div>
        </div>
        <div className={"row"}>
          <div
            className={"col messageboxborder"}
            style={{ overflowY: "auto", height: "500px" }}
          >
            <MessageBox role={role} />
          </div>
        </div>
      </div>
      <div className={"col-8"}>
        <div
          id={"myDiv"}
          style={{ overflowY: "auto", height: "500px", overflowX: "hidden" }}
        >
          <MessageBody item={item} />
        </div>
        {uuid && (
          <div
            className={"row bg-white col-12 mt-3 pr-0"}
            style={{ position: "sticky", bottom: "0px" }}
          >
            <div className={"col-10"}>
              <textarea
                className={"form-control resumeforminputfield shadow-none"}
                // ref={textareaRef}
                placeholder="Write your message"
                id="comment"
                value={information}
                onChange={(event) => setInformation(event.target.value)}
                style={{
                  fontSize: "14px",
                  paddingTop: "10px",
                  resize: "none",
                  overflow: "hidden",
                }}
              />
            </div>
            <div className={"col"}>
              {sending ? (
                <button
                  type={"button"}
                  className={"btn save pt-2 pb-2"}
                  onClick={handleSend}
                >
                  Sending..
                </button>
              ) : (
                <button
                  type={"button"}
                  className={"btn save pt-2 pb-2"}
                  onClick={handleSend}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <GoToTop />
    </div>
  );
}

export default React.memo(Message);
