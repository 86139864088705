import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { markasReadMessage, messageList } from "../api/common/messageList";
import { changeBgText } from "../function/common/message";

function MessageBox(props) {
  const token = useSelector((state) => state.token);
  const [match, setMatch] = useState([]);
  const history = useHistory();
  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = await messageList(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.rows;
          const totalRecord = data.payload.count;
          if (totalRecord !== 0) {
            let dataSort = payload.sort(function compare(a, b) {
              var dateA = new Date(a.updatedAt);
              var dateB = new Date(b.updatedAt);
              return dateA - dateB;
            });
            setMatch(dataSort && dataSort.reverse());
          }
        }
      } catch {}
    }
    callUserDetails();
  }, []);
  let role = props.role;
  const handleMessages = async (id, unreadMessages) => {
    history.push(`/${role}/message/${id}`);
    if (unreadMessages !== 0) {
      markasReadMessage(token, id);
    }
  };
  // return a message list card which contain short info
  return (
    <>
      {match.map((data, index) => (
        <div
          key={index}
          className={"pt-1 pb-1"}
          style={{ borderBottom: "2px solid #F1F1F5" }}
        >
          <div
            key={index}
            className={"p-2"}
            style={{
              background: "#ffffff",
              borderRadius: "15px",
            }}
            onClick={(e) => {
              changeBgText(e);
              handleMessages(data.uuid, data.unreadMessages);
            }}
          >
            <div className={"row"}>
              <div className={"col-2"}>
                <div className={"row"}>
                  <div className={"col"}>
                    <figure className="story_shape">
                      <img
                        src="/icon/common/darkprofile.png"
                        alt="person on a tour"
                        className="story_img"
                        style={{ transform: "scale(0.9)" }}
                      />
                      <figcaption className="story_caption">
                        {data.from !== null && data.from.firstName.charAt(0)}
                        {data.from !== null && data.from.lastName.charAt(0)}
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <div className={"col"}>
                <div className={"row ml-0"}>
                  <div className={"col-8"}>
                    <label className={"heading"}>
                      {data.from !== null && data.from.firstName}&nbsp;&nbsp;
                      {data.from !== null && data.from.lastName}
                    </label>
                  </div>
                  <div className={"col"}>
                    <label className={"time"}>
                      {new Date(data.updatedAt).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </label>
                  </div>
                </div>
                <div className={"row ml-0"}>
                  <label className={"title ml-3"}>{data.subject}</label>
                </div>
                <div className={"row ml-0"}>
                  <div className={"col-md"}>
                    {data.messages[0].type === "TEMPLATE" ? (
                      <label
                        className={"name"}
                        style={{
                          color: `${data.messages[0].read ? "" : "black"}`,
                          fontWeight: `${
                            data.messages[0].read ? "500" : "600"
                          }`,
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "200px",
                          }}
                        >
                          {data.messages[0].message.replace(/<[^>]+>/g, "")}
                        </div>
                      </label>
                    ) : (
                      <label
                        className={"name"}
                        style={{
                          color: `${data.messages[0].read ? "" : "black"}`,
                          fontWeight: `${
                            data.messages[0].read ? "500" : "600"
                          }`,
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "200px",
                          }}
                        >
                          {data.messages[0].message}
                        </div>
                      </label>
                    )}
                  </div>
                  {data.unreadMessages !== 0 ? (
                    <div className={"col-md"}>
                      <div className="res-circle">
                        <div className="circle-txt">{data.unreadMessages}</div>
                      </div>
                    </div>
                  ) : (
                    <div className={"col-md"}></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default React.memo(MessageBox);
