import { CLIENTMATCHINGJOBS } from "../ENVIRONMENT";

// api for
async function candidateDetails(token, id) {
  const response = await fetch(CLIENTMATCHINGJOBS + `/${id}`, {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { candidateDetails };
