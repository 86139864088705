import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/app.scss";

const ResumePreview = () => {
  // return resume preview page which appear after upload resume
  const [edit, setEdit] = useState(false);
  const [skilledit, setskillEdit] = useState(false);
  const [projectedit, setprojectEdit] = useState(false);
  const [experienceedit, setexperienceEdit] = useState(false);
  const [workedit, setworkEdit] = useState(false);

  return (
    <div className={"col-md mx-5 justify-content-center"}>
      <div className={"row my-3"}>
        <div className={"col-md mx-5"}>
          <div className={"row"}>
            <div
              className={"col-md dashboardheadingfont"}
              style={{ textAlign: "center" }}
            >
              Uploaded Resume Preview
            </div>
          </div>
          {/*this is second box*/}
          <div className={"row px-5 pr-5 mx-3 pt-4"}>
            <div
              className={
                "w-100 pt-3  pb-3 px-5 contentholder align-items-center"
              }
            >
              <div className={"col"}>
                <div className={"row"}>
                  <div className={"col-md-11"}>
                    <span style={{ fontWeight: "500", fontSize: "14px" }}>
                      Cover Letter
                    </span>
                  </div>
                  <div className={"col-md"}>
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      className={"float-right"}
                      onClick={() => setEdit(!edit)}
                    >
                      edit
                    </span>
                  </div>
                </div>
                <div className={"row pt-2 pr-3"}>
                  {edit ? (
                    <textarea
                      className={"col-md dashboardforminputtext"}
                      rows="6"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum que
                      laudantium, totam rem aperiam, eaque ipsa quae ab illo
                      inventore veritatis et quasi architecto beatae vitae dicta
                      su
                    </textarea>
                  ) : (
                    <div>
                      <div className={"col-md dashboardforminputtext"}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum que laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta su
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*this is third box*/}
          <div className={"row  px-5 pr-5 mx-3 pt-3"}>
            <div
              className={
                "w-100 pt-3 pb-3 px-5 contentholder align-items-center"
              }
            >
              <div className={"col"}>
                <div className={"row"}>
                  <div className={"col-md-11"}>
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      Skill
                    </span>
                  </div>
                  <div className={"col-md"}>
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      className={"float-right"}
                      onClick={() => setskillEdit(!skilledit)}
                    >
                      edit
                    </span>
                  </div>
                </div>
                <div className={"row pt-2  pr-3"}>
                  {skilledit ? (
                    <textarea
                      className={"col-md dashboardforminputtext"}
                      rows="6"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum que
                      laudantium, totam rem aperiam, eaque ipsa quae ab illo
                      inventore veritatis et quasi architecto beatae vitae dicta
                      su
                    </textarea>
                  ) : (
                    <div>
                      <div className={"col-md dashboardforminputtext"}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum que laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta su
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*this is forth box*/}
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div
              className={
                "w-100 pt-3 pb-3 px-5 contentholder align-items-center"
              }
            >
              <div className={"col"}>
                <div className={"row"}>
                  <div className={"col-md-11"}>
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      Project details
                    </span>
                  </div>
                  <div className={"col-md"}>
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      className={"float-right"}
                      onClick={() => setprojectEdit(!projectedit)}
                    >
                      edit
                    </span>
                  </div>
                </div>
                <div className={"row pt-2 pr-3"}>
                  {projectedit ? (
                    <textarea
                      className={"col-md dashboardforminputtext"}
                      rows="6"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum que
                      laudantium, totam rem aperiam, eaque ipsa quae ab illo
                      inventore veritatis et quasi architecto beatae vitae dicta
                      su
                    </textarea>
                  ) : (
                    <div>
                      <div className={"col-md dashboardforminputtext"}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum que laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta su
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*this is fifth box*/}
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div
              className={
                "w-100 pt-3 pb-3 px-5 contentholder align-items-center"
              }
            >
              <div className={"col"}>
                <div className={"row"}>
                  <div className={"col-md-11"}>
                    <span style={{ fontWeight: "500", fontSize: "14px" }}>
                      Experience
                    </span>
                  </div>
                  <div className={"col-md"}>
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      className={"float-right"}
                      onClick={() => setexperienceEdit(!experienceedit)}
                    >
                      edit
                    </span>
                  </div>
                </div>
                <div className={"row pt-2 pr-3"}>
                  {experienceedit ? (
                    <textarea
                      className={"col-md dashboardforminputtext"}
                      rows="6"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum que
                      laudantium, totam rem aperiam, eaque ipsa quae ab illo
                      inventore veritatis et quasi architecto beatae vitae dicta
                      su
                    </textarea>
                  ) : (
                    <div>
                      <div className={"col-md dashboardforminputtext"}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum que laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta su
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*this is sixth box*/}
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div
              className={
                "w-100 pt-3 pb-3 px-5 contentholder align-items-center"
              }
            >
              <div className={"col"}>
                <div className={"row"}>
                  <div className={"col-md-11"}>
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      Work Authorization
                    </span>
                  </div>
                  <div className={"col-md"}>
                    <span
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      className={"float-right"}
                      onClick={() => setworkEdit(!workedit)}
                    >
                      edit
                    </span>
                  </div>
                </div>
                <div className={"row pt-2 pr-3"}>
                  {workedit ? (
                    <textarea
                      className={"col-md dashboardforminputtext"}
                      rows="6"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum que
                      laudantium, totam rem aperiam, eaque ipsa quae ab illo
                      inventore veritatis et quasi architecto beatae vitae dicta
                      su
                    </textarea>
                  ) : (
                    <div>
                      <div className={"col-md dashboardforminputtext"}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum que laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta su
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"row  my-4"}>
        <div className={"container"}>
          <div className={"col-md-12 "}>
            <Link
              to={"/candidate/dashboardview"}
              className={"btn offset-sm-9 save"}
            >
              Save & Continue
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ResumePreview);
