const requiredList = [
  "firstName",
  "lastName",
  "department",
  "title",
  "emailId",
  "role",
  "action",
  "mobileNumber",
];

const errorMsg = [
  "first name",
  "last name",
  "department",
  "title",
  "emailId",
  "role",
  "action",
  "mobile number",
];

function verifyValidate(information, setError) {
  // check if required empty form will not submit
  let flag = true;
  requiredList.map((value, index) => {
    const val = information[value];
    if (!val) {
      flag = false;
      setError((state) => {
        return { ...state, [value]: `Please enter the ${errorMsg[index]}` };
      });
    }
  });

  const flagTwo = requiredList
    .map((value) => {
      return information[value] ? true : false;
    })
    .every((value) => {
      return value ? true : false;
    });

  return flag && flagTwo;
}

function validateRegistration(event, setInfo, setError) {
  // validate field to check wherther meet requirement or not
  let { name, value } = event.currentTarget;
  setError((state) => {
    if (name === "mobileNumber") {
      if (value.replace(/\s/g, "").length !== 10)
        return { ...state, [name]: "Must be 10 digits" };
      return { ...state, [name]: "" };
    } 
    else if (
      name === "firstName" ||
      name === "middleName" ||
      name === "lastName"
    ) {
      if (/\d/.test(value) || /[^a-zA-Z\s]/.test(value)) {
        return {
          ...state,
          [name]: "Numbers & special characters are not allowed",
        };
      } else {
        return { ...state, [name]: "" };
      }
    } 
    else {
      if (name === "emailId") {
        if (
          /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
            value
          ) 
        )
            return { ...state, [name]: "" };
            return { ...state, [name]: "Please enter valid email ID" };
       }
       else {
        return { ...state, [name]: "" };
      }
    }
  });
  setInfo((state) => {
    return { ...state, [name]: value };
  });
}

async function submit(
  event,
  // btnRef,
  setLoader,
  error,
  information,
  setError
  //token
) {
  event.preventDefault();
  if (!verifyValidate(information, setError)) return;
  const result = Object.values(error)
    .map((data) => {
      if (data) return true;
      return false;
    })
    .some((data) => {
      if (data) return true;
      return false;
    });

  if (!result) {
    console.log("true baba true");
  }
}

export { validateRegistration, submit, verifyValidate };
