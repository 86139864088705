import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  WorkAuthorization,
  rateCardValidate,
} from "../../function/client/rateCard";
import { data } from "../../function/client/rateCardData";
import { getRatecardDetails } from "../../api/client/rateCard";
import { getRequirementInfo } from "../../api/client/getRequirementInfo";
import { recgetRequirementInfo } from "../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { freegetRequirementInfo } from "../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { recgetRatecardDetails } from "../../api/recruiter/client/BuildRequirement/rateCard";
import { freegetRatecardDetails } from "../../api/freelance/client/BuildRequirement/rateCard";

const RateCard = ({
  workAuthorization,
  preferredEmployment,
  rateType,
  rate,
  setSkillNavigation,
  setAcademicNavigation,
  setSecurityNavigation,
  setDescriptionNavigation,
}) => {
  // return rate card
  const rateVal = [
    "W2With",
    "W2Without",
    "C2H",
    "C2C",
    "W2HourlyWith",
    "W2HourlyWithout",
    "N1099With",
    "N1099Without"
  ];
  const rateValError = [
    "W2Witherror",
    "W2Withouterror",
    "c2herror",
    "c2cerror",
    "W2Hourlyerror",
    "W2Hourlywithouterror",
    "1099error",
    "1099Withouterror"
  ];
  //const submitEnabled = props.submitEnabled;
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const param = useParams();
  const id = param.id;
  useEffect(() => {
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await recgetRequirementInfo(token, id)
          : isFreelance
          ? await freegetRequirementInfo(token, id)
          : await getRequirementInfo(token, id);
        if (response.status === 200) {
          const form = document.forms[0];
          const data = await response.json();
          const payload = data.payload.requirement;
          if (payload) {
            if (payload.W2With) {
              form.W2With.value = payload.W2With;
              form.W2WithCheck.checked = true;
            } else {
              form.W2With.value = "";
              form.W2WithCheck.checked = false;
            }
            if (payload.W2Without) {
              form.W2Without.value = payload.W2Without;
              form.W2WithoutCheck.checked = true;
            } else {
              form.W2Without.value = "";
              form.W2WithoutCheck.checked = false;
            }
            if (payload.W2HourlyWith) {
              form.W2HourlyWith.value = payload.W2HourlyWith;
              form.W2HourlyWithCheck.checked = true;
            } else {
              form.W2HourlyWith.value = "";
              form.W2HourlyWithCheck.checked = false;
            }
            if (payload.W2HourlyWithout) {
              form.W2HourlyWithout.value = payload.W2HourlyWithout;
              form.W2HourlyWithoutCheck.checked = true;
            } else {
              form.W2HourlyWithout.value = "";
              form.W2HourlyWithoutCheck.checked = false;
            }
            if (payload.N1099With) {
              form.N1099With.value = payload.N1099With;
              form.N1099WithCheck.checked = true;
            } else {
              form.N1099With.value = "";
              form.N1099WithCheck.checked = false;
            }
            if (payload.N1099Without) {
              form.N1099Without.value = payload.N1099Without;
              form.N1099WithoutCheck.checked = true;
            } else {
              form.N1099Without.value = "";
              form.N1099WithoutCheck.checked = false;
            }
            if (payload.C2H) {
              form.C2H.value = payload.C2H;
              form.C2HCheck.checked = true;
            } else {
              form.C2H.value = "";
              form.C2HCheck.checked = false;
            }
            if (payload.C2C) {
              form.C2C.value = payload.C2C;
              form.C2CCheck.checked = true;
            } else {
              form.C2C.value = "";
              form.C2CCheck.checked = false;
            }
            document.getElementById("ratecardid").style.display = "";
          }
          if(payload.rateType && payload.rateType === "Hourly") {
            document.getElementsByClassName("ratefield")[0].maxLength = "3"
          }
          if(payload.rateType && payload.rateType === "Yearly") {
            document.getElementsByClassName("ratefield")[0].maxLength = "6" 
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);

  async function reloadRateValue() {
    try {
      const form = document.forms[0];
      const data = {
        employmentType: form.taxterm.value || "",
        rateType: form.duration.value || "",
        rate: form.rate.value || 0,
      };
      document.getElementsByClassName("totalCheckError")[0].innerHTML = ""
      const rateCardResponse = await getRatecardDetails(token, data);
      if (rateCardResponse.status === 200) {
        let ratePayload = await rateCardResponse.json();
        ratePayload = ratePayload.payload;
        if (ratePayload) {
          rateVal.map((key) => {
            if (ratePayload[key]) {
              try {
                document.getElementsByClassName(key)[0].style.display = "";
              } catch {}
              form[key].value = ratePayload[key];
              form[key + "Check"].checked = true;
            } else {
              form[key].value = "";
              form[key + "Check"].checked = false;
              document.getElementsByClassName(key)[0].style.display = "none";
            }
            return null;
          });
        }
      }
      rateValError.map((key) => {
        document.getElementsByClassName(`${key}`)[0].innerHTML =""
      })
    } catch {}
  }

  useEffect(reloadRateValue, []);

  if (rate) document.getElementById("rate").value = rate;

  function Duration() {
    try {
      let option = ["Select", "Hourly", "Yearly"];
      if (rateType === "Hourly") option = ["Hourly", "Yearly"];
      else if (rateType === "Yearly") option = ["Yearly", "Hourly"];
      return option.map((val, index) => {
        return (
          <option key={index} value={val}>
            {val}
          </option>
        );
      });
    } catch {}
  }

  function submitEnabled() {
    try {
      document.getElementsByClassName("hidebtn")[0].style.display = "block";
      setSkillNavigation(false);
      setAcademicNavigation(false);
      setSecurityNavigation(false);
      setDescriptionNavigation(false);
    } catch {}
  }

  function callRateType(event) {
    rateCardValidate(event);
    reloadRateValue();
    document.getElementById("rateTypeerror").innerHTML =""
    document.getElementsByClassName("selectrateType")[0].style.cssText = ""
    const rateValue = event.target.form.rate.value
    
    if(rateValue && rateValue.substring(0,1) === "0") {
      document.getElementById("rateerror").innerHTML ="cannot be 0"
      document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
    }
    else if(rateValue !== ""){
      if(event.target.form.duration.value === "Hourly") {
        document.getElementsByClassName("ratefield")[0].maxLength = "3"
        if(rateValue > 500 || rateValue < 15) {
          document.getElementById("rateerror").innerHTML ="Must be 2-3 digits (between 15 to 500)"
          document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
        }
        else {
          document.getElementById("rateerror").innerHTML =""
          document.getElementsByClassName("ratefield")[0].style.cssText = ""
        }
      }
      else if(event.target.form.duration.value === "Yearly") {
        document.getElementsByClassName("ratefield")[0].maxLength = "6"
        if(rateValue > 500000 || rateValue < 30000) {
          document.getElementById("rateerror").innerHTML ="Must be 5-6 digits (between 30000 to 500000)"
          document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
        }
        else {
          document.getElementById("rateerror").innerHTML =""
          document.getElementsByClassName("ratefield")[0].style.cssText = ""
        }
      }
    }
    submitEnabled();
  }

  function checkRateValue (e) {
    if(e.target.value !== "") {

      const ratefieldValue = e.target.form.rate.value

      if(e.target.value.substring(0,1) === "0") {
        document.getElementById("rateerror").innerHTML ="cannot be 0"
        document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
      }
      else {
        if(e.target.form.duration.value === "Hourly") {
          if(ratefieldValue > 500 || ratefieldValue < 15 ) {
            document.getElementById("rateerror").innerHTML ="Must be 2-3 digits (between 15 to 500)"
            document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
          }
          else {
            document.getElementById("rateerror").innerHTML =""
            document.getElementsByClassName("ratefield")[0].style.cssText = ""
          }
        }
        else if(e.target.form.duration.value === "Yearly") {
          if(ratefieldValue > 500000 || ratefieldValue < 30000) {
            document.getElementById("rateerror").innerHTML ="Must be 5-6 digits (between 30000 to 500000)"
            document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
          }
          else {
            document.getElementById("rateerror").innerHTML =""
            document.getElementsByClassName("ratefield")[0].style.cssText = ""
          }
        }
      }

    }
    else {
      document.getElementById("rateerror").innerHTML =""
      document.getElementsByClassName("ratefield")[0].style.cssText = ""
    }
  }

  function taxTermValidation (e,name) {
    let fieldValue = e.target.value
    let fieldLength = e.target.value.length
    if(fieldValue && fieldValue.substring(0,1) === "0") {
      document.getElementsByClassName(`${name}`)[0].innerHTML ="cannot be 0"
    }
    else {
      if(fieldValue !== "") {
         if(fieldLength > 3 || fieldLength < 2) {
            document.getElementsByClassName(`${name}`)[0].innerHTML ="Must be 2-3 digits"
          }
          else {
            document.getElementsByClassName(`${name}`)[0].innerHTML =""
          }
      }
      else {
        document.getElementsByClassName(`${name}`)[0].innerHTML =""
      }
    }
  }

  return (
    <>
      <div className={"row pt-1 px-3 pl-4"}>
        <div className={"col-md-4 pt-3"}>
          <label htmlFor={"taxterms"} className={"dashboardforminputtext"}>
            Employment Type<span style={{ color: "red" }}>*</span>
          </label>
          <select
            className={
              "form-control dashboardforminputfield form-select inputgray2 shadow-none selectemploy"
            }
            id={"taxtermsid"}
            name={"taxterm"}
            onChange={(e) => {
              rateCardValidate(e)
              reloadRateValue();
              document.getElementById("employmenterror").innerHTML =""
              document.getElementsByClassName("selectemploy")[0].style.cssText = ""
              submitEnabled();
            }}
          >
            <option value={"Select"}>Select</option>
            <option value={"W2With"}>W2With</option>
            <option value={"W2Without"}>W2Without</option>
            <option value={"W2HourlyWith"}>W2HourlyWith</option>
            <option value={"W2HourlyWithout"}>W2HourlyWithout</option>
            <option value={"C2H"}>C2H</option>
            <option value={"C2C"}>C2C</option>
            <option value={"N1099With"}>N1099With</option>
            <option value={"N1099With"}>N1099Without</option>
          </select>
          <label id={"employmenterror"} className={"dashboardforminputtext text-danger text-align-center"}></label>
        </div>
        <div className={"col-md-2 pt-5"}>
          <select
            className={
              "form-control dashboardforminputfield form-select inputgray2 shadow-none selectrateType"
            }
            name={"duration"}
            onChange={callRateType}
          >
            <Duration />
          </select>
          <label id={"rateTypeerror"} className={"dashboardforminputtext text-danger text-align-center"}></label>
        </div>
        <div className={"col-md-2 pt-3"}>
          <label htmlFor={"rate"} className={"dashboardforminputtext pl-1"}>
            Rate<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type={"text"}
            id={"rate"}
            className={"form-control dashboardforminputfield shadow-none ratefield"}
            name={"rate"}
            onKeyUp={rateCardValidate}
            onKeyDown={rateCardValidate}
            onKeyPress={rateCardValidate}
            onClick={rateCardValidate}
            maxLength={6}
            onChange={(e) => {
              reloadRateValue();
              checkRateValue(e)
              submitEnabled();
            }}
            onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
          />
          <label id={"rateerror"} className={"dashboardforminputtext text-danger text-align-center"}></label>
        </div>
      </div>
      <div className={"row"} id={"ratecardid"} style={{ display: "none" }}>
        <div className={"col-lg-9 pt-5"}>
          <table className="table  p-5 table-bordered">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Tax Term Calculation</th>
                <th>Benefits</th>
                <th>Rate</th>
                <th>Select</th>
                <th style={{border:"1px solid white"}}></th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              <tr className={"W2With"}>
                <td>W2</td>
                <td>With</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"W2With"}
                      onChange={(e)=>{
                        taxTermValidation(e,"W2Witherror")
                      }}
                      maxLength={3}
                      onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
                      />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="W2WithCheck"
                    onChange={()=>document.getElementsByClassName("totalCheckError")[0].innerHTML = ""}
                  />
                </td>
                <td className={"W2Witherror col-lg-3"} style={{border:"1px solid white",color: "red"}}></td>
              </tr>

              <tr className={"W2Without"}>
                <td>W2</td>
                <td>Without</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"W2Without"}
                      onChange={(e)=>{
                        taxTermValidation(e,"W2Withouterror")
                      }}
                      maxLength={3}
                      onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
                      />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="W2WithoutCheck"
                    onChange={()=>document.getElementsByClassName("totalCheckError")[0].innerHTML = ""}
                  />
                </td>
                <td className={"W2Withouterror col-lg-3"} style={{border:"1px solid white",color: "red"}}></td>
              </tr>

              <tr className={"W2HourlyWith"}>
                <td>W2 Hourly</td>
                <td>With</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"W2HourlyWith"}
                      onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
                      onChange={(e)=>{
                        taxTermValidation(e,"W2Hourlyerror")
                      }}
                      maxLength={3}
                      />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="W2HourlyWithCheck"
                    onChange={()=>document.getElementsByClassName("totalCheckError")[0].innerHTML = ""}
                  />
                </td>
                <td className={"W2Hourlyerror"} 
                    style={{border:"1px solid white",color: "red"}}></td>
              </tr>
              <tr className={"W2HourlyWithout"}>
                <td>W2 Hourly</td>
                <td>Without</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"W2HourlyWithout"}
                      onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
                      onChange={(e)=>{
                        taxTermValidation(e,"W2Hourlywithouterror")
                      }}
                      maxLength={3}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="W2HourlyWithoutCheck"
                    onChange={()=>document.getElementsByClassName("totalCheckError")[0].innerHTML = ""}
                  />
                </td>
                <td className={"W2Hourlywithouterror"} 
                    style={{border:"1px solid white",color: "red"}}></td>
              </tr>

              <tr className={"N1099With"}>
                <td>1099</td>
                <td>With</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"N1099With"}
                      onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
                      onChange={(e)=>{
                        taxTermValidation(e,"1099error")
                      }}
                      maxLength={3}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="N1099WithCheck"
                    onChange={()=>document.getElementsByClassName("totalCheckError")[0].innerHTML = ""}
                  />
                </td>
                <td className={"1099error"} 
                    style={{border:"1px solid white",color: "red"}}></td>
              </tr>

              <tr className={"N1099Without"}>
                <td>1099</td>
                <td>Without</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"N1099Without"}
                      onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
                      onChange={(e)=>{
                        taxTermValidation(e,"1099Withouterror")
                      }}
                      maxLength={3}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="N1099WithoutCheck"
                    onChange={()=>document.getElementsByClassName("totalCheckError")[0].innerHTML = ""}
                  />
                </td>
                <td className={"1099Withouterror"} 
                    style={{border:"1px solid white",color: "red"}}></td>
              </tr>

              <tr className={"C2C"}>
                <td>C2C</td>
                <td></td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"C2C"}
                      onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
                      onChange={(e)=>{
                        taxTermValidation(e,"c2cerror")
                      }}
                      maxLength={3}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="C2CCheck"
                    onChange={()=>document.getElementsByClassName("totalCheckError")[0].innerHTML = ""}
                  />
                </td>
                <td className={"c2cerror"} 
                    style={{border:"1px solid white",color: "red"}}></td>
              </tr>

              <tr className={"C2H"}>
                <td>C2H</td>
                <td></td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"C2H"}
                      onInput={(e) => {e.target.value=e.target.value.replace(/[^0-9]/g,'')}}
                      onChange={(e)=>{
                        taxTermValidation(e,"c2herror")
                      }}
                      maxLength={3}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="C2HCheck"
                    onChange={()=>document.getElementsByClassName("totalCheckError")[0].innerHTML = ""}
                  />
                </td>
                <td className={"c2herror"} 
                    style={{border:"1px solid white",color: "red"}}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className={"col-md"}>
          <label className={"totalCheckError text-danger"} style={{color: "red",fontSize: "14px"}}></label>
        </div>
      </div>
    </>
  );
};

export default React.memo(RateCard);
