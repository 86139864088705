import { CANDIDATESKILL } from "../ENVIRONMENT";

async function postBuildResumeSkill(token, data) {
  // api for submit candidate buildresume skills
  return await fetch(CANDIDATESKILL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

async function getBuildResumeSkill(token) {
  // api for candidate buildresume skills details
  return await fetch(CANDIDATESKILL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { postBuildResumeSkill, getBuildResumeSkill };
