import { CLIENTSHORTLIST } from "../ENVIRONMENT";

// api for client shortilist job
async function shortlistJobs(token, data, status) {
  return await fetch(CLIENTSHORTLIST + `?shortlist=${status}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { shortlistJobs };
