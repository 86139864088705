import { MATCHINGJOBS } from "../ENVIRONMENT";

async function Search(token, data, page) {
  // api for candidate search
  const response = await fetch(
    MATCHINGJOBS + `?q=${data}&limit=6&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function SearchByCard(token, data, page,card) {
  // api for candidate search
  const response = await fetch(
    MATCHINGJOBS + `?q=${data}&limit=6&page=${page}&card=${card}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { Search,SearchByCard };
