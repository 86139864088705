function postJob(event, setUploadBtnFlag) {
  // onclick postjob button appearance will be change
  event.target.style.backgroundColor = "#3F9AE0";
  event.target.parentNode.nextSibling.childNodes[0].style.backgroundColor =
    "#BEBEBE";
  setUploadBtnFlag(false);
}

function uploadProfile(event, setUploadBtnFlag) {
  // onclick uploadprofile button appearance will be change
  event.target.style.backgroundColor = "#3F9AE0";
  event.target.parentNode.previousSibling.childNodes[0].style.backgroundColor =
    "#BEBEBE";
  setUploadBtnFlag(true);
}

export { postJob, uploadProfile };
