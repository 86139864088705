async function getPermanentData(location, token) {
  // api for candidate permanentdata details
  return await fetch(location, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { getPermanentData };
