import "../../styles/app.scss";
import React, {
  useEffect,
  forwardRef,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import GoToTop from "../common/GoToTop";
import Getmonth from "../../common-component/GetMonth";
import Getyear from "../../common-component/GetYear";
import SnapshotDiv from "../../common-component/clientSnapshotDiv";
import SelectCalendar from "../../function/common/SelectCalendar";
import { Link, Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { homePage } from "../../function/common/homePage";
import {
  postBuildResumeSkill,
  getBuildResumeSkill,
} from "../../api/recruiter/candidate/buildResume/buildResumeSkill";
import { setSkillData } from "../../function/candidate/setSkillData";
import { getSkillParseData } from "../../function/candidate/getSkillParseData";
import {
  getExperienceParseData,
  postExperienceParseData,
} from "../../function/candidate/parseExperienceData";
import {
  getExperience,
  postExperience,
} from "../../api/recruiter/candidate/buildResume/buildResumeExperience";
import {
  postAcademic,
  getAcademic,
} from "../../api/recruiter/candidate/buildResume/buildResumeAcademic";
import {
  parseGetAcademic,
  parsePostAcademic,
} from "../../function/candidate/parseAcademicData";
import {
  postParseData,
  getParseData,
} from "../../function/candidate/parseSecurityData";
import {
  postSecurity,
  getSecurity,
} from "../../api/recruiter/candidate/buildResume/buildResumeSecurity";
import {
  postBuildResumeSocial,
  getBuildResumeSocial,
} from "../../api/recruiter/candidate/buildResume/buildResumeSocial";
import {
  buildAcademic,
  buildResumeExperience,
  buildResumeSkill,
  securityClearance,
  buildCertificate,
  secondarySkill,
} from "../../function/candidate/validateField.js";
import {
  verifyBuildAcademic,
  verifyBuildResumeExperience,
  verifySecurityClearance,
} from "../../function/candidate/checkRequired.js";
import YearOfPassing from "../common/YearOfPassing";
import { uploadDocument } from "../../api/candidate/uploadDocument";
import { snapshot } from "../../api/recruiter/candidate/buildResume/snapshot";
import { useParams } from "react-router-dom";
import {
  freegetBuildResumeSkill,
  freepostBuildResumeSkill,
} from "../../api/freelance/candidate/buildResume/buildResumeSkill";
import {
  freegetExperience,
  freepostExperience,
} from "../../api/freelance/candidate/buildResume/buildResumeExperience";
import {
  freegetAcademic,
  freepostAcademic,
} from "../../api/freelance/candidate/buildResume/buildResumeAcademic";
import {
  freegetSecurity,
  freepostSecurity,
} from "../../api/freelance/candidate/buildResume/buildResumeSecurity";
import {
  freegetBuildResumeSocial,
  freepostBuildResumeSocial,
} from "../../api/freelance/candidate/buildResume/buildResumeSocial";
import { freelanceHomePage } from "../../function/common/freelanceHomepage";
import { freesnapshot } from "../../api/freelance/candidate/buildResume/snapshot";
import { freeuploadDocument } from "../../api/freelance/candidate/buildResume/uploadDocument";
import { recuploadDocument } from "../../api/recruiter/candidate/buildResume/uploadDocument";
import { locationListSearch } from "../../api/common/locationList";
import YearOfValidity from "../common/yearOfValidity";
import GetValidityMonth from "../common/GetValidityMonth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  CreateSignedUrlAPI,
  uploadImage,
} from "../../api/common/ImageUpload";
import GetPrimaryMonth from "../../common-component/GetPrimaryMonth";

const ResumeSnapshot = () => {
  // return resume snapshot page
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  try {
    document.getElementById("dashboardfont").innerText = "Your Resume";
    document.getElementById("dashboardbtn").style.display = "none";
    document.getElementById("replacetitleimg").innerText = "Resume Snapshot";
  } catch {}

  const token = useSelector((state) => state.token);
  const isFreelance = useSelector((state) => state.isFreelance);
  const param = useParams();
  const id = param.id;
  let snapRef = useRef();

  // primary skill required assets
  const [redirect, setRedirect] = useState(false);
  const [primaryDate, setPrimaryDate] = useState(new Date());
  const [primaryDate0, setPrimaryDate0] = useState(new Date());
  const [primaryDate1, setPrimaryDate1] = useState(new Date());
  const [primaryDate2, setPrimaryDate2] = useState(new Date());
  const [secondaryDate, setSecondaryDate] = useState(new Date());
  const [secondaryDate0, setSecondaryDate0] = useState(new Date());
  const [secondaryDate1, setSecondaryDate1] = useState(new Date());
  const [secondaryDate2, setSecondaryDate2] = useState(new Date());

  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate1, setToDate1] = useState(new Date());
  const [fromDate1, setFromDate1] = useState(new Date());
  const [toDate2, setToDate2] = useState(new Date());
  const [fromDate2, setFromDate2] = useState(new Date());

  const [socialLink, setSocialLink] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pintrest: "",
    youtube: "",
    skype: "",
    website: "",
  });
  const [primaryInformation, setPrimaryInformation] = useState({
    month: "",
    year: "",
    month0: "",
    year0: "",
    month1: "",
    year1: "",
    month2: "",
    year2: "",
    primarySkill: "",
    primarySkill0: "",
    primarySkill1: "",
    primarySkill2: "",
  });
  const [primaryError, setPrimaryError] = useState({
    month: "",
    year: "",
    month0: "",
    year0: "",
    month1: "",
    year1: "",
    month2: "",
    year2: "",
    primarySkill: "",
    primarySkill0: "",
    primarySkill1: "",
    primarySkill2: "",
  });

  const [primaryVisible, setPrimaryVisible] = useState({
    one: false,
    two: false,
    three: false,
  });
  //more primary skill
  function AddPrimarySkill() {
    setPrimaryVisible((state) => {
      if (state.one === false && state.two) return { ...state, one: true };
      else if (state.two === false && state.three)
        return { ...state, two: true };
      else if (state.three === false) return { ...state, three: true };
      return state;
    });
  }
  //secondary skill
  const [secondaryInfo, setSecondaryInfo] = useState({
    secondarySkill: "",
    secondarySkillMonth: "",
    secondarySkillYear: "",
    secondarySkill0: "",
    secondarySkillMonth0: "",
    secondarySkillYear0: "",
    secondarySkill1: "",
    secondarySkillMonth1: "",
    secondarySkillYear1: "",
    secondarySkill2: "",
    secondarySkillMonth2: "",
    secondarySkillYear2: "",
  });
  const [secondaryError, setSecondaryError] = useState({
    secondarySkill: "",
    secondarySkill0: "",
    secondarySkill1: "",
    secondarySkill2: "",
  });
  const [secondaryVisible, setSecondaryVisible] = useState({
    one: false,
    two: false,
    three: false,
  });
  //more secondary skill
  function addSecondarySkill() {
    setSecondaryVisible((state) => {
      if (!state.one) return { ...state, one: true };
      if (!state.two) return { ...state, two: true };
      if (!state.three) return { ...state, three: true };
      return state;
    });
  }
  // experience tab assets
  const [experienceInformation, setExperienceInformation] = useState({
    yearOfExp: "",
    yearOfExpUS: "",
    jobTitle: "",
    client: "",
    domain: "",
    location: "",
    projectSummary: "",
    roleAndResponse: "",
  });
  const [experienceError, setExperienceError] = useState({
    yearOfExp: "",
    yearOfExpUS: "",
    jobTitle: "",
    client: "",
    domain: "",
    location: "",
  });

  const [experienceVisibility, setExperienceVisibility] = useState({
    one: false,
    two: false,
  });

  const MIN_TEXTAREA_HEIGHT = 100;
  const MIN_TEXTAREA_HEIGHT1 = 100;
  const MIN_TEXTAREA_HEIGHT2 = 100;

  const textareaRef = useRef(null);
  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (textareaRef.current !== null) {
      textareaRef.current.style.height = "inherit";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    } else {
    }
  }, [experienceInformation.roleAndResponse]);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (textareaRef1.current !== null) {
      textareaRef1.current.style.height = "inherit";
      // Set height
      textareaRef1.current.style.height = `${Math.max(
        textareaRef1.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT1
      )}px`;
    } else {
    }
  }, [experienceInformation.roleAndResponse1]);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (textareaRef2.current !== null) {
      textareaRef2.current.style.height = "inherit";
      // Set height
      textareaRef2.current.style.height = `${Math.max(
        textareaRef2.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT2
      )}px`;
    } else {
    }
  }, [experienceInformation.roleAndResponse2]);

  const MIN_SUMMARY_HEIGHT = 100;
  const MIN_SUMMARY_HEIGHT1 = 100;
  const MIN_SUMMARY_HEIGHT2 = 100;
  //increase height of project summary
  const summaryRef = useRef(null);
  const summaryRef1 = useRef(null);
  const summaryRef2 = useRef(null);

  useLayoutEffect(() => {
    if (summaryRef.current !== null) {
      summaryRef.current.style.height = "inherit";
      summaryRef.current.style.height = `${Math.max(
        summaryRef.current.scrollHeight,
        MIN_SUMMARY_HEIGHT
      )}px`;
    } else {
    }
  }, [experienceInformation.projectSummary]);

  useLayoutEffect(() => {
    if (summaryRef1.current !== null) {
      summaryRef1.current.style.height = "inherit";
      summaryRef1.current.style.height = `${Math.max(
        summaryRef1.current.scrollHeight,
        MIN_SUMMARY_HEIGHT1
      )}px`;
    } else {
    }
  }, [experienceInformation.projectSummary1]);

  useLayoutEffect(() => {
    if (summaryRef2.current !== null) {
      summaryRef2.current.style.height = "inherit";
      summaryRef2.current.style.height = `${Math.max(
        summaryRef2.current.scrollHeight,
        MIN_SUMMARY_HEIGHT2
      )}px`;
    } else {
    }
  }, [experienceInformation.projectSummary2]);

  useEffect(() => {
    setExperienceInformation((state) => {
      return {
        ...state,
        yearOfExp1: "",
        yearOfExpUS1: "",
        jobTitle1: "",
        client1: "",
        domain1: "",
        location1: "",
        projectSummary1: "",
        roleAndResponse1: "",
      };
    });
    setExperienceError((state) => {
      return {
        ...state,
        yearOfExp1: "",
        yearOfExpUS1: "",
        jobTitle1: "",
        client1: "",
        domain1: "",
        location1: "",
      };
    });
  }, [experienceVisibility.one]);

  useEffect(() => {
    setExperienceInformation((state) => {
      return {
        ...state,
        yearOfExp2: "",
        yearOfExpUS2: "",
        jobTitle2: "",
        client2: "",
        domain2: "",
        location2: "",
        projectSummary2: "",
        roleAndResponse2: "",
      };
    });
    setExperienceError((state) => {
      return {
        ...state,
        yearOfExp2: "",
        yearOfExpUS2: "",
        jobTitle2: "",
        client2: "",
        domain2: "",
        location2: "",
      };
    });
  }, [experienceVisibility.two]);
  /*add more experience*/
  const Addproject = () => {
    setExperienceVisibility((state) => {
      if (!state.one) state = { ...state, one: true };
      else if (!state.two) state = { ...state, two: true };
      return state;
    });
  };

  useEffect(() => {
    setExperienceError((state) => {
      if (
        parseInt(experienceInformation.yearOfExp) <
        parseInt(experienceInformation.yearOfExpUS)
      ) {
        return {
          ...state,
          yearOfExpUS: "US experience cannot be greater than experience",
        };
      }
      return {
        ...state,
        yearOfExpUS: "",
      };
    });

    setExperienceError((state) => {
      if (
        parseInt(experienceInformation.yearOfExp1) <
        parseInt(experienceInformation.yearOfExpUS1)
      ) {
        return {
          ...state,
          yearOfExpUS1: "US experience cannot be greater than experience",
        };
      }
      return {
        ...state,
        yearOfExpUS1: "",
      };
    });

    setExperienceError((state) => {
      if (
        parseInt(experienceInformation.yearOfExp2) <
        parseInt(experienceInformation.yearOfExpUS2)
      ) {
        return {
          ...state,
          yearOfExpUS2: "US experience cannot be greater than experience",
        };
      }
      return {
        ...state,
        yearOfExpUS2: "",
      };
    });
  }, [
    experienceInformation.yearOfExpUS,
    experienceInformation.yearOfExp,
    experienceInformation.yearOfExpUS1,
    experienceInformation.yearOfExp1,
    experienceInformation.yearOfExpUS2,
    experienceInformation.yearOfExp2,
  ]);

  //Location integration
  const [citylist, setCityList] = useState([]);
  const [cityShow, setCityShow] = useState(false);

  async function handleLocationChange() {
    let data = experienceInformation.location;
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList(payload);
    } else if (response.status === 404) {
      setCityShow(false);
      setExperienceError({
        ...experienceError,
        location: "please enter valid city",
      });
    }
  }

  useEffect(() => {
    handleLocationChange();
  }, [experienceInformation.location]);

  async function selectedLocation(city) {
    setCityShow(false);
    setExperienceInformation({
      ...experienceInformation,
      location: city,
    });
    setExperienceError({
      ...experienceError,
      location: "",
    });
  }
  function locationValid() {
    let locationValue = experienceInformation.location;
    let mapped = citylist.map((data) => data.city);
    let found = mapped.includes(locationValue);
    if (experienceInformation.location.length !== 0) {
      if (found) {
        setExperienceError({
          ...experienceError,
          location: "",
        });
      } else {
        setExperienceError({
          ...experienceError,
          location: "please enter valid city",
        });
      }
    }
  }
  // for location outside click
  const locref = React.useRef();
  useEffect(() => {
    const locHandleClickOutside = (event) => {
      if (
        cityShow &&
        locref.current &&
        !locref.current.contains(event.target)
      ) {
        document.getElementById("preLocCodeDiv").style.display = "none";
        locationValid();
      }
      document.body.removeEventListener("click", locHandleClickOutside);
    };
    document.body.addEventListener("click", locHandleClickOutside);
  }, [cityShow, citylist, experienceInformation.location]);

  //location1 integration
  const [citylist1, setCityList1] = useState([]);
  const [cityShow1, setCityShow1] = useState(false);

  async function handleLocationOneChange() {
    let data = experienceInformation.location1
      ? experienceInformation.location1
      : "";
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList1(payload);
    } else if (response.status === 404) {
      setCityShow1(false);
      setExperienceError({
        ...experienceError,
        location1: "please enter valid city",
      });
    }
  }

  useEffect(() => {
    handleLocationOneChange();
  }, [experienceInformation.location1]);

  async function selectedLocationOne(city) {
    setCityShow1(false);
    setExperienceInformation({
      ...experienceInformation,
      location1: city,
    });
    setExperienceError({
      ...experienceError,
      location1: "",
    });
  }
  function locationOneValid() {
    let locationValue = experienceInformation.location1;
    let mapped = citylist.map((data) => data.city);
    let found = mapped.includes(locationValue);
    if (experienceInformation.location1.length !== 0) {
      if (found) {
        setExperienceError({
          ...experienceError,
          location1: "",
        });
      } else {
        setExperienceError({
          ...experienceError,
          location1: "please enter valid city",
        });
      }
    }
  }
  // for location1 outside click
  const firstref = React.useRef();
  useEffect(() => {
    const firstLocHandleClickOutside = (event) => {
      if (
        cityShow1 &&
        firstref.current &&
        !firstref.current.contains(event.target)
      ) {
        document.getElementById("firstLocCodeDiv").style.display = "none";
        locationOneValid();
      }
      document.body.removeEventListener("click", firstLocHandleClickOutside);
    };
    document.body.addEventListener("click", firstLocHandleClickOutside);
  }, [cityShow1, citylist1, experienceInformation.location1]);

  //location2 integration
  const [citylist2, setCityList2] = useState([]);
  const [cityShow2, setCityShow2] = useState(false);

  async function handleLocationTwoChange() {
    let data = experienceInformation.location2
      ? experienceInformation.location2
      : "";
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList2(payload);
    } else if (response.status === 404) {
      setCityShow2(false);
      setExperienceError({
        ...experienceError,
        location2: "please enter valid city",
      });
    }
  }

  useEffect(() => {
    handleLocationTwoChange();
  }, [experienceInformation.location2]);

  async function selectedLocationTwo(city) {
    setCityShow2(false);
    setExperienceInformation({
      ...experienceInformation,
      location2: city,
    });
    setExperienceError({
      ...experienceError,
      location2: "",
    });
  }
  function locationTwoValid() {
    let locationValue = experienceInformation.location2;
    let mapped = citylist.map((data) => data.city);
    let found = mapped.includes(locationValue);
    if (experienceInformation.location2.length !== 0) {
      if (found) {
        setExperienceError({
          ...experienceError,
          location2: "",
        });
      } else {
        setExperienceError({
          ...experienceError,
          location2: "please enter valid city",
        });
      }
    }
  }
  // for location2 outside click
  const secref = React.useRef();
  useEffect(() => {
    const secLocHandleClickOutside = (event) => {
      if (
        cityShow2 &&
        secref.current &&
        !secref.current.contains(event.target)
      ) {
        document.getElementById("secLocCodeDiv").style.display = "none";
        locationTwoValid();
      }
      document.body.removeEventListener("click", secLocHandleClickOutside);
    };
    document.body.addEventListener("click", secLocHandleClickOutside);
  }, [cityShow2, citylist2, experienceInformation.location2]);

  // end of experience tab

  // start academic tab
  const [academicInformation, setAcademicInformation] = useState({
    qualification: "",
    specialization: "",
    institute: "",
    yearOfPassing: "",
  });
  const [academicError, setAcademicError] = useState({
    qualification: "",
    specialization: "",
    institute: "",
  });

  const [academicVisibility, setAcademicVisibility] = useState({
    zero: true,
    one: false,
    two: false,
  });
  useEffect(() => {
    setAcademicInformation((state) => {
      return {
        ...state,
        qualification1: "",
        specialization1: "",
        institute1: "",
        yearOfPassing1: "",
      };
    });
    setAcademicError((state) => {
      return {
        ...state,
        qualification1: "",
        specialization1: "",
        institute1: "",
      };
    });
  }, [academicVisibility.one]);

  useEffect(() => {
    setAcademicInformation((state) => {
      return {
        ...state,
        qualification2: "",
        specialization2: "",
        institute2: "",
        yearOfPassing2: "",
      };
    });
    setAcademicError((state) => {
      return {
        ...state,
        qualification2: "",
        specialization2: "",
        institute2: "",
      };
    });
  }, [academicVisibility.two]);

  //add more education
  const AddEducation = () => {
    setAcademicVisibility((state) => {
      if (state.one === false && state.two) return { ...state, one: true };
      else return { ...state, two: true };
    });
  };
  //end of academic tab
  //certificate
  const [certificateInformation, setCertificateInformation] = useState({
    certificateName: "",
    institute: "",
    year: "Year",
    month: "Month",
    lifeTimeValidity: false,
    certificateName0: "",
    institute0: "",
    year0: "Year",
    month0: "Month",
    lifeTimeValidity0: false,
    certificateName1: "",
    institute1: "",
    year1: "Year",
    month1: "Month",
    lifeTimeValidity1: false,
  });
  const [certificateError, setCertificateError] = useState({
    certificateName: "",
    institute: "",
    certificateName0: "",
    institute0: "",
    certificateName1: "",
    institute1: "",
  });

  const [certificateVisibility, setCertificateVisibility] = useState({
    one: false,
    two: false,
  });

  const AddCertificate = () => {
    // return certificate duplicate div
    setCertificateVisibility((state) => {
      if (!state.one) return { ...state, one: true };
      if (!state.two) return { ...state, two: true };
      return state;
    });
  };
  // security clearance assets
  const [securityInformation, setSecurityInformation] = useState({
    classification: "",
    category: "",
    year: "",
    month: "",
    lifeTimeValidity: false
  });
  const [securityError, setSecurityError] = useState({
    classification: "",
    category: "",
    year: "",
    month: "",
  });
  const [documentLoader, setDocumentLoader] = useState(false);
  const [workAuthLoader, setWorkAuthLoader] = useState(false);
  const [licenseLoader, setLicenseLoader] = useState(false);
  const [securityVisibility, setSecurityVisibility] = useState({
    zero: true,
    one: false,
    two: false,
    zeroin: true,
    onein: true,
    twoin: true,
  });

  const [securityDocument, setSecurityDocument] = useState({
    passport: "",
    workAuth: "",
    drivingLicense: "",
  });
  const [documentError, setDocumentError] = useState({
    passportError: "",
    workAuthError: "",
    drivingLicenseError: "",
  });

  useEffect(() => {
    if(!securityVisibility.zeroin){
      setSecurityInformation((state) => {
        return {
          ...state,
          classification: "",
          category: "",
          year: "",
          month: "",
          comment: "",
          lifeTimeValidity: false,
        };
      });
      setSecurityError((state) => {
        return {
          ...state,
          classification: "",
          category: "",
          year: "",
          month: "",
        };
      });
    }
  }, [securityVisibility.zeroin]);

  
  useEffect(() => {
    setSecurityInformation((state) => {
      return {
        ...state,
        classification1: "",
        category1: "",
        year1: "",
        month1: "",
        comment1: "",
        lifeTimeValidity1: false
      };
    });
    setSecurityError((state) => {
      return {
        ...state,
        classification1: "",
        category1: "",
        year1: "",
        month1: "",
      };
    });
  }, [securityVisibility.one]);

  useEffect(() => {
    setSecurityInformation((state) => {
      return {
        ...state,
        classification2: "",
        category2: "",
        year2: "",
        month2: "",
        comment2: "",
        lifeTimeValidity2: false
      };
    });
    setSecurityError((state) => {
      return {
        ...state,
        classification2: "",
        category2: "",
        year2: "",
        month2: "",
      };
    });
  }, [securityVisibility.two]);
  // add more button of security clearence
  
  const addSecurityQuestion = () => {
    // return security duplicate div
    setSecurityVisibility((state) => {
      if (!state.one) return { ...state, one: true,onein: true  };
      else return { ...state, two: true,twoin: true };
    });
  };

  const workAuthRef = useRef();
  const licenseRef = useRef();

  //Integration of upload file
  function handlePassport() {
    const file = document.getElementById("uploadDocument").files[0];
    if (file === undefined) {
      setDocumentError({
        ...documentError,
        passportError: "Please select a file",
      });
    } else if (file.size >= 5000000) {
      setDocumentError({
        ...documentError,
        passportError: "File size < 5MB",
      });
    } else if (
      !(
        file.type === "application/pdf" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      )
    ) {
      setDocumentError({
        ...documentError,
        passportError: "Please upload a valid file",
      });
    } 
    else {
      setDocumentError({
        ...documentError,
        passportError: ""
      })
      if (resumeRef.current) {
        resumeRef.current.setAttribute("disabled", "disabled");
      }
      setDocumentLoader(true);
      const obj = {
        for: isFreelance
        ? "FREELANCE_RECRUITER"
        : "RECRUITER",
        files: [
          {
            extension: file.name.split(".")[1],
            contentType: file.type,
            fileName: file.name,
          },
        ],
      };
      try {
        CreateSignedUrlAPI(token, obj)
          .then((result) => {
            const statusCode = result.status;
            const data = result.json();
            return Promise.all([statusCode, data]).then((res) => ({
              statusCode: res[0],
              data: res[1],
            }));
          })
          .then((res) => {
            if (res.statusCode === 200) {
              const promises = res.data?.payload?.signedUrls.map((url) => {
                uploadImage(url.signedUrl, file);
                setSecurityDocument({
                  ...securityDocument,
                  passport: url.fileUrl
                })
                setDocumentLoader(false);
                resumeRef.current.removeAttribute("disabled");
                return url;
              });
            } else {
              setDocumentLoader(false);
              resumeRef.current.removeAttribute("disabled");
            }
          });
      } catch {}
    }
  }

  function handleWorkAuth() {
    const file = document.getElementById("uploadWorkAuthDocument").files[0];
    if (file === undefined) {
      setDocumentError({
        ...documentError,
        workAuthError: "Please select a file",
      });
    } else if (file.size >= 5000000) {
      setDocumentError({
        ...documentError,
        workAuthError: "File size < 5MB",
      });
    } else if (
      !(
        file.type === "application/pdf" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      )
    ) {
      setDocumentError({
        ...documentError,
        workAuthError: "Please upload a valid file",
      });
    } 
    else {
      setDocumentError({
        ...documentError,
        workAuthError: ""
      })
      if (workAuthRef.current) {
        workAuthRef.current.setAttribute("disabled", "disabled");
      }
      setWorkAuthLoader(true);
      const obj = {
        for: isFreelance
        ? "FREELANCE_RECRUITER"
        : "RECRUITER",
        files: [
          {
            extension: file.name.split(".")[1],
            contentType: file.type,
            fileName: file.name,
          },
        ],
      };
      try {
        CreateSignedUrlAPI(token, obj)
          .then((result) => {
            const statusCode = result.status;
            const data = result.json();
            return Promise.all([statusCode, data]).then((res) => ({
              statusCode: res[0],
              data: res[1],
            }));
          })
          .then((res) => {
            if (res.statusCode === 200) {
              const promises = res.data?.payload?.signedUrls.map((url) => {
                uploadImage(url.signedUrl, file);
                setSecurityDocument({
                  ...securityDocument,
                  workAuth: url.fileUrl
                })
                setWorkAuthLoader(false);
                workAuthRef.current.removeAttribute("disabled");
                return url;
              });
            } else {
              setWorkAuthLoader(false);
              workAuthRef.current.removeAttribute("disabled");
            }
          });
      } catch {}
    }
   
  }

  function handleDrivingLicense() {
    const file = document.getElementById("uploadLicenseDocument").files[0];
    if (file === undefined) {
      setDocumentError({
        ...documentError,
        drivingLicenseError: "Please select a file",
      });
    } else if (file.size >= 5000000) {
      setDocumentError({
        ...documentError,
        drivingLicenseError: "File size < 5MB",
      });
    } else if (
      !(
        file.type === "application/pdf" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      )
    ) {
      setDocumentError({
        ...documentError,
        drivingLicenseError: "Please upload a valid file",
      });
    } 
    else {
      setDocumentError({
        ...documentError,
        drivingLicenseError: "",
      });
      if (licenseRef.current) {
        licenseRef.current.setAttribute("disabled", "disabled");
      }
      setLicenseLoader(true);
      
      const obj = {
        for: isFreelance
        ? "FREELANCE_RECRUITER"
        : "RECRUITER",
        files: [
          {
            extension: file.name.split(".")[1],
            contentType: file.type,
            fileName: file.name,
          },
        ],
      };
      try {
        CreateSignedUrlAPI(token, obj)
          .then((result) => {
            const statusCode = result.status;
            const data = result.json();
            return Promise.all([statusCode, data]).then((res) => ({
              statusCode: res[0],
              data: res[1],
            }));
          })
          .then((res) => {
            if (res.statusCode === 200) {
              const promises = res.data?.payload?.signedUrls.map((url) => {
                uploadImage(url.signedUrl, file);
                setSecurityDocument({
                  ...securityDocument,
                  drivingLicense: url.fileUrl
                })
                setLicenseLoader(false);
                licenseRef.current.removeAttribute("disabled");
                return url;
              });
            } else {
              setLicenseLoader(false);
              licenseRef.current.removeAttribute("disabled");
            }
          });
      } catch {}
    }
 }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="resumeformcalendarfield w-100"
      onClick={onClick}
      ref={ref}
    >
      <label style={{ marginTop: "10px" }}>{value}</label>
      <i
        className="fas fa-calendar-alt"
        style={{ fontSize: "20px", marginTop: "10px" }}
      ></i>
    </button>
  ));

  const schema = yup.object().shape({
    picture: yup
      .mixed()
      .test("name", "File is required", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "The file is too large", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Upload only .doc , .docx. or pdf file", (value) => {
        return value && value[0] && (
            value[0].type === 'application/pdf' ||
            value[0].type === "application/msword" ||
            value[0].type === 'application/doc' ||
            value[0].type === 'application/ms-doc' ||
            value[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const resumeRef = useRef();

  //Integration of upload file
  function handleDocument() {
    if (resumeRef.current) {
      resumeRef.current.setAttribute("disabled", "disabled");
    }
    setDocumentLoader(true);
    const file = document.getElementById("uploadDocument").files[0];
    const obj = {
      for: isFreelance ? "FREELANCE_RECRUITER" : "RECRUITER",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    try {
      CreateSignedUrlAPI(token, obj)
        .then((result) => {
          const statusCode = result.status;
          const data = result.json();
          return Promise.all([statusCode, data]).then((res) => ({
            statusCode: res[0],
            data: res[1],
          }));
        })
        .then((res) => {
          if (res.statusCode === 200) {
            const promises = res.data?.payload?.signedUrls.map((url) => {
              uploadImage(url.signedUrl, file);
              setSecurityInformation({
                ... securityInformation,
                docUrl: url.fileUrl
              })
              setDocumentLoader(false);
              resumeRef.current.removeAttribute("disabled");
              return url;
            });
          } else {
            setDocumentLoader(false);
            resumeRef.current.removeAttribute("disabled");
          }
        });
    } catch {}
  }

 const handelDelete = () => {
    setSecurityInformation({
      docUrl: "",
    });
    document.getElementById("uploadDocument").value = null;
  };

  useEffect(() => {
    if (!primaryVisible.one) {
      setPrimaryInformation({
        ...primaryInformation,
        ...{
          month0: "",
          year0: "",
          primarySkill0: "",
        },
      });
      setPrimaryDate0(new Date());
    }
    if (!primaryVisible.two) {
      setPrimaryInformation({
        ...primaryInformation,
        ...{
          month1: "",
          year1: "",
          primarySkill1: "",
        },
      });
      setPrimaryDate1(new Date());
    }
    if (!primaryVisible.three) {
      setPrimaryInformation({
        ...primaryInformation,
        ...{
          month2: "",
          year2: "",
          primarySkill2: "",
        },
      });
      setPrimaryDate2(new Date());
    }

    // secondary visible
    if (!secondaryVisible.one) {
      setSecondaryInfo({
        ...secondaryInfo,
        ...{
          secondarySkill0: "",
          secondarySkillMonth0: "",
          secondarySkillYear0: "",
        },
      });
      setSecondaryDate0(new Date());
    }
    if (!secondaryVisible.two) {
      setSecondaryInfo({
        ...secondaryInfo,
        ...{
          secondarySkill1: "",
          secondarySkillMonth1: "",
          secondarySkillYear1: "",
        },
      });
      setSecondaryDate1(new Date());
    }
    if (!secondaryVisible.three) {
      setSecondaryInfo({
        ...secondaryInfo,
        ...{
          secondarySkill2: "",
          secondarySkillMonth2: "",
          secondarySkillYear2: "",
        },
      });
      setSecondaryDate2(new Date());
    }
  }, [primaryVisible, secondaryVisible]);

  useEffect(async () => {
    if (isFreelance) {
      try {
        const response = await freegetBuildResumeSkill(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          setSkillData(
            payload,
            primaryInformation,
            setPrimaryInformation,
            secondaryInfo,
            setSecondaryInfo,
            setSecondaryDate,
            setSecondaryDate0,
            setSecondaryDate1,
            setSecondaryDate2,
            primaryVisible,
            setPrimaryVisible,
            secondaryVisible,
            setSecondaryVisible,
            setPrimaryDate,
            setPrimaryDate0,
            setPrimaryDate1,
            setPrimaryDate2
          );
        }
      } catch {}
      // experience
      try {
        const response = await freegetExperience(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          getExperienceParseData(
            payload,
            experienceInformation,
            setExperienceInformation,
            setExperienceVisibility,
            setFromDate,
            setToDate,
            setFromDate1,
            setToDate1,
            setFromDate2,
            setToDate2
          );
        }
      } catch {}
      // education and certificate
      try {
        const response = await freegetAcademic(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          parseGetAcademic(
            payload,
            academicInformation,
            setAcademicInformation,
            academicVisibility,
            setAcademicVisibility,
            certificateInformation,
            setCertificateInformation,
            certificateVisibility,
            setCertificateVisibility
          );
        }
      } catch {}
      // social
      try {
        const response = await freegetBuildResumeSocial(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload[0]) setSocialLink(payload[0]);
        }
      } catch {}
    } else {
      try {
        const response = await getBuildResumeSkill(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          setSkillData(
            payload,
            primaryInformation,
            setPrimaryInformation,
            secondaryInfo,
            setSecondaryInfo,
            setSecondaryDate,
            setSecondaryDate0,
            setSecondaryDate1,
            setSecondaryDate2,
            primaryVisible,
            setPrimaryVisible,
            secondaryVisible,
            setSecondaryVisible,
            setPrimaryDate,
            setPrimaryDate0,
            setPrimaryDate1,
            setPrimaryDate2
          );
        }
      } catch {}
      // experience
      try {
        const response = await getExperience(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          getExperienceParseData(
            payload,
            experienceInformation,
            setExperienceInformation,
            setExperienceVisibility,
            setFromDate,
            setToDate,
            setFromDate1,
            setToDate1,
            setFromDate2,
            setToDate2
          );
        }
      } catch {}
      // education and certificate
      try {
        const response = await getAcademic(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          parseGetAcademic(
            payload,
            academicInformation,
            setAcademicInformation,
            academicVisibility,
            setAcademicVisibility,
            certificateInformation,
            setCertificateInformation,
            certificateVisibility,
            setCertificateVisibility
          );
        }
      } catch {}
      // social
      try {
        const response = await getBuildResumeSocial(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload[0]) setSocialLink(payload[0]);
        }
      } catch {}
    }
  }, []);

  const [securityFlag, setSecurityFlag] = useState(false);
  useEffect(() => {
    async function callSelf() {
      if (isFreelance) {
        try {
          const response = await freegetSecurity(token, id);
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload;
            getParseData(
              payload,
              securityVisibility,
              setSecurityVisibility,
              securityInformation,
              setSecurityInformation
            );
            setSecurityFlag(true);
          }
        } catch {}
      } else {
        try {
          const response = await getSecurity(token, id);
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload;
            getParseData(
              payload,
              securityVisibility,
              setSecurityVisibility,
              securityInformation,
              setSecurityInformation
            );
            setSecurityFlag(true);
          }
        } catch {}
      }
    }
    callSelf();
  }, [securityFlag]);

  async function handleClick(event) {
    event.preventDefault();
    let flag = false;
    Object.keys(primaryInformation).map((key) => {
      setPrimaryError((state) => {
        if (!primaryVisible.one && /0$/.test(key)) return state;
        if (!primaryVisible.two && /1$/.test(key)) return state;
        if (!primaryVisible.three && /2$/.test(key)) return state;
        if (!primaryInformation[key]) {
          return {
            ...state,
            [key]: `Please Fill The ${key.replace(/\d$/, "")} `,
          };
        } else return { ...state, [key]: "" };
      });
      return null;
    });
    const flag1 = Object.keys(setPrimaryError)
      .map((key) => {
        if (!primaryVisible.one && /0$/.test(key)) return null;
        if (!primaryVisible.two && /1$/.test(key)) return null;
        if (!primaryVisible.three && /2$/.test(key)) return null;
        return setPrimaryError[key] ? true : false;
      })
      .includes(true);
    const flag2 = Object.keys(primaryInformation)
      .map((key) => {
        if (!primaryVisible.one && /0$/.test(key)) return null;
        if (!primaryVisible.two && /1$/.test(key)) return null;
        if (!primaryVisible.three && /2$/.test(key)) return null;
        return primaryInformation[key] ? true : false;
      })
      .includes(false);

    setSecondaryError((state) => {
      if (!secondaryVisible.one) state = { ...state, secondarySkill0: "" };
      if (!secondaryVisible.two) state = { ...state, secondarySkill1: "" };
      if (!secondaryVisible.three) state = { ...state, secondarySkill1: "" };
      return state;
    });

    const result = Object.keys(secondaryError)
      .map((key) => {
        return secondaryError[key] ? true : false;
      })
      .includes(true);

    const flag3 = verifySecurityClearance(
      securityError,
      setSecurityError,
      securityInformation,
      securityVisibility
    );
    const flag4 = verifyBuildResumeExperience(
      event,
      setExperienceError,
      experienceInformation,
      experienceVisibility
    );
    let flag5;
    try {
      flag5 = verifyBuildAcademic(
        academicError,
        setAcademicError,
        academicInformation,
        academicVisibility,
        certificateVisibility,
        certificateError
      );
    } catch {}

    if (flag3 && flag4 && !flag1 && !flag2 && flag5 & !result) {
      if (snapRef.current) {
        snapRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      let skillData = getSkillParseData(
        primaryInformation,
        secondaryInfo,
        secondaryVisible,
        primaryDate,
        primaryDate0,
        primaryDate1,
        primaryDate2,
        secondaryDate,
        secondaryDate0,
        secondaryDate1,
        secondaryDate2
      )
      let ExpData = postExperienceParseData(
        experienceInformation,
        experienceVisibility,
        toDate,
        fromDate,
        toDate1,
        fromDate1,
        toDate2,
        fromDate2
      )
      let academicData = parsePostAcademic(
        academicInformation,
        academicVisibility,
        certificateInformation,
        certificateVisibility
      )
      let securityData = postParseData(
        securityInformation, securityVisibility,securityDocument
      )
      let socialData = socialLink

      let PROMSIEARRAY = isFreelance
      ?
      Promise.all([
        freepostBuildResumeSkill(token,skillData,id),
        freepostExperience(token,ExpData,id),
        freepostAcademic(token,academicData,id),
        freepostSecurity(token,securityData,id),
        freepostBuildResumeSocial(token,socialData,id)
      ])
      :
      Promise.all([
        postBuildResumeSkill(token,skillData,id),
        postExperience(token,ExpData,id),
        postAcademic(token,academicData,id),
        postSecurity(token,securityData,id),
        postBuildResumeSocial(token,socialData,id)
      ])

      PROMSIEARRAY.then(async() => {
        if (isFreelance) {
          const response = await freesnapshot(token, id);
          if (response.status === 200) {
            freelanceHomePage(dispatch)
            setRedirect(true);
          } else {
            snapRef.current.removeAttribute("disabled");
            setLoader(false);
          }
        } else {
          const response = await snapshot(token, id);
          if (response.status === 200) {
            homePage(dispatch)
            setRedirect(true);
          } else {
            snapRef.current.removeAttribute("disabled");
            setLoader(false);
          }
        }
      })
    }
  }

// end of security clearance
  return (
    <div className={"col mx-5 pt-3"}>
      {/*skill first box*/}
      <form className={"form-group"} onSubmit={handleClick}>
        <SnapshotDiv
          postData={
            isFreelance ? freepostBuildResumeSkill : postBuildResumeSkill
          }
          parsedData={getSkillParseData(
            primaryInformation,
            secondaryInfo,
            secondaryVisible,
            primaryDate,
            primaryDate0,
            primaryDate1,
            primaryDate2,
            secondaryDate,
            secondaryDate0,
            secondaryDate1,
            secondaryDate2
          )}
          id={id}
        >
          <div className={"row mx-4"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Skill
              </label>
            </div>
          </div>
          <div className={"row  mx-4 pt-0"}>
            <div className={"col-md"}>
              <label htmlFor={"pskill"} className={"resumeforminputtext"}>
                Primary Skill<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={"text"}
                placeholder={"Type here"}
                id={"pskill"}
                className={"form-control resumeforminputfield shadow-none "}
                name={"primarySkill"}
                value={primaryInformation.primarySkill}
                style={{border:`${primaryError.primarySkill ? "1px solid red" : ""}`}}
                onChange={(event) =>
                  buildResumeSkill(
                    event,
                    setPrimaryInformation,
                    setPrimaryError
                  )
                }
                disabled
              />
              <font className={"dashboardforminputtext text-danger"}>
                {primaryError.primarySkill}
              </font>
            </div>
            <div className={"col-md"}>
              <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                Years Of Experience<span style={{ color: "red" }}>*</span>
              </label>
              <div
                className={"dropdownforminputfield"}
                style={{ display: "flex", flexDirection: "row" ,
                border:`${primaryError.year || primaryError.month ? "1px solid red" : ""}`}}
              >
                <select
                  className={
                    "form-control form-select dropgray shadow-none ml-0"
                  }
                  id={"pexp"}
                  name={"year"}
                  value={primaryInformation.year}
                  onChange={(event) =>
                    buildResumeSkill(
                      event,
                      setPrimaryInformation,
                      setPrimaryError
                    )
                  }
                  disabled
                >
                  <option value={""}>Year</option>
                  <Getyear />
                </select>
                <select
                  className={"form-control form-select dropgray1 shadow-none"}
                  id={"pexp"}
                  name={"month"}
                  value={primaryInformation.month}
                  onChange={(event) =>
                    buildResumeSkill(
                      event,
                      setPrimaryInformation,
                      setPrimaryError
                    )
                  }
                  disabled
                >
                  <option value={""}>Month</option>
                  <GetPrimaryMonth />
                </select>
              </div>
              <font className={"dashboardforminputtext text-danger"}>
                {primaryError.year}
              </font>
              <font className={"dashboardforminputtext text-danger"}>
                {primaryError.month}
              </font>
            </div>
            <div className={"col-md"}>
              <label htmlFor={"lastused"} className={"resumeforminputtext"}>
                Last Used<span style={{ color: "red" }}>*</span>
              </label>
              <DatePicker
                selected={primaryDate}
                required="true"
                onChange={(date) => setPrimaryDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                maxDate={new Date()}
                customInput={<CustomInput />}
              />
            </div>
          </div>
        </SnapshotDiv>
        {/*duplciate here*/}
        {primaryVisible.one && (
          <SnapshotDiv
            postData={
              isFreelance ? freepostBuildResumeSkill : postBuildResumeSkill
            }
            parsedData={getSkillParseData(
              primaryInformation,
              secondaryInfo,
              secondaryVisible,
              primaryDate,
              primaryDate0,
              primaryDate1,
              primaryDate2,
              secondaryDate,
              secondaryDate0,
              secondaryDate1,
              secondaryDate2
            )}
            id={id}
          >
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Skill
                </label>
              </div>
            </div>
            <div className={"row  mx-4 pt-0"}>
              <div className={"col-md"}>
                <label htmlFor={"pskill"} className={"resumeforminputtext"}>
                  Primary Skill<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"pskill"}
                  className={"form-control resumeforminputfield shadow-none "}
                  name={"primarySkill0"}
                  value={primaryInformation.primarySkill0}
                  style={{border:`${primaryError.primarySkill0 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    buildResumeSkill(
                      event,
                      setPrimaryInformation,
                      setPrimaryError
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {primaryError.primarySkill0}
                </font>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                  Years Of Experience<span style={{ color: "red" }}>*</span>
                </label>
                <div
                  className={"dropdownforminputfield"}
                  style={{ display: "flex", flexDirection: "row" ,
                  border:`${primaryError.year0 || primaryError.month0 ? "1px solid red" : ""}`}}
                >
                  <select
                    className={
                      "form-control form-select dropgray shadow-none ml-0"
                    }
                    id={"pexp"}
                    name={"year0"}
                    value={primaryInformation.year0}
                    onChange={(event) =>
                      buildResumeSkill(
                        event,
                        setPrimaryInformation,
                        setPrimaryError
                      )
                    }
                    disabled
                  >
                    <option value={""}>Year</option>
                    <Getyear />
                  </select>
                  <select
                    className={"form-control form-select dropgray1 shadow-none"}
                    id={"pexp"}
                    name={"month0"}
                    value={primaryInformation.month0}
                    onChange={(event) =>
                      buildResumeSkill(
                        event,
                        setPrimaryInformation,
                        setPrimaryError
                      )
                    }
                    disabled
                  >
                    <option value={""}>Month</option>
                    <GetPrimaryMonth />
                  </select>
                </div>
                <font className={"dashboardforminputtext text-danger"}>
                  {primaryError.year0}
                </font>
                <font className={"dashboardforminputtext text-danger"}>
                  {primaryError.month0}
                </font>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"lastused"} className={"resumeforminputtext"}>
                  Last Used<span style={{ color: "red" }}>*</span>
                </label>
                <DatePicker
                  selected={primaryDate0}
                  required="true"
                  onChange={(date) => setPrimaryDate0(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  maxDate={new Date()}
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn float-right badge border-0 mr-5 cancelskill"}
                  onClick={() =>
                    setPrimaryVisible((state) => {
                      return { ...state, one: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here*/}
        {primaryVisible.two && (
          <SnapshotDiv
            postData={
              isFreelance ? freepostBuildResumeSkill : postBuildResumeSkill
            }
            parsedData={getSkillParseData(
              primaryInformation,
              secondaryInfo,
              secondaryVisible,
              primaryDate,
              primaryDate0,
              primaryDate1,
              primaryDate2,
              secondaryDate,
              secondaryDate0,
              secondaryDate1,
              secondaryDate2
            )}
            id={id}
          >
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Skill
                </label>
              </div>
            </div>
            <div className={"row  mx-4 pt-0"}>
              <div className={"col-md"}>
                <label htmlFor={"pskill"} className={"resumeforminputtext"}>
                  Primary Skill<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"pskill"}
                  className={"form-control resumeforminputfield shadow-none "}
                  name={"primarySkill1"}
                  value={primaryInformation.primarySkill1}
                  style={{border:`${primaryError.primarySkill1 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    buildResumeSkill(
                      event,
                      setPrimaryInformation,
                      setPrimaryError
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {primaryError.primarySkill1}
                </font>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                  Years Of Experience<span style={{ color: "red" }}>*</span>
                </label>
                <div
                  className={"dropdownforminputfield"}
                  style={{ display: "flex", flexDirection: "row",
                  border:`${primaryError.year1 || primaryError.month1 ? "1px solid red" : ""}` }}
                >
                  <select
                    className={
                      "form-control form-select dropgray shadow-none ml-0"
                    }
                    id={"pexp"}
                    name={"year1"}
                    value={primaryInformation.year1}
                    onChange={(event) =>
                      buildResumeSkill(
                        event,
                        setPrimaryInformation,
                        setPrimaryError
                      )
                    }
                    disabled
                  >
                    <option value={""}>Year</option>
                    <Getyear />
                  </select>
                  <select
                    className={"form-control form-select dropgray1 shadow-none"}
                    id={"pexp"}
                    name={"month1"}
                    value={primaryInformation.month1}
                    onChange={(event) =>
                      buildResumeSkill(
                        event,
                        setPrimaryInformation,
                        setPrimaryError
                      )
                    }
                    disabled
                  >
                    <option value={""}>Month</option>
                    <GetPrimaryMonth />
                  </select>
                </div>
                <font className={"dashboardforminputtext text-danger"}>
                  {primaryError.year1}
                </font>
                <font className={"dashboardforminputtext text-danger"}>
                  {primaryError.month1}
                </font>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"lastused"} className={"resumeforminputtext"}>
                  Last Used<span style={{ color: "red" }}>*</span>
                </label>
                <DatePicker
                  selected={primaryDate1}
                  required="true"
                  onChange={(date) => setPrimaryDate1(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  maxDate={new Date()}
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn float-right badge border-0 mr-5 cancelskill"}
                  onClick={() =>
                    setPrimaryVisible((state) => {
                      return { ...state, two: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here*/}
        {!(
          primaryVisible.one &&
          primaryVisible.two &&
          primaryVisible.three
        ) && (
          <div className={"row"}>
            <div className={"col-md pb-0 pr-5"}>
              <div
                className={
                  "btn bg-transparent badge border-0 offset-sm-9 newskill"
                }
                onClick={AddPrimarySkill}
              >
                + Add More Skills
              </div>
            </div>
          </div>
        )}
        {/*skill second box*/}
        <SnapshotDiv
          postData={
            isFreelance ? freepostBuildResumeSkill : postBuildResumeSkill
          }
          parsedData={getSkillParseData(
            primaryInformation,
            secondaryInfo,
            secondaryVisible,
            primaryDate,
            primaryDate0,
            primaryDate1,
            primaryDate2,
            secondaryDate,
            secondaryDate0,
            secondaryDate1,
            secondaryDate2
          )}
          id={id}
        >
          <div className={"row mx-4"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Skill
              </label>
            </div>
          </div>
          <div className={"row pt-0 mx-4"}>
            <div className={"col-md"}>
              <label htmlFor={"Secskill"} className={"resumeforminputtext"}>
                Secondary Skill
              </label>
              <input
                type={"text"}
                placeholder={"Type here"}
                id={"Secskill"}
                className={"form-control resumeforminputfield shadow-none"}
                name={"secondarySkill"}
                value={secondaryInfo.secondarySkill}
                style={{border:`${secondaryError.secondarySkill ? "1px solid red" : ""}`}}
                onChange={(event) =>
                  secondarySkill(event, setSecondaryInfo, setSecondaryError)
                }
                disabled
              />
              <font className={"dashboardforminputtext text-danger"}>
                {secondaryError.secondarySkill}
              </font>
            </div>
            <div className={"col-md"}>
              <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                Years Of Experience
              </label>
              <div
                className={"dropdownforminputfield"}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <select
                  className={
                    "form-control form-select dropgray shadow-none ml-0"
                  }
                  id={"pexp"}
                  value={secondaryInfo.secondarySkillYear}
                  onChange={(event) =>
                    setSecondaryInfo({
                      ...secondaryInfo,
                      secondarySkillYear: event.currentTarget.value,
                    })
                  }
                  disabled
                >
                  <option value={""}>Year</option>
                  <Getyear />
                </select>
                <select
                  className={"form-control form-select dropgray1 shadow-none"}
                  id={"pexp"}
                  value={secondaryInfo.secondarySkillMonth}
                  onChange={(event) =>
                    setSecondaryInfo({
                      ...secondaryInfo,
                      secondarySkillMonth: event.currentTarget.value,
                    })
                  }
                  disabled
                >
                  <option value={""}>Month</option>
                  <GetPrimaryMonth />
                </select>
              </div>
            </div>
            <div className={"col-md"}>
              <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                Last Used
              </label>
              <DatePicker
                selected={secondaryDate}
                required="true"
                onChange={(date) => setSecondaryDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                maxDate={new Date()}
                customInput={<CustomInput />}
              />
            </div>
          </div>
        </SnapshotDiv>
        {/*duplicate here*/}
        {secondaryVisible.one && (
          <SnapshotDiv
            postData={
              isFreelance ? freepostBuildResumeSkill : postBuildResumeSkill
            }
            parsedData={getSkillParseData(
              primaryInformation,
              secondaryInfo,
              secondaryVisible,
              primaryDate,
              primaryDate0,
              primaryDate1,
              primaryDate2,
              secondaryDate,
              secondaryDate0,
              secondaryDate1,
              secondaryDate2
            )}
            id={id}
          >
            <div className={"row  mx-4 pt-1"}>
              <div className={"col-md"}>
                <label htmlFor={"sskill"} className={"resumeforminputtext"}>
                  Secondary Skill
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"pskill"}
                  className={"form-control resumeforminputfield shadow-none"}
                  name={"secondarySkill0"}
                  value={secondaryInfo.secondarySkill0}
                  style={{border:`${secondaryError.secondarySkill0 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    secondarySkill(event, setSecondaryInfo, setSecondaryError)
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {secondaryError.secondarySkill0}
                </font>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                  Years Of Experience
                </label>
                <div
                  className={"dropdownforminputfield"}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <select
                    className={
                      "form-control form-select dropgray shadow-none ml-0"
                    }
                    id={"sexp"}
                    value={secondaryInfo.secondarySkillYear0}
                    onChange={(event) =>
                      setSecondaryInfo({
                        ...secondaryInfo,
                        secondarySkillYear0: event.currentTarget.value,
                      })
                    }
                    disabled
                  >
                    <option value={""}>Year</option>
                    <Getyear />
                  </select>
                  <select
                    className={"form-control form-select dropgray1 shadow-none"}
                    id={"sexp"}
                    value={secondaryInfo.secondarySkillMonth0}
                    onChange={(event) =>
                      setSecondaryInfo({
                        ...secondaryInfo,
                        secondarySkillMonth0: event.currentTarget.value,
                      })
                    }
                    disabled
                  >
                    <option value={""}>Month</option>
                    <GetPrimaryMonth />
                  </select>
                </div>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"lastused"} className={"resumeforminputtext"}>
                  Last Used
                </label>
                <DatePicker
                  selected={secondaryDate0}
                  required="true"
                  onChange={(date) => setSecondaryDate0(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  maxDate={new Date()}
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <div className={"row pb-0"}>
              <div className={"col-md  pb-0"}>
                <div
                  className={"btn float-right badge border-0 mr-5 cancelskill"}
                  onClick={() =>
                    setSecondaryVisible((state) => {
                      return { ...state, one: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here */}
        {secondaryVisible.two && (
          <SnapshotDiv
            postData={
              isFreelance ? freepostBuildResumeSkill : postBuildResumeSkill
            }
            parsedData={getSkillParseData(
              primaryInformation,
              secondaryInfo,
              secondaryVisible,
              primaryDate,
              primaryDate0,
              primaryDate1,
              primaryDate2,
              secondaryDate,
              secondaryDate0,
              secondaryDate1,
              secondaryDate2
            )}
            id={id}
          >
            <div className={"row  mx-4 pt-1"}>
              <div className={"col-md"}>
                <label htmlFor={"sskill"} className={"resumeforminputtext"}>
                  Secondary Skill
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"pskill"}
                  className={"form-control resumeforminputfield shadow-none"}
                  name={"secondarySkill1"}
                  value={secondaryInfo.secondarySkill1}
                  style={{border:`${secondaryError.secondarySkill1 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    secondarySkill(event, setSecondaryInfo, setSecondaryError)
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {secondaryError.secondarySkill1}
                </font>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                  Years Of Experience
                </label>
                <div
                  className={"dropdownforminputfield"}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <select
                    className={
                      "form-control form-select dropgray shadow-none ml-0"
                    }
                    id={"sexp"}
                    value={secondaryInfo.secondarySkillYear1}
                    onChange={(event) =>
                      setSecondaryInfo({
                        ...secondaryInfo,
                        secondarySkillYear1: event.currentTarget.value,
                      })
                    }
                    disabled
                  >
                    <option value={""}>Year</option>
                    <Getyear />
                  </select>
                  <select
                    className={"form-control form-select dropgray1 shadow-none"}
                    id={"sexp"}
                    value={secondaryInfo.secondarySkillMonth1}
                    onChange={(event) =>
                      setSecondaryInfo({
                        ...secondaryInfo,
                        secondarySkillMonth1: event.currentTarget.value,
                      })
                    }
                    disabled
                  >
                    <option value={""}>Month</option>
                    <GetPrimaryMonth />
                  </select>
                </div>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"lastused"} className={"resumeforminputtext"}>
                  Last Used
                </label>

                <DatePicker
                  selected={secondaryDate1}
                  required="true"
                  onChange={(date) => setSecondaryDate1(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  maxDate={new Date()}
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <div className={"row pb-0"}>
              <div className={"col-md  pb-0"}>
                <div
                  className={"btn float-right badge border-0 mr-5 cancelskill"}
                  onClick={() =>
                    setSecondaryVisible((state) => {
                      return { ...state, two: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here */}
        {secondaryVisible.three && (
          <SnapshotDiv
            postData={
              isFreelance ? freepostBuildResumeSkill : postBuildResumeSkill
            }
            parsedData={getSkillParseData(
              primaryInformation,
              secondaryInfo,
              secondaryVisible,
              primaryDate,
              primaryDate0,
              primaryDate1,
              primaryDate2,
              secondaryDate,
              secondaryDate0,
              secondaryDate1,
              secondaryDate2
            )}
            id={id}
          >
            <div className={"row  mx-4 pt-1"}>
              <div className={"col-md"}>
                <label htmlFor={"sskill"} className={"resumeforminputtext"}>
                  Secondary Skill
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"pskill"}
                  className={"form-control resumeforminputfield shadow-none"}
                  name={"secondarySkill2"}
                  value={secondaryInfo.secondarySkill2}
                  style={{border:`${secondaryError.secondarySkill2 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    secondarySkill(event, setSecondaryInfo, setSecondaryError)
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {secondaryError.secondarySkill2}
                </font>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                  Years Of Experience
                </label>
                <div
                  className={"dropdownforminputfield"}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <select
                    className={
                      "form-control form-select dropgray shadow-none ml-0"
                    }
                    id={"sexp"}
                    value={secondaryInfo.secondarySkillYear2}
                    onChange={(event) =>
                      setSecondaryInfo({
                        ...secondaryInfo,
                        secondarySkillYear2: event.currentTarget.value,
                      })
                    }
                    disabled
                  >
                    <option value={""}>Year</option>
                    <Getyear />
                  </select>
                  <select
                    className={"form-control form-select dropgray1 shadow-none"}
                    id={"sexp"}
                    value={secondaryInfo.secondarySkillMonth2}
                    onChange={(event) =>
                      setSecondaryInfo({
                        ...secondaryInfo,
                        secondarySkillMonth2: event.currentTarget.value,
                      })
                    }
                    disabled
                  >
                    <option value={""}>Month</option>
                    <GetPrimaryMonth />
                  </select>
                </div>
              </div>
              <div className={"col-md"}>
                <label htmlFor={"lastused"} className={"resumeforminputtext"}>
                  Last Used
                </label>
                <DatePicker
                  selected={secondaryDate2}
                  required="true"
                  onChange={(date) => setSecondaryDate2(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  maxDate={new Date()}
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <div className={"row pb-0"}>
              <div className={"col-md  pb-0"}>
                <div
                  className={"btn float-right badge border-0 mr-5 cancelskill"}
                  onClick={() =>
                    setSecondaryVisible((state) => {
                      return { ...state, three: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here */}
        {!(
          secondaryVisible.one &&
          secondaryVisible.two &&
          secondaryVisible.three
        ) && (
          <div className={"row"}>
            <div className={"col-md pb-0 pr-5"}>
              <div
                className={
                  "btn bg-transparent badge border-0 offset-sm-9 newskill"
                }
                onClick={addSecondarySkill}
              >
                + Add More Skills
              </div>
            </div>
          </div>
        )}
        {/*experience*/}
        <SnapshotDiv
          postData={isFreelance ? freepostExperience : postExperience}
          parsedData={postExperienceParseData(
            experienceInformation,
            experienceVisibility,
            toDate,
            fromDate,
            toDate1,
            fromDate1,
            toDate2,
            fromDate2
          )}
          id={id}
        >
          <div className={"row mx-4"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Experience
              </label>
            </div>
          </div>
          <div className={"row mx-4"}>
            <div className={"col-md-4"}>
              <label htmlFor={"US"} className={"resumeforminputtext"}>
                Total years of experience<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className={
                  "form-control resumeforminputfield form-select inputgray2 shadow-none"
                }
                id={"US"}
                name={"yearOfExp"}
                value={experienceInformation.yearOfExp}
                style={{border:`${experienceError.yearOfExp ? "1px solid red" : ""}`}}
                onChange={(event) =>
                  buildResumeExperience(
                    event,
                    setExperienceInformation,
                    setExperienceError,
                    experienceInformation
                  )
                }
                disabled
              >
                <option value={""}></option>
                <Getyear />
              </select>
              <font className={"dashboardforminputtext text-danger"}>
                {experienceError.yearOfExp}
              </font>
            </div>
            <div className={"col-md-4"}>
              <label htmlFor={"totalexp"} className={"resumeforminputtext"}>
                Years of Experience in US<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className={
                  "form-control resumeforminputfield form-select inputgray2 shadow-none"
                }
                id={"totalexp"}
                name={"yearOfExpUS"}
                value={experienceInformation.yearOfExpUS}
                style={{border:`${experienceError.yearOfExpUS ? "1px solid red" : ""}`}}
                onChange={(event) =>
                  buildResumeExperience(
                    event,
                    setExperienceInformation,
                    setExperienceError,
                    experienceInformation
                  )
                }
                disabled
              >
                <option value={""}></option>
                <Getyear />
              </select>
              <font className={"dashboardforminputtext text-danger"}>
                {experienceError.yearOfExpUS}
              </font>
            </div>
          </div>
        </SnapshotDiv>

        <SnapshotDiv
          postData={isFreelance ? freepostExperience : postExperience}
          parsedData={postExperienceParseData(
            experienceInformation,
            experienceVisibility,
            toDate,
            fromDate,
            toDate1,
            fromDate1,
            toDate2,
            fromDate2
          )}
          id={id}
        >
          <div className={"row mx-4"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Experience
              </label>
            </div>
          </div>
          <div className={"row pt-1 mx-4"}>
            <div className={"col-md-4"}>
              <label htmlFor={"jtitle"} className={"resumeforminputtext"}>
                Job Title<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={"text"}
                placeholder={"Recent job title"}
                id={"jtitle"}
                name={"jobTitle"}
                className={"form-control resumeforminputfield shadow-none"}
                value={experienceInformation.jobTitle}
                style={{border:`${experienceError.jobTitle ? "1px solid red" : ""}`}}
                onChange={(event) =>
                  buildResumeExperience(
                    event,
                    setExperienceInformation,
                    setExperienceError
                  )
                }
                disabled
              />
              <font className={"dashboardforminputtext text-danger"}>
                {experienceError.jobTitle}
              </font>
            </div>
            <div className={"col-md-4"}>
              <label htmlFor={"java"} className={"resumeforminputtext"}>
                Client<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={"text"}
                placeholder={"Type here"}
                id={"java"}
                name={"client"}
                value={experienceInformation.client}
                style={{border:`${experienceError.client ? "1px solid red" : ""}`}}
                className={"form-control resumeforminputfield shadow-none"}
                onChange={(event) =>
                  buildResumeExperience(
                    event,
                    setExperienceInformation,
                    setExperienceError
                  )
                }
                disabled
              />
              <font className={"dashboardforminputtext text-danger"}>
                {experienceError.client}
              </font>
            </div>
          </div>
          <div className={"row pt-1 mx-4"}>
            <div className={"col-md"}>
              <label htmlFor={"domain"} className={"resumeforminputtext"}>
                Domain
              </label>
              <input
                type={"text"}
                placeholder={"Java Backend"}
                id={"domain"}
                className={"form-control resumeforminputfield shadow-none"}
                name={"domain"}
                value={experienceInformation.domain}
                style={{border:`${experienceError.domain ? "1px solid red" : ""}`}}
                onChange={(event) =>
                  buildResumeExperience(
                    event,
                    setExperienceInformation,
                    setExperienceError
                  )
                }
                disabled
              />
              <font className={"dashboardforminputtext text-danger"}>
                {experienceError.domain}
              </font>
            </div>
            <div className={"col-md"} ref={locref}>
              <label htmlFor={"location"} className={"resumeforminputtext"}>
                Location<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type={"text"}
                placeholder={"Type here"}
                id={"location"}
                className={"form-control resumeforminputfield shadow-none"}
                name={"location"}
                value={experienceInformation.location}
                style={{border:`${experienceError.location ? "1px solid red" : ""}`}}
                onChange={(event) => {
                  buildResumeExperience(
                    event,
                    setExperienceInformation,
                    setExperienceError
                  );
                  setCityShow(true);
                }}
                autoComplete={"off"}
                onClick={() => {
                  setCityShow(!cityShow);
                  locationValid();
                }}
              />
              <font className={"dashboardforminputtext text-danger"}>
                {experienceError.location}
              </font>
              <div
                id={"preLocCodeDiv"}
                style={{
                  display: `${
                    cityShow && experienceInformation.location.length > 0
                      ? "block"
                      : "none"
                  }`,
                }}
              >
                {citylist &&
                  citylist.length > 0 &&
                  citylist.map((data, index) => (
                    <div
                      key={index}
                      style={{ cursor: "pointer" }}
                      value={data.city}
                      onClick={() => selectedLocation(data.city)}
                    >
                      {data.city}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-4">
              <SelectCalendar
                {...{
                  fromDate: fromDate,
                  setFromDate: setFromDate,
                  toDate: toDate,
                  setToDate: setToDate,
                }}
              />
            </div>
          </div>
          <div className={"row pt-3 mx-4"}>
            <div className={"col-md"}>
            <label htmlFor={"comment"} className={"resumeforminputtext"}>
                  Project Summary
                </label>
              <textarea
                className={
                  "form-control resumeforminputfield pt-2 pb-0 shadow-none"
                }
                rows="2"
                id="comment"
                value={experienceInformation.projectSummary}
                onChange={(event) =>
                  setExperienceInformation({
                    ...experienceInformation,
                    projectSummary: event.currentTarget.value,
                  })
                }
                placeholder={"Project Summary"}
                ref={summaryRef}
                style={{
                  minHeight: MIN_SUMMARY_HEIGHT,
                  resize: "none",
                  overflow: "hidden",
                }}
                disabled
              ></textarea>
            </div>
          </div>
          <div className={"row pt-2 mx-4"}>
            <div className={"col-md"}>
              <label htmlFor={"comment"} className={"resumeforminputtext"}>
                Roles and Responsibilities
              </label>
              <textarea
                className={"form-control resumeforminputfield shadow-none"}
                ref={textareaRef}
                id="comment"
                value={experienceInformation.roleAndResponse}
                placeholder={"Roles and Responsibilities"}
                onChange={(event) =>
                  setExperienceInformation({
                    ...experienceInformation,
                    roleAndResponse: event.currentTarget.value,
                  })
                }
                style={{
                  minHeight: MIN_TEXTAREA_HEIGHT,
                  resize: "none",
                  overflow: "hidden",
                }}
                disabled
              ></textarea>
            </div>
          </div>
        </SnapshotDiv>
        {/*duplicate here*/}
        {experienceVisibility.one && (
          <SnapshotDiv
            postData={isFreelance ? freepostExperience : postExperience}
            parsedData={postExperienceParseData(
              experienceInformation,
              experienceVisibility,
              toDate,
              fromDate,
              toDate1,
              fromDate1,
              toDate2,
              fromDate2
            )}
            id={id}
          >
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Experience
                </label>
              </div>
            </div>
            <div className={"row pt-1 mx-4"}>
              <div className={"col-md-4"}>
                <label htmlFor={"jtitle"} className={"resumeforminputtext"}>
                  Job Title<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Recent job title"}
                  id={"jtitle"}
                  name={"jobTitle1"}
                  className={"form-control resumeforminputfield shadow-none"}
                  value={experienceInformation.jobTitle1}
                  style={{border:`${experienceError.jobTitle1 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    buildResumeExperience(
                      event,
                      setExperienceInformation,
                      setExperienceError
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {experienceError.jobTitle1}
                </font>
              </div>
              <div className={"col-md-4"}>
                <label htmlFor={"java"} className={"resumeforminputtext"}>
                  Client<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"java"}
                  name={"client1"}
                  value={experienceInformation.client1}
                  style={{border:`${experienceError.client1 ? "1px solid red" : ""}`}}
                  className={"form-control resumeforminputfield shadow-none"}
                  onChange={(event) =>
                    buildResumeExperience(
                      event,
                      setExperienceInformation,
                      setExperienceError
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {experienceError.client1}
                </font>
              </div>
            </div>
            <div className={"row pt-1 mx-4"}>
              <div className={"col-md"}>
                <label htmlFor={"domain"} className={"resumeforminputtext"}>
                  Domain
                </label>
                <input
                  type={"text"}
                  placeholder={"Java Backend"}
                  id={"domain"}
                  className={"form-control resumeforminputfield shadow-none"}
                  name={"domain1"}
                  value={experienceInformation.domain1}
                  style={{border:`${experienceError.domain1 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    buildResumeExperience(
                      event,
                      setExperienceInformation,
                      setExperienceError
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {experienceError.domain1}
                </font>
              </div>
              <div className={"col-md"} ref={firstref}>
                <label htmlFor={"location"} className={"resumeforminputtext"}>
                  Location<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"location"}
                  className={"form-control resumeforminputfield shadow-none"}
                  name={"location1"}
                  value={experienceInformation.location1}
                  style={{border:`${experienceError.locaton1 ? "1px solid red" : ""}`}}
                  onChange={(event) => {
                    buildResumeExperience(
                      event,
                      setExperienceInformation,
                      setExperienceError
                    );
                    setCityShow1(true);
                  }}
                  autoComplete={"off"}
                  onClick={() => {
                    setCityShow1(!cityShow1);
                    locationOneValid();
                  }}
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {experienceError.location1}
                </font>
                <div
                  id={"firstLocCodeDiv"}
                  style={{
                    display: `${
                      cityShow1 && experienceInformation.location1.length > 0
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  {citylist1 &&
                    citylist1.length > 0 &&
                    citylist1.map((data, index) => (
                      <div
                        key={index}
                        style={{ cursor: "pointer" }}
                        value={data.city}
                        onClick={() => selectedLocationOne(data.city)}
                      >
                        {data.city}
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-md-4">
                <SelectCalendar
                  {...{
                    fromDate: fromDate1,
                    setFromDate: setFromDate1,
                    toDate: toDate1,
                    setToDate: setToDate1,
                  }}
                />
              </div>
            </div>
            <div className={"row pt-3 mx-4"}>
              <div className={"col-md"}>
              <label htmlFor={"comment"} className={"resumeforminputtext"}>
                  Project Summary
                </label>
                <textarea
                  className={
                    "form-control resumeforminputfield pt-2 pb-0 shadow-none"
                  }
                  rows="2"
                  id="comment"
                  placeholder={"Project Summary"}
                  value={experienceInformation.projectSummary1}
                  onChange={(event) =>
                    setExperienceInformation({
                      ...experienceInformation,
                      projectSummary1: event.currentTarget.value,
                    })
                  }
                  ref={summaryRef1}
                  style={{
                    minHeight: MIN_SUMMARY_HEIGHT1,
                    resize: "none",
                    overflow: "hidden",
                  }}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className={"row pt-2 mx-4"}>
              <div className={"col-md"}>
                <label htmlFor={"comment"} className={"resumeforminputtext"}>
                  Roles and Responsibilities
                </label>
                <textarea
                  className={"form-control resumeforminputfield shadow-none"}
                  ref={textareaRef1}
                  id="comment"
                  value={experienceInformation.roleAndResponse1}
                  placeholder={"Roles and Responsibilities"}
                  onChange={(event) =>
                    setExperienceInformation({
                      ...experienceInformation,
                      roleAndResponse1: event.currentTarget.value,
                    })
                  }
                  style={{
                    minHeight: MIN_TEXTAREA_HEIGHT1,
                    resize: "none",
                    overflow: "hidden",
                  }}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn float-right badge border-0 mr-5 cancelskill"}
                  onClick={() =>
                    setExperienceVisibility((state) => {
                      return { ...state, one: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*    duplicate here*/}
        {experienceVisibility.two && (
          <SnapshotDiv
            postData={isFreelance ? freepostExperience : postExperience}
            parsedData={postExperienceParseData(
              experienceInformation,
              experienceVisibility,
              toDate,
              fromDate,
              toDate1,
              fromDate1,
              toDate2,
              fromDate2
            )}
            id={id}
          >
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Experience
                </label>
              </div>
            </div>
            <div className={"row pt-1 mx-4"}>
              <div className={"col-md-4"}>
                <label htmlFor={"jtitle"} className={"resumeforminputtext"}>
                  Job Title<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Recent job title"}
                  id={"jtitle"}
                  name={"jobTitle2"}
                  className={"form-control resumeforminputfield shadow-none"}
                  value={experienceInformation.jobTitle2}
                  style={{border:`${experienceError.jobTitle2 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    buildResumeExperience(
                      event,
                      setExperienceInformation,
                      setExperienceError
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {experienceError.jobTitle2}
                </font>
              </div>
              <div className={"col-md-4"}>
                <label htmlFor={"java"} className={"resumeforminputtext"}>
                  Client<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"java"}
                  name={"client2"}
                  value={experienceInformation.client2}
                  style={{border:`${experienceError.client2 ? "1px solid red" : ""}`}}
                  className={"form-control resumeforminputfield shadow-none"}
                  onChange={(event) =>
                    buildResumeExperience(
                      event,
                      setExperienceInformation,
                      setExperienceError
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {experienceError.client2}
                </font>
              </div>
            </div>
            <div className={"row pt-1 mx-4"}>
              <div className={"col-md"}>
                <label htmlFor={"domain"} className={"resumeforminputtext"}>
                  Domain
                </label>
                <input
                  type={"text"}
                  placeholder={"Java Backend"}
                  id={"domain"}
                  className={"form-control resumeforminputfield shadow-none"}
                  name={"domain2"}
                  value={experienceInformation.domain2}
                  style={{border:`${experienceError.domain2 ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    buildResumeExperience(
                      event,
                      setExperienceInformation,
                      setExperienceError
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {experienceError.domain2}
                </font>
              </div>
              <div className={"col-md"} ref={secref}>
                <label htmlFor={"location"} className={"resumeforminputtext"}>
                  Location<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"location"}
                  className={"form-control resumeforminputfield shadow-none"}
                  name={"location2"}
                  value={experienceInformation.location2}
                  style={{border:`${experienceError.location2 ? "1px solid red" : ""}`}}
                  onChange={(event) => {
                    buildResumeExperience(
                      event,
                      setExperienceInformation,
                      setExperienceError
                    );
                    setCityShow2(true);
                  }}
                  autoComplete={"off"}
                  onClick={() => {
                    setCityShow2(!cityShow2);
                    locationTwoValid();
                  }}
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {experienceError.location2}
                </font>
                <div
                  id={"secLocCodeDiv"}
                  style={{
                    display: `${
                      cityShow2 && experienceInformation.location2.length > 0
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  {citylist2 &&
                    citylist2.length > 0 &&
                    citylist2.map((data, index) => (
                      <div
                        key={index}
                        style={{ cursor: "pointer" }}
                        value={data.city}
                        onClick={() => selectedLocationTwo(data.city)}
                      >
                        {data.city}
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-md-4">
                <SelectCalendar
                  {...{
                    fromDate: fromDate2,
                    setFromDate: setFromDate2,
                    toDate: toDate2,
                    setToDate: setToDate2,
                  }}
                />
              </div>
            </div>
            <div className={"row pt-3 mx-4"}>
              <div className={"col-md"}>
              <label htmlFor={"comment"} className={"resumeforminputtext"}>
                  Project Summary
                </label>
                <textarea
                  className={
                    "form-control resumeforminputfield pt-2 pb-0 shadow-none"
                  }
                  rows="2"
                  id="comment"
                  placeholder={"Project Summary"}
                  value={experienceInformation.projectSummary2}
                  onChange={(event) =>
                    setExperienceInformation({
                      ...experienceInformation,
                      projectSummary2: event.currentTarget.value,
                    })
                  }
                  ref={summaryRef2}
                  style={{
                    minHeight: MIN_SUMMARY_HEIGHT2,
                    resize: "none",
                    overflow: "hidden",
                  }}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className={"row pt-2 mx-4"}>
              <div className={"col-md"}>
                <label htmlFor={"comment"} className={"resumeforminputtext"}>
                  Roles and Responsibilities
                </label>
                <textarea
                  className={"form-control resumeforminputfield shadow-none"}
                  ref={textareaRef2}
                  id="comment"
                  placeholder={"Roles and Responsibilities"}
                  value={experienceInformation.roleAndResponse2}
                  onChange={(event) =>
                    setExperienceInformation({
                      ...experienceInformation,
                      roleAndResponse2: event.currentTarget.value,
                    })
                  }
                  style={{
                    minHeight: MIN_TEXTAREA_HEIGHT2,
                    resize: "none",
                    overflow: "hidden",
                  }}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn float-right badge border-0 mr-5 cancelskill"}
                  onClick={() =>
                    setExperienceVisibility((state) => {
                      return { ...state, two: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}

        {/*duplicate here*/}
        {!(experienceVisibility.one && experienceVisibility.two) && (
          <div className={"row"}>
            <div className={"col-md mt-2"}>
              <div
                className={
                  "btn offset-sm-9 badge border-0 newskill bg-transparent"
                }
                onClick={Addproject}
              >
                + Add Project
              </div>
            </div>
          </div>
        )}
        {/*academic first box */}
        <SnapshotDiv
          postData={isFreelance ? freepostAcademic : postAcademic}
          parsedData={parsePostAcademic(
            academicInformation,
            academicVisibility,
            certificateInformation,
            certificateVisibility
          )}
          id={id}
        >
          <div className={"row mx-4"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Academics (Please provide all academic qualifications)
              </label>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-2 pl-5 ml-1"}>
              <label className={"resumeformquerytext"}>US Degree</label>
            </div>
            <div className="col-md-1 form-inline pl-0">
              <input
                type="radio"
                className="resumeradio"
                id="customRadio"
                name="degree"
                value="yes"
                onClick={() =>
                  setAcademicVisibility((state) => {
                    return { ...state, zero: true };
                  })
                }
                checked={academicVisibility.zero}
                onChange={() => {}}
              />
              <label
                className="dashboardforminputtext pl-2"
                htmlFor="customRadio"
              >
                Yes
              </label>
            </div>
            <div className="col-md-1 form-inline pl-0">
              <input
                type="radio"
                className="resumeradio"
                id="customRadio0"
                name="degree"
                value="no"
                onClick={() =>
                  setAcademicVisibility((state) => {
                    return { ...state, zero: false };
                  })
                }
                checked={!academicVisibility.zero}
                onChange={() => {}}
              />
              <label
                className="dashboardforminputtext pl-2"
                htmlFor="customRadio0"
              >
                No
              </label>
            </div>
          </div>

          <div id={"education"}>
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label htmlFor={"equal"} className={"resumeforminputtext"}>
                  Academics Qualification
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className={
                    "form-control resumeforminputfield form-select inputgray shadow-none"
                  }
                  id={"equal"}
                  name={"qualification"}
                  value={academicInformation.qualification}
                  style={{border:`${academicError.qualification ? "1px solid red" : ""}`}}
                  onChange={(event) =>
                    buildAcademic(
                      event,
                      academicError,
                      setAcademicError,
                      academicInformation,
                      setAcademicInformation
                    )
                  }
                  disabled
                >
                  <option value={""}>Select Highest Degree</option>
                  <option value={"Doctorate"}>Doctorate</option>
                  <option value={"Masters"}>Masters</option>
                  <option value={"Bachelors"}>Bachelors</option>
                  <option value={"Associate"}>Associate</option>
                  <option value={"Vocational"}>Vocational</option>
                  <option value={"HighSchool"}>High School</option>
                </select>
                <font className={"dashboardforminputtext text-danger"}>
                  {academicError.qualification}
                </font>
              </div>
              <div className={"col-md"}>
                <label
                  htmlFor={"specialization"}
                  className={"resumeforminputtext"}
                >
                  Specialization<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"specialization"}
                  className={"form-control resumeforminputfield shadow-none"}
                  value={academicInformation.specialization}
                  style={{border:`${academicError.specialization ? "1px solid red" : ""}`}}
                  name={"specialization"}
                  onChange={(event) =>
                    buildAcademic(
                      event,
                      academicError,
                      setAcademicError,
                      academicInformation,
                      setAcademicInformation
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {academicError.specialization}
                </font>
              </div>
              <div className={"col-md"}>
                <label
                  htmlFor={"institution"}
                  className={"resumeforminputtext"}
                >
                  Institution
                </label>
                <input
                  type={"text"}
                  placeholder={"Type here"}
                  id={"institution"}
                  name={"institute"}
                  value={academicInformation.institute}
                  style={{border:`${academicError.institute ? "1px solid red" : ""}`}}
                  className={"form-control resumeforminputfield shadow-none"}
                  onChange={(event) =>
                    buildAcademic(
                      event,
                      academicError,
                      setAcademicError,
                      academicInformation,
                      setAcademicInformation
                    )
                  }
                  disabled
                />
                <font className={"dashboardforminputtext text-danger"}>
                  {academicError.institute}
                </font>
              </div>
            </div>
            <div className={"row mx-4 pt-1"}>
              <div className={"col-md-4"}>
                <label htmlFor={"ypass"} className={"resumeforminputtext"}>
                  Year Of Passing
                </label>
                <select
                  className={
                    "form-control resumeforminputfield form-select inputgray shadow-none"
                  }
                  id={"ypass"}
                  value={academicInformation.yearOfPassing}
                  onChange={(event) =>
                    setAcademicInformation({
                      ...academicInformation,
                      yearOfPassing: event.currentTarget.value,
                    })
                  }
                  disabled
                >
                  <option value={""}></option>
                  <YearOfPassing />
                </select>
              </div>
              <div className={"col-md-7 pl-0"}></div>
            </div>
          </div>
        </SnapshotDiv>
        {/*duplicate here*/}
        {academicVisibility.one && (
          <SnapshotDiv
            postData={isFreelance ? freepostAcademic : postAcademic}
            parsedData={parsePostAcademic(
              academicInformation,
              academicVisibility,
              certificateInformation,
              certificateVisibility
            )}
            id={id}
          >
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Education
                </label>
              </div>
            </div>
            <div id={"education"}>
              <div className={"row mx-4"}>
                <div className={"col-md"}>
                  <label htmlFor={"equal"} className={"resumeforminputtext"}>
                    Academics Qualification
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray shadow-none"
                    }
                    id={"equal"}
                    name={"qualification1"}
                    value={academicInformation.qualification1}
                    style={{border:`${academicError.qualification1 ? "1px solid red" : ""}`}}
                    onChange={(event) =>
                      buildAcademic(
                        event,
                        academicError,
                        setAcademicError,
                        academicInformation,
                        setAcademicInformation
                      )
                    }
                    disabled
                  >
                    <option value={""}>Select Highest Degree</option>
                    <option value={"Doctorate"}>Doctorate</option>
                    <option value={"Masters"}>Masters</option>
                    <option value={"Bachelors"}>Bachelors</option>
                    <option value={"Associate"}>Associate</option>
                    <option value={"Vocational"}>Vocational</option>
                    <option value={"HighSchool"}>High School</option>
                  </select>
                  <font className={"dashboardforminputtext text-danger"}>
                    {experienceError.qualification1}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label
                    htmlFor={"specialization"}
                    className={"resumeforminputtext"}
                  >
                    Specialization<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type here"}
                    id={"specialization"}
                    className={"form-control resumeforminputfield shadow-none"}
                    value={academicInformation.specialization1}
                    style={{border:`${academicError.specialization1 ? "1px solid red" : ""}`}}
                    name={"specialization1"}
                    onChange={(event) =>
                      buildAcademic(
                        event,
                        academicError,
                        setAcademicError,
                        academicInformation,
                        setAcademicInformation
                      )
                    }
                    disabled
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {academicError.specialization1}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label
                    htmlFor={"institution"}
                    className={"resumeforminputtext"}
                  >
                    Institution
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type here"}
                    id={"institution"}
                    name={"institute1"}
                    value={academicInformation.institute1}
                    style={{border:`${academicError.institute1 ? "1px solid red" : ""}`}}
                    className={"form-control resumeforminputfield shadow-none"}
                    onChange={(event) =>
                      buildAcademic(
                        event,
                        academicError,
                        setAcademicError,
                        academicInformation,
                        setAcademicInformation
                      )
                    }
                    disabled
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {academicError.institute1}
                  </font>
                </div>
              </div>
              <div className={"row mx-4 pt-1"}>
                <div className={"col-md-4"}>
                  <label htmlFor={"ypass"} className={"resumeforminputtext"}>
                    Year Of Passing
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray shadow-none"
                    }
                    id={"ypass"}
                    value={academicInformation.yearOfPassing1}
                    onChange={(event) =>
                      setAcademicInformation({
                        ...academicInformation,
                        yearOfPassing1: event.currentTarget.value,
                      })
                    }
                    disabled
                  >
                    <option value={""}></option>
                    <YearOfPassing />
                  </select>
                </div>
                <div className={"col-md-7 pl-0"}></div>
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                  onClick={() =>
                    setAcademicVisibility((state) => {
                      return { ...state, one: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here*/}
        {academicVisibility.two && (
          <SnapshotDiv
            postData={isFreelance ? freepostAcademic : postAcademic}
            parsedData={parsePostAcademic(
              academicInformation,
              academicVisibility,
              certificateInformation,
              certificateVisibility
            )}
            id={id}
          >
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Education
                </label>
              </div>
            </div>
            <div id={"education"}>
              <div className={"row mx-4"}>
                <div className={"col-md"}>
                  <label htmlFor={"equal"} className={"resumeforminputtext"}>
                    Academics Qualification
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray shadow-none"
                    }
                    id={"equal"}
                    name={"qualification2"}
                    value={academicInformation.qualification2}
                    style={{border:`${academicError.qualification2 ? "1px solid red" : ""}`}}
                    onChange={(event) =>
                      buildAcademic(
                        event,
                        academicError,
                        setAcademicError,
                        academicInformation,
                        setAcademicInformation
                      )
                    }
                    disabled
                  >
                    <option value={""}>Select Highest Degree</option>
                    <option value={"Doctorate"}>Doctorate</option>
                    <option value={"Masters"}>Masters</option>
                    <option value={"Bachelors"}>Bachelors</option>
                    <option value={"Associate"}>Associate</option>
                    <option value={"Vocational"}>Vocational</option>
                    <option value={"HighSchool"}>High School</option>
                  </select>
                  <font className={"dashboardforminputtext text-danger"}>
                    {academicError.qualification2}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label
                    htmlFor={"specialization"}
                    className={"resumeforminputtext"}
                  >
                    Specialization<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type here"}
                    id={"specialization"}
                    className={"form-control resumeforminputfield shadow-none"}
                    value={academicInformation.specialization2}
                    style={{border:`${academicError.specialization2 ? "1px solid red" : ""}`}}
                    name={"specialization2"}
                    onChange={(event) =>
                      buildAcademic(
                        event,
                        academicError,
                        setAcademicError,
                        academicInformation,
                        setAcademicInformation
                      )
                    }
                    disabled
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {academicError.specialization2}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label
                    htmlFor={"institution"}
                    className={"resumeforminputtext"}
                  >
                    Institution
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type here"}
                    id={"institution"}
                    name={"institute2"}
                    value={academicInformation.institute2}
                    style={{border:`${academicError.institute2 ? "1px solid red" : ""}`}}
                    className={"form-control resumeforminputfield shadow-none"}
                    onChange={(event) =>
                      buildAcademic(
                        event,
                        academicError,
                        setAcademicError,
                        academicInformation,
                        setAcademicInformation
                      )
                    }
                    disabled
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {academicError.institute2}
                  </font>
                </div>
              </div>
              <div className={"row mx-4 pt-1"}>
                <div className={"col-md-4"}>
                  <label htmlFor={"ypass"} className={"resumeforminputtext"}>
                    Year Of Passing
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray shadow-none"
                    }
                    id={"ypass"}
                    value={academicInformation.yearOfPassing2}
                    onChange={(event) =>
                      setAcademicInformation({
                        ...academicInformation,
                        yearOfPassing2: event.currentTarget.value,
                      })
                    }
                    disabled
                  >
                    <option value={""}></option>
                    <YearOfPassing />
                  </select>
                </div>
                <div className={"col-md-7 pl-0"}></div>
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                  onClick={() =>
                    setAcademicVisibility((state) => {
                      return { ...state, two: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here*/}
        {!(academicVisibility.one && academicVisibility.two) && (
          <div className={"row"}>
            <div className={"col-md"}>
              <div
                className={
                  "btn offset-sm-9 bg-transparent badge border-0  newskill"
                }
                onClick={AddEducation}
              >
                + Add More
              </div>
            </div>
          </div>
        )}
         <SnapshotDiv
            postData={isFreelance ? freepostAcademic : postAcademic}
            parsedData={parsePostAcademic(
              academicInformation,
              academicVisibility,
              certificateInformation,
              certificateVisibility
            )}
          >
            <div className={"row mx-4"}>
                <div className={"col-md-1"}>
                  <label className={"resumeformquerytext"}>Veteran</label>
                </div>
                <div className="col-md-1 form-inline pl-0">
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="veteran"
                    value="Yes"
                    onClick={() =>
                      setAcademicVisibility((state) => {
                        return { ...state, four: true };
                      })
                    }
                    checked={academicVisibility.four}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                </div>
                <div className="col-md-1 form-inline pl-0">
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="veteran"
                    value="No"
                    onClick={() =>
                      setAcademicVisibility((state) => {
                        return { ...state, four: false };
                      })
                    }
                    checked={!academicVisibility.four}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>
          </SnapshotDiv>
        {/*academic second box*/}
        <SnapshotDiv
          postData={isFreelance ? freepostAcademic : postAcademic}
          parsedData={parsePostAcademic(
            academicInformation,
            academicVisibility,
            certificateInformation,
            certificateVisibility
          )}
          id={id}
        >
          <div className={"row mx-4"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Certificates
              </label>
            </div>
          </div>

          <div className={"row pt-0 mx-4"}>
            <div className={"col-md-4"}>
              <label htmlFor={"cname"} className={"resumeforminputtext"}>
                Certificate Name
              </label>
              <input
                type={"text"}
                placeholder={"MS certified"}
                id={"cname"}
                name={"certificateName"}
                value={certificateInformation.certificateName}
                style={{border:`${certificateError.certificateName ? "1px solid red" : ""}`}}
                onChange={(event) =>
                  buildCertificate(
                    event,
                    setCertificateError,
                    setCertificateInformation
                  )
                }
                className={"form-control resumeforminputfield shadow-none"}
                disabled
              />
              <font className={"dashboardforminputtext text-danger"}>
                {certificateError.certificateName}
              </font>
            </div>
            <div className={"col-md-4"}>
              <label htmlFor={"institute"} className={"resumeforminputtext"}>
                Institute
              </label>
              <input
                type={"text"}
                placeholder={"Type here"}
                id={"institute"}
                className={"form-control resumeforminputfield shadow-none"}
                name={"institute"}
                value={certificateInformation.institute}
                style={{border:`${certificateError.institute ? "1px solid red" : ""}`}}
                onChange={(event) =>
                  buildCertificate(
                    event,
                    setCertificateError,
                    setCertificateInformation
                  )
                }
                disabled
              />
              <font className={"dashboardforminputtext text-danger"}>
                {certificateError.institute}
              </font>
            </div>
        </div>

        <div className={"row pt-2 mx-4"}>
        <div className={"col-md-4"}>
              <label htmlFor={"valid"} className={"resumeforminputtext"}>
                Valid Up To
              </label>
              <div
                className={"dropdownforminputfield"}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <select
                  className={"form-control form-select dropgray1 shadow-none"}
                  id={"pexp"}
                  value={certificateInformation.month}
                  onChange={(event) =>
                    setCertificateInformation({
                      ...certificateInformation,
                      month: event.currentTarget.value,
                    })
                  }
                  disabled
                >
                  <option value={"Month_ _    year_ _"}>Month</option>
                  <GetValidityMonth />
                </select>
                <select
                  className={
                    "form-control form-select dropgray shadow-none ml-0"
                  }
                  id={"pexp"}
                  value={certificateInformation.year}
                  onChange={(event) =>
                    setCertificateInformation({
                      ...certificateInformation,
                      year: event.currentTarget.value,
                    })
                  }
                  disabled
                >
                  <option value={0}>Year</option>
                  <YearOfValidity />
                </select>
              </div>
            </div>
            <div className={"col-md-4 form-inline mt-4"}>
                <input
                  type="checkbox"
                  style={{width: "18px",height: "18px"}}
                  checked={certificateInformation.lifeTimeValidity}
                  onChange={() => {
                    setCertificateInformation((state) => {
                      return { ...state, lifeTimeValidity: !certificateInformation.lifeTimeValidity };
                      });
                    }}
                  />
                  <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
              </div>       
        </div>
        </SnapshotDiv>

       {/*duplicate here */}
        {certificateVisibility.one && (
          <SnapshotDiv
            postData={isFreelance ? freepostAcademic : postAcademic}
            parsedData={parsePostAcademic(
              academicInformation,
              academicVisibility,
              certificateInformation,
              certificateVisibility
            )}
            id={id}
          >
            <div className={"form-group"}>
              <div className={"row pt-3 mx-4"}>
                <div className={"col-md-4"}>
                  <label htmlFor={"cname"} className={"resumeforminputtext"}>
                    Certificate Name
                  </label>
                  <input
                    type={"text"}
                    placeholder={"MS certified"}
                    id={"cname"}
                    className={"form-control resumeforminputfield shadow-none"}
                    name={"certificateName0"}
                    value={certificateInformation.certificateName0}
                    style={{border:`${certificateError.certificateName0 ? "1px solid red" : ""}`}}
                    onChange={(event) =>
                      buildCertificate(
                        event,
                        setCertificateError,
                        setCertificateInformation
                      )
                    }
                    disabled
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {certificateError.certificateName0}
                  </font>
                </div>
                <div className={"col-md-4"}>
                  <label
                    htmlFor={"institute"}
                    className={"resumeforminputtext"}
                  >
                    Institute
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type here"}
                    id={"institute"}
                    name={"institute0"}
                    value={certificateInformation.institute0}
                    style={{border:`${certificateError.institute0 ? "1px solid red" : ""}`}}
                    onChange={(event) =>
                      buildCertificate(
                        event,
                        setCertificateError,
                        setCertificateInformation
                      )
                    }
                    disabled
                    className={"form-control resumeforminputfield shadow-none"}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {certificateError.institute0}
                  </font>
                </div>
              </div>
              <div className={"row pt-3 mx-4"}>
              <div className={"col-md-4"}>
                  <label htmlFor={"valid"} className={"resumeforminputtext"}>
                    Valid Up To
                  </label>
                  <div
                    className={"dropdownforminputfield"}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <select
                      className={
                        "form-control form-select dropgray1 shadow-none"
                      }
                      id={"pexp"}
                      value={certificateInformation.month0}
                      onChange={(event) =>
                        setCertificateInformation({
                          ...certificateInformation,
                          month0: event.currentTarget.value,
                        })
                      }
                      disabled
                    >
                      <option value={"Month_ _    year_ _"}>Month</option>
                      <Getmonth />
                    </select>
                    <select
                      className={
                        "form-control form-select dropgray shadow-none ml-0"
                      }
                      id={"pexp"}
                      value={certificateInformation.year0}
                      onChange={(event) =>
                        setCertificateInformation({
                          ...certificateInformation,
                          year0: event.currentTarget.value,
                        })
                      }
                      disabled
                    >
                      <option value={0}>Year</option>
                      <YearOfValidity />
                    </select>
                  </div>
                </div>
                <div className={"col-md-4 form-inline mt-4"}>
                  <input
                      type="checkbox"
                      style={{width: "18px",height: "18px"}}
                      checked={certificateInformation.lifeTimeValidity0}
                      onChange={() => {
                        setCertificateInformation((state) => {
                          return { ...state, lifeTimeValidity0: !certificateInformation.lifeTimeValidity0 };
                        });
                      }}
                      />
                    <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
                  </div>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={
                    "btn offset-sm-10 pl-5 badge bg-transparent cancelskill"
                  }
                  onClick={() =>
                    setCertificateVisibility((state) => {
                      return { ...state, one: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here */}
        {/*duplicate here */}
        {certificateVisibility.two && (
          <SnapshotDiv
            postData={isFreelance ? freepostAcademic : postAcademic}
            parsedData={parsePostAcademic(
              academicInformation,
              academicVisibility,
              certificateInformation,
              certificateVisibility
            )}
            id={id}
          >
            <div className={"form-group"}>
              <div className={"row pt-3 mx-4"}>
                <div className={"col-md-4"}>
                  <label htmlFor={"cname"} className={"resumeforminputtext"}>
                    Certificate Name
                  </label>
                  <input
                    type={"text"}
                    placeholder={"MS certified"}
                    id={"cname"}
                    className={"form-control resumeforminputfield shadow-none"}
                    name={"certificateName1"}
                    value={certificateInformation.certificateName1}
                    style={{border:`${certificateError.certificateName1 ? "1px solid red" : ""}`}}
                    onChange={(event) =>
                      buildCertificate(
                        event,
                        setCertificateError,
                        setCertificateInformation
                      )
                    }
                    disabled
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {certificateError.certificateName1}
                  </font>
                </div>
                <div className={"col-md-4"}>
                  <label
                    htmlFor={"institute"}
                    className={"resumeforminputtext"}
                  >
                    Institute
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type here"}
                    id={"institute"}
                    name={"institute1"}
                    value={certificateInformation.institute1}
                    style={{border:`${certificateError.institute1 ? "1px solid red" : ""}`}}
                    onChange={(event) =>
                      buildCertificate(
                        event,
                        setCertificateError,
                        setCertificateInformation
                      )
                    }
                    disabled
                    className={"form-control resumeforminputfield shadow-none"}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {certificateError.institute1}
                  </font>
                </div>
              </div>
            <div className={"row pt-3 mx-4"}>
            <div className={"col-md-4"}>
                  <label htmlFor={"valid"} className={"resumeforminputtext"}>
                    Valid Up To
                  </label>
                  <div
                    className={"dropdownforminputfield"}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <select
                      className={
                        "form-control form-select dropgray1 shadow-none"
                      }
                      id={"pexp"}
                      value={certificateInformation.month1}
                      onChange={(event) =>
                        setCertificateInformation({
                          ...certificateInformation,
                          month1: event.currentTarget.value,
                        })
                      }
                      disabled
                    >
                      <option value={0}>Month</option>
                      <GetValidityMonth />
                    </select>
                    <select
                      className={
                        "form-control form-select dropgray shadow-none ml-0"
                      }
                      id={"pexp"}
                      value={certificateInformation.year1}
                      onChange={(event) =>
                        setCertificateInformation({
                          ...certificateInformation,
                          year1: event.currentTarget.value,
                        })
                      }
                      disabled
                    >
                      <option value={0}>Year</option>
                      <YearOfValidity />
                    </select>
                  </div>
                </div>
                <div className={"col-md-4 form-inline mt-4"}>
                  <input
                      type="checkbox"
                      style={{width: "18px",height: "18px"}}
                      checked={certificateInformation.lifeTimeValidity1}
                      onChange={() => {
                        setCertificateInformation((state) => {
                          return { ...state, lifeTimeValidity1: !certificateInformation.lifeTimeValidity1 };
                        });
                      }}
                      />
                    <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
                  </div>
            </div>
            </div>
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={
                    "btn offset-sm-10 pl-5 badge bg-transparent cancelskill"
                  }
                  onClick={() =>
                    setCertificateVisibility((state) => {
                      return { ...state, one: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*duplicate here */}
        {!(certificateVisibility.one && certificateVisibility.two) && (
          <div className={"row"}>
            <div className={"col-md"}>
              <div
                className={
                  "btn offset-sm-9 badge border-0  newskill bg-transparent"
                }
                onClick={AddCertificate}
              >
                + Add More
              </div>
            </div>
          </div>
        )}
        {/*security clearance*/}
        <SnapshotDiv
          postData={isFreelance ? freepostSecurity : postSecurity}
          parsedData={postParseData(securityInformation, securityVisibility,securityDocument)}
          id={id}
        >
          <div className={"row mx-4"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Security Clearance
              </label>
            </div>
          </div>
          <div className={"row pt-2 mx-3"}>
            <div className={"col-md-4 form-inline"}>
              <label className={"resumeformquerytext pr-1"}>
                Security Clearance<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio"
                name="security"
                value="yes"
                checked={securityVisibility.zeroin}
                onClick={() =>
                  setSecurityVisibility((state) => {
                    return { ...state, zeroin: true };
                  })
                }
              />
              <label
                className="dashboardforminputtext pl-1 pr-2"
                htmlFor="customRadio"
              >
                Yes
              </label>
              <input
                type="radio"
                className="resumeradio"
                id="customRadio0"
                name="security"
                value="no"
                checked={!securityVisibility.zeroin}
                onClick={() =>
                  setSecurityVisibility((state) => {
                    return { ...state, zeroin: false,one: false,two: false };
                  })
                }
              />
              <label
                className="dashboardforminputtext pl-1"
                htmlFor="customRadio0"
              >
                No
              </label>
            </div>
          </div>
          {securityVisibility.zeroin && (
            <div id={"securityclearance"}>
              <div className={"row mx-3 mt-2"}>
                <div className={"col-md-4"}>
                  <label
                    htmlFor={"classification"}
                    className={"resumeforminputtext"}
                  >
                    Classification<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray shadow-none"
                    }
                    id={"classification"}
                    name={"classification"}
                    value={securityInformation.classification}
                    style={{border:`${securityError.classification ? "1px solid red" : ""}`}}
                    onChange={(event) => {
                      securityClearance(
                        event,
                        securityError,
                        setSecurityError,
                        securityInformation,
                        setSecurityInformation
                      );
                    }}
                    disabled
                  >
                    <option value={""}>Select</option>
                    <option value={"TOP_SECRET"}>Top Secret</option>
                    <option value={"SECRET"}>Secret</option>
                    <option value={"CONFIDENTIAL"}>Confidential</option>
                  </select>
                  <font className={"dashboardforminputtext text-danger"}>
                    {securityError.classification}
                  </font>
                </div>
                <div className={"col-md-4"}>
                  <label htmlFor={"category"} className={"resumeforminputtext"}>
                    Category<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray shadow-none"
                    }
                    id={"category"}
                    name={"category"}
                    value={securityInformation.category}
                    style={{border:`${securityError.category ? "1px solid red" : ""}`}}
                    onChange={(event) => {
                      securityClearance(
                        event,
                        securityError,
                        setSecurityError,
                        securityInformation,
                        setSecurityInformation
                      );
                    }}
                    disabled
                  >
                    <option value={""}>Select</option>
                    <option value={"SCI"}>SCI</option>
                    <option value={"SAPS"}>SAPS</option>
                    <option value={"RD"}>RD</option>
                    <option value={"FRD"}>FRD</option>
                    <option value={"NSI"}>NSI</option>
                  </select>
                  <font className={"dashboardforminputtext text-danger"}>
                    {securityError.category}
                  </font>
                </div>
              </div>
              <div className={"row pt-2 mx-3"}>
              <div className={"col-md-4"}>
                  <label
                    htmlFor={"experience"}
                    className={"resumeforminputtext"}
                  >
                    Validity<span style={{ color: "red" }}>*</span>
                  </label>
                  <div
                    className={"dropdownforminputfield"}
                    style={{ display: "flex", flexDirection: "row",
                    border: `${
                      securityError.year || securityError.month ? "1px solid red" : ""
                    }` }}
                  >
                    <select
                      className={
                        "form-control form-select dropgray1 shadow-none"
                      }
                      id={"pexp"}
                      name={"month"}
                      value={securityInformation.month}
                      onChange={(event) => {
                        securityClearance(
                          event,
                          securityError,
                          setSecurityError,
                          securityInformation,
                          setSecurityInformation
                        );
                      }}
                      disabled
                    >
                      <option value={0}>Month</option>
                      <GetValidityMonth />
                    </select>
                    <select
                      className={
                        "form-control form-select dropgray shadow-none ml-0"
                      }
                      id={"pexp"}
                      name={"year"}
                      value={securityInformation.year}
                      onChange={(event) => {
                        securityClearance(
                          event,
                          securityError,
                          setSecurityError,
                          securityInformation,
                          setSecurityInformation
                        );
                      }}
                      disabled
                    >
                      <option value={0}>Year</option>
                      <YearOfValidity />
                    </select>
                  </div>
                  <font className={"dashboardforminputtext text-danger"}>
                    {securityError.month}
                  </font>
                  <font className={"dashboardforminputtext text-danger"}>
                    {securityError.year}
                  </font>
                </div>
                <div className={"col-md-4 form-inline mt-4"}>
                    <input
                      type="checkbox"
                      style={{width: "18px",height: "18px"}}
                      checked={securityInformation.lifeTimeValidity}
                      onChange={() => {
                        setSecurityInformation((state) => {
                          return { ...state, lifeTimeValidity: !securityInformation.lifeTimeValidity };
                        });
                      }}
                      />
                    <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
                </div>
              </div>
              <div className={"row pt-2 mx-3"}>
                <div className={"col-md-8"}>
                  <label
                    htmlFor={"information"}
                    className={"resumeforminputtext"}
                  >
                    Add More Information
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Java Backend"}
                    id={"information"}
                    value={securityInformation.comment}
                    onChange={(event) =>
                      setSecurityInformation({
                        ...securityInformation,
                        comment: event.currentTarget.value,
                      })
                    }
                    disabled
                    className={"form-control resumeforminputfield shadow-none"}
                  />
                </div>
              </div>
            </div>
          )}
        </SnapshotDiv>
        {/*new div flow here*/}
        {securityVisibility.one && (
          <SnapshotDiv
            postData={isFreelance ? freepostSecurity : postSecurity}
            parsedData={postParseData(securityInformation, securityVisibility,securityDocument)}
            id={id}
          >
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Security Clearance
                </label>
              </div>
            </div>
            {securityVisibility.onein && (
              <div id={"securityclearance"}>
                <div className={"row mx-3 mt-2"}>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"classification"}
                      className={"resumeforminputtext"}
                    >
                      Classification<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className={
                        "form-control resumeforminputfield form-select inputgray shadow-none"
                      }
                      id={"classification"}
                      name={"classification1"}
                      value={securityInformation.classification1}
                      style={{border:`${securityError.classification1 ? "1px solid red" : ""}`}}
                      onChange={(event) => {
                        securityClearance(
                          event,
                          securityError,
                          setSecurityError,
                          securityInformation,
                          setSecurityInformation
                        );
                      }}
                      disabled
                    >
                      <option value={""}>Select</option>
                      <option value={"TOP_SECRET"}>Top Secret</option>
                      <option value={"SECRET"}>Secret</option>
                      <option value={"CONFIDENTIAL"}>Confidential</option>
                    </select>
                    <font className={"dashboardforminputtext text-danger"}>
                      {securityError.classification1}
                    </font>
                  </div>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"category"}
                      className={"resumeforminputtext"}
                    >
                      Category<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className={
                        "form-control resumeforminputfield form-select inputgray shadow-none"
                      }
                      id={"category"}
                      name={"category1"}
                      value={securityInformation.category1}
                      style={{border:`${securityError.category1 ? "1px solid red" : ""}`}}
                      onChange={(event) => {
                        securityClearance(
                          event,
                          securityError,
                          setSecurityError,
                          securityInformation,
                          setSecurityInformation
                        );
                      }}
                      disabled
                    >
                      <option value={""}>Select</option>
                      <option value={"SCI"}>SCI</option>
                      <option value={"SAPS"}>SAPS</option>
                      <option value={"RD"}>RD</option>
                      <option value={"FRD"}>FRD</option>
                      <option value={"NSI"}>NSI</option>
                    </select>
                    <font className={"dashboardforminputtext text-danger"}>
                      {securityError.category1}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2 mx-3"}>
                 <div className={"col-md-4"}>
                    <label
                      htmlFor={"experience"}
                      className={"resumeforminputtext"}
                    >
                      Validity<span style={{ color: "red" }}>*</span>
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row",
                      border: `${
                        securityError.year1 || securityError.month1 ? "1px solid red" : ""
                      }` 
                      }} 
                    >
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name={"month1"}
                        value={securityInformation.month1}
                        onChange={(event) => {
                          securityClearance(
                            event,
                            securityError,
                            setSecurityError,
                            securityInformation,
                            setSecurityInformation
                          );
                        }}
                        disabled
                      >
                        <option value={0}>Month</option>
                        <GetValidityMonth />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name={"year1"}
                        value={securityInformation.year1}
                        onChange={(event) => {
                          securityClearance(
                            event,
                            securityError,
                            setSecurityError,
                            securityInformation,
                            setSecurityInformation
                          );
                        }}
                        disabled
                      >
                        <option value={0}>Year</option>
                        <YearOfValidity />
                      </select>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {securityError.month1}
                    </font>
                    <font className={"dashboardforminputtext text-danger"}>
                      {securityError.year1}
                    </font>
                  </div>
                  <div className={"col-md-4 form-inline mt-4"}>
                    <input
                      type="checkbox"
                      style={{width: "18px",height: "18px"}}
                      checked={securityInformation.lifeTimeValidity1}
                      onChange={() => {
                        setSecurityInformation((state) => {
                          return { ...state, lifeTimeValidity1: !securityInformation.lifeTimeValidity1 };
                        });
                      }}
                      />
                    <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
                  </div>
                 </div>
                <div className={"row pt-2 mx-3"}>
                  <div className={"col-md-8"}>
                    <label
                      htmlFor={"information"}
                      className={"resumeforminputtext"}
                    >
                      Add More Information
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Java Backend"}
                      id={"information"}
                      value={securityInformation.comment1}
                      onChange={(event) =>
                        setSecurityInformation({
                          ...securityInformation,
                          comment1: event.currentTarget.value,
                        })
                      }
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                  onClick={() =>
                    setSecurityVisibility((state) => {
                      return { ...state, one: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*another div*/}
        {securityVisibility.two && (
          <SnapshotDiv
            postData={isFreelance ? freepostSecurity : postSecurity}
            parsedData={postParseData(securityInformation, securityVisibility,securityDocument)}
            id={id}
          >
            <div className={"row mx-4"}>
              <div className={"col-md"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Security Clearance
                </label>
              </div>
            </div>
            {securityVisibility.twoin && (
              <div id={"securityclearance"}>
                <div className={"row mx-3 mt-2"}>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"classification"}
                      className={"resumeforminputtext"}
                    >
                      Classification<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className={
                        "form-control resumeforminputfield form-select inputgray shadow-none"
                      }
                      id={"classification"}
                      name={"classification2"}
                      value={securityInformation.classification2}
                      style={{border:`${securityError.classification2 ? "1px solid red" : ""}`}}
                      onChange={(event) => {
                        securityClearance(
                          event,
                          securityError,
                          setSecurityError,
                          securityInformation,
                          setSecurityInformation
                        );
                      }}
                      disabled
                    >
                      <option value={""}>Select</option>
                      <option value={"TOP_SECRET"}>Top Secret</option>
                      <option value={"SECRET"}>Secret</option>
                      <option value={"CONFIDENTIAL"}>Confidential</option>
                    </select>
                    <font className={"dashboardforminputtext text-danger"}>
                      {securityError.classification2}
                    </font>
                  </div>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"category"}
                      className={"resumeforminputtext"}
                    >
                      Category<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className={
                        "form-control resumeforminputfield form-select inputgray shadow-none"
                      }
                      id={"category"}
                      name={"category2"}
                      value={securityInformation.category2}
                      style={{border:`${securityError.category2 ? "1px solid red" : ""}`}}
                      onChange={(event) => {
                        securityClearance(
                          event,
                          securityError,
                          setSecurityError,
                          securityInformation,
                          setSecurityInformation
                        );
                      }}
                      disabled
                    >
                      <option value={""}>Select</option>
                      <option value={"SCI"}>SCI</option>
                      <option value={"SAPS"}>SAPS</option>
                      <option value={"RD"}>RD</option>
                      <option value={"FRD"}>FRD</option>
                      <option value={"NSI"}>NSI</option>
                    </select>
                    <font className={"dashboardforminputtext text-danger"}>
                      {securityError.category2}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2 mx-3"}>
                 <div className={"col-md-4"}>
                    <label
                      htmlFor={"experience"}
                      className={"resumeforminputtext"}
                    >
                      Validity<span style={{ color: "red" }}>*</span>
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row",
                      border: `${
                        securityError.year1 || securityError.month1 ? "1px solid red" : ""
                      }` 
                      }} 
                    >
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name={"month1"}
                        value={securityInformation.month1}
                        onChange={(event) => {
                          securityClearance(
                            event,
                            securityError,
                            setSecurityError,
                            securityInformation,
                            setSecurityInformation
                          );
                        }}
                        disabled
                      >
                        <option value={0}>Month</option>
                        <GetValidityMonth />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name={"year1"}
                        value={securityInformation.year1}
                        onChange={(event) => {
                          securityClearance(
                            event,
                            securityError,
                            setSecurityError,
                            securityInformation,
                            setSecurityInformation
                          );
                        }}
                        disabled
                      >
                        <option value={0}>Year</option>
                        <YearOfValidity />
                      </select>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {securityError.month1}
                    </font>
                    <font className={"dashboardforminputtext text-danger"}>
                      {securityError.year1}
                    </font>
                  </div>
                  <div className={"col-md-4 form-inline mt-4"}>
                    <input
                      type="checkbox"
                      style={{width: "18px",height: "18px"}}
                      checked={securityInformation.lifeTimeValidity1}
                      onChange={() => {
                        setSecurityInformation((state) => {
                          return { ...state, lifeTimeValidity1: !securityInformation.lifeTimeValidity1 };
                        });
                      }}
                      />
                    <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
                  </div>
                 </div>
                <div className={"row pt-2 mx-3"}>
                  <div className={"col-md-8"}>
                    <label
                      htmlFor={"information"}
                      className={"resumeforminputtext"}
                    >
                      Add More Information
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Java Backend"}
                      id={"information"}
                      value={securityInformation.comment2}
                      onChange={(event) =>
                        setSecurityInformation({
                          ...securityInformation,
                          comment2: event.currentTarget.value,
                        })
                      }
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={"row"}>
              <div className={"col-md"}>
                <div
                  className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                  onClick={() =>
                    setSecurityVisibility((state) => {
                      return { ...state, two: false };
                    })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </SnapshotDiv>
        )}
        {/*end of div*/}
        {securityVisibility.zeroin && !(securityVisibility.one && securityVisibility.two) && (
          <div className={"row"}>
            <div className={"col-md"}>
              <div
                className={
                  "btn offset-sm-9 badge border-0  newskill bg-transparent"
                }
                onClick={addSecurityQuestion}
              >
                + Add More
              </div>
            </div>
          </div>
        )}
        <SnapshotDiv
          postData={isFreelance ? freepostSecurity : postSecurity}
          parsedData={postParseData(securityInformation, securityVisibility,securityDocument)}
          id={id}
        >
          <div className={"row mx-3 mt-1"}>
            <div className={"col-md-4"}>
              <label className={"resumeforminputtext"}>
                Upload Passport{" "}
              </label>
            </div>
          </div>
          <div className={"row mx-3"}>
            <div className={"col-md-4"}>
            <input
                type={"text"}
                className={"form-control resumeuploadtextbox shadow-none"}
                id={"filename0"}
                placeholder={
                  securityInformation.passport
                    ? securityInformation.passport.name
                    : "Upload Document"
                }
                disabled
              />
            </div>
            <div className={"col-md-1  pl-3"}>
              <label className={"browsebtn btn"}>
              <input
                  type="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ opacity: 0, position: "absolute" }}
                      name="picture"
                      id={"uploadDocument"}
                      onChange={(event) => {
                        setSecurityInformation({
                          ...securityInformation,
                          passport: event.target.files[0],
                        });
                       }}
                  />
                <span>Browse</span>
              </label>
            </div>
            <div className={"col-md-5 pr-0"}>
            <button
                    type={"button"}
                    className={"btn uploadbtn ml-4 mb-5"}
                    ref={resumeRef}
                    onClick={handlePassport}
                  >
                    {documentLoader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
            </div>
          </div>
          <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.passportError && (
                    <p className="errormsg">{documentError.passportError}</p>
                  )}
                  <label
                    className={"fileerr errormsg"}
                    style={{ fontSize: "14px" }}
                  ></label>
                </div>
              </div>
        </SnapshotDiv>

        <SnapshotDiv
        postData={isFreelance ? freepostSecurity : postSecurity}
        parsedData={postParseData(securityInformation, securityVisibility,securityDocument)}
        id={id}>
           <div className={"row mx-3 mt-1"}>
            <div className={"col-md-4"}>
              <label className={"resumeforminputtext"}>
                Upload Work Auth{" "}
              </label>
            </div>
          </div>
          <div className={"row mx-3"}>
            <div className={"col-md-4 pb-2"}>
            <input
                type={"text"}
                className={"form-control resumeuploadtextbox shadow-none"}
                id={"filename0"}
                placeholder={
                  securityInformation.workAuth
                    ? securityInformation.workAuth.name
                    : "Upload Document"
                }
                disabled
              />
            </div>
            <div className={"col-md-1  pl-3"}>
              <label className={"browsebtn btn"}>
              <input
                  type="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ opacity: 0, position: "absolute" }}
                      name="workAuthPicture"
                      id={"uploadWorkAuthDocument"}
                      onChange={(event) => {
                        setSecurityInformation({
                          ...securityInformation,
                          workAuth: event.target.files[0],
                        });
                       }}
                  />
                <span>Browse</span>
              </label>
            </div>
            <div className={"col-md-5 pr-0"}>
            <button
                    type={"button"}
                    className={"btn uploadbtn ml-4 mb-5"}
                    ref={workAuthRef}
                    onClick={handleWorkAuth}
                  >
                    {workAuthLoader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
            </div>
          </div>
          <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.workAuthError && (
                    <p className="errormsg">{documentError.workAuthError}</p>
                  )}
                  <label
                    className={"fileerr errormsg"}
                    style={{ fontSize: "14px" }}
                  ></label>
                </div>
              </div>
        </SnapshotDiv>

        <SnapshotDiv
        postData={isFreelance ? freepostSecurity : postSecurity}
        parsedData={postParseData(securityInformation, securityVisibility,securityDocument)}
        id={id}>
           <div className={"row mx-3 mt-1"}>
            <div className={"col-md-4"}>
              <label className={"resumeforminputtext"}>
                Upload Driving License{" "}
              </label>
            </div>
          </div>
          <div className={"row mx-3"}>
            <div className={"col-md-4 pb-2"}>
            <input
                type={"text"}
                className={"form-control resumeuploadtextbox shadow-none"}
                id={"filename0"}
                placeholder={
                  securityInformation.drivingLicense
                    ? securityInformation.drivingLicense.name
                    : "Upload Document"
                }
                disabled
              />
            </div>
            <div className={"col-md-1  pl-3"}>
              <label className={"browsebtn btn"}>
              <input
                  type="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ opacity: 0, position: "absolute" }}
                      name="licensePicture"
                      id={"uploadLicenseDocument"}
                      onChange={(event) => {
                        setSecurityInformation({
                          ...securityInformation,
                          drivingLicense: event.target.files[0],
                        });
                       }}
                  />
                <span>Browse</span>
              </label>
            </div>
            <div className={"col-md-5 pr-0"}>
            <button
                    type={"button"}
                    className={"btn uploadbtn ml-4 mb-5"}
                    ref={licenseRef}
                    onClick={handleDrivingLicense}
                  >
                    {licenseLoader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
            </div>
          </div>
          <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.drivingLicenseError && (
                    <p className="errormsg">{documentError.drivingLicenseError}</p>
                  )}
                  <label
                    className={"fileerr errormsg"}
                    style={{ fontSize: "14px" }}
                  ></label>
                </div>
              </div>
        </SnapshotDiv>

        {/*social*/}
        <SnapshotDiv
          postData={
            isFreelance ? freepostBuildResumeSocial : postBuildResumeSocial
          }
          parsedData={socialLink}
          social={true}
          id={id}
        >
          <div className={"row mx-4"}>
            <div className={"col-md"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                Social
              </label>
            </div>
          </div>
          {/*facebook*/}
          <div className={"row pl-4"}>
            <div className={"col-lg-5"}>
              <div
                className={"pt-1"}
                style={{ background: "#FBFBFB", borderRadius: "10px" }}
              >
                <div className={"row"}>
                  <div className="col-lg-2">
                    <span
                      className={"p-2"}
                      style={{ background: "#3D6AD6", borderRadius: "10px" }}
                    >
                      <img
                        src="/icon/candidate/resume/facebook.png"
                        alt={"facebook"}
                        className={""}
                      />
                    </span>
                  </div>
                  <div className={"col-lg-10 portfoliocontent"}>
                    <input
                      type={"text"}
                      className={"form-control socialinputfield shadow-none"}
                      placeholder={"facebook.com/userid"}
                      value={socialLink.facebook}
                      onChange={(event) =>
                        setSocialLink({
                          ...socialLink,
                          facebook: event.currentTarget.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*ball */}
          <div className={"row pl-4 pt-3"}>
            <div className={"col-lg-5"}>
              <div
                className={"pt-1"}
                style={{ background: "#FBFBFB", borderRadius: "10px" }}
              >
                <div className={"row"}>
                  <div className="col-lg-2">
                    <span
                      className={"p-2"}
                      style={{ background: "#03A9F4", borderRadius: "10px" }}
                    >
                      <img
                        src="/icon/candidate/resume/ball.png"
                        alt={"ball"}
                        className={""}
                      />
                    </span>
                  </div>
                  <div className={"col-lg-10 portfoliocontent"}>
                    <input
                      type={"text"}
                      className={"form-control socialinputfield shadow-none"}
                      placeholder={"twitter.com/userid"}
                      value={socialLink.twitter}
                      onChange={(event) =>
                        setSocialLink({
                          ...socialLink,
                          twitter: event.currentTarget.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* linkedin*/}
          <div className={"row pl-4 pt-3"}>
            <div className={"col-lg-5"}>
              <div
                className={"pt-1"}
                style={{ background: "#FBFBFB", borderRadius: "10px" }}
              >
                <div className={"row"}>
                  <div className="col-lg-2">
                    <span
                      className={"p-2"}
                      style={{ background: "#0073B1", borderRadius: "10px" }}
                    >
                      <img
                        src="/icon/candidate/resume/linkedin.png"
                        alt={"linkedin"}
                        className={""}
                      />
                    </span>
                  </div>
                  <div className={"col-lg-10 portfoliocontent"}>
                    <input
                      type={"text"}
                      className={"form-control socialinputfield shadow-none"}
                      placeholder={"linkedln.com/userid"}
                      value={socialLink.linkedin}
                      onChange={(event) =>
                        setSocialLink({
                          ...socialLink,
                          linkedin: event.currentTarget.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*pinterest*/}
          <div className={"row pl-4 pt-3"}>
            <div className={"col-lg-5"}>
              <div
                className={"pt-1"}
                style={{ background: "#FBFBFB", borderRadius: "10px" }}
              >
                <div className={"row"}>
                  <div className="col-lg-2">
                    <span
                      className={"p-2"}
                      style={{ background: "#BFBFBF", borderRadius: "10px" }}
                    >
                      <img
                        src="/icon/candidate/resume/pin.png"
                        alt={"pinterest"}
                        className={""}
                      />
                    </span>
                  </div>
                  <div className={"col-lg-10 portfoliocontent"}>
                    <input
                      type={"text"}
                      className={"form-control socialinputfield shadow-none"}
                      placeholder={"pinterest.com/userid"}
                      value={socialLink.pintrest}
                      onChange={(event) =>
                        setSocialLink({
                          ...socialLink,
                          pintrest: event.currentTarget.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* youtube*/}
          <div className={"row pl-4 pt-3"}>
            <div className={"col-lg-5"}>
              <div
                className={"pt-1"}
                style={{ background: "#FBFBFB", borderRadius: "10px" }}
              >
                <div className={"row"}>
                  <div className="col-lg-2">
                    <span
                      className={"p-2"}
                      style={{ background: "#FF0000", borderRadius: "10px" }}
                    >
                      <img
                        src="/icon/candidate/resume/youtube.png"
                        alt={"youtube"}
                        className={""}
                      />
                    </span>
                  </div>
                  <div className={"col-lg-10 portfoliocontent"}>
                    <input
                      type={"text"}
                      className={"form-control socialinputfield shadow-none"}
                      placeholder={"youtube.com/userid"}
                      value={socialLink.youtube}
                      onChange={(event) =>
                        setSocialLink({
                          ...socialLink,
                          youtube: event.currentTarget.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*skype*/}

          <div className={"row pl-3 pt-3"}>
            <div className={"col-lg-5"}>
              <div className={"row pt-0 pb-0"}>
                <div className="col-lg-2 pt-0 pb-0">
                  <img
                    src="/icon/candidate/resume/skype.png"
                    alt={"skype"}
                    style={{
                      transform: "scale(0.7)",
                      backgroundColor: "#F9F9F9",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      paddingRight: "30px",
                    }}
                  />
                </div>
                <div className={"col-lg-10 skypetext pt-2"}>
                  <input
                    type={"text"}
                    className={"form-control socialinputfield shadow-none"}
                    placeholder={"/userid"}
                    style={{ height: "39px" }}
                    value={socialLink.skype}
                    onChange={(event) =>
                      setSocialLink({
                        ...socialLink,
                        skype: event.currentTarget.value,
                      })
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          {/*www*/}

          <div className={"row pl-4 pt-3"}>
            <div className={"col-lg-5"}>
              <div
                className={"pt-1"}
                style={{ background: "#FBFBFB", borderRadius: "10px" }}
              >
                <div className={"row"}>
                  <div className="col-lg-2">
                    <span className={"p-2"} style={{ borderRadius: "10px" }}>
                      <img
                        src="/icon/candidate/resume/www.png"
                        alt={"www"}
                        className={""}
                        style={{ transform: "scale(0.8)" }}
                      />
                    </span>
                  </div>
                  <div className={"col-lg-10 portfoliocontent"}>
                    <input
                      type={"text"}
                      className={"form-control socialinputfield shadow-none"}
                      placeholder={"www.domainname.com"}
                      value={socialLink.website}
                      onChange={(event) =>
                        setSocialLink({
                          ...socialLink,
                          website: event.currentTarget.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SnapshotDiv>
        <div className={"row pt-3"}>
          {isFreelance ? (
            <div className={"col-md pr-5 mr-5"}>
              {redirect ? (
                <Redirect
                  to={"/candidate/dashboardview"}
                  className={"btn offset-sm-9 save"}
                />
              ) : (
                <button
                  type="submit"
                  className={"btn offset-sm-9  save"}
                  ref={snapRef}
                >
                  {loader ? (
                    <i
                      className = "fa fa-refresh fa-spin"
                      style={{ padding: "3px 5px" }}
                    ></i>
                  ) : (
                    ""
                  )}
                  Save & Continue
                </button>
              )}
            </div>
          ) : (
            <div className={"col-md pr-5 mr-5"}>
              {redirect ? (
                <Redirect
                  to={"/candidate/dashboardview"}
                  className={"btn offset-sm-9 save"}
                />
              ) : (
                <button
                  type="submit"
                  className={"btn offset-sm-9  save"}
                  ref={snapRef}
                >
                  {loader ? (
                    <i
                      className = "fa fa-refresh fa-spin"
                      style={{ padding: "3px 5px" }}
                    ></i>
                  ) : (
                    ""
                  )}
                  Save & Continue
                </button>
              )}
            </div>
          )}
        </div>
      </form>
      <GoToTop />
    </div>
  );
};
export default React.memo(ResumeSnapshot);
