import React from "react";
import GoToTop from "../../../common/GoToTop";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "../../../../styles/app.scss";
import { Link } from "react-router-dom";
import CandidateMatchingJobs from "./Matchingjob/candidateMatchingJobs";
import DashboardCards from "../../common/DashboardCards";

const CandidateDetails = () => {
  const token = useSelector((state) => state.token);

  const data = [
    {
      name: "Matching Jobs",
      progressColor: "#2BC155",
      count: 100
    },
    {
      name: "Interview Schedule",
      progressColor: "#3F9AE0",
      count: 1
    },
    {
      name: "Shortlisted Jobs",
      progressColor: "#FF9B52",
      count: 2
    },
    {
      name: "Application Sent",
      progressColor: "#8743DF",
      count: 1
    }
  ]

  return (
    <div className={"col mx-5"}>
      <div className={"contentholder w-100 pl-4 pb-3 pt-4 pr-4 mt-5"}>
        <div className={"row"}>
          <div
            className={"col-md-2 pt-5"}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <figure className="story_shape">
              <img
                src="/icon/common/darkprofile.png"
                alt="person on a tour"
                className="story_img"
              />
              <figcaption className="story_caption">
                {/* {props.firstName.charAt(0)}
                  {props.lastName.charAt(0)} */}
                DS
              </figcaption>
            </figure>
          </div>
          <div className={"col-md-4"}>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Name :{" "}
                  <font style={{ fontWeight: "normal" }}>
                    Debapriya Subudhi
                  </font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Candidate ID :{" "}
                  <font style={{ fontWeight: "normal" }}>DS1092020</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Email :{" "}
                  <font style={{ fontWeight: "normal" }}>
                    debapriyasubudhi2000@gmail.com
                  </font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Phone :{" "}
                  <font style={{ fontWeight: "normal" }}>(999) 898-7889</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">Address :</label>
                <font className="parafont" style={{ fontWeight: "normal" }}>
                  {" "}
                  1629,K St NW Suite 300 <br /> Washington,DC,20006,US
                </font>
              </div>
            </div>
          </div>
          <div className={"col-md-3"}>
            <div className={"row pl-4 pt-2"}>
              <div className={"col-md"}>
                <img
                  src="../../icon/candidate/applications/correction.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                />
                <font className={"parafont"} style={{ fontWeight: "bold" }}>
                  WorkAuth Details
                </font>
              </div>
            </div>
            <div className={"row ml-4"}>
              <div
                className={"col-md parafont ml-3"}
                style={{ fontWeight: "normal" }}
              >
                <font>W2_Hourly</font>
                <br />
                <font>$45/Hourly</font>
                <br />
              </div>
            </div>
            <div className={"row pl-4 pt-2"}>
              <div className={"col-md"}>
                <img
                  src="../../icon/candidate/applications/correction.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                />
                <font className={"parafont"} style={{ fontWeight: "bold" }}>
                  Primary Skill
                </font>
              </div>
            </div>
            <div className={"row ml-4"}>
              <div
                className={"col-md parafont ml-3"}
                style={{ fontWeight: "normal" }}
              >
                <font>Java</font>
                <br />
              </div>
            </div>
          </div>
          <div className={"col-md-3"}>
            <div className={"row pl-4 pt-2"}>
              <div className={"col-md"}>
                <img
                  src="../../icon/candidate/applications/correction.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                />
                <font className={"parafont"} style={{ fontWeight: "bold" }}>
                  Academics Qualification
                </font>
              </div>
            </div>
            <div className={"row ml-4"}>
              <div
                className={"col-md parafont ml-3"}
                style={{ fontWeight: "normal" }}
              >
                <font>Master</font>
                <br />
                <font>Doctorate</font>
                <br />
              </div>
            </div>
            <div className={"row pl-4 pt-2"}>
              <div className={"col-md"}>
                <img
                  src="../../icon/candidate/applications/correction.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                />
                <font className={"parafont"} style={{ fontWeight: "bold" }}>
                  Security Clearance
                </font>
              </div>
            </div>
            <div className={"row ml-4"}>
              <div
                className={"col-md parafont ml-3"}
                style={{ fontWeight: "normal" }}
              >
                <font>SCI</font>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"row"}>
      <DashboardCards data={data} col={"col-lg-3"}/>
      </div>
      <CandidateMatchingJobs />
      <GoToTop />
    </div>
  );
};
export default React.memo(CandidateDetails);
