import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMatchingJob } from "../../../api/candidate/matchingJobList";
import { recgetMatchingJob } from "../../../api/recruiter/candidate/matchingJobList";
import { freegetMatchingJob } from "../../../api/freelance/candidate/matchingJobList";

const JobApplyContent = () => {
  // return jobapply content data

  const paramId = useParams();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);

  const [data, setData] = useState();

  useEffect(() => {
    async function callItSelf() {
      try {
        let payload;
        let response = isrecruiterCandidate
          ? await recgetMatchingJob(token, paramId.reqID)
          : isFreelance
          ? await freegetMatchingJob(token, paramId.reqID)
          : await getMatchingJob(token, paramId.reqID);
        if (response.status === 200) {
          payload = await response.json();
          payload = payload.payload;
          if (payload) {
            setData(payload.JobRequirement);
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);

  return (
    <React.Fragment>
      <div className={"row  pt-5"}>
        <div className={"col-md"}>
          <div
            className={"btn globalfontrule"}
            style={{
              fontSize: "12px",
              color: "#3F9AE0",
              backgroundColor: "rgba(121,57,203,0.16)",
              borderRadius: "6px",
            }}
          >
            Job Description
          </div>
        </div>
      </div>

      <div className={"row pt-2 pb-3"}>
        <div className={"col-md"}>
          <font className={"parafont"} style={{ fontWeight: "normal" }}>
            {data ? data.description : ""}
          </font>
        </div>
      </div>
      <div className={"row "}>
        <div className={"col-md"}>
          <img
            src="/icon/candidate/applications/correction.png"
            alt={"correction icon"}
            className={"img-fluid pr-3"}
          />
          <font className={"parafont"} style={{ fontWeight: "bold" }}>
            Primary Skill
          </font>
        </div>
      </div>
      {data
        ? data.ClientPrimarySkills.map((obj, index) => {
            return (
              <div className={"row ml-2"} key={index}>
                <div className={"col-md parafont ml-3"}>
                  <font style={{ fontWeight: "normal" }}>
                    {obj.ClientPrimarySkill}
                  </font>
                </div>
              </div>
            );
          })
        : ""}
      {data && data.ClientSecondarySkills.length!==0 ? (
        <div className={"row pt-2"}>
          <div className={"col-md"}>
            <img
              src="/icon/candidate/applications/correction.png"
              alt={"correction icon"}
              className={"img-fluid pr-3"}
            />
            <font className={"parafont"}>Secondary Skill</font>
          </div>
        </div>
      ) : (
        ""
      )}
      {data
        ? data.ClientSecondarySkills.map((obj, index) => {
            return (
              <div className={"row ml-2"} key={index}>
                <div className={"col-md parafont ml-3"}>
                  <font style={{ fontWeight: "normal" }}>
                    {obj.ClientSecondarySkill}
                  </font>
                </div>
              </div>
            );
          })
        : ""}
      <div className={"row pt-2"}>
        <div className={"col-md"}>
          <img
            src="/icon/candidate/applications/correction.png"
            alt={"correction icon"}
            className={"img-fluid pr-3"}
          />
          <font className={"parafont"}>Academics Qualification</font>
        </div>
      </div>
      {data
        ? data.AcademicCertificates.map((obj, index) => {
            return (
              <div className={"row pt-1 ml-2"} key={index}>
                <div className={"col-md parafont ml-3"}>
                  <font style={{ fontWeight: "normal" }}>
                    {obj.academicsQualification} - {obj.specialization}
                  </font>
                </div>
              </div>
            );
          })
        : ""}
      {data && data.securityClearanceRequired === "YES" ? (
        <>
          <div className={"row pt-2"}>
            <div className={"col-md parafont"}>
              <img
                src="/icon/candidate/applications/correction.png"
                alt={"correction icon"}
                className={"img-fluid pr-3"}
              />
              <font className={"parafont"}>Security Clearance</font>
            </div>
          </div>
          <div className={"row pt-2 ml-3"}>
            <div className={"col-md"}>
              <font style={{ fontWeight: "normal" }} className={"parafont"}>
                {data ? data.securityClearanceClassification : ""}
              </font>
              <br />
              <font style={{ fontWeight: "normal" }} className={"parafont"}>
                {data ? data.securityClearanceCategory : ""}
              </font>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className={"row pl-5 ml-5"}>
        <div
          className={"col-md"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div>
            {/* <Link to={"/"} className={"btn shortlistjob"}>
              Shortlist job
            </Link> */}
          </div>
        </div>
      </div>
      <div className={"row pt-2 pb-2"}>
        <div className={"col-xl"}>
          <label className="main pt-1">
            Create alert for similar jobs
            <input type="checkbox" />
            <span className="geekmark"></span>
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(JobApplyContent);
