import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { MatchingPagination } from "../../../api/candidate/matchingPagination";
import { recmatchingPagination } from "../../../api/recruiter/candidate/matchingPagination";
import { freematchingPagination } from "../../../api/freelance/candidate/matchingPagination";

const JobApplyHeader = () => {
  // return jobapply header data
  const paramId = useParams();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const [data, setData] = useState();

  useEffect(() => {
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await recmatchingPagination(token, 1)
          : isFreelance
          ? await freematchingPagination(token, 1)
          : await MatchingPagination(token, 1);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload) {
            payload.map((obj) => {
              if (obj.uuid === paramId.reqID) {
                setData({
                  id: obj.jobRequirementId,
                  employType: obj.JobRequirement.employementType,
                  jobTitle: obj.JobRequirement.jobTitle,
                  rate: obj.JobRequirement.rate,
                  rateType: obj.JobRequirement.rateType,
                  location: obj.JobRequirement.location,
                  matchPercent: obj.matchPercentage,
                });
              }
              return null;
            });
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);
  return (
    <React.Fragment>
      <div className={"row mt-4"}>
        <div className={"col-md-8"}>
          <label
            style={{ fontWeight: "500", fontSize: "16px", color: "#515151" }}
          >
            Requirement ID -{data ? data.id : ""}
          </label>
        </div>
        <div className={"col-md-1"}>
          <label
            style={{ color: "#C2C2C2", fontWeight: "500", fontSize: "16px" }}
          >
            Matching
          </label>
        </div>
        <div className={"col-md  pr-0"}>
          <div
            className={"badge py-2"}
            style={{
              backgroundColor: "#D3D3D3",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {data ? data.matchPercent : ""}%
          </div>
        </div>
      </div>
      <div className={"row align-items-center pt-1 mt-4"}>
        <div className={"col-md-3"}>
          <font
            className={"globalfontrule"}
            style={{ fontSize: "10px", color: "#3F9AE0" }}
          >
            {data ? data.employType : ""}
          </font>
          <br />
          <font
            className={"globalfontrule"}
            style={{ fontSize: "16px", color: "#313131" }}
          >
            {data ? data.jobTitle : ""}
          </font>
          <br />
          <div className={"row"}>
            <div className={"col"}>
              <font
                className={"globalfontrule"}
                style={{
                  fontWeight: "normal",
                  fontSize: "12px",
                  color: "#B2B0AF",
                }}
              >
                {data ? data.location : ""}&nbsp;&nbsp;&nbsp; . USA
              </font>
            </div>
          </div>
        </div>
        <div className={"col-md-2 pl-4"}>
          <font
            className={"globalfontrule"}
            style={{ fontSize: "12px", color: "#313131" }}
          >
            ${data ? data.rate : ""}/{data ? data.rateType : ""}
          </font>
          <br />
        </div>
        <div className={"col-md"}>
          <font
            className={"globalfontrule"}
            style={{ fontSize: "12px", color: "#313131" }}
          >
            Client Rating
          </font>
          <div
            className={"globalfontrule btn starbtn"}
            style={{ fontSize: "12px", color: "#FF8A00" }}
          >
            4.3
          </div>
        </div>
        {/* <div className={"col-md"}>
          <Link to={"/candidate/jobapply"} className={"btn sharejobbtn"}>
            Share job
          </Link>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default React.memo(JobApplyHeader);
