import { RECRUITERCANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for recruiter matchingjob sorting
async function recmatchingjobSorting(token, currentPage, percentage, order) {
  const response = await fetch(
    RECRUITERCANDIDATEMATCHINGJOBS +
      `?limit=6&page=${currentPage}&${percentage}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter matchingjob card sorting
async function recmatchingjobCardSorting(
  token,
  card,
  currentPage,
  percentage,
  order
) {
  const response = await fetch(
    RECRUITERCANDIDATEMATCHINGJOBS +
      `?card=${card}&limit=6&page=${currentPage}&${percentage}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { recmatchingjobSorting, recmatchingjobCardSorting };
