import React from "react";
const GetNoOfPosition = () => {
  // return a group of options from 0 to 11
  const child = [];
  for (var i = 1; i < 21; i++)
    child.push(
      <option key={i} value={`${i}`}>
        {i}
      </option>
    );
  return child;
};

export default React.memo(GetNoOfPosition);
