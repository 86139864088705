import { CLIENTCERTIFICATE } from "../ENVIRONMENT";

// api for client certificate list
function certificateList(token) {
  const response = fetch(CLIENTCERTIFICATE, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { certificateList };
