import { FREELANCECLIENTMATCHINGJOBS } from "../../ENVIRONMENT";

// api for freelance client application sorting
async function freeapplicationSorting(token, currentPage, name, order) {
  const response = await fetch(
    FREELANCECLIENTMATCHINGJOBS +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance client application sorting of filter
async function freefilterApplicationSorting(
  token,
  data,
  currentPage,
  name,
  order
) {
  const response = await fetch(
    FREELANCECLIENTMATCHINGJOBS +
      `?id=${data}&limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance client application sorting of other card
async function freeotherApplicationSorting(
  token,
  data,
  card,
  currentPage,
  name,
  order
) {
  const response = await fetch(
    FREELANCECLIENTMATCHINGJOBS +
      `?id=${data}&card=${card}&limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance client application sorting status
async function freefilterStatusSorting(token, card, currentPage, name, order) {
  const response = await fetch(
    FREELANCECLIENTMATCHINGJOBS +
      `?card=${card}&limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  freeapplicationSorting,
  freefilterApplicationSorting,
  freeotherApplicationSorting,
  freefilterStatusSorting,
};
