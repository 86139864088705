import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import UploadResume from "./UploadResume";
import UserRegistrationForm from "./UserRegistrationForm";
import ResumePreview from "./ResumePreview";
import BuildResume from "./Resume-Builder/BuildResume";

const DashboardHome = () => {
  // return hadboardhome page for new user registration
  const dispatch = useDispatch();
  try {
    dispatch({ type: "newusertrue" });
  } catch {}

  try {
    document.getElementById("replacetitleimg").innerText = "Dashboard";
  } catch {}

  return (
    <React.Fragment>
      <Route path={"/candidate/dashboard/register"}>
        <UserRegistrationForm
          headername={"Registration"}
          buttonname="Save & Continue"
        />
      </Route>
      <Route path={"/candidate/dashboard/uploadresume"}>
        <UploadResume />
      </Route>
      <Route path={"/candidate/dashboard/resumepreview"}>
        <ResumePreview />
      </Route>
      <Route path={"/candidate/dashboard/buildresume"}>
        <BuildResume />
      </Route>
    </React.Fragment>
  );
};

export default React.memo(DashboardHome);
