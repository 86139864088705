import { REGISTERCANDIDATE } from "../ENVIRONMENT.js";

async function register(token, registerData) {
  // api for submit candidate register 
  const response = await fetch(REGISTERCANDIDATE, {
    method: "PUT",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(registerData),
  });
  return response;
}

async function notificationRegister(token, registerData) {
  // api for submit candidate register 
  const response = await fetch(REGISTERCANDIDATE + "?initialStep=true", {
    method: "PUT",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(registerData),
  });
  return response;
}

export { register,notificationRegister };
