import {
  FREELANCECLIENTREQUIREMENT,
  FREELANCECLIENTACTIVEJOBS,
  FREELANCEARCHIVEREQUIREMENTS,
} from "../../ENVIRONMENT";

// api for freelance client dashboard requirement
async function freeDashboardRequirment(token, currentPage) {
  const response = await fetch(
    FREELANCECLIENTREQUIREMENT + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance client dashboardcard requirement
async function freeDashboardCardRequirment(token, currentPage, card) {
  const response = await fetch(
    FREELANCECLIENTREQUIREMENT + `?limit=6&page=${currentPage}&card=${card}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance client dashboard active jobs
async function freeDashboardActiveJobs(token, currentPage) {
  const response = await fetch(
    FREELANCECLIENTACTIVEJOBS + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance client archive requirement
async function freeArchiveRequirements(token, currentPage) {
  const response = await fetch(
    FREELANCEARCHIVEREQUIREMENTS + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  freeDashboardRequirment,
  freeDashboardCardRequirment,
  freeDashboardActiveJobs,
  freeArchiveRequirements,
};
