import { SUPERADMINRECRUITERLIST } from "../../ENVIRONMENT";

async function recruiterListData(token, currentPage) {
  // api for recruiter list
  const response = await fetch(
    SUPERADMINRECRUITERLIST + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
export { recruiterListData };
