import { FREELANCECANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for freelance multiple matching filter
async function freematchingMultipleFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page
) {
  const response = await fetch(
    FREELANCECANDIDATEMATCHINGJOBS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance multiple matching card filter
async function freematchingCardFilter(
  token,
  location,
  locationData,
  skill,
  skillData,
  secondary,
  secondaryData,
  work,
  workData,
  security,
  securityData,
  employ,
  employData,
  domain,
  domainData,
  certificate,
  certiData,
  qual,
  qualData,
  rate,
  rateData,
  clearance,
  clearanceData,
  page,
  card
) {
  const response = await fetch(
    FREELANCECANDIDATEMATCHINGJOBS +
      `?${location}=${locationData}&${skill}=${skillData}&${secondary}=${secondaryData}&${work}=${workData}&${security}=${securityData}&${employ}=${employData}&${domain}=${domainData}&${certificate}=${certiData}&${qual}=${qualData}&${rate}=${rateData}&${clearance}=${clearanceData}&limit=6&page=${page}&card=${card}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freematchingCardFilter, freematchingMultipleFilter };
