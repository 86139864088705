import { MATCHINGJOBS } from "../ENVIRONMENT";

async function matchingjobSorting(token, currentPage, percentage, order) {
  // api for candidate matching job sorting
  const response = await fetch(
    MATCHINGJOBS + `?limit=6&page=${currentPage}&${percentage}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function matchingjobCardSorting(
  token,
  card,
  currentPage,
  percentage,
  order
) {
  // api for candidate matchingjob card list
  const response = await fetch(
    MATCHINGJOBS +
      `?card=${card}&limit=6&page=${currentPage}&${percentage}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { matchingjobSorting, matchingjobCardSorting };
