import { LINKEDINURL } from "../ENVIRONMENT";

async function linkedinToken(code) {
  // api for candidate domain list
  const response = await fetch(LINKEDINURL, {
    method:"POST",
    headers: {
      "Content-Type": "application/json",
      
    },
    body:JSON.stringify(code)
     });
  return response;
}
export { linkedinToken };
