import { CLIENTPERMANENTDATA } from "../ENVIRONMENT";

// api for client permanentdata details
async function getPermanentDataClient(token) {
  return await fetch(CLIENTPERMANENTDATA, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { getPermanentDataClient };
