import "../../../styles/app.scss";
import "reactjs-popup/dist/index.css";
import React from "react";
import { Route } from "react-router-dom";
import NavbarSidebar from "../NavbarSidebar";
import DashboardView from "../Dashboard/DashboardView";
import CandidateList from "../candidate/candidateList";
import ClientList from "../client/clientList";
import FreelanceList from "../freelance/freelanceList";
import UserList from "../user/userList";
import RecruiterList from "../recruiter/recruiterList";
import NavbarTopbar from "../../../common-component/NavbarTopbar";
import CreateAdmin from "../user/CreateAdmin";
import CandidateDetails from "../candidate/specificCandidate/candidateDetails";
import ClientDetails from "../client/specificClient/clientDetails";
import RequirementDetails from "../client/specificClient/specificRequirement/RequirementDetails";
import RecruiterDashboard from "../recruiter/specificRecruiter/Dashboard";
import RecruiterCandidateList from "../recruiter/specificRecruiter/candidate/CandidateList";
import RecruiterRequirementList from "../recruiter/specificRecruiter/requirement/RequirementList";
import FreelanceDashboard from "../freelance/specificFreelance/Dashboard";
import FreelanceCandidateList from "../freelance/specificFreelance/candidate/CandidateList";
import FreelanceRequirementList from "../freelance/specificFreelance/requirement/RequirementList";

const Mainmenu = () => {
  // return mainmenu page of candidate from where all candidate are loaded
  
  return (
    <div className={"container-fluid"}>
      <div className={"row"}>
        <NavbarSidebar homelocation={"/admin/dashboardview"} />
        <div className={"col "}>
          <NavbarTopbar
            // candidateId={userInfo.candidateId}
            firstName={"John"}
            lastName={"Doe"}
            // profilePicture={userInfo.profilePicture}
             profilelocation={"/candidate/profile"}
             settinglocation={"/candidate/setting"}
          />
          {/*dashboard page views goes here*/}
          <div
            className={"row h-100 stick"}
            style={{
              backgroundColor: "#F9F9F9",
              borderRadius: "38px 0px 0px 0px",
            }}
          >
            {/* <Redirect to={"/admin/dashboardview"} /> */}
           <Route path={"/admin/dashboardview"}>
              <DashboardView />
            </Route>
            <Route path={"/admin/candidateList"}>
              <CandidateList />
            </Route>
            <Route path={"/admin/candidateDetails"}>
              <CandidateDetails />
            </Route>
            <Route path={"/admin/clientList"}>
              <ClientList />
            </Route>
            <Route path={"/admin/clientDetails"}>
              <ClientDetails />
            </Route>
            <Route path={"/admin/client/requirementDetails"}>
              <RequirementDetails />
            </Route>
            <Route path={"/admin/recruiterList"}>
              <RecruiterList />
            </Route>
            <Route path={"/admin/recruiterDashboard"}>
              <RecruiterDashboard />
            </Route>
            <Route path={"/admin/recruiter/candidate"}>
              <RecruiterCandidateList />
            </Route>
            <Route path={"/admin/recruiter/requirement"}>
              <RecruiterRequirementList />
            </Route>
            <Route path={"/admin/recruiter/requirementDetails"}>
              <RequirementDetails />
            </Route>
            <Route path={"/admin/recruiter/candidateDetails"}>
              <CandidateDetails />
            </Route>
            <Route path={"/admin/freelanceList"}>
              <FreelanceList />
            </Route>
            <Route path={"/admin/freelanceDashboard"}>
              <FreelanceDashboard />
            </Route>
            <Route path={"/admin/freelance/candidate"}>
              <FreelanceCandidateList />
            </Route>
            <Route path={"/admin/freelance/requirement"}>
              <FreelanceRequirementList />
            </Route>
            <Route path={"/admin/freelance/requirementDetails"}>
              <RequirementDetails />
            </Route>
            <Route path={"/admin/freelance/candidateDetails"}>
              <CandidateDetails />
            </Route>
            <Route path={"/admin/user"}>
              <UserList />
            </Route>
            <Route path={"/admin/createAdmin"}>
              <CreateAdmin />
            </Route>
            </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Mainmenu);
