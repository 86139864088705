import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPermanentData } from "../../api/candidate/permanentData";
import { PERMANENTDATA } from "../../api/ENVIRONMENT";
import { toTitleCase } from "../../function/common/convertToTitleCase";

const PermanentData = () => {
  // return permanent data div which appear during buildresume
  const [information, setInformation] = useState({
    name: " ",
    candidateId: " ",
    resumeTitle: " ",
    email: " ",
    rate: " ",
    rateType: " ",
    location: " ",
    employmentType: " ",
    mobile: " ",
  });
  const token = useSelector((state) => state.token);
  const candidate = useSelector((state) => state.candidate);
  useEffect(() => {
    async function callItSelf(){
      try {
        const response = await getPermanentData(
          PERMANENTDATA.replace("candidate", candidate),
          token
        );
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload.location.length > 20)
            payload.location = payload.location + "...";
          else if (payload.resumeTitle.length > 20) {
            payload.resumeTitle = payload.resumeTitle + "...";
          }
          Object.keys(payload).map((key) => {
            if (!payload[key]) payload[key] = " ";
          });
          setInformation({ ...information, ...payload });
        }
      } catch {}
    }
    callItSelf()
  }, []);

  return (
    <div className="pt-5">
      <div className={"row  pl-3 pr-4 py-5"}>
        <div
          className="pt-3 pb-3 pr-2 pl-0"
          style={{
            backgroundColor: "#F9F9F9",
            borderRadius: "10px",
            maxWidth: "210px",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          <div className={"col"}>
            <div className={"row staticdatafontheader pl-4"}>
              Permanent Data &#62;
            </div>
            <div className={"row staticdatafontusername  mt-1"}>
              <div className={"col"}>
                {information.firstName &&
                  information.firstName.charAt(0).toUpperCase() +
                    information.firstName.slice(1).toLowerCase()}
                &nbsp;
                {information.middleName === "null"
                  ? ""
                  : information.middleName &&
                    information.middleName.charAt(0).toUpperCase() +
                      information.middleName.slice(1).toLowerCase()}
                &nbsp;
                {information.lastName &&
                  information.lastName.charAt(0).toUpperCase() +
                    information.lastName.slice(1).toLowerCase()}
              </div>
            </div>
            
            <div className={"row staticdatacommonfont mt-2"}>
              <div className={"col"} style={{ textAlign: "center" }}>
                {information.candidateId}
              </div>
            </div>
            <div className={"row staticdatacommonfont mt-2"}>
              <div className={"col"} style={{ textAlign: "center" }}>
                <span style={{ color: "#8743DF" }}>
                  {toTitleCase(information.resumeTitle)}
                </span>
              </div>
            </div>
            <div className={"staticdatacommonfont"}>
              <div
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  margin: "10px",
                }}
              >
                <span style={{ color: "#8A8A8A" }}>
                  Email:{information.email}
                </span>
              </div>
            </div>
            <div className={"row mt-3 pl-3"}>
              <div className={"col-3 pl-0"}>
                <img
                  src="../../../icon/candidate/dashboard/dollar.png"
                  alt={"pound"}
                  className={"pl-2 pr-2 pt-2 pb-2"}
                  style={{ backgroundColor: "#2BC155", borderRadius: "10px" }}
                />
              </div>
              <div className={"col"}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#2E2E2E",
                  }}
                >
                  ${information.rate}
                </div>
                <div
                  style={{ color: "#8a8a8a" }}
                  className={"resumepreviewname"}
                >
                  Per{" "}
                  {information.rateType &&
                    toTitleCase(information.rateType.substring(0, 4))}
                </div>
              </div>
            </div>
            <div className={"row mt-2 pl-3"}>
              <div className={"col-3 pl-0"}>
                <img
                  src="../../../icon/candidate/dashboard/whitelocation.png"
                  alt={"whitelocationicon"}
                  className={"pl-2 pr-2 pt-2 pb-2"}
                  style={{ backgroundColor: "#FBA556", borderRadius: "10px" }}
                />
              </div>
              <div className={"col"}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#2E2E2E",
                  }}
                >
                  {toTitleCase(information.location)}
                </div>
              </div>
            </div>
            <div className={"row mt-2 pl-2"}>
              <div className={"col-3 pl-0"}>
                <img
                  src="../../../icon/common/calendar.png"
                  alt={"smallcalendar"}
                  className={"pl-1 pr-1 pt-1 pb-1"}
                  style={{
                    backgroundColor: "#E0F2FF",
                    borderRadius: "10px",
                    transform: "scale(0.8)",
                  }}
                />
              </div>
              <div className={"col mt-3"}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#2E2E2E",
                  }}
                >
                  {information.employmentType &&
                    information.employmentType.replace(/_/g, " ")}
                </div>
              </div>
            </div>
            <div className={"row mt-2 pl-3"}>
              <div className={"col-3 pl-0"}>
                <img
                  src="../../../icon/candidate/dashboard/call.png"
                  alt={"call"}
                  className={"pl-2 pr-2 pt-2 pb-2"}
                  style={{ backgroundColor: "#F4EBFF", borderRadius: "10px" }}
                />
              </div>
              <div className={"col mt-2"}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "#2E2E2E",
                  }}
                >
                  {information.mobile}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(PermanentData);
