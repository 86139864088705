import { RECRUITERBUILDREQUIREMENT } from "../../../ENVIRONMENT";

// api for submit recruiter builrequirement skills
async function recpostBuildRequirementSkill(token, data, id) {
  return await fetch(RECRUITERBUILDREQUIREMENT + `/${id}/skill`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { recpostBuildRequirementSkill };
