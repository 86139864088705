import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dashboardcount } from "../../../api/admin/dashboard/dashboardCount";


const AdminDashcard = () => {
  // return dashboard card group which generated by data variable
  const token = useSelector((state) => state.token);

  const [count, setCount] = useState({
    candidate_count: "",
    client_count: "",
    recruiter_count: "",
    freelance_count: "",
  });
  // get call for dashboard count
   useEffect(() => {
     async function callUserDetails() {
       try {
         const response = await dashboardcount(token);
         if (response.status === 200) {
           const data = await response.json();
           const payload = data.payload;
           setCount({
             ...count,
             candidate_count: payload.candidate_count,
             client_count: payload.client_count,
             recruiter_count: payload.recruiter_count,
             freelance_count: payload.freelance_count,
           });
         }
       } catch {}
     }
     callUserDetails();
   }, []);

  const data = [
    {
      count: count.candidate_count,
      countColor: "#2BC155",
      progressColor: "#2BC155",
      icon: "../../icon/common/suitcase.png",
      iconColor: "#E9FFEF",
      message: "Candidate",
      Link: "/admin/candidateList"
    },
    {
      count: count.client_count,
      countColor: "#3F9AE0",
      progressColor: "#3F9AE0",
      icon: "../../icon/common/calendar.png",
      iconColor: "#E0F2FF",
      message: "Client",
      Link: "/admin/clientList"
    },
    {
      count: count.recruiter_count,
      countColor: "#FF9B52",
      progressColor: "#FF9B52",
      icon: "../../icon/common/person.png",
      iconColor: "#FFF0EB",
      message: "recruiter",
      Link: "/admin/recruiterList"
    },
    {
      count: count.freelance_count,
      countColor: "#2BC155",
      progressColor: "#2BC155",
      icon: "../../icon/common/suitcase.png",
      iconColor: "#E9FFEF",
      message: "freelance",
      Link: "/admin/freelanceList"
    },
  ];

  return data.map((data, index) => (
    <div key={index} className={"col-lg-3 pt-4"}>
      <Link to={data.Link}>
      <div
        className={"pt-3 pb-0 viewcontent"}
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
          borderRadius: "20px",
          fontFamily: "poppins",
        }}
      >
        <div className={"row mx-3"}>
          <div className={"col-lg-6"}>
            <label
              style={{
                fontWeight: "600",
                fontSize: "40px",
                color: data.countColor,
              }}
            >
              {data.count}
            </label>
            <br />
          </div>
          <div className={"col-lg my-4"}>
            <div className={"pl-5"}>
              <span
                className={"pt-3 pb-3 pl-2 pr-2"}
                style={{ background: data.iconColor, borderRadius: "15px" }}
              >
                <img src={data.icon} alt={"case"} className={"img-fluid"} />
              </span>
            </div>
          </div>
        </div>
        <div className={"row mx-3"}>
          <div className={"col-lg pb-1"}>
            <Link to={data.Link}>
            <label
              style={{
                fontWeight: "normal",
                fontSize: "16px",
                color: "rgba(15, 15, 15, 0.7)",
              }}
            >
              {data.message}
            </label>
            </Link>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-lg"}>
            <div
              style={{
                background: data.progressColor,
                borderRadius: "20px",
                width: "90%",
                height: "4px",
                marginLeft: "5%",
              }}
            ></div>
          </div>
        </div>
      </div>
      </Link>
    </div>
  ));
};
export default AdminDashcard;
