import { FREELANCECANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for freelance matching pagination
async function freematchingPagination(token, currentPage) {
  const response = await fetch(
    FREELANCECANDIDATEMATCHINGJOBS + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance matchingcard pagination
async function freematchingCardPagination(token, card, currentPage) {
  const response = await fetch(
    FREELANCECANDIDATEMATCHINGJOBS +
      `?card=${card}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freematchingPagination, freematchingCardPagination };
