import "../../styles/app.scss";
import "reactjs-popup/dist/index.css";
import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavbarSidebar from "../../common-component/NavbarSidebar";
import NavbarTopbar from "../../common-component/NavbarTopbar";
import NavbarSidebarForNewUserClient from "../../common-component/NavbarSidebarForNewUserClient";
import DashboardHome from "./DashboardHome";
import DashboardView from "./DashboardView";
import Requirement from "./Requirement";
import Applications from "./Applications";
import UploadRequirment from "./UploadRequirement";
import Profile from "./Profile";
import Setting from "./Setting";
import Message from "../../common-component/Message";
import JobRequirment1 from "./JobRequirment/JobRequirment1";
import JobRequirment2 from "./JobRequirment/JobRequirment2";
import JobRequirment3 from "./JobRequirment/JobRequirment3";
import JobRequirment4 from "./JobRequirment/JobRequirment4";
import JobRequirment5 from "./JobRequirment/JobRequirment5";
import JobRequirmentPreview from "./JobRequirment/JobRequirmentPreview";
import RecProfile from "../recruiter/Profile";
import CandidateDetails from "./CandidateDetails";
import JobPreview from "./JobPreview";
import { getUserInfo } from "../../api/common/userInfo.js";
import Interview from "./Interview";
import Notification from "../../common-component/Notification";
import ViewDetails from "../../common-component/ViewDetails";
import { counting } from "../../api/common/countNotifications";
import { notification } from "../../api/common/notification";
import { SOCKET_URL } from "../../api/ENVIRONMENT";
import decode from "jwt-decode";
import io from "socket.io-client"

const Mainmenu = () => {
  // return mainmenu page from here all client pages are loaded
  const [userInfo, setUserInfo] = useState({
    candidateId: "",
    firstName: "",
    lastName: "",
    profilePicture: "",
  });
  const isnewuser = useSelector((state) => state.isnewuser);
  const isrecruiter = useSelector((state) => state.isrecruiter);
  const token = useSelector((state) => state.token);
  const socketVal = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  const tablink = [
    "/client/postjob",
    "/client/postskill",
    "/client/postacademic",
    "/client/postsecurity",
    "/client/postdescription",
    "/client/postjobpreview",
    "/client/editjob",
  ];

  useEffect(() => {
    async function callUserDetails() {
      const response = await getUserInfo(token);
      const status = response.status;
      let data = await response.json();
      data = data.payload;
      if (status === 200) {
        const id = Object.keys(data).filter((key) => {
          if (key.endsWith("Id") && key !== "userId") return true;
          return false;
        });
        setUserInfo({
          candidateId: data[id],
          firstName:
            data.firstName.charAt(0).toUpperCase() +
            data.firstName.slice(1).toLowerCase(),
          lastName:
            data.lastName.charAt(0).toUpperCase() +
            data.lastName.slice(1).toLowerCase(),
          profilePicture: data.profilePicture,
        });
      }
    }

    callUserDetails();
  }, []);

  const [countNotification, setCountNotification] = useState();
  const [message, setMessage] = useState([]);

 // count notification
  useEffect(() => {
    async function notifyCount() {
      try {
        const response = await counting(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setCountNotification(payload.count);
        }
        const notificationRespose = await notification(token, 1);
        if (notificationRespose.status === 200) {
          const data = await notificationRespose.json();
          const payload = data.payload;
          setMessage(payload);
        }
      } catch {}
    }
    notifyCount();
  }, []);

  useEffect(()=>{
    if(socketVal.length === 0){
      const socket = io.connect(SOCKET_URL, {
        auth: {
          userId: decode(token).uuid
        }
      });
      dispatch({ type: "setsocket", value: socket });
    }
  },[])

  return (
    <div className={"container-fluid"}>
      <div className={"row"}>
        {/*first bar*/}
        {isnewuser ? (
          <NavbarSidebarForNewUserClient
            homelocation={"/client/dashboard/register"}
          />
        ) : (
          <NavbarSidebar homelocation={"/client/dashboardview"} />
        )}
        {/*second bar*/}
        <div className={"col"}>
          <NavbarTopbar
            candidateId={userInfo.candidateId}
            firstName={userInfo.firstName}
            lastName={userInfo.lastName}
            profilePicture={userInfo.profilePicture}
            profilelocation={"/client/profile"}
            settinglocation={"/client/setting"}
            allnotification={"/client/notification"}
            count={countNotification}
            message={message}
          />
          {/*dashboard page views goes here*/}
          <div
            className={"row h-100 stick"}
            style={{
              backgroundColor: "#F9F9F9",
              borderRadius: "38px 0px 0px 0px",
              height: "100vh",
            }}
          >
            <Route path={"/client/dashboard"}>
              <DashboardHome />
            </Route>
            <Route path={"/client/dashboardview"}>
              <DashboardView />
            </Route>
            <Route
              path={["/client/requirements", "/client/requirements/:title"]}
            >
              <Requirement />
            </Route>
            <Route
              path={[
                "/client/archiverequirements",
                "/client/archiverequirements/:title",
              ]}
            >
              <Requirement />
            </Route>
            <Route
              path={[
                "/client/candidatedetails/:cadidateID",
                "/client/candidatedetails",
              ]}
            >
              <CandidateDetails />
            </Route>
            <Route path={["/client/jobapply/:reqID", "/client/jobapply"]}>
              <JobPreview />
            </Route>
            <Route
              path={["/client/applications/:card", "/client/applications"]}
            >
              <Applications />
            </Route>
            <Route
              path={[
                "/client/filteredapplications/:id/:card",
                "/client/filteredapplications/:id",
                "/client/filteredapplications",
              ]}
            >
              <Applications />
            </Route>
            <Route path={"/client/interview/:id/:uuid"}>
              <Interview />
            </Route>
            <Route path={["/client/message/:id", "/client/message"]}>
              <Message homelocation={"/client/dashboardview"} />
            </Route>
            <Route path={"/client/profile"}>
              {isrecruiter ? <RecProfile /> : <Profile />}
            </Route>
            <Route path={"/client/setting"}>
              <Setting />
            </Route>
            <Route path={"/client/notification"}>
              <Notification viewdetails={"/client/viewdetails"} />
            </Route>
            <Route path={"/client/viewdetails"}>
              <ViewDetails />
            </Route>
            <Route path={"/client/uploadresume"}>
              <UploadRequirment
                jobpreviewlink={"/client/postingpreview"}
                jobreq1link={"/client/postjob"}
              />
            </Route>
            <Route path={["/client/postjob/:refId","/client/postjob"]}>
              <JobRequirment1 link={tablink} />
            </Route>
            <Route path={"/client/editjob/:id"}>
              <JobRequirment1 link={tablink} />
            </Route>
            <Route path={["/client/postskill/:id/:refId","/client/postskill/:id"]}>
              <JobRequirment2 link={tablink} />
            </Route>
            <Route path={["/client/postacademic/:id/:refId","/client/postacademic/:id"]}>
              <JobRequirment3 link={tablink} />
            </Route>
            <Route path={["/client/postsecurity/:id/:refId","/client/postsecurity/:id"]}>
              <JobRequirment4 link={tablink} />
            </Route>
            <Route path={["/client/postdescription/:id/:refId","/client/postdescription/:id"]}>
              <JobRequirment5 link={tablink} />
            </Route>
            <Route
              path={["/client/postjobpreview/:id", "/client/postjobpreview"]}
            >
              <JobRequirmentPreview />
            </Route>
            {/* <Route path={"/client/notification"}>
                <Notification />
              </Route> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Mainmenu);
