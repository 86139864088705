import { FREELANCECANDIDATEAPPLICATION } from "../../ENVIRONMENT";

// api for freelance application sorting
async function freeapplicationSorting(token, currentPage, name, order) {
  const response = await fetch(
    FREELANCECANDIDATEAPPLICATION +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freeapplicationSorting };
