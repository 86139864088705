import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CandidateDashcard from "../../common-component/CandidateDashcard";
import MatchingjobCard from "../../common-component/candidateMatchingjobCard";
import GoToTop from "../common/GoToTop";
import { getPermanentDataDashboard } from "../../api/candidate/permanentDataDashboard";
import { toTitleCase } from "../../function/common/convertToTitleCase";
import { recgetPermanentDataDashboard } from "../../api/recruiter/candidate/permanentDataDashboard";
import { freegetPermanentDataDashboard } from "../../api/freelance/candidate/permanentDataDashboard";

const DashboardView = () => {
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const isrecruiter = useSelector((state) => state.isrecruiter);
 // permanent data
  const [information, setInformation] = useState({
    name: "",
    candidateId: "",
    resumeTitle: "",
    email: "",
    rate: "",
    rateType: "",
    location: "",
    employmentType: "",
    mobile: "",
  });

  // const socketVal = useSelector((state) => state.socket);

  // console.log("socket val dash",socketVal)

  // get call of permanent data
  useEffect(() => {
    async function callItSelf() {
      
      try {
        let response = isrecruiterCandidate
          ? await recgetPermanentDataDashboard(token)
          : isFreelance
          ? await freegetPermanentDataDashboard(token)
          : await getPermanentDataDashboard(token);
        if (response.status === 200) {
          const payload = await response.json();
          if (payload.payload.location.length > 20) {
            payload.payload.location = payload.payload.location + "...";
          } else if (payload.payload.resumeTitle.length > 20) {
            payload.payload.resumeTitle = payload.payload.resumeTitle + "...";
          }
          setInformation({ ...information, ...payload.payload });
        }
      } catch {}
    }
    callItSelf();
  }, []);
  // return dashboardview page for existing user to view their data
  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  dispatch({ type: "buildresumefalse" });
  try {
    document.getElementById("replacetitleimg").innerText = "Dashboard";
  } catch {}

  return (
    <div className={"col mx-3"}>
      <div className={"col-lg-12 pr-0"}>
        <div className={"row"}>
          <div className={"col-lg-9 p-0"}>
            <div className={"row viewalignment pb-3"}>
              <CandidateDashcard />
            </div>
          </div>
          {/*permanent data*/}
          <div className={"col-xl-3 pt-4"}>
            <div
              className={"p-2"}
              style={{ background: "#FFFFFF", borderRadius: "20px" }}
            >
              <div className={"row"}>
                <div className={"col-md"}>
                  <div className={"float-right"}>
                    <span
                      className={"pt-1 pb-1 pl-2 pr-2"}
                      style={{
                        background: "rgba(255, 138, 0, 0.2)",
                        borderRadius: "8px",
                      }}
                    >
                      <label style={{ color: "#FF8A00" }}>&#9733; 4.3</label>
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className={"dashboardfontusername"}>
                      {information.firstName &&
                        information.firstName.charAt(0).toUpperCase() +
                          information.firstName.slice(1).toLowerCase()}
                      &nbsp;
                      {information.middleName === "null"
                        ? ""
                        : information.middleName &&
                          information.middleName.charAt(0).toUpperCase() +
                            information.middleName.slice(1).toLowerCase()}
                      &nbsp;
                      {information.lastName &&
                        information.lastName.charAt(0).toUpperCase() +
                          information.lastName.slice(1).toLowerCase()}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={"col-md"}
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#8743DF",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    <label>{toTitleCase(information.resumeTitle)}</label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md pt-0"}>
                    <label className="staticdatacommonfont">
                      {information.candidateId}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className="dashboardfontusername">
                      Permanent Data
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={"col-md"}
                    style={{
                      color: "#8A8A8A",
                      wordBreak: "break-all",
                      textAlign: "justify",
                      marginRight: "42px",
                      marginLeft: "50px",
                    }}
                  >
                    <label className="staticdatacommonfont">
                      Email:{information.email}
                    </label>
                  </div>
                </div>
              </div>
              {isrecruiter ? (
                ""
              ) : (
                <div className={"row"}>
                  <div className={"col-3 pl-5 ml-3 mt-2"}>
                    <span
                      className={"pt-2 pb-2 pl-2 pr-2"}
                      style={{ background: "#2BC155", borderRadius: "10px" }}
                    >
                      <img
                        src="../icon/candidate/dashboard/dollar.png"
                        alt={"whitelocation"}
                      />
                    </span>
                  </div>
                  <div className={"col mt-1 ml-4"}>
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#2E2E2E",
                      }}
                    >
                      {information.rate}
                    </div>
                    <div
                      style={{ color: "#8a8a8a" }}
                      className={"resumepreviewname"}
                    >
                      Per{" "}
                      {information.rateType &&
                        toTitleCase(information.rateType.substring(0, 4))}
                    </div>
                  </div>
                </div>
              )}
              <div className={"row mt-3"}>
                <div className={"col-3 pl-5 ml-3"}>
                  <span
                    className={"pt-2 pb-2 pl-2 pr-2"}
                    style={{ background: "#FBA556", borderRadius: "10px" }}
                  >
                    <img
                      src="../icon/candidate/dashboard/whitelocation.png"
                      alt={"whitelocation"}
                    />
                  </span>
                </div>
                <div className={"col-7 ml-4"} style={{}}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#2E2E2E",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    {information.location.length !== 0 &&
                      toTitleCase(information.location)}
                  </div>
                </div>
              </div>
              {isrecruiter ? (
                <div className="row pt-2 pb-2"></div>
              ) : (
                <div className={"row mt-2"}>
                  <div className={"col-3 pl-5 ml-2 mr-2"}>
                    <img
                      src="../icon/common/calendar.png"
                      alt={"smallcalendar"}
                      className={"pl-1 pr-1 pt-1 pb-1"}
                      style={{
                        backgroundColor: "#E0F2FF",
                        borderRadius: "10px",
                        transform: "scale(0.8)",
                      }}
                    />
                  </div>
                  <div className={"col-7 mt-3 ml-4"}>
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#2E2E2E",
                      }}
                    >
                      {information.employmentType &&
                        information.employmentType.replace(/_/g, " ")}
                    </div>
                  </div>
                </div>
              )}
              {information.mobile === null ? (
                <div className="row pt-3 pb-3"></div>
              ) : (
                <div className={"row mt-2"}>
                  <div className={"col-3 pl-5 ml-3 mr-1"}>
                    <img
                      src="../icon/candidate/dashboard/call.png"
                      alt={"call"}
                      className={"pl-2 pr-2 pt-2 pb-2"}
                      style={{
                        backgroundColor: "#F4EBFF",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className={"col pt-2 ml-4"}>
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#2E2E2E",
                      }}
                    >
                      {information.mobile}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={"row viewalignment"}>
        <MatchingjobCard />
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(DashboardView);
