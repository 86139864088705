import { FREELANCECANDIDATEDOMAIN } from "../../ENVIRONMENT";

// api for freelance domainlist
function freedomainList(token) {
  const response = fetch(FREELANCECANDIDATEDOMAIN, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { freedomainList };
