import React from "react";
import { useEffect, useRef } from "react";
import { validateRegistration } from "../function/candidate/validateField";
import { useState } from "react";
import { zipCodeDetails } from "../api/common/zipCode";
import { useSelector } from "react-redux";

const AddMoreAddress = (props) => {
  // return same address duplicate div
  const information = props.information;
  const setInformation = props.setInformation;
  const error = props.error;
  const setError = props.setError;
  const addmore = props.addmore;
  const setAddMore = props.setAddMore;
  const zipCodeList = props.zipCodeList;
  // const show = props.show;
  //const callBoth = props.callBoth;
  //const selectedAddPincode = props.selectedAddPincode;
  // const secondZipCode = props.secondZipCode;

  const [show, setShow] = useState(false);
  const token = useSelector((state) => state.token);

  function callAddMoreBoth(event) {
    validateRegistration(event, setInformation, setError);
    setShow(true);
  }

  async function selectedAddPincode(zipCodeId, zipCode) {
    setShow(false);
    setInformation({
      ...information,
      zipCode: zipCode,
    });
    setError({
      ...error,
      zipCode: ""
    })
    const response = await zipCodeDetails(token, zipCodeId);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setInformation({
        ...information,
        zipCode: payload.zip,
        city: payload.city,
        state: payload.stateName,
        county: payload.countyName,
      });
      setError({
        ...error,
        zipCode: "",
        city: "",
        state: ""
      })
    }
  }

  // zipcode dropdown
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && ref.current && !ref.current.contains(event.target)) {
        document.getElementById("secondzipCodeDiv").style.display = "none";
      }
      document.body.removeEventListener("click", handleClickOutside);
    };
    document.body.addEventListener("click", handleClickOutside);
  }, [show]);

  useEffect(() => {
    if (zipCodeList.length === 0) {
        setShow(false);
         setError({
           ...error,
           zipCode: "please enter valid zipCode",
         });
      }
      else if(error.zipCode !== "Must be 5 digits") {
        setError({
          ...error,
          zipCode: "",
        });
      }
  }, [zipCodeList]);

  return (
    <div className={"row px-5 pr-5 mx-3 pt-4"}>
      <div
        className={"w-100 pt-4 pb-2 px-5 contentholder"}
        id={"addmoreaddress"}
      >
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label
              style={{
                fontWeight: "500",
                fontSize: "14px",
                color: "#5C5C5C",
              }}
            >
              Mailing Address
            </label>
          </div>
        </div>
        <div className={"row form-group mt-1"}>
          <div className={"col-md-4 input-placeholder"} ref={ref}>
            <input
              type={"text"}
              maxLength={"5"}
              className={
                "form-control dashboardforminputfield ziplocation shadow-none ziperror1"
              }
              name={"zipCode"}
              value={information.zipCode}
              onChange={callAddMoreBoth}
              style={{
                border: `${error.zipCode ? "1px solid red" : ""}`,
              }}
              autoComplete={"off"}
              required
              onClick={() => {
                setShow(!show);
              }}
            />
            <div className={"placeholder"}>
              Enter Zip Code<span>*</span>
            </div>
            <font className={"dashboardforminputtext text-danger"}>
              {error.zipCode}
            </font>
            <div
              id={"secondzipCodeDiv"}
              style={{
                display: `${
                  show && information.zipCode > 0 ? "block" : "none"
                }`,
              }}
            >
              {zipCodeList &&
                zipCodeList.length > 0 &&
                zipCodeList.map((employee) => (
                  <div
                    key={employee.id}
                    style={{ cursor: "pointer" }}
                    value={employee.zip}
                    onClick={() =>
                      selectedAddPincode(employee.id, employee.zip)
                    }
                  >
                    {employee.zip} - {employee.city}
                  </div>
                ))}
            </div>
          </div>
          <div className={"col-md input-placeholder"}>
            <input
              type={"text"}
              className={"form-control dashboardforminputfield shadow-none"}
              name={"addressLine1"}
              value={information.addressLine1}
              style={{
                border: `${error.addressLine1 ? "1px solid red" : ""}`,
              }}
              onChange={(event) =>
                validateRegistration(event, setInformation, setError)
              }
              required
            />
            <div className={"placeholder"}>
              Address Line 1<span>*</span>
            </div>
            <font className={"dashboardforminputtext text-danger"}>
              {error.addressLine1}
            </font>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md"}>
            <input
              type={"text"}
              placeholder={"Address Line 2"}
              value={information.addressLine2}
              style={{
                border: `${error.addressLine2 ? "1px solid red" : ""}`,
              }}
              name={"addressLine2"}
              className={
                "form-control dashboardforminputfield address shadow-none"
              }
              onChange={(event) =>
                validateRegistration(event, setInformation, setError)
              }
            />
            <font className={"dashboardforminputtext text-danger"}>
              {error.addressLine2}
            </font>
          </div>
          <div className={"col-md-4 input-placeholder"}>
            <input
              type={"text"}
              value={information.city}
              name={"city"}
              disabled
              style={{
                border: `${error.city ? "1px solid red" : ""}`,
              }}
              required
              className={"form-control dashboardforminputfield shadow-none"}
              onChange={(event) =>
                validateRegistration(event, setInformation, setError)
              }
            />
            {
              information.city === "" &&
              <div className={"placeholder"}>
              City<span>*</span>
              </div>
            }
            <font className={"dashboardforminputtext text-danger"}>
              {error.city}
            </font>
          </div>
        </div>
        <div className={"row pt-3"}>
          <div className={"col-md-4"}>
            <input
              type={"text"}
              value={information.county}
              style={{
                border: `${error.county ? "1px solid red" : ""}`,
              }}
              disabled
              name={"county"}
              placeholder="County"
              className={"form-control dashboardforminputfield shadow-none"}
              onChange={(event) =>
                validateRegistration(event, setInformation, setError)
              }
            />
            <font className={"dashboardforminputtext text-danger"}>
              {error.county}
            </font>
          </div>
          <div className={"col-md-4 input-placeholder"}>
            <input
              type={"text"}
              value={information.state}
              style={{
                border: `${error.state ? "1px solid red" : ""}`,
              }}
              disabled
              name={"state"}
              className={"form-control dashboardforminputfield shadow-none"}
              onChange={(event) =>
                validateRegistration(event, setInformation, setError)
              }
              required
            />
            {
              information.state === "" &&
              <div className={"placeholder"}>
                State<span>*</span>
              </div>
            }
            <font className={"dashboardforminputtext text-danger"}>
              {error.state}
            </font>
          </div>
          <div className={"col-md-4"}>
            <input
              type={"text"}
              value={information.country}
              name={"country"}
              className={"form-control dashboardforminputfield shadow-none"}
              style={{
                border: `${error.country ? "1px solid red" : ""}`,
              }}
              onChange={(event) =>
                validateRegistration(event, setInformation, setError)
              }
              required
              disabled
            />
            <font className={"dashboardforminputtext text-danger"}>
              {error.country}
            </font>
          </div>
        </div>
        {addmore && (
          <div className={"row pt-2"}>
            <div className={"col-md"}>
              <div
                className={"btn float-right badge border-0 mr-5 cancelskill"}
                onClick={() => setAddMore(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(AddMoreAddress);
