import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import GoToTop from "../../common/GoToTop";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import Getyear from "../../../common-component/GetYear";
import { Link, useLocation } from "react-router-dom";
import SelectCalendar from "../../../function/common/SelectCalendar";
import { buildResumeExperience } from "../../../function/candidate/validateField";
import { verifyBuildResumeExperience } from "../../../function/candidate/checkRequired";
import {
  postExperienceParseData,
  getExperienceParseData,
  getExperienceFromParse,
} from "../../../function/candidate/parseExperienceData";
import {
  postExperience,
  getExperience,
} from "../../../api/candidate/buildResumeExperience";
import { getAcademic } from "../../../api/candidate/buildResumeAcademic";
import { getSecurity } from "../../../api/candidate/buildResumeSecurity";
import { locationListSearch } from "../../../api/common/locationList";
import { getProfileDetails } from "../../../api/candidate/getProfileDetails";


const MIN_TEXTAREA_HEIGHT = 100;
const MIN_TEXTAREA_HEIGHT1 = 100;
const MIN_TEXTAREA_HEIGHT2 = 100;

const MIN_SUMMARY_HEIGHT = 100;
const MIN_SUMMARY_HEIGHT1 = 100;
const MIN_SUMMARY_HEIGHT2 = 100;

const BuildResume2 = () => {
  // return buildresume second tab button data
  const [loader, setLoader] = useState(false);
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate1, setToDate1] = useState(new Date());
  const [fromDate1, setFromDate1] = useState(new Date());
  const [toDate2, setToDate2] = useState(new Date());
  const [fromDate2, setFromDate2] = useState(new Date());
  const [redirect, setRedirect] = useState(false);
  const [information, setInformation] = useState({
    yearOfExp: "",
    yearOfExpUS: "",
    jobTitle: "",
    client: "",
    domain: "",
    location: "",
    projectSummary: "",
    roleAndResponse: "",
  });

  const [error, setError] = useState({
    yearOfExp: "",
    yearOfExpUS: "",
    jobTitle: "",
    client: "",
    domain: "",
    location: "",
  });

  const [visibility, setVisibility] = useState({
    one: false,
    two: false,
  });

  const [uploadResume, setUploadResume] = useState();
  const [academicNavigation, setAcademicNavigation] = useState(false);
  const [securityNavigation, setSecurityNavigation] = useState(false);
  const [socialNavigation, setSocialNavigation] = useState(false);

  const expRef = useRef();
  const summaryRef = useRef(null);
  const summaryRef1 = useRef(null);
  const summaryRef2 = useRef(null);

  useLayoutEffect(() => {
    if (summaryRef.current !== null) {
      summaryRef.current.style.height = "inherit";
      summaryRef.current.style.height = `${Math.max(
        summaryRef.current.scrollHeight,
        MIN_SUMMARY_HEIGHT
      )}px`;
    } else {
    }
  }, [information.projectSummary]);

  useLayoutEffect(() => {
    if (summaryRef1.current !== null) {
      summaryRef1.current.style.height = "inherit";
      summaryRef1.current.style.height = `${Math.max(
        summaryRef1.current.scrollHeight,
        MIN_SUMMARY_HEIGHT1
      )}px`;
    } else {
    }
  }, [information.projectSummary1]);

  useLayoutEffect(() => {
    if (summaryRef2.current !== null) {
      summaryRef2.current.style.height = "inherit";
      summaryRef2.current.style.height = `${Math.max(
        summaryRef2.current.scrollHeight,
        MIN_SUMMARY_HEIGHT2
      )}px`;
    } else {
    }
  }, [information.projectSummary2]);

  const textareaRef = useRef(null);
  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (textareaRef.current !== null) {
      textareaRef.current.style.height = "inherit";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    } else {
    }
  }, [information.roleAndResponse]);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (textareaRef1.current !== null) {
      textareaRef1.current.style.height = "inherit";
      // Set height
      textareaRef1.current.style.height = `${Math.max(
        textareaRef1.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT1
      )}px`;
    } else {
    }
  }, [information.roleAndResponse1]);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (textareaRef2.current !== null) {
      textareaRef2.current.style.height = "inherit";
      // Set height
      textareaRef2.current.style.height = `${Math.max(
        textareaRef2.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT2
      )}px`;
    } else {
    }
  }, [information.roleAndResponse2]);

  const token = useSelector((state) => state.token);
  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        yearOfExp1: "",
        yearOfExpUS1: "",
        jobTitle1: "",
        client1: "",
        domain1: "",
        location1: "",
        projectSummary1: "",
        roleAndResponse1: "",
      };
    });
    setError((state) => {
      return {
        ...state,
        yearOfExp1: "",
        yearOfExpUS1: "",
        jobTitle1: "",
        client1: "",
        domain1: "",
        location1: "",
      };
    });
  }, [visibility.one]);

  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        yearOfExp2: "",
        yearOfExpUS2: "",
        jobTitle2: "",
        client2: "",
        domain2: "",
        location2: "",
        projectSummary2: "",
        roleAndResponse2: "",
      };
    });
    setError((state) => {
      return {
        ...state,
        yearOfExp2: "",
        yearOfExpUS2: "",
        jobTitle2: "",
        client2: "",
        domain2: "",
        location2: "",
      };
    });
  }, [visibility.two]);

  useEffect(() => {
    if(information.yearOfExpUS !== "") {
      setError((state) => {
        if (parseInt(information.yearOfExp) < parseInt(information.yearOfExpUS)) {
          return {
            ...state,
            yearOfExpUS: "US experience cannot be greater than experience",
          };
        }
        return {
          ...state,
          yearOfExpUS: "",
        };
      });
    }
  }, [
    information.yearOfExpUS,
    information.yearOfExp,
  ]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let cardName = query.get("data");
  useEffect(() => {
    async function callItSelf() {
      try {
        if (cardName) {
          const response = await getProfileDetails(token);
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload.parsedResume.experience;
            try {
              getExperienceFromParse(
                payload,
                information,
                setInformation,
                setVisibility,
                setFromDate,
                setToDate,
                setFromDate1,
                setToDate1,
                setFromDate2,
                setToDate2
              );
            } catch {}
          }
        } else {
          const response = await getExperience(token);
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload;
            if (payload[0].jobTitle.length !== 0) {
              setAcademicNavigation(true);
              document.getElementsByClassName("buildhide")[0].style.display =
                "none";
            }
            try {
              getExperienceParseData(
                payload,
                information,
                setInformation,
                setVisibility,
                setFromDate,
                setToDate,
                setFromDate1,
                setToDate1,
                setFromDate2,
                setToDate2
              );
            } catch {}
            const acaResponse = await getAcademic(token);
            if (acaResponse.status === 200) {
              let payload = await acaResponse.json();
              payload = payload.payload;
              setUploadResume(payload.education.length);
              if (payload.education[0].academicQualification.length !== 0) {
                setSecurityNavigation(true);
              }
            }
            const socialResponse = await getSecurity(token);
            if (socialResponse.status === 200) {
              let payload = await socialResponse.json();
              payload = payload.payload;
              if (payload.candidate.securityClearance !== null) {
                setSocialNavigation(true);
              }
            }
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);

  async function handleClick(event) {
    event.preventDefault();
    try {
      const parseData = postExperienceParseData(
        information,
        visibility,
        toDate,
        fromDate,
        toDate1,
        fromDate1,
        toDate2,
        fromDate2
      );
      if (
        verifyBuildResumeExperience(error, setError, information, visibility)
      ) {
        if (expRef.current) {
          expRef.current.setAttribute("disabled", "disabled");
        }
        setLoader(true);
        const response = await postExperience(token, parseData);
        if (response.status === 200) {
          setRedirect(true);
        }
        if (response.status === 422) {
          expRef.current.removeAttribute("disabled");
          setLoader(false);
          document.getElementsByClassName("experr")[0].innerText =
            "Please Fill Valid Format";
        } else {
          expRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      }
    } catch {}
  }
  const Addproject = () => {
    // return project div duplicate on
    setVisibility((state) => {
      if (!state.one) state = { ...state, one: true };
      else if (!state.two) state = { ...state, two: true };
      return state;
    });
  };

  function submitEnabled() {
    try {
      document.getElementsByClassName("buildhide")[0].style.display = "block";
      setAcademicNavigation(false);
      setSecurityNavigation(false);
      setSocialNavigation(false);
    } catch {}
  }

  //Location integration
  const [citylist, setCityList] = useState([]);
  const [cityShow, setCityShow] = useState(false);

  async function handleLocationChange() {
    let data = information.location;
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList(payload);
    } else if (response.status === 404) {
      setCityShow(false);
      setError({
        ...error,
        location: "please enter valid city",
      });
    }
  }

  useEffect(() => {
    handleLocationChange();
  }, [information.location]);

  async function selectedLocation(city) {
    setCityShow(false);
    setInformation({
      ...information,
      location: city,
    });
    setError({
      ...error,
      location: "",
    });
  }
  function locationValid() {
    let locationValue = information.location;
    let mapped = citylist.map((data) => data.city);
    let found = mapped.includes(locationValue);
    if (information.location.length !== 0) {
      if (found) {
        setError({
          ...error,
          location: "",
        });
      } else {
        setError({
          ...error,
          location: "please enter valid city",
        });
      }
    }
  }
  // for location outside click
  const locref = React.useRef();
  useEffect(() => {
    const locHandleClickOutside = (event) => {
      if (
        cityShow &&
        locref.current &&
        !locref.current.contains(event.target)
      ) {
        document.getElementById("preLocCodeDiv").style.display = "none";
        locationValid();
      }
      document.body.removeEventListener("click", locHandleClickOutside);
    };
    document.body.addEventListener("click", locHandleClickOutside);
  }, [cityShow, citylist, information.location]);

  //location1 integration
  const [citylist1, setCityList1] = useState([]);
  const [cityShow1, setCityShow1] = useState(false);

  async function handleLocationOneChange() {
    let data = information.location1 ? information.location1 : "";
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList1(payload);
    } else if (response.status === 404) {
      setCityShow1(false);
      setError({
        ...error,
        location1: "please enter valid city",
      });
    }
  }

  useEffect(() => {
    handleLocationOneChange();
  }, [information.location1]);

  async function selectedLocationOne(city) {
    setCityShow1(false);
    setInformation({
      ...information,
      location1: city,
    });
    setError({
      ...error,
      location1: "",
    });
  }
  function locationOneValid() {
    let locationValue = information.location1;
    let mapped = citylist.map((data) => data.city);
    let found = mapped.includes(locationValue);
    if (information.location1.length !== 0) {
      if (found) {
        setError({
          ...error,
          location1: "",
        });
      } else {
        setError({
          ...error,
          location1: "please enter valid city",
        });
      }
    }
  }
  // for location outside click
  const firstref = React.useRef();
  useEffect(() => {
    const firstLocHandleClickOutside = (event) => {
      if (
        cityShow1 &&
        firstref.current &&
        !firstref.current.contains(event.target)
      ) {
        document.getElementById("firstLocCodeDiv").style.display = "none";
        locationOneValid();
      }
      document.body.removeEventListener("click", firstLocHandleClickOutside);
    };
    document.body.addEventListener("click", firstLocHandleClickOutside);
  }, [cityShow1, citylist1, information.location1]);

  //location2 integration
  const [citylist2, setCityList2] = useState([]);
  const [cityShow2, setCityShow2] = useState(false);

  async function handleLocationTwoChange() {
    let data = information.location2 ? information.location2 : "";
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList2(payload);
    } else if (response.status === 404) {
      setCityShow2(false);
      setError({
        ...error,
        location2: "please enter valid city",
      });
    }
  }

  useEffect(() => {
    handleLocationTwoChange();
  }, [information.location2]);

  async function selectedLocationTwo(city) {
    setCityShow2(false);
    setInformation({
      ...information,
      location2: city,
    });
    setError({
      ...error,
      location2: "",
    });
  }
  function locationTwoValid() {
    let locationValue = information.location2;
    let mapped = citylist.map((data) => data.city);
    let found = mapped.includes(locationValue);
    if (information.location2.length !== 0) {
      if (found) {
        setError({
          ...error,
          location2: "",
        });
      } else {
        setError({
          ...error,
          location2: "please enter valid city",
        });
      }
    }
  }
  // for location outside click
  const secref = React.useRef();
  useEffect(() => {
    const secLocHandleClickOutside = (event) => {
      if (
        cityShow2 &&
        secref.current &&
        !secref.current.contains(event.target)
      ) {
        document.getElementById("secLocCodeDiv").style.display = "none";
        locationTwoValid();
      }
      document.body.removeEventListener("click", secLocHandleClickOutside);
    };
    document.body.addEventListener("click", secLocHandleClickOutside);
  }, [cityShow2, citylist2, information.location2]);

  return (
    <div className={"col"}>
      <form className={"form-group"} onSubmit={handleClick}>
        <div className={"row pl-5 ml-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5  pr-2"}>
              <li>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className={"row mx-5 pl-5 resumealignment"}>
          <div className={"col-lg-1"}></div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <div
              className={"pt-3 pb-0 pl-3 pr-3"}
              style={{
                width: "140px",
                borderRadius: "10px",
                background: "#FFFFFF",
                textAlign: "center",
              }}
            >
              <div className={"row"}>
                <div className={"col-lg pb-1"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#000000",
                      fontFamily: "poppins",
                    }}
                  >
                    Experience
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg pl-1"}>
                  <div
                    style={{
                      background: "#3F9AE0",
                      width: "110%",
                      height: "4px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {academicNavigation ? (
              uploadResume === 0 ? (
                <Link
                  to={
                    "/candidate/dashboard/buildresume/resumecertificate?data=uploadresume"
                  }
                >
                  <div
                    className={"pt-2  pl-2 pr-2"}
                    style={{
                      width: "140px",
                      borderRadius: "10px",
                      background: "#FFFFFF",
                      textAlign: "center",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#BFBFBF",
                      }}
                    >
                      Academics
                      <br />& Certificates
                    </label>
                    <br />
                  </div>
                </Link>
              ) : (
                <Link to={"/candidate/dashboard/buildresume/resumecertificate"}>
                  <div
                    className={"pt-2  pl-2 pr-2"}
                    style={{
                      width: "140px",
                      borderRadius: "10px",
                      background: "#FFFFFF",
                      textAlign: "center",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#BFBFBF",
                      }}
                    >
                      Academics
                      <br />& Certificates
                    </label>
                    <br />
                  </div>
                </Link>
              )
            ) : (
              <div
                className={"pt-2  pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#BFBFBF",
                  }}
                >
                  Academics
                  <br />& Certificates
                </label>
                <br />
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {securityNavigation ? (
              <Link to={"/candidate/dashboard/buildresume/resumesecurity"}>
                <div
                  className={"pt-2 pl-2 pr-2"}
                  style={{
                    width: "140px",
                    borderRadius: "10px",
                    background: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#BFBFBF",
                    }}
                  >
                    Security Clearance
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#BFBFBF",
                  }}
                >
                  Security Clearance
                </label>
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {socialNavigation ? (
              <Link to={"/candidate/dashboard/buildresume/resumesocial"}>
                <div
                  className={"pl-3 pb-2 pr-3 pt-3"}
                  style={{
                    width: "140px",
                    borderRadius: "10px",
                    background: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#BFBFBF",
                    }}
                  >
                    Social
                  </label>
                  <br />
                </div>
              </Link>
            ) : (
              <div
                className={"pl-3 pb-2 pr-3 pt-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#BFBFBF",
                  }}
                >
                  Social
                </label>
                <br />
              </div>
            )}
          </div>
        </div>
        {/* second box */}
        <div className="mr-5 pr-5 px-3">
          <div className={"row px-5 pr-5 mx-3 pt-4"}>
            <div className={"w-100 pt-3 pb-3 contentholder"}>
            <div className={"row mx-4"}>
                <div className={"col-md-4"}>
                  <label htmlFor={"US"} className={"resumeforminputtext"}>
                    Total years of experience
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray2 shadow-none"
                    }
                    id={"US"}
                    name={"yearOfExp"}
                    value={information.yearOfExp}
                    style={{
                      border: `${error.yearOfExp ? "1px solid red" : ""}`,
                    }}
                    onChange={(event) => {
                      buildResumeExperience(event, setInformation, setError);
                      submitEnabled();
                    }}
                  >
                    <option value={""}></option>
                    <Getyear />
                  </select>
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.yearOfExp}
                  </font>
                </div>
                <div className={"col-md-4"}>
                  <label htmlFor={"totalexp"} className={"resumeforminputtext"}>
                    Years of Experience in US
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className={
                      "form-control resumeforminputfield form-select inputgray2 shadow-none totalexpus"
                    }
                    id={"totalexp"}
                    name={"yearOfExpUS"}
                    value={information.yearOfExpUS}
                    style={{
                      border: `${error.yearOfExpUS ? "1px solid red" : ""}`,
                    }}
                    onChange={(event) => {
                      buildResumeExperience(
                        event,
                        setInformation,
                        setError,
                        information
                      );
                      submitEnabled();
                    }}
                  >
                    <option value={""}></option>
                    <Getyear />
                  </select>
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.yearOfExpUS}
                  </font>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*third Box*/}
        <div className="mr-5 pr-5 px-3">
          <div className={"row px-5 pr-5 mx-3 pt-4"}>
            <div className={"w-100 pt-3 pb-3 contentholder"}>
              <div className={"row pt-1 mx-4"}>
                <div className={"col-md-4"}>
                  <label htmlFor={"jtitle"} className={"resumeforminputtext"}>
                    Job Title<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"jtitle"}
                    name={"jobTitle"}
                    className={"form-control resumeforminputfield shadow-none"}
                    value={information.jobTitle}
                    style={{
                      border: `${error.jobTitle ? "1px solid red" : ""}`,
                    }}
                    onChange={(event) => {
                      buildResumeExperience(event, setInformation, setError);
                      submitEnabled();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.jobTitle}
                  </font>
                </div>
                <div className={"col-md-4"}>
                  <label htmlFor={"java"} className={"resumeforminputtext"}>
                    Client<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"java"}
                    name={"client"}
                    value={information.client}
                    style={{
                      border: `${error.client ? "1px solid red" : ""}`,
                    }}
                    className={"form-control resumeforminputfield shadow-none"}
                    onChange={(event) => {
                      buildResumeExperience(event, setInformation, setError);
                      submitEnabled();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.client}
                  </font>
                </div>
              </div>
              <div className={"row pt-1 mx-4"}>
                <div className={"col-md"}>
                  <label htmlFor={"domain"} className={"resumeforminputtext"}>
                    Domain
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"domain"}
                    className={"form-control resumeforminputfield shadow-none"}
                    name={"domain"}
                    value={information.domain}
                    style={{
                      border: `${error.domain ? "1px solid red" : ""}`,
                    }}
                    onChange={(event) => {
                      buildResumeExperience(event, setInformation, setError);
                      submitEnabled();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.domain}
                  </font>
                </div>
                <div className={"col-md"} ref={locref}>
                  <label htmlFor={"location"} className={"resumeforminputtext"}>
                    Location
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"location"}
                    className={"form-control resumeforminputfield shadow-none"}
                    name={"location"}
                    value={information.location}
                    style={{
                      border: `${error.location ? "1px solid red" : ""}`,
                    }}
                    onChange={(event) => {
                      buildResumeExperience(event, setInformation, setError);
                      submitEnabled();
                      setCityShow(true);
                    }}
                    autoComplete={"off"}
                    onClick={() => {
                      setCityShow(!cityShow);
                      locationValid();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.location}
                  </font>
                  <div
                    id={"preLocCodeDiv"}
                    style={{
                      display: `${
                        cityShow && information.location.length > 0
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    {citylist &&
                      citylist.length > 0 &&
                      citylist.map((data, index) => (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          value={data.city}
                          onClick={() => selectedLocation(data.city)}
                        >
                          {data.city}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-md-4">
                  <SelectCalendar
                    {...{
                      fromDate,
                      setFromDate,
                      toDate,
                      setToDate,
                      setAcademicNavigation: setAcademicNavigation,
                      setSecurityNavigation: setSecurityNavigation,
                      setSocialNavigation: setSocialNavigation,
                    }}
                  />
                </div>
              </div>
              <div className={"row pt-3 mx-4"}>
                <div className={"col-md"}>
                <label htmlFor={"comment"} className={"resumeforminputtext"}>
                    Project Summary
                  </label>
                  <textarea
                    className={
                      "form-control resumeforminputfield pt-2 pb-0 shadow-none"
                    }
                    rows="2"
                    id="comment"
                    value={information.projectSummary || ""}
                    onChange={(event) => {
                      setInformation({
                        ...information,
                        projectSummary: event.currentTarget.value,
                      });
                      submitEnabled();
                    }}
                    placeholder={"Type Here"}
                    name={"projectSummary"}
                    ref={summaryRef}
                    style={{
                      minHeight: MIN_SUMMARY_HEIGHT,
                      resize: "none",
                      overflow: "hidden",
                    }}
                  ></textarea>
                </div>
              </div>
              <div className={"row pt-2 mx-4"}>
                <div className={"col-md"}>
                  <label htmlFor={"comment"} className={"resumeforminputtext"}>
                    Roles and Responsibilities
                  </label>
                  <textarea
                    className={"form-control resumeforminputfield shadow-none"}
                    ref={textareaRef}
                    id="comment"
                    value={information.roleAndResponse || ""}
                    placeholder={"Type Here"}
                    onChange={(event) => {
                      setInformation({
                        ...information,
                        roleAndResponse: event.currentTarget.value,
                      });
                      submitEnabled();
                    }}
                    style={{
                      minHeight: MIN_TEXTAREA_HEIGHT,
                      resize: "none",
                      overflow: "hidden",
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*duplicate here*/}
        {visibility.one && (
          <div className="mr-5 pr-5 px-3">
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-3 pb-3 contentholder"}>
                <div className={"row pt-1 mx-4"}>
                  <div className={"col-md-4"}>
                    <label htmlFor={"jtitle"} className={"resumeforminputtext"}>
                      Job Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"jtitle"}
                      name={"jobTitle1"}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      value={information.jobTitle1}
                      style={{
                        border: `${error.jobTitle1 ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        buildResumeExperience(event, setInformation, setError);
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.jobTitle1}
                    </font>
                  </div>
                  <div className={"col-md-4"}>
                    <label htmlFor={"java"} className={"resumeforminputtext"}>
                      Client<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"java"}
                      name={"client1"}
                      value={information.client1}
                      style={{
                        border: `${error.client1 ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      onChange={(event) => {
                        buildResumeExperience(event, setInformation, setError);
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.client1}
                    </font>
                  </div>
                </div>
                <div className={"row pt-1 mx-4"}>
                  <div className={"col-md"}>
                    <label htmlFor={"domain"} className={"resumeforminputtext"}>
                      Domain
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"domain"}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      name={"domain1"}
                      value={information.domain1}
                      style={{
                        border: `${error.domain1 ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        buildResumeExperience(event, setInformation, setError);
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.domain1}
                    </font>
                  </div>
                  <div className={"col-md"} ref={firstref}>
                    <label
                      htmlFor={"location"}
                      className={"resumeforminputtext"}
                    >
                      Location<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"location"}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      name={"location1"}
                      value={information.location1}
                      style={{
                        border: `${error.location1 ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        buildResumeExperience(event, setInformation, setError);
                        submitEnabled();
                        setCityShow1(true);
                      }}
                      autoComplete={"off"}
                      onClick={() => {
                        setCityShow1(!cityShow1);
                        locationOneValid();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.location1}
                    </font>
                    <div
                      id={"firstLocCodeDiv"}
                      style={{
                        display: `${
                          cityShow1 && information.location1.length > 0
                            ? "block"
                            : "none"
                        }`,
                      }}
                    >
                      {citylist1 &&
                        citylist1.length > 0 &&
                        citylist1.map((data, index) => (
                          <div
                            key={index}
                            style={{ cursor: "pointer" }}
                            value={data.city}
                            onClick={() => selectedLocationOne(data.city)}
                          >
                            {data.city}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <SelectCalendar
                      {...{
                        fromDate: fromDate1,
                        setFromDate: setFromDate1,
                        toDate: toDate1,
                        setToDate: setToDate1,
                        setAcademicNavigation: setAcademicNavigation,
                        setSecurityNavigation: setSecurityNavigation,
                        setSocialNavigation: setSocialNavigation,
                      }}
                    />
                  </div>
                </div>
                <div className={"row pt-3 mx-4"}>
                  <div className={"col-md"}>
                  <label
                      htmlFor={"comment"}
                      className={"resumeforminputtext"}
                    >
                      Project Summary
                    </label>
                    <textarea
                      className={
                        "form-control resumeforminputfield pt-2 pb-0 shadow-none"
                      }
                      rows="2"
                      id="comment"
                      value={information.projectSummary1 || ""}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          projectSummary1: event.currentTarget.value,
                        });
                        submitEnabled();
                      }}
                      ref={summaryRef1}
                      placeholder={"Type Here"}
                      style={{
                        minHeight: MIN_SUMMARY_HEIGHT1,
                        resize: "none",
                        overflow: "hidden",
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className={"row pt-2 mx-4"}>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"comment"}
                      className={"resumeforminputtext"}
                    >
                      Roles and Responsibilities
                    </label>
                    <textarea
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      ref={textareaRef1}
                      id="comment"
                      placeholder={"Type Here"}
                      value={information.roleAndResponse1 || ""}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          roleAndResponse1: event.currentTarget.value,
                        });
                        submitEnabled();
                      }}
                      style={{
                        minHeight: MIN_TEXTAREA_HEIGHT1,
                        resize: "none",
                        overflow: "hidden",
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={
                        "btn float-right badge border-0 mr-5 cancelskill"
                      }
                      onClick={() => {
                        setVisibility((state) => {
                          return { ...state, one: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*duplicate here*/}
        {visibility.two && (
          <div className="mr-5 pr-5 px-3">
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-3 pb-3 contentholder"}>
                <div className={"row pt-1 mx-4"}>
                  <div className={"col-md-4"}>
                    <label htmlFor={"jtitle"} className={"resumeforminputtext"}>
                      Job Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"jtitle"}
                      name={"jobTitle2"}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      value={information.jobTitle2}
                      style={{
                        border: `${error.jobTitle2 ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        buildResumeExperience(event, setInformation, setError);
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.jobTitle2}
                    </font>
                  </div>
                  <div className={"col-md-4"}>
                    <label htmlFor={"java"} className={"resumeforminputtext"}>
                      Client<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"java"}
                      name={"client2"}
                      value={information.client2}
                      style={{
                        border: `${error.client2 ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      onChange={(event) => {
                        buildResumeExperience(event, setInformation, setError);
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.client2}
                    </font>
                  </div>
                </div>
                <div className={"row pt-1 mx-4"}>
                  <div className={"col-md"}>
                    <label htmlFor={"domain"} className={"resumeforminputtext"}>
                      Domain
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"domain"}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      name={"domain2"}
                      value={information.domain2}
                      style={{
                        border: `${error.domain2 ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        buildResumeExperience(event, setInformation, setError);
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.domain2}
                    </font>
                  </div>
                  <div className={"col-md"} ref={secref}>
                    <label
                      htmlFor={"location"}
                      className={"resumeforminputtext"}
                    >
                      Location<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"location"}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      name={"location2"}
                      value={information.location2}
                      style={{
                        border: `${error.location2 ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        buildResumeExperience(event, setInformation, setError);
                        submitEnabled();
                        setCityShow2(true);
                      }}
                      autoComplete={"off"}
                      onClick={() => {
                        setCityShow2(!cityShow2);
                        locationTwoValid();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.location2}
                    </font>
                    <div
                      id={"secLocCodeDiv"}
                      style={{
                        display: `${
                          cityShow2 && information.location2.length > 0
                            ? "block"
                            : "none"
                        }`,
                      }}
                    >
                      {citylist2 &&
                        citylist2.length > 0 &&
                        citylist2.map((data, index) => (
                          <div
                            key={index}
                            style={{ cursor: "pointer" }}
                            value={data.city}
                            onClick={() => selectedLocationTwo(data.city)}
                          >
                            {data.city}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <SelectCalendar
                      {...{
                        fromDate: fromDate2,
                        setFromDate: setFromDate2,
                        toDate: toDate2,
                        setToDate: setToDate2,
                        setAcademicNavigation: setAcademicNavigation,
                        setSecurityNavigation: setSecurityNavigation,
                        setSocialNavigation: setSocialNavigation,
                      }}
                    />
                  </div>
                </div>
                <div className={"row pt-3 mx-4"}>
                  <div className={"col-md"}>
                  <label
                      htmlFor={"comment"}
                      className={"resumeforminputtext"}
                    >
                      Project Summary
                    </label>
                    <textarea
                      className={
                        "form-control resumeforminputfield pt-2 pb-0 shadow-none"
                      }
                      rows="2"
                      id="comment"
                      value={information.projectSummary2 || ""}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          projectSummary2: event.currentTarget.value,
                        });
                        submitEnabled();
                      }}
                      placeholder={"Type Here"}
                      ref={summaryRef2}
                      style={{
                        minHeight: MIN_SUMMARY_HEIGHT2,
                        resize: "none",
                        overflow: "hidden",
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className={"row pt-2 mx-4"}>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"comment"}
                      className={"resumeforminputtext"}
                    >
                      Roles and Responsibilities
                    </label>
                    <textarea
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      ref={textareaRef2}
                      id="comment"
                      value={information.roleAndResponse2 || ""}
                      placeholder={"Type Here"}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          roleAndResponse2: event.currentTarget.value,
                        });
                        submitEnabled();
                      }}
                      style={{
                        minHeight: MIN_TEXTAREA_HEIGHT2,
                        resize: "none",
                        overflow: "hidden",
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={
                        "btn float-right badge border-0 mr-5 cancelskill"
                      }
                      onClick={() => {
                        setVisibility((state) => {
                          return { ...state, two: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*duplicate here*/}
        {!(visibility.one && visibility.two) && (
          <div className={"row"}>
            <div className={"col-md mt-2"}>
              <div
                className={"btn offset-sm-9 badge bg-transparent  newskill"}
                onClick={Addproject}
              >
                + Add Project
              </div>
            </div>
          </div>
        )}
        <div className="row pr-5">
          <div className="col-md offset-sm-9">
            <label
              className={"dashboardforminputtext text-danger experr"}
            ></label>
          </div>
        </div>
        {/* New div comes when we click add project */}
        <div className={"row hidebox"}>
          <div className={"col pt-2 pb-2 pr-5"}>
            {academicNavigation ? (
              <>
                {redirect ? (
                  <Redirect to="/candidate/dashboard/buildresume/resumecertificate" />
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 save buildhide"}
                    ref={expRef}
                  >
                    {loader ? (
                      <i
                        class="fa fa-refresh fa-spin"
                        style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  cardName ? (
                    <Redirect to="/candidate/dashboard/buildresume/resumecertificate?data=uploadresume" />
                  ) : (
                    <Redirect to="/candidate/dashboard/buildresume/resumecertificate" />
                  )
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 save"}
                    ref={expRef}
                  >
                    {loader ? (
                      <i
                        class="fa fa-refresh fa-spin"
                        style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};
export default React.memo(BuildResume2);
