import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { useEffect } from "react";
import { markasReadNotifications } from "../api/common/readNotification";

export default function NavbarTopbar(props) {
  // return top position navbar
  const [match, setMatch] = useState([]);
  const isrecruiter = useSelector((state) => state.isrecruiter);
  const isnewuser = useSelector((state) => state.isnewuser);
  const socket = useSelector((state) => state.socket);
  const token = useSelector((state) => state.token);
  const history = useHistory();
  const ref = useRef();
  const viewref = useRef();
  const dispatch = useDispatch();
  let count = 2,
    deg = 90;

  function resetToken() {
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("clientnewuser", "");
    dispatch({ type: "settoken", value: "" });

    if (socket && socket.connected) {
      socket.disconnect();
    }
  }
  

  const toggleSidebar = (event) => {
    event.target.style.cssText = `transform: rotate(${deg}deg);cursor:pointer;padding-top:5px;transform:scale(0.8)`;
    const companylogo = document.getElementsByClassName("companylogo")[0];
    const sidebar = document.getElementsByClassName("sidebar")[0];
    const hide = document.getElementsByClassName("hide");
    const homebtntxt = document.getElementsByClassName("hometext")[0];
    const container = document.getElementsByClassName("stick")[0];
    let permanentdataid, newuserbtn, navbarsidebar;
    try {
      permanentdataid = document.getElementById("permanentdataid");
      newuserbtn = document.getElementById("dashboardfont");
      navbarsidebar = document.getElementsByClassName(
        "sidebarBtnForNewUser"
      )[0];
    } catch {}
    if (count % 2 === 0) {
      try {
        newuserbtn.style.display = "none";
        permanentdataid.style.display = "none";
        navbarsidebar.style.cssText = "display:none";
      } catch {}
      companylogo.style.cssText =
        "width:200px;height:auto;position:relative;right:50px";
      for (const dom of hide) {
        dom.style.display = "none";
      }
      sidebar.style.cssText = "min-width:100px !important;padding-left:0px";
      container.style.cssText =
        "margin-left:100px;background-color:#F9F9F9;border-radius:38px 0px 0px 0px";

      try {
        homebtntxt.style.display = "none";
      } catch {}
      deg += 90;
    } else {
      try {
        newuserbtn.style.display = "inline-block";
        permanentdataid.style.display = "inline-block";
        navbarsidebar.style.cssText =
          "border-radius:16px;background-color:#F9F9F9";
      } catch {}
      companylogo.style.cssText = "position:relative;right:50px";
      sidebar.style.cssText = "min-width:280px";
      container.style.cssText =
        "margin-left:260px;background-color:#F9F9F9;border-radius:38px 0px 0px 0px";
      for (const dom of hide) {
        dom.style.display = "inline-block";
      }
      try {
        homebtntxt.style.display = "inline-block";
      } catch {}
      deg -= 90;
    }
    count += 1;
  };

  // notification
  useEffect(() => {
    try {
      if (props.message.length !== 0) {
        setMatch(props.message);
      }
    } catch {}
  }, [props.message]);

  async function countRead (){
    const response = await markasReadNotifications(token);
  }


  return (
    <div className={"row align-items-center  pl-5 scroll"}>
      <div className={"col-lg-2 col-md-3"}></div>
      <div className={"col-lg-7 col-md-5 col-sm-12 col-12"}>
        <img
          src="/icon/common/dashline.png"
          alt={"dashline"}
          onClick={toggleSidebar}
          style={{ cursor: "pointer", transform: "scale(0.8)" }}
          className={"pb-2"}
          id={"sidebaricon"}
        />
        {/*below is condition if recruiter come select will return else div */}
        {isrecruiter && isnewuser === false ? (
          <select
            className={"navbartopbarselectrole"}
            onChange={(event) => history.push(event.target.value)}
          >
            {props.profilelocation.split("/")[1] === "client" ? (
              <>
                <option value={"/client/dashboardview"}>
                  Client's Dashboard
                </option>
                <option value={"/candidate/dashboardview"}>
                  Candidate's Dashboard
                </option>
              </>
            ) : (
              <>
                <option value={"/candidate/dashboardview"}>
                  Candidate's Dashboard
                </option>
                <option value={"/client/dashboardview"}>
                  Client's Dashboard
                </option>
              </>
            )}
          </select>
        ) : (
          <div style={{ display: "inline-block" }}>
            <font
              style={{ fontSize: "16px" }}
              className={"pl-4"}
              id={"replacetitleimg"}
            >
              Dashboard
            </font>
          </div>
        )}

        {/*upload profile button show only in recruiter & freelance recruiter dashboardview*/}
        {isrecruiter && isnewuser === false && (
          <>
            {props.profilelocation.split("/")[1] === "candidate" ? (
              <>
                <div className="float-right pt-2">
                  <Link
                    to={"/candidate/registration"}
                    className={"btn postjobbtn"}
                  >
                    Upload Profile
                  </Link>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        {/*post job button show only in client dashboardview*/}
        {props.profilelocation.split("/")[1] === "client" ? (
          <div className="float-right pt-2" id={"postjobid"}>
            <Link to={"/client/uploadresume"} className={"btn postjobbtn"}>
              Post A Job
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={"row align-items-center"}>
        <div className={"col-md col-sm-6 col-6"}>
          <div className={"col-md d-flex align-item-center"}>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "500",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "97px",
              }}
            >
              {props.firstName}&nbsp;{props.lastName}
            </div>
            <br />
          </div>
          <div className={"row"}>
            <div className={"col-md"}>
              <font
                style={{
                  fontSize: "12px",
                  color: "#8F8F8F",
                  fontWeight: "500",
                }}
                className={"ml-3"}
              >
                {props.candidateId}
              </font>
            </div>
          </div>
        </div>

        {props.count !== 0 ? (
          <>
            <Popup
              trigger={
                <div>
                  {props.count !== 0 ? (
                    <div className="notifybadges bg-primary">{props.count}</div>
                  ) : (
                    ""
                  )}
                  <img
                    src="/icon/common/bell.png"
                    alt={"bell"}
                    className={"float-right"}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              }
              position="bottom right"
              contentStyle={{
                width: "389px",
                height: "auto",
                background: "#ffffff",
                borderRadius: "10px",
              }}
              ref={viewref}
            >
              {match.hasOwnProperty("notifications") &&
                match.notifications.slice(0, 4).map((data, index) => (
                  <div key={index}>
                    <div></div>
                    <div className="row">
                      <div className="col-1">
                        <i
                          className="fas fa-user-circle"
                          style={{
                            padding: "13px 10px",
                            color: "rgb(63, 154, 224)",
                          }}
                        ></i>
                      </div>
                      <div className="col">
                        <label
                          className="messagetopbar"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#515151",
                            padding: "10px 10px 10px 0px",
                          }}
                        >
                          {data.message.replace(/<[^>]+>/g, "")}
                        </label>
                      </div>
                    </div>
                    <hr className={"divider"} />
                  </div>
                ))}
              <Link
                to={`${props.allnotification}`}
                onClick={() => {
                  viewref.current.close();
                  countRead();
              }}
             style={{ textDecoration: "none" }}
              >
                <div
                  className="text-primary text-center font-weight-bold mt-2"
                >
                  View All
                </div>
              </Link>
            </Popup>
          </>
        ) : (
          <Link to={`${props.allnotification}`}>
            <img
              src="/icon/common/bell.png"
              alt={"bell"}
              className={"float-right"}
            />
          </Link>
        )}
      </div>
      <div className={"col-md-1 col-sm-4 col-3 popbox"}>
        <Popup
          trigger={
            props.profilePicture ? (
              <img
                src={
                  props.profilePicture
                    ? props.profilePicture
                    : "/icon/common/darkprofile.png"
                }
                alt={"user"}
                className={"ml-5 "}
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                }}
              />
            ) : (
              <figure className="story_shape ml-5 mt-2">
                <img
                  src="/icon/common/darkprofile.png"
                  alt="person on a tour"
                  className="story_img"
                />
                <figcaption className="story_caption">
                  {props.firstName.charAt(0)}
                  {props.lastName.charAt(0)}
                </figcaption>
              </figure>
            )
          }
          position="bottom right"
          contentStyle={{
            width: "197px",
            height: "126px",
            background: "#C4C4C4",
            borderRadius: "10px",
          }}
          ref={ref}
        >
          <div
            className={"p-3"}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Link
              to={props.profilelocation}
              onClick={() => ref.current.close()}
            >
              <label
                style={{
                  color: "#ffffff",
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              >
                Profile
              </label>
            </Link>
            <Link
              to={props.settinglocation}
              onClick={() => ref.current.close()}
            >
              <label
                style={{
                  color: "#ffffff",
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              >
                Settings
              </label>
            </Link>
            <Link to={"/user/login"} onClick={resetToken}>
              <label
                style={{
                  color: "#ffffff",
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              >
                Logout
              </label>
            </Link>
          </div>
        </Popup>
      </div>
    </div>
  );
}
