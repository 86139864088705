import React, { useState, useEffect, useRef } from "react";
import { clearFilter } from "../common/clearFilter";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { toTitleCase } from "../common/convertToTitleCase";
import { clientRedirect } from "./clientRedirect";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clientArchiveRedirect } from "./clientArchiveRedirect";

const Data = (props) => {
  const [loading, setLoading] = useState();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let cardName = query.get("title");
  const [match, setMatch] = useState([]);
  const ref = useRef();

  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch {}
  }, [props.item]);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const dispatch = useDispatch();

  return (
    // return matching job table data group which generated by data variable
    <>
      {loading ? (
        <div
          className="row h-100"
          style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
        >
          <div className="col-sm-12 my-auto">
            <img
              src={"/icon/Gif/loader.gif"}
              className={"mx-auto d-block"}
              alt={"loader"}
            />
          </div>
        </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {match.map((match) => (
                <div
                  className={"row pl-4"}
                  style={{
                    boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                    backgroundColor: "white",
                    marginTop: "1px",
                  }}
                  key={match.uuid && match.uuid.split("-").pop()}
                >
                  {/* <input type={"checkbox"} className={"checkbx  mr-4 mt-4"}/> */}
                  <div className={"col py-3 box"}>
                    {/*heading box data*/}
                    <div className={"row  align-items-center pl-3"}>
                      <div className={"col-2 pl-4"}>
                        <Link
                          to={`/client/jobapply/${match.uuid}`}
                          className={"matchingjobdata text-primary"}
                        >
                          {match.ClientRequirementId &&
                            match.ClientRequirementId}
                        </Link>
                        <br />
                        <font className="matchingjobdata">
                          {new Date(match.createdAt).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </font>
                        <br />
                        <font className={"matchingjobdata"}>
                          {match.hiringType && match.hiringType.substring(0, 8)}
                        </font>
                      </div>
                      <div className={"col-md-2"}>
                        <div className="row">
                          <div
                            className="col-md"
                            style={{
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                              textAlign: "center",
                            }}
                          >
                            {cardName === "archive" ? (
                              <Link
                                to={`/client/filteredapplications?id=${match.uuid}`}
                                className={"matchingjobdata text-primary"}
                                onClick={() => clientArchiveRedirect(dispatch)}
                              >
                                {match.jobTitle}
                              </Link>
                            ) : (
                              <Link
                                to={`/client/filteredapplications?id=${match.uuid}`}
                                className={"matchingjobdata text-primary"}
                                onClick={() => clientRedirect(dispatch)}
                              >
                                {match.jobTitle}
                              </Link>
                            )}
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {cardName === null && (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}`}
                              className={"matchingjobdata text-primary"}
                              onClick={() => clientRedirect(dispatch)}
                            >
                              No Of Position {match.numberOfPosition}
                            </Link>
                          )}
                          {cardName === "total" && (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}`}
                              className={"matchingjobdata text-primary"}
                              onClick={() => clientRedirect(dispatch)}
                            >
                              No Of Position {match.numberOfPosition}
                            </Link>
                          )}
                          {cardName === "archive" && (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}`}
                              className={"matchingjobdata text-primary"}
                              onClick={() => clientArchiveRedirect(dispatch)}
                            >
                              No Of Position {match.numberOfPosition}
                            </Link>
                          )}
                          {cardName === "active" && (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}`}
                              className={"matchingjobdata text-primary"}
                              onClick={() => clientRedirect(dispatch)}
                            >
                              No Of Position {match.numberOfPosition}
                            </Link>
                          )}
                          {cardName === "scheduled" && (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}&card=scheduled`}
                              className={"matchingjobdata text-primary"}
                              onClick={() => clientRedirect(dispatch)}
                            >
                              Scheduled interviews {match.scheduledCount}
                            </Link>
                          )}
                          {cardName === "shortlisted" && (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}&card=shortlisted`}
                              className={"matchingjobdata text-primary"}
                              onClick={() => clientRedirect(dispatch)}
                            >
                              Shortlisted Candidates {match.shortlistedCount}
                            </Link>
                          )}
                          {cardName === "waiting" && (
                            <label className={"matchingjobdata text-primary"}>
                              Waiting Candidates
                            </label>
                          )}
                          {cardName === "offer" && (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}&card=offer`}
                              className={"matchingjobdata text-primary"}
                            >
                              Offer Roleout {match.offerCount}
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className={"row pl-4"}>
                          <div className={"col ml-1"}>
                            <div className={"row"}>
                              <div className={"col-md"}>
                                <font className={"matchingjobdata"}>
                                  {match.minDuration} month
                                </font>
                              </div>
                            </div>
                            <div className={"row"}>
                              <div
                                className="col-md"
                                style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                <font className={"matchingjobdata"}>
                                  {match.location}
                                </font>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-1 pr-5"}>
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <font className={"matchingjobdata"}>
                            ${match.rate}
                          </font>
                        </div>
                      </div>
                      <div className={"col-1 pl-0 pr-0"}>
                        <font className={"matchingjobdata"}>
                          {match.shortlistedCount}
                        </font>
                      </div>
                      <div className="col-2 pl-0">
                        <font className={"matchingjobdata"}>
                          {match.workType &&
                            toTitleCase(match.workType.replace(/_/g, " "))}
                        </font>
                      </div>
                      <div className="col-1 pr-0">
                        <font className={"matchingjobdata"}>0</font>
                      </div>
                      <div className="col-1">
                        {cardName === "archive" ? (
                          <Popup
                            ref={ref}
                            trigger={
                              <i
                                className="fa fa-ellipsis-v pl-3"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                              />
                            }
                            position="bottom right"
                            contentStyle={{
                              width: "165px",
                              height: "100px",
                              background: "#F9F9F9",
                              borderRadius: "10px",
                              marginLeft: "20px",
                            }}
                          >
                            <label
                              className="popuptext"
                              onClick={() => {
                                props.handleDuplicate(match.uuid);
                                ref.current.close();
                              }}
                            >
                              Duplicate
                            </label>
                            <label
                              type={"button"}
                              onClick={() => {
                                ref.current.close();
                                props.handleDownload(match.uuid);
                              }}
                              className="popuptext"
                            >
                              Download Response
                            </label>
                          </Popup>
                        ) : (
                          <Popup
                            ref={ref}
                            trigger={
                              <i
                                className="fa fa-ellipsis-v pl-3"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                              />
                            }
                            position="bottom right"
                            contentStyle={{
                              width: "165px",
                              height: "140px",
                              background: "#F9F9F9",
                              borderRadius: "10px",
                              marginLeft: "20px",
                            }}
                          >
                            <Link to={`/client/postjobpreview/${match.uuid}`}>
                              <label className="popuptext">Edit</label>
                            </Link>
                            <br />
                            <label
                              type={"button"}
                              onClick={(e) => {
                                ref.current.close();
                                props.handleDelete(match.uuid);
                              }}
                              className="popuptext"
                            >
                              Archive
                            </label>
                            <br />
                            <label
                              className="popuptext"
                              onClick={() => {
                                props.handleDuplicate(match.uuid);
                                ref.current.close();
                              }}
                            >
                              Duplicate
                            </label>
                            <label
                              type={"button"}
                              onClick={() => {
                                ref.current.close();
                                props.handleDownload(match.uuid);
                              }}
                              className="popuptext"
                            >
                              Download Response
                            </label>
                          </Popup>
                        )}
                      </div>
                      <div
                        className={`modal fade ${
                          props.archiveModal ? "show" : ""
                        }`}
                        id="deactivateprofile"
                        data-backdrop={"static"}
                        style={{
                          display: `${props.archiveModal ? "block" : "none"}`,
                        }}
                      >
                        <div
                          className="modal-dialog"
                          style={{ border: "2px solid #eaeaec" }}
                        >
                          <div className="modal-content">
                            <div className="modal-title pr-3 pt-3">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={props.hideModal}
                              >
                                &times;
                              </button>
                            </div>
                            <div className="modal-body pl-5 ml-3 matchingjobdata">
                              {props.archiveErrmsg} <br />
                              you want to archive the requirement
                            </div>
                            <div className="row pt-3 pb-4 pl-5 ml-5">
                              <div className="col-md-5">
                                <button
                                  type="button"
                                  className="btn btn-success py-1"
                                  onClick={() =>
                                    props.handleAssociatedRequirement(
                                      props.archiveId
                                    )
                                  }
                                  data-dismiss="modal"
                                >
                                  Yes
                                </button>
                              </div>
                              <div className="col-md-3">
                                <button
                                  type="button"
                                  className="btn btn-danger py-1"
                                  data-dismiss="modal"
                                  onClick={props.hideModal}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className={"d-flex justify-content-center pt-5 pb-5"}>
              No Data Found
            </div>
          )}
        </>
      )}
    </>
  );
};
export { Data, clearFilter };
