import React from "react";
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UploadResume from './UploadResume';
import UserRegistrationForm from './UserRegistrationForm';
import JobRequirment1 from './JobRequirment/JobRequirment1';
import JobRequirment2 from './JobRequirment/JobRequirment2';
import JobRequirment3 from './JobRequirment/JobRequirment3';
import JobRequirment4 from './JobRequirment/JobRequirment4';
import JobRequirment5 from './JobRequirment/JobRequirment5';
import JobRequirmentPreview from "./JobRequirment/JobRequirmentPreview";
import UploadProfile from "./UploadProfile";
import RegistrationForm from "./Resume-Builder/RegistrationForm";
import BuildResume2 from "./Resume-Builder/BuildResume2";
import BuildResume3 from "./Resume-Builder/BuildResume3";
import BuildResume4 from "./Resume-Builder/BuildResume4";
import BuildResume5 from "./Resume-Builder/BuildResume5";
import ResumeSnapshot from "./ResumeSnapshot";

const DashboardHome = () => {
  // return dashboard home page for new user
  const dispatch = useDispatch();
  dispatch({ type: "newusertrue" });
  const tablink = [
    "/recruiter/dashboard/postjob",
    "/recruiter/dashboard/postskill",
    "/recruiter/dashboard/postacademic",
    "/recruiter/dashboard/postsecurity",
    "/recruiter/dashboard/postdescription",
    "/recruiter/dashboard/postjobpreview",
    "/recruiter/dashboard/editjob",
  ];
  const candidateTablink = ["/recruiter/dashboard/registration",
    "/recruiter/dashboard/uploadprofile",
    "/recruiter/dashboard/resumeexperience",
    "/recruiter/dashboard/resumecertificate",
    "/recruiter/dashboard/resumesecurity",
    "/recruiter/dashboard/resumesocial",
    "/recruiter/dashboard/resumesnapshot"]

  try {
    document.getElementById("replacetitleimg").innerText = "Dashboard";
  } catch { }

  return (
    <React.Fragment>
      <Route path={"/recruiter/dashboard/register"}>
        <UserRegistrationForm link={"/recruiter/dashboard/uploadresume"} />
      </Route>
      <Route path={"/recruiter/dashboard/uploadresume"}>
        <UploadResume />
      </Route>
      <Route
        path={[
          "/recruiter/dashboard/registration/:id",
          "/recruiter/dashboard/registration",
        ]}
      >
        <RegistrationForm link={candidateTablink} />
      </Route>
      <Route path={"/recruiter/dashboard/uploadprofile/:id"}>
        <UploadProfile
          userregistration={"/recruiter/dashboard/resumeexperience"}
        />
      </Route>
      <Route
        path={[
          "/recruiter/dashboard/resumeexperience/:id",
          "/recruiter/dashboard/resumeexperience",
        ]}
      >
        <BuildResume2 link={candidateTablink} />
      </Route>
      <Route path={"/recruiter/dashboard/resumecertificate/:id"}>
        <BuildResume3 link={candidateTablink} />
      </Route>
      <Route path={"/recruiter/dashboard/resumesecurity/:id"}>
        <BuildResume4 link={candidateTablink} />
      </Route>
      <Route path={"/recruiter/dashboard/resumesocial/:id"}>
        <BuildResume5 link={candidateTablink} />
      </Route>
      <Route path={"/recruiter/dashboard/resumesnapshot/:id"}>
        <ResumeSnapshot />
      </Route>
      <Route
        path={[
          "/recruiter/dashboard/postjob/:refId",
           "/recruiter/dashboard/postjob",
        ]}
      >
        <JobRequirment1 link={tablink} />
      </Route>
      <Route
        path={[
         "/recruiter/dashboard/editjob/:id",
          "/recruiter/dashboard/editjob",
        ]}
      >
        <JobRequirment1 link={tablink} />
      </Route>
      <Route
        path={[
          "/recruiter/dashboard/postskill/:id/:refId",
          "/recruiter/dashboard/postskill/:id",
        ]}
      >
        <JobRequirment2 link={tablink} />
      </Route>
      <Route
        path={[
          "/recruiter/dashboard/postacademic/:id/:refId",
          "/recruiter/dashboard/postacademic/:id",
        ]}
      >
        <JobRequirment3 link={tablink} />
      </Route>
      <Route
        path={[
          "/recruiter/dashboard/postsecurity/:id/:refId",
          "/recruiter/dashboard/postsecurity/:id",
        ]}
      >
        <JobRequirment4 link={tablink} />
      </Route>
      <Route
        path={[
          "/recruiter/dashboard/postdescription/:id/:refId",
          "/recruiter/dashboard/postdescription/:id",
        ]}
      >
        <JobRequirment5 link={tablink} />
      </Route>
      <Route
        path={[
          "/recruiter/dashboard/postjobpreview/:id",
          "/recruiter/dashboard/postjobpreview",
        ]}
      >
        <JobRequirmentPreview />
      </Route>
    </React.Fragment>
  );
}

export default React.memo(DashboardHome);