function parsePostAcademic(
  information,
  visibility,
  certificateInformation,
  certificateVisibility
) {
  let educationData = [];

  educationData.push({
    academicQualification: information.qualification,
    specialization: information.specialization,
    institution: information.institute,
    yearOfPassing: information.yearOfPassing,
  });

  if (visibility.one) {
    educationData.push({
      academicQualification: information.qualification1,
      specialization: information.specialization1,
      institution: information.institute1,
      yearOfPassing: information.yearOfPassing1,
    });
  }
  if (visibility.two) {
    educationData.push({
      academicQualification: information.qualification2,
      specialization: information.specialization2,
      institution: information.institute2,
      yearOfPassing: information.yearOfPassing2,
    });
  }
  const certificateData = [];
  if (certificateInformation.certificateName) {
    certificateData.push({
      certificateName: certificateInformation.certificateName,
      institute: certificateInformation.institute,
      validUpToYear: certificateInformation.year,
      validUpToMonth: certificateInformation.month,
      lifeTimeValidity: certificateInformation.lifeTimeValidity
    });
  }
  if (certificateVisibility.one && certificateInformation.certificateName0) {
    certificateData.push({
      certificateName: certificateInformation.certificateName0,
      institute: certificateInformation.institute0,
      validUpToYear: certificateInformation.year0,
      validUpToMonth: certificateInformation.month0,
      lifeTimeValidity: certificateInformation.lifeTimeValidity0
    });
  }
  if (certificateVisibility.two && certificateInformation.certificateName1) {
    certificateData.push({
      certificateName: certificateInformation.certificateName1,
      institute: certificateInformation.institute1,
      validUpToYear: certificateInformation.year1,
      validUpToMonth: certificateInformation.month1,
      lifeTimeValidity: certificateInformation.lifeTimeValidity1
    });
  }
  try {
    const educationKey = Object.keys(educationData[0]);
    educationData.map((obj) => {
      educationKey.map((key) => {
        if (!obj[key]) {
          delete obj[key];
        }
        return obj;
      });
    });

    const certificateKey = Object.keys(certificateData[0]);
    certificateData.map((obj) => {
      certificateKey.map((key) => {
        if (obj[key] === "") {
          delete obj[key];
        }
        return obj;
      });
    });
  } catch {}

  const res = {
    education: educationData,
    USADegree: visibility.zero ? "Yes" : "No",
    veteran: visibility.four
  };
  if (certificateData.length) {
    res["certificate"] = certificateData;
  }
  return res;
}

function parseGetAcademic(
  payload,
  information,
  setInformation,
  visibility,
  setVisibility,
  certificateInformation,
  setCertificateInformation,
  certificateVisibility,
  setCertificateVisibility
) {
  // academic data getter
  let parseEducation = {};
  const eduVisible = {};
  if (payload.hasOwnProperty("education")) {
    const firstObj = [];
    const tmpObj = [];
    payload.education.map((obj) => {
      if (obj.USADegree === "Yes") {
        firstObj.push(obj);
        return null;
      }
      tmpObj.push(obj);
    });
    firstObj.push(...tmpObj);

    try {
      const data = firstObj[0];
      if (data) {
        parseEducation = {
          ...parseEducation,
          qualification: data.academicQualification,
          specialization: data.specialization,
          institute: data.institution,
          yearOfPassing: data.yearOfPassing,
        };
      }
    } catch {}
    try {
      const data = firstObj[1];
      if (data) {
        parseEducation = {
          ...parseEducation,
          qualification1: data.academicQualification,
          specialization1: data.specialization,
          institute1: data.institution,
          yearOfPassing1: data.yearOfPassing,
        };
      }
    } catch {}
    try {
      const data = firstObj[2];
      if (data) {
        parseEducation = {
          ...parseEducation,
          qualification2: data.academicQualification,
          specialization2: data.specialization,
          institute2: data.institution,
          yearOfPassing2: data.yearOfPassing,
        };
      }
    } catch {}
  }
  // certificate data getting
  let parseCertificate = {};
  const certVisible = {};
  if (payload.hasOwnProperty("certificate")) {
    try {
      const data = payload.certificate[0];
      if (data) {
        parseCertificate = {
          ...parseCertificate,
          certificateName: data.certificateName || "",
          institute: data.institute || "",
          year: data.validUpToYear || "",
          month: data.validUpToMonth || "",
          lifeTimeValidity: data.lifeTimeValidity
        };
      }
    } catch {}
    try {
      const data = payload.certificate[1];

      if (data) {
        certVisible["one"] = true;
        parseCertificate = {
          ...parseCertificate,
          certificateName0: data.certificateName || "",
          institute0: data.institute || "",
          year0: data.validUpToYear || "",
          month0: data.validUpToMonth || "",
          lifeTimeValidity0: data.lifeTimeValidity
        };
      }
    } catch {}
    try {
      const data = payload.certificate[2];
      if (data) {
        certVisible["two"] = true;
        parseCertificate = {
          ...parseCertificate,
          certificateName1: data.certificateName || "",
          institute1: data.institute || "",
          year1: data.validUpToYear || "",
          month1: data.validUpToMonth || "",
          lifeTimeValidity1: data.lifeTimeValidity
        };
      }
    } catch {}
  }
  if(payload.hasOwnProperty("candidate")){
    eduVisible["zero"] = payload.candidate.USADegree === "Yes" ? true : false;
    eduVisible["four"] = payload.candidate.veteran
  }
  setVisibility({ ...visibility, ...eduVisible });
  setCertificateVisibility({ ...certificateVisibility, ...certVisible });
  setInformation({ ...information, ...parseEducation });
  setCertificateInformation({ ...certificateInformation, ...parseCertificate });
}

function GetAcademicFromParse(
  payload,
  information,
  setInformation,
  visibility,
  setVisibility,
  certificateInformation,
  setCertificateInformation,
  certificateVisibility,
  setCertificateVisibility
) {
  // academic data getter
  if (payload.hasOwnProperty("education")) {
  const { education } = payload;
  const plength = education.length;
  //   set first primary data
  let visibleObject = {};
  let primaryObject = {
    specialization: education[0].specialization,
    institute: education[0].institute,
  };
  visibleObject["zero"] = education[0].usDegree !== null && education[0].usDegree ;
  if (plength > 1) {
    try {
      primaryObject = {
        ...primaryObject,
        specialization1: education[1].specialization,
        institute1: education[1].institute,
      };
     visibleObject["one"] = true;
    } catch {}
  }
  if (plength > 2) {
    try {
      primaryObject = {
        ...primaryObject,
        specialization2: education[2].specialization,
        institute2: education[2].institute,
      };
     visibleObject["two"] = true;
    } catch {}
  }
  setVisibility(Object.assign(visibility, visibleObject));
  setInformation({
    ...information,
    ...primaryObject,
  });
}
  
}


export { parsePostAcademic, parseGetAcademic , GetAcademicFromParse};
