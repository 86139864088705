import { FREELANCEBUILDREQUIREMENTINFO } from "../../../ENVIRONMENT";

// api for freelance client requirementInfo
async function freerequirementInfo(token, userdetails) {
  const response = await fetch(FREELANCEBUILDREQUIREMENTINFO, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(userdetails),
  });
  return response;
}
// api for freelance client requirementInfo
async function freeditRequirementInfo(token, userdetails, id) {
  const response = await fetch(FREELANCEBUILDREQUIREMENTINFO + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(userdetails),
  });
  return response;
}

export { freerequirementInfo, freeditRequirementInfo };
