import { SUPERADMINDASHBOARDCOUNT } from "../../ENVIRONMENT";

function dashboardcount(token) {
  // api for get requiremetInfo details
  const response = fetch(SUPERADMINDASHBOARDCOUNT, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
export { dashboardcount };
