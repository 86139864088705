import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewMore } from "../function/common/viewmore";
import { clientRequirementDetils } from "../api/client/clientrequirementDetails";
import { recDashboardRequirment } from "../api/recruiter/client/dashboardRequirement";
import { freeDashboardRequirment } from "../api/freelance/client/dashboardRequirement";

const MatchingjobCard = () => {
  // return group of matching job table data which generated by data variable
  const [match, setMatch] = useState([]);
  const [record, setRecord] = useState();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isrecruiterCandidate
          ? await recDashboardRequirment(token, 1)
          : isFreelance
          ? await freeDashboardRequirment(token, 1)
          : await clientRequirementDetils(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.slice(0, 3);
          const totalRecord = payload[payload.length - 1].totalRecord;
          if (totalRecord !== 0) {
            setMatch(payload);
          }
          setRecord(totalRecord);
        }
      } catch {}
    }
    callUserDetails();
  }, []);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });

  return (
    <>
      {record !== 0 ? (
        <div className={"row col-md-12"}>
          <div className={"col-md-12 pt-2 pl-3"}>
            <label
              style={{ fontWeight: "500", fontSize: "16px", color: "#000000" }}
            >
              Active Jobs
            </label>
          </div>
        </div>
      ) : (
        <div className={"row offset-sm-5 pt-5"}>
          <div className={"col-md-12 pt-2 pl-3"}>
            <label
              style={{ fontWeight: "500", fontSize: "16px", color: "#000000" }}
            >
              No Active Jobs
            </label>
          </div>
        </div>
      )}
      {match.map((match) => (
        <div className={"col-lg-4 pt-2"} key={match.uuid}>
          {match.uuid ? (
            <div
              className={"pt-3 pb-1 lastrowcontent"}
              style={{
                background: "#FFFFFF",
                boxShadow: "0px 12px 23px rgba(0, 0, 0, 0.1)",
                borderRadius: "20px",
                fontFamily: "poppins",
              }}
            >
              <div className={"row mx-3"}>
                <div className={"col-md-9 mx-2"}>
                  <Link
                    to={`/client/jobapply/${match.uuid}`}
                    className={"matchingjobdata text-primary"}
                  >
                    {match.jobTitle && match.jobTitle.toUpperCase()}
                  </Link>
                  <br />
                  <label style={{ fontWeight: "500", fontSize: "12px" }}>
                    {new Date(match.createdAt).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </label>
                </div>
              </div>
              <hr style={{ marginTop: "0" }} />
              <div className={"row mx-3"}>
                <div className={"col-md-2"}>
                  <img
                    src={"/icon/common/currency.png"}
                    alt={"currency"}
                    className={"img-fluid"}
                  />
                </div>
                <div className={"col-md my-1"}>
                  <label
                    className={"mr-4"}
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#2E2E2E",
                    }}
                  >
                    ${match.rate}
                  </label>
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "14px",
                      color: "#C2C2C2",
                    }}
                    className={"ml-5"}
                  >
                    /{match.rateType && match.rateType.toLowerCase()}
                  </label>
                </div>
              </div>
              <div className={"row mx-3 my-1"}>
                <div className={"col-md-2"}>
                  <img
                    src={"/icon/common/map.png"}
                    alt={"map"}
                    className={"img-fluid"}
                  />
                </div>
                <div className={"col-md my-1"}>
                  <label
                    className={"mr-5"}
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#2E2E2E",
                    }}
                  >
                    {match.location &&
                      match.location.charAt(0).toUpperCase() +
                        match.location.slice(1).toLowerCase()}
                  </label>
                </div>
              </div>
              <div className={"row mx-3 my-1"}>
                <div className={"col-md-2"}>
                  <img
                    src={"/icon/common/suitcase 1.png"}
                    alt={"Time"}
                    className={"img-fluid"}
                  />
                </div>
                <div className={"col-md my-1"}>
                  <label
                    className={"mr-5"}
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#2E2E2E",
                    }}
                  >
                    {match.employementType}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
      {record !== 0 ? (
        <div className={"row pt-4 pb-0 col-md-12"}>
          <div className={"col-md"}>
            <Link
              to={"/client/requirements"}
              className={"btn float-right dashboardviewmorelink"}
              onClick={() => viewMore(dispatch)}
            >
              view more
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default MatchingjobCard;
