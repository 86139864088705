import { GETRECRUITERBUILDREQUIREMENT } from "../../../ENVIRONMENT";

// api for submit recruiter requirement information
async function recgetRequirementInfo(token, id) {
  const response = await fetch(GETRECRUITERBUILDREQUIREMENT + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { recgetRequirementInfo };
