import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dashboardJobCount } from "../api/client/dashboardJobCount";
import { freedashboardJobCount } from "../api/freelance/client/dashboardJobCount";
import { recdashboardJobCount } from "../api/recruiter/client/dashboardJobCount";
import { viewMore } from "../function/common/viewmore";

const ClientDashcard = () => {
  // return dashboard card group which generated by data variable
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const dispatch = useDispatch();
  const [count, setCount] = useState({
    totalRequirements: "",
    interviewSchedule: "",
    activeJobs: "",
    shortlistedCandidates: "",
    waitingCandidates: "",
    offerRoleout: "",
  });

  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isrecruiterCandidate
          ? await recdashboardJobCount(token)
          : isFreelance
          ? await freedashboardJobCount(token)
          : await dashboardJobCount(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setCount({
            ...count,
            totalRequirements: payload.totalRequirements,
            interviewSchedule: payload.interviewSchedule,
            activeJobs: payload.activeJobs,
            shortlistedCandidates: payload.shortlistedCandidates,
            waitingCandidates: payload.waitingCandidates,
            offerRoleout: payload.offerRoleout,
          });
        }
      } catch {}
    }
    callUserDetails();
  }, []);

  const data = [
    {
      count: count.totalRequirements,
      countColor: "#2BC155",
      progressColor: "#2BC155",
      icon: "../../icon/common/suitcase.png",
      iconColor: "#E9FFEF",
      message: "Total Requirements",
    },
    {
      count: count.interviewSchedule,
      countColor: "#3F9AE0",
      progressColor: "#3F9AE0",
      icon: "../../icon/common/calendar.png",
      iconColor: "#E0F2FF",
      message: "Interviews Scheduled",
    },
    {
      count: count.activeJobs,
      countColor: "#FF9B52",
      progressColor: "#FF9B52",
      icon: "../../icon/common/person.png",
      iconColor: "#FFF0EB",
      message: "Active Jobs",
    },
    {
      count: count.shortlistedCandidates,
      countColor: "#8743DF",
      progressColor: "#8743DF",
      icon: "../../icon/common/suitcase1.png",
      iconColor: "rgba(135, 67, 223, 0.37)",
      message: "Shortlisted candidates",
    },
    {
      count: count.waitingCandidates,
      countColor: "#3F9AE0",
      progressColor: "#3F9AE0",
      icon: "../../icon/common/calendar.png",
      iconColor: "#E0F2FF",
      message: "Waiting Candidates",
    },
    {
      count: count.offerRoleout,
      countColor: "#FF9B52",
      progressColor: "#FF9B52",
      icon: "../../icon/common/person.png",
      iconColor: "#FFF0EB",
      message: "Offer Roleout",
    },
  ];

  return data.map((data, index) => (
    <div key={index} className={"col-lg-4 pt-4"}>
      {data.message === "Interviews Scheduled" ? (
        <Link
          to={`/client/requirements?title=${data.message
            .split(" ")[1]
            .toLowerCase()}`}
          onClick={() => viewMore(dispatch)}
        >
          <div
            className={"pt-3 pb-0 viewcontent"}
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              borderRadius: "20px",
              fontFamily: "poppins",
            }}
          >
            <div className={"row mx-3"}>
              <div className={"col-lg"}>
                <label
                  style={{
                    fontWeight: "600",
                    fontSize: "50px",
                    color: data.countColor,
                  }}
                >
                  {data.count}
                </label>
                <br />
              </div>
              <div className={"col-lg my-4"}>
                <div className={"pl-5"}>
                  <span
                    className={"pt-3 pb-3 pl-2 pr-2"}
                    style={{ background: data.iconColor, borderRadius: "15px" }}
                  >
                    <img src={data.icon} alt={"case"} className={"img-fluid"} />
                  </span>
                </div>
              </div>
            </div>
            <div className={"row mx-3"}>
              <div className={"col-lg pb-1"}>
                <Link
                  to={`/client/requirements?title=${data.message
                    .split(" ")[1]
                    .toLowerCase()}`}
                  onClick={() => viewMore(dispatch)}
                >
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                      color: "rgba(15, 15, 15, 0.7)",
                    }}
                  >
                    {data.message}
                  </label>
                </Link>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-lg"}>
                <div
                  style={{
                    background: data.progressColor,
                    borderRadius: "20px",
                    width: "90%",
                    height: "4px",
                    marginLeft: "5%",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <Link
          to={`/client/requirements?title=${data.message
            .split(" ")[0]
            .toLowerCase()}`}
          onClick={() => viewMore(dispatch)}
        >
          <div
            className={"pt-3 pb-0 viewcontent"}
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              borderRadius: "20px",
              fontFamily: "poppins",
            }}
          >
            <div className={"row mx-3"}>
              <div className={"col-lg"}>
                <label
                  style={{
                    fontWeight: "600",
                    fontSize: "50px",
                    color: data.countColor,
                  }}
                >
                  {data.count}
                </label>
                <br />
              </div>
              <div className={"col-lg my-4"}>
                <div className={"pl-5"}>
                  <span
                    className={"pt-3 pb-3 pl-2 pr-2"}
                    style={{ background: data.iconColor, borderRadius: "15px" }}
                  >
                    <img src={data.icon} alt={"case"} className={"img-fluid"} />
                  </span>
                </div>
              </div>
            </div>
            <div className={"row mx-3"}>
              <div className={"col-lg pb-1"}>
                <Link
                  to={`/client/requirements?title=${data.message
                    .split(" ")[0]
                    .toLowerCase()}`}
                  onClick={() => viewMore(dispatch)}
                >
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                      color: "rgba(15, 15, 15, 0.7)",
                    }}
                  >
                    {data.message}
                  </label>
                </Link>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-lg"}>
                <div
                  style={{
                    background: data.progressColor,
                    borderRadius: "20px",
                    width: "90%",
                    height: "4px",
                    marginLeft: "5%",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </div>
  ));
};
export default ClientDashcard;
