import { RECRUITERCANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for recruiter matching job
async function recgetMatchingJob(token, id) {
  let url = RECRUITERCANDIDATEMATCHINGJOBS;
  if (id) {
    url = url + "/" + id;
  }
  return await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { recgetMatchingJob };
