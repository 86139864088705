import { FREELANCECANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for freelance search
async function freeSearch(token, data, page) {
  const response = await fetch(
    FREELANCECANDIDATEMATCHINGJOBS + `?q=${data}&limit=6&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function freeSearchByCard(token, data, page,card) {
  const response = await fetch(
    FREELANCECANDIDATEMATCHINGJOBS + `?q=${data}&limit=6&page=${page}&card=${card}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freeSearch,freeSearchByCard };
