import { CLIENTBUILDREQUIREMENT } from "../ENVIRONMENT";

// api for submit buildresume requirement skill
async function postBuildRequirementSkill(token, data, id) {
  return await fetch(CLIENTBUILDREQUIREMENT + `/${id}/skill`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { postBuildRequirementSkill };
