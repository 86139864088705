import "../../styles/app.scss";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoToTop from "../common/GoToTop";
import { MatchingJobSelectAllData } from "../../function/common/MatchingJobSelectAllData";
import Filter from "../../common-component/clientFilter";
import ReactPaginate from "react-paginate";
import {
  applicationSorting,
  filterApplicationSorting,
  filterStatusSorting,
  otherApplicationSorting,
} from "../../api/client/applicationSorting";
import {
  filterScheduledCandidates,
  filterStatusDropList,
} from "../../api/client/filterApplication";
import {
  locationList,
  locationListSearch,
} from "../../api/common/locationList";
import { primaryList } from "../../api/client/primaryList";
import { secondaryList } from "../../api/client/secondaryList";
import { domainList } from "../../api/client/domainList";
import {
  applicationFilter,
  singleRequirementFilter,
  singleRequirementStatusFilter,
  statusFilter,
} from "../../api/client/applicationFilter";
import { academicList } from "../../api/client/academicList";
import { certificateList } from "../../api/client/certificateList";
import { useHistory } from "react-router-dom";
import {
  recfilterScheduledCandidates,
  recfilterStatusDropList,
} from "../../api/recruiter/client/filterApplication";
import {
  freefilterScheduledCandidates,
  freefilterStatusDropList,
} from "../../api/freelance/client/filterApplication";
import {
  recapplicationFilter,
  recsingleRequirementFilter,
  recsingleRequirementStatusFilter,
  recstatusFilter,
} from "../../api/recruiter/client/applicationFilter";
import {
  freeapplicationFilter,
  freesingleRequirementFilter,
  freesingleRequirementStatusFilter,
  freestatusFilter,
} from "../../api/freelance/client/applicationFilter";
import {
  recapplicationSorting,
  recfilterApplicationSorting,
  recfilterStatusSorting,
  recotherApplicationSorting,
} from "../../api/recruiter/client/applicationSorting";
import {
  freeapplicationSorting,
  freefilterApplicationSorting,
  freefilterStatusSorting,
  freeotherApplicationSorting,
} from "../../api/freelance/client/applicationSorting";
import { recprimaryList } from "../../api/recruiter/client/primaryList";
import { freeprimaryList } from "../../api/freelance/client/primaryList";
import { recdomainList } from "../../api/recruiter/client/domainList";
import { freedomainList } from "../../api/freelance/client/domainList";
import { recacademicList } from "../../api/recruiter/client/academicList";
import { freeacademicList } from "../../api/freelance/client/academicList";
import { reccertificateList } from "../../api/recruiter/client/certificateList";
import { freecertificateList } from "../../api/freelance/client/certificateList";
import { recsecondaryList } from "../../api/recruiter/client/secondaryList";
import { freesecondaryList } from "../../api/freelance/client/secondaryList";
import { Data } from "../../function/client/applicationData";
import { shortlistJobs } from "../../api/client/shortlistJobs";
import { recshortlistJobs } from "../../api/recruiter/client/shortlistJobs";
import { freeshortlistJobs } from "../../api/freelance/client/shortlistJobs";
import { rejectApplication } from "../../api/client/rejectApplication";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { recrejectApplication } from "../../api/recruiter/client/rejectApplication";
import { freerejectApplication } from "../../api/freelance/client/rejectApplication";

const Applications = () => {
  // return application page
  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let title = query.get("id");
  let cardName = query.get("card");
  try {
    if(cardName === "scheduled"){
      document.getElementById("replacetitleimg").innerText = "Interview Scheduled";
    }else if(cardName === "shortlisted"){
      document.getElementById("replacetitleimg").innerText = "Shortlisted Candidates";
    }else{
      document.getElementById("replacetitleimg").innerText = "Applications";
    }
    document.getElementById("dashboardfont").innerText = "Job Posting";
    document.getElementById("dashboardbtn").style.display = "none";
  } catch {}
  
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const [item, setItem] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [matchOrder, setMatchOrder] = useState(true);
  const [idOrder, setIdOrder] = useState(true);
  const [expOrder, setExpOrder] = useState(true);
  const [rateOrder, setRateOrder] = useState(true);
  const [totalLength, setTotalLength] = useState();
  const [loading, setLoading] = useState();
  const [statusValue,setStatusValue] = useState();
  const history = useHistory();
  let blankData = [];
    let location = "location";
    let primarySkill = "primarySkill";
    let secondarySkill = "secondarySkill";
    let workAuth = "workAuthorization";
    let security = "securityClearanceCategory";
    let employment = "employementType";
    let domain = "domain";
    let certificate = "certificate";
    let education = "academicsQualification";
    let USADegree = "clearanceRequired";
    let rateType = "rateType";

  //pagination
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPage(data.selected);
    setLoading(true);
    if (title && !cardName) {
      const response = isrecruiterCandidate
        ? await recsingleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            title
          )
        : isFreelance
        ? await freesingleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            title
          )
        : await singleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            title
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setLoading(false);
      }
    } else if (!title && cardName) {
      const response = isrecruiterCandidate
        ? await recstatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            cardName
          )
        : isFreelance
        ? await freestatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            cardName
          )
        : await statusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            cardName
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setLoading(false);
      }
    } else if (title && cardName !== null) {
      const response = isrecruiterCandidate
        ? await recsingleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            title,
            cardName
          )
        : isFreelance
        ? await freesingleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            title,
            cardName
          )
        : await singleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            title,
            cardName
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setLoading(false);
      }
    } else {
      const response = isrecruiterCandidate
        ? await recapplicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage
          )
        : isFreelance
        ? await freeapplicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage
          )
        : await applicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage
          );
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setLoading(false);
      }
    }
  };

  //for id sorting
  const handleIdSorting = async () => {
    let rate = "candidateId";
    let high = "high";
    let low = "low";
    setPage(0);
    setLoading(true);
    if (title && !cardName) {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recfilterApplicationSorting(token, title, 1, rate, high)
          : isFreelance
          ? await freefilterApplicationSorting(token, title, 1, rate, high)
          : await filterApplicationSorting(token, title, 1, rate, high);

        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterApplicationSorting(token, title, 1, rate, low)
          : isFreelance
          ? await freefilterApplicationSorting(token, title, 1, rate, low)
          : await filterApplicationSorting(token, title, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else if (!title && cardName) {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recfilterStatusSorting(token, cardName, 1, rate, high)
          : isFreelance
          ? await freefilterStatusSorting(token, cardName, 1, rate, high)
          : await filterStatusSorting(token, cardName, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterStatusSorting(token, cardName, 1, rate, low)
          : isFreelance
          ? await freefilterStatusSorting(token, cardName, 1, rate, low)
          : await filterStatusSorting(token, cardName, 1, rate, low);

        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else if (title && cardName !== null) {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            )
          : isFreelance
          ? await freeotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            )
          : await otherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            );
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              low
            )
          : isFreelance
          ? await freeotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              low
            )
          : await otherApplicationSorting(token, title, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recapplicationSorting(token, 1, rate, high)
          : isFreelance
          ? await freeapplicationSorting(token, 1, rate, high)
          : await applicationSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recapplicationSorting(token, 1, rate, low)
          : isFreelance
          ? await freeapplicationSorting(token, 1, rate, low)
          : await applicationSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    }
  };
  //for rate sorting
  const handleRateSorting = async () => {
    let rate = "rate";
    let high = "high";
    let low = "low";
    setPage(0);
    setLoading(true);
    if (title && !cardName) {
      if (rateOrder) {
        const response = isrecruiterCandidate
          ? await recfilterApplicationSorting(token, title, 1, rate, high)
          : isFreelance
          ? await freefilterApplicationSorting(token, title, 1, rate, high)
          : await filterApplicationSorting(token, title, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterApplicationSorting(token, title, 1, rate, low)
          : isFreelance
          ? await freefilterApplicationSorting(token, title, 1, rate, low)
          : await filterApplicationSorting(token, title, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else if (!title && cardName) {
      if (rateOrder) {
        const response = isrecruiterCandidate
          ? await recfilterStatusSorting(token, cardName, 1, rate, high)
          : isFreelance
          ? await freefilterStatusSorting(token, cardName, 1, rate, high)
          : await filterStatusSorting(token, cardName, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterStatusSorting(token, cardName, 1, rate, low)
          : isFreelance
          ? await freefilterStatusSorting(token, cardName, 1, rate, low)
          : await filterStatusSorting(token, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else if (title && cardName !== null) {
      if (rateOrder) {
        const response = isrecruiterCandidate
          ? await recotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            )
          : isFreelance
          ? await freeotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            )
          : await otherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            );
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              low
            )
          : isFreelance
          ? await freeotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              low
            )
          : await otherApplicationSorting(token, title, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else {
      if (rateOrder) {
        const response = isrecruiterCandidate
          ? await recapplicationSorting(token, 1, rate, high)
          : isFreelance
          ? await freeapplicationSorting(token, 1, rate, high)
          : await applicationSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recapplicationSorting(token, 1, rate, low)
          : isFreelance
          ? await freeapplicationSorting(token, 1, rate, low)
          : await applicationSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    }
  };
  //for experience sorting
  const handleExpSorting = async () => {
    let rate = "experience";
    let high = "high";
    let low = "low";
    setPage(0);
    if (title && !cardName) {
      if (expOrder) {
        const response = isrecruiterCandidate
          ? await recfilterApplicationSorting(token, title, 1, rate, high)
          : isFreelance
          ? await freefilterApplicationSorting(token, title, 1, rate, high)
          : await filterApplicationSorting(token, title, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterApplicationSorting(token, title, 1, rate, low)
          : isFreelance
          ? await freefilterApplicationSorting(token, title, 1, rate, low)
          : await filterApplicationSorting(token, title, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else if (!title && cardName) {
      if (expOrder) {
        const response = isrecruiterCandidate
          ? await recfilterStatusSorting(token, cardName, 1, rate, high)
          : isFreelance
          ? await freefilterStatusSorting(token, cardName, 1, rate, high)
          : await filterStatusSorting(token, cardName, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterStatusSorting(token, cardName, 1, rate, low)
          : isFreelance
          ? await freefilterStatusSorting(token, cardName, 1, rate, low)
          : await filterStatusSorting(token, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else if (title && cardName !== null) {
      if (expOrder) {
        const response = isrecruiterCandidate
          ? await recotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            )
          : isFreelance
          ? await freeotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            )
          : await otherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            );
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              low
            )
          : isFreelance
          ? await freeotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              low
            )
          : await otherApplicationSorting(token, title, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else {
      if (expOrder) {
        const response = isrecruiterCandidate
          ? await recapplicationSorting(token, 1, rate, high)
          : isFreelance
          ? await freeapplicationSorting(token, 1, rate, high)
          : await applicationSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recapplicationSorting(token, 1, rate, low)
          : isFreelance
          ? await freeapplicationSorting(token, 1, rate, low)
          : await applicationSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    }
  };
  //for matching sorting
  const handleMatchSorting = async () => {
    let rate = "matching";
    let high = "high";
    let low = "low";
    setPage(0);
    if (title && !cardName) {
      if (matchOrder) {
        const response = isrecruiterCandidate
          ? await recfilterApplicationSorting(token, title, 1, rate, high)
          : isFreelance
          ? await freefilterApplicationSorting(token, title, 1, rate, high)
          : await filterApplicationSorting(token, title, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterApplicationSorting(token, title, 1, rate, low)
          : isFreelance
          ? await freefilterApplicationSorting(token, title, 1, rate, low)
          : await filterApplicationSorting(token, title, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else if (!title && cardName) {
      if (matchOrder) {
        const response = isrecruiterCandidate
          ? await recfilterStatusSorting(token, cardName, 1, rate, high)
          : isFreelance
          ? await freefilterStatusSorting(token, cardName, 1, rate, high)
          : await filterStatusSorting(token, cardName, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterStatusSorting(token, cardName, 1, rate, low)
          : isFreelance
          ? await freefilterStatusSorting(token, cardName, 1, rate, low)
          : await filterStatusSorting(token, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else if (title && cardName !== null) {
      if (matchOrder) {
        const response = isrecruiterCandidate
          ? await recotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            )
          : isFreelance
          ? await freeotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            )
          : await otherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              high
            );
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              low
            )
          : isFreelance
          ? await freeotherApplicationSorting(
              token,
              title,
              cardName,
              1,
              rate,
              low
            )
          : await otherApplicationSorting(token, title, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else {
      if (matchOrder) {
        const response = isrecruiterCandidate
          ? await recapplicationSorting(token, 1, rate, high)
          : isFreelance
          ? await freeapplicationSorting(token, 1, rate, high)
          : await applicationSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recapplicationSorting(token, 1, rate, low)
          : isFreelance
          ? await freeapplicationSorting(token, 1, rate, low)
          : await applicationSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    }
  };

  //status dropdown filter
  const handleStatusFilter = async (event) => {
    try {
      setLoading(true);
      let cardName = event.target.value;
      setStatusValue(cardName)
      if (title) {
        const response = isrecruiterCandidate
          ? await recfilterScheduledCandidates(token, title, cardName, 1)
          : isFreelance
          ? await freefilterScheduledCandidates(token, title, cardName, 1)
          : await filterScheduledCandidates(token, title, cardName, 1);
        if (response.status === 200) {
          history.push(
            `/client/filteredapplications?id=${title}&card=${cardName}`
          );
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setTotalLength(totalRecord);
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recfilterStatusDropList(token, cardName, 1)
          : isFreelance
          ? await freefilterStatusDropList(token, cardName, 1)
          : await filterStatusDropList(token, cardName, 1);
        if (response.status === 200) {
          history.push(`/client/applications?card=${cardName}`);
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setTotalLength(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
        }
      }
    } catch {}
  };

  // for modal
  const filterToggleHide = () => {
    document.getElementById("sidebaricon").style.pointerEvents = "none";
    document.getElementsByClassName(
      "filtertogglebutton"
    )[0].style.pointerEvents = "none";
    const sidebar = document.getElementsByClassName("sidebar")[0];
    sidebar.style.cssText = "min-width:280px";
    const companylogo = document.getElementsByClassName("companylogo")[0];
    companylogo.style.cssText = "position:relative;right:50px";
    const container = document.getElementsByClassName("stick")[0];
    container.style.cssText =
      "margin-left:260px;background-color:#F9F9F9;border-radius:38px 0px 0px 0px";
  };

  //location list
  const [filterlocation, setLocation] = useState([]);
  const [outsideLocation,setOutsideLocation] = useState([]);
  //primary skill list
  const [skill, setSkill] = useState([]);
  //seconday
  const [filtersecondary, setSecondary] = useState([]);
  //domain
  const [filterdomain, setDomain] = useState([]);
  //education
  const [filtereducation, setEducation] = useState([]);
  //certificate
  const [filtercertificate, setCertificate] = useState([]);
  
    useEffect(() => {
    function callLocationDetails() {
      try {
        setLoading(true);
        locationList(token)
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setOutsideLocation(payload);
            setLocation(payload);
            setLoading(false);
          });
      } catch {}
      try {
        setLoading(true);
        const primRes = isrecruiterCandidate
          ? recprimaryList(token)
          : isFreelance
          ? freeprimaryList(token)
          : primaryList(token);
        primRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setSkill(payload)
          });
      } catch {}
      try {
        setLoading(false);
        const secRes = isrecruiterCandidate
          ? recsecondaryList(token)
          : isFreelance
          ? freesecondaryList(token)
          : secondaryList(token);
        secRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            if (payload.length !== 0) {
              setSecondary(payload);
            }
          });
      } catch {}
      try {
        setLoading(false);
        const domainRes = isrecruiterCandidate
          ? recdomainList(token)
          : isFreelance
          ? freedomainList(token)
          : domainList(token);
        domainRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setDomain(payload);
          });
      } catch {}
      try {
        setLoading(false);
        const academicRes = isrecruiterCandidate
          ? recacademicList(token)
          : isFreelance
          ? freeacademicList(token)
          : academicList(token);
        academicRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            if (payload.length !== 0) {
              setEducation(payload);
            }
          });
      } catch {}
      try {
        setLoading(false);
        const certificateRes = isrecruiterCandidate
          ? reccertificateList(token)
          : isFreelance
          ? freecertificateList(token)
          : certificateList(token);
        certificateRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            try {
              const payload = data.payload;
              if (payload.length !== 0) {
              setCertificate(payload);
              }
            } catch {}
          });
      } catch {}
    }
    callLocationDetails();
  }, []);

  const [record, setRecord] = useState();
  const [checked, setChecked] = useState([]);
  //filter location
  const handleLocationFilter = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter primary skill
  const [checkedPrimary, setCheckedPrimary] = useState([]);

  const handlePrimarySkillFilter = (event) => {
    var updatedList = [...checkedPrimary];
    if (event.target.checked) {
      updatedList = [...checkedPrimary, event.target.value];
    } else {
      updatedList.splice(checkedPrimary.indexOf(event.target.value), 1);
    }
    setCheckedPrimary(updatedList);
  };
  const checkedPrimaryItems = checkedPrimary.length
    ? checkedPrimary.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter secondary skill
  const [checkedSecondary, setCheckedSecondary] = useState([]);

  const handleSecondarySkillFilter = (event) => {
    var updatedList = [...checkedSecondary];
    if (event.target.checked) {
      updatedList = [...checkedSecondary, event.target.value];
    } else {
      updatedList.splice(checkedSecondary.indexOf(event.target.value), 1);
    }
    setCheckedSecondary(updatedList);
  };
  const checkedSecondaryItems = checkedSecondary.length
    ? checkedSecondary.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter workAuthorization
  const [checkedAuth, setCheckedAuth] = useState([]);

  const handleWorkAuthFilter = (event) => {
    var updatedList = [...checkedAuth];
    if (event.target.checked) {
      updatedList = [...checkedAuth, event.target.value];
    } else {
      updatedList.splice(checkedAuth.indexOf(event.target.value), 1);
    }
    setCheckedAuth(updatedList);
  };
  const checkedAuthItems = checkedAuth.length
    ? checkedAuth.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter employmentType
  const [checkedEmploy, setCheckedEmploy] = useState([]);

  const handleEmploymentFilter = (event) => {
    var updatedList = [...checkedEmploy];
    if (event.target.checked) {
      updatedList = [...checkedEmploy, event.target.value];
    } else {
      updatedList.splice(checkedEmploy.indexOf(event.target.value), 1);
    }
    setCheckedEmploy(updatedList);
  };
  const checkedEmployItems = checkedEmploy.length
    ? checkedEmploy.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter securityCategory
  const [checkedSecurity, setCheckedSecurity] = useState([]);

  const handleSecurityFilter = (event) => {
    var updatedList = [...checkedSecurity];
    if (event.target.checked) {
      updatedList = [...checkedSecurity, event.target.value];
    } else {
      updatedList.splice(checkedSecurity.indexOf(event.target.value), 1);
    }
    setCheckedSecurity(updatedList);
  };
  const checkedSecurityItems = checkedSecurity.length
    ? checkedSecurity.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter USADegree

  const [checkedDegree, setCheckedDegree] = useState("");

  const handleUSDegreeFilter = async (event) => {
    setCheckedDegree(event.target.value);
  };
  //filterRatetype
  const [checkedRate, setCheckedRate] = useState("");

  const handleRateFilter = async (event) => {
    setCheckedRate(event.target.value);
  };

  //filter domain
  const [checkedDomain, setCheckedDomain] = useState([]);

  const handleDomainFilter = (event) => {
    var updatedList = [...checkedDomain];
    if (event.target.checked) {
      updatedList = [...checkedDomain, event.target.value];
    } else {
      updatedList.splice(checkedDomain.indexOf(event.target.value), 1);
    }
    setCheckedDomain(updatedList);
  };
  const checkedDomainItems = checkedDomain.length
    ? checkedDomain.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter certificates
  const [checkedCertificate, setCheckedCertificate] = useState([]);

  const handleCertificateFilter = (event) => {
    var updatedList = [...checkedCertificate];
    if (event.target.checked) {
      updatedList = [...checkedCertificate, event.target.value];
    } else {
      updatedList.splice(checkedCertificate.indexOf(event.target.value), 1);
    }
    setCheckedCertificate(updatedList);
  };
  const checkedCertificateItems = checkedCertificate.length
    ? checkedCertificate.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  //filter education
  const [checkedEducation, setCheckedEducation] = useState([]);

  const handleEducationFilter = (event) => {
    var updatedList = [...checkedEducation];
    if (event.target.checked) {
      updatedList = [...checkedEducation, event.target.value];
    } else {
      updatedList.splice(checkedEducation.indexOf(event.target.value), 1);
    }
    setCheckedEducation(updatedList);
  };
  const checkedEducationItems = checkedEducation.length
    ? checkedEducation.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

//all filters
  useEffect(() => {
    async function callItSelf(){        
    setLoading(true);
    if (title && !cardName) {
      const response = isrecruiterCandidate
        ? await recsingleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            title
          )
        : isFreelance
        ? await freesingleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            title
          )
        : await singleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            title
          );
      try {
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setTotalLength(totalRecord);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
        }
      } catch {}
    } else if (!title && cardName) {
      setStatusValue(cardName)
      const response = isrecruiterCandidate
        ? await recstatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            cardName
          )
        : isFreelance
        ? await freestatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            cardName
          )
        : await statusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            cardName
          );
      try {
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setTotalLength(totalRecord);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
        }
      } catch {}
    } else if (title && cardName !== null) {
      setStatusValue(cardName)
      const response = isrecruiterCandidate
        ? await recsingleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            title,
            cardName
          )
        : isFreelance
        ? await freesingleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            title,
            cardName
          )
        : await singleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1,
            title,
            cardName
          );
      try {
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setTotalLength(totalRecord);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
        }
      } catch {}
    } else {
      const response = isrecruiterCandidate
        ? await recapplicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1
          )
        : isFreelance
        ? await freeapplicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1
          )
        : await applicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY")
              ? blankData
              : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            1
          );
      try {
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setTotalLength(totalRecord);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
        }
      } catch {}
    }
    }
    callItSelf()
  }, [
    checked,
    checkedPrimary,
    checkedSecondary,
    checkedAuth,
    checkedSecurity,
    checkedEmploy,
    checkedDomain,
    checkedCertificate,
    checkedEducation,
    checkedRate,
    checkedDegree,
  ]);

  //shortlist
  const [listId, setListId] = useState([]);
  const [matchingselcetallId, setMatchingSelectallId] = useState([]);
  const [shortlistValue, setShortlistValue] = useState([]);
  const [allshortlistValues, setAllShortlistValue] = useState([]);
  var updated = [...matchingselcetallId];
  var updateAllShortlistData = [...allshortlistValues];

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      item.slice(0,6).map((data) => updated.push(data.uuid));
      item
        .slice(0,6)
        .map((data) => updateAllShortlistData.push(data.clientShortlisted));
    } else {
      updated.splice(0,6);
      updateAllShortlistData.splice(0,6);
    }
    setMatchingSelectallId(updated);
    setAllShortlistValue(updateAllShortlistData);
  };

  function handleShortlist(event,shortlistData) {
    var updatedList = [...listId];
    var updateShortlistData = [...shortlistValue];
    if (event.target.checked) {
      updatedList = [...listId, event.target.value];
      updateShortlistData = [...shortlistValue, shortlistData];
    } else {
      updatedList.splice(listId.indexOf(event.target.value), 1);
      updateShortlistData.splice(shortlistValue.indexOf(shortlistData), 1);
      updated.splice(matchingselcetallId.indexOf(event.target.value), 1);
      updateAllShortlistData.splice(
        allshortlistValues.indexOf(shortlistData),
        1
      );
    }
    setListId(updatedList);
    setShortlistValue(updateShortlistData);
    setMatchingSelectallId(updated);
    setAllShortlistValue(updateAllShortlistData);
  }

  const commonForAllShortlist = async() =>{
    if (title && !cardName) {
      const response = isrecruiterCandidate
        ? await recsingleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            title
          )
        : isFreelance
        ? await freesingleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            title
          )
        : await singleRequirementFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            title
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setTotalLength(totalRecord);
      }
    } else if (!title && cardName) {
      const response = isrecruiterCandidate
        ? await recstatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            cardName
          )
        : isFreelance
        ? await freestatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            cardName
          )
        : await statusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            cardName
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setTotalLength(totalRecord);
      }
    } else if (title && cardName !== null) {
      const response = isrecruiterCandidate
        ? await recsingleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            title,
            cardName
          )
        : isFreelance
        ? await freesingleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            title,
            cardName
          )
        : await singleRequirementStatusFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1,
            title,
            cardName
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setTotalLength(totalRecord);
      }
    } else {
      const response = isrecruiterCandidate
        ? await recapplicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1
          )
        : isFreelance
        ? await freeapplicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1
          )
        : await applicationFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page+1
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setTotalLength(totalRecord);
      }
    }
  }

  const ShortlistJobs = async () => {
    setLoading(true);
    if (listId.length !== 0) {
      var data = {
        uuid: [listId],
      };
    } else if (matchingselcetallId.length !== 0) {
      var data = {
        uuid: [matchingselcetallId],
      };
    }
    let booleanValueForShortlist = "";
    if (shortlistValue.includes(false)) {
      booleanValueForShortlist = true;
    } else if (allshortlistValues.includes(false)) {
      booleanValueForShortlist = true;
    } else {
      booleanValueForShortlist = false;
    }
    const response = isrecruiterCandidate
      ? await recshortlistJobs(token,data,booleanValueForShortlist)
      : isFreelance
      ? await freeshortlistJobs(token,data,booleanValueForShortlist)
      : await shortlistJobs(token,data,booleanValueForShortlist);
    if (response.status === 200) {
      commonForAllShortlist();
      setListId([]);
      setShortlistValue([]);
      setAllShortlistValue([]);
      setMatchingSelectallId([]);
      setTimeout(() => { 
        for (let obj of document.getElementsByClassName("hidebox")) {
          obj.style.cssText = "display:inline";
        }
        for (let obj of document.getElementsByClassName("showbox")) {
          obj.style.cssText = "display:none";
        }
        const chkbtnobj = document.getElementsByClassName("checkbx");
        for (let obj of chkbtnobj) {
          obj.checked = false;
        }
        for (let obj of document.getElementsByClassName("box")) {
          obj.style.cssText = "background-color:white";
        }
      }, 1800)
      
    }
  };

  async function handleaddSingleShortlist(id) {
    var data = {
      uuid: [id],
    };
    const response = isrecruiterCandidate
      ? await recshortlistJobs(token,data,true)
      : isFreelance
      ? await freeshortlistJobs(token,data,true)
      : await shortlistJobs(token,data,true);
    if (response.status === 200) {
     commonForAllShortlist();
    }
  }

  async function handleremoveSingleShortlist(id) {
    var data = {
      uuid: [id],
    };
    const response = isrecruiterCandidate
    ? await recshortlistJobs(token,data,false)
    : isFreelance
    ? await freeshortlistJobs(token,data,false)
    : await shortlistJobs(token,data,false);
    if (response.status === 200) {
      commonForAllShortlist();
    }
  }

const [information, setInformation] = useState({ locationData: "" });

  function handleInformation(event, setInformation) {
    let { name, value } = event.currentTarget;
    setInformation((state) => {
      return { ...state, [name]: value };
    });
  }
  function callMethod(event) {
    handleInformation(event, setInformation);
  }
  async function handleLocationSearch() {
    let data = information.locationData;
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setLocation(payload);
    }
  }

  useEffect(() => {
    handleLocationSearch();
  }, [information.locationData]);
//clear all filter
  const handleClearAll = () => {
    var aa = document.getElementsByTagName("input");
    for (var i = 0; i < aa.length; i++) {
      if (aa[i].type == "checkbox") {
        if (aa[i].checked) {
          setChecked([]);
          setCheckedPrimary([]);
          setCheckedSecondary([]);
          setCheckedAuth([]);
          setCheckedSecurity([]);
          setCheckedEmploy([]);
          setCheckedDomain([]);
          setCheckedCertificate([]);
          setCheckedEducation([]);
          aa[i].checked = false;
        }
      }
      if (aa[i].type == "radio") {
        if (aa[i].checked) {
          setCheckedRate("");
          setCheckedDegree("");
          aa[i].checked = false;
        }
      }
    }
  };
//common for all reject
const commonForAllReject = async() =>{
  setLoading(true);
  if (title && !cardName) {
    const response = isrecruiterCandidate
      ? await recsingleRequirementFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          title
        )
      : isFreelance
      ? await freesingleRequirementFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          title
        )
      : await singleRequirementFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          title
        );
      try{
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      const totalRecord = payload[payload.length - 1].totalRecord;
      setRecord(totalRecord);
      setItem(payload.slice(0, -1));
      setLoading(false);
      setTotalLength(totalRecord);
      setPageCount(Math.ceil(payload[6].totalRecord / 6));
    }
  }catch{}
  } else if (!title && cardName) {
    const response = isrecruiterCandidate
      ? await recstatusFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          cardName
        )
      : isFreelance
      ? await freestatusFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          cardName
        )
      : await statusFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          cardName
        );
    try{
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      const totalRecord = payload[payload.length - 1].totalRecord;
      setRecord(totalRecord);
      setItem(payload.slice(0, -1));
      setLoading(false);
      setTotalLength(totalRecord);
      setPageCount(Math.ceil(payload[6].totalRecord / 6));
    }
    }catch{}
  } else if (title && cardName !== null) {
    const response = isrecruiterCandidate
      ? await recsingleRequirementStatusFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          title,
          cardName
        )
      : isFreelance
      ? await freesingleRequirementStatusFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          title,
          cardName
        )
      : await singleRequirementStatusFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1,
          title,
          cardName
        );
    try{
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      const totalRecord = payload[payload.length - 1].totalRecord;
      setRecord(totalRecord);
      setItem(payload.slice(0, -1));
      setLoading(false);
      setTotalLength(totalRecord);
      setPageCount(Math.ceil(payload[6].totalRecord / 6));
    }
  }catch{}
  } else {
    const response = isrecruiterCandidate
      ? await recapplicationFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1
        )
      : isFreelance
      ? await freeapplicationFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1
        )
      : await applicationFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems.includes("ANY") ? blankData : checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          1
        );
    try{
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      const totalRecord = payload[payload.length - 1].totalRecord;
      setRecord(totalRecord);
      setItem(payload.slice(0, -1));
      setLoading(false);
      setTotalLength(totalRecord);
      setPageCount(Math.ceil(payload[6].totalRecord / 6));
    }
  }catch{}
  }
}

 //single reject
  async function handleSingleReject(id){
    var data = {
      UUID : [id]
    }
    const response = isrecruiterCandidate
    ? await recrejectApplication(token,data)
    : isFreelance
    ? await freerejectApplication(token,data)
    : await rejectApplication(token,data)
    if(response.status === 200){
      commonForAllReject();
      toast.error("Rejected");
    }else{
      toast.error("Some error rises");
    }
}

//reject all applications
const rejectAllApplication = async () => {
  setLoading(true);
  if (listId.length !== 0) {
    var data = {
      UUID: [listId],
    };
  } else if (matchingselcetallId.length !== 0) {
    var data = {
      UUID: [matchingselcetallId],
    };
  }
  const response =isrecruiterCandidate
  ? await recrejectApplication(token,data)
  : isFreelance
  ? await freerejectApplication(token,data)
  : await rejectApplication(token,data)
  if (response.status === 200) {
    setListId([]);
    setShortlistValue([]);
    setAllShortlistValue([]);
    setMatchingSelectallId([]);
    commonForAllReject();
    for (let obj of document.getElementsByClassName("hidebox")) {
      obj.style.cssText = "display:inline";
    }
    for (let obj of document.getElementsByClassName("showbox")) {
      obj.style.cssText = "display:none";
    }
    const chkbtnobj = document.getElementsByClassName("checkbx");
    for (let obj of chkbtnobj) {
      obj.checked = false;
    }
    for (let obj of document.getElementsByClassName("box")) {
      obj.style.cssText = "background-color:white";
    }
    toast.error("Rejected")
  }else{
    setListId([]);
    setShortlistValue([]);
    setAllShortlistValue([]);
    setMatchingSelectallId([]);
    commonForAllReject();
    for (let obj of document.getElementsByClassName("hidebox")) {
      obj.style.cssText = "display:inline";
    }
    for (let obj of document.getElementsByClassName("showbox")) {
      obj.style.cssText = "display:none";
    }
    const chkbtnobj = document.getElementsByClassName("checkbx");
    for (let obj of chkbtnobj) {
      obj.checked = false;
    }
    for (let obj of document.getElementsByClassName("box")) {
      obj.style.cssText = "background-color:white";
    }
    toast.error("Some error arises")
  }
};


  return (
    <div className={"col mx-4"}>
        <ToastContainer position={"top-center"} />
      {/*this is first box*/}
      <div className={"row mt-3"}>
        <div className={"col offset-sm-10"}>
          <button
            className={"filterbtn filtertogglebutton"}
            onClick={filterToggleHide}
            data-toggle={"modal"}
            data-target={"#modalfilter"}
          >
            FILTER
          </button>
        </div>
      </div>
      {/*modal model*/}
      <Filter
        location={filterlocation}
        outsideLocation={outsideLocation}
        checked={checked}
        primary={skill}
        secondary={filtersecondary}
        domain={filterdomain}
        education={filtereducation}
        certificate={filtercertificate}
        locationInformation={information}
        handleInformation={callMethod}
        handleLocationFilter={handleLocationFilter}
        handlePrimarySkillFilter={handlePrimarySkillFilter}
        handleSecondarySkillFilter={handleSecondarySkillFilter}
        handleWorkAuthFilter={handleWorkAuthFilter}
        handleEmploymentFilter={handleEmploymentFilter}
        handleSecurityFilter={handleSecurityFilter}
        handleUSDegreeFilter={handleUSDegreeFilter}
        handleRateFilter={handleRateFilter}
        handleDomainFilter={handleDomainFilter}
        handleEducationFilter={handleEducationFilter}
        handleCertificateFilter={handleCertificateFilter}
        handleClearAll={handleClearAll}
      />
      {/*this is second box of data heading-----changed*/}
      <div
        className={"row mt-3 align-items-center pl-3"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row"}>
            <div className={"col-1 pr-0 ml-3"}>
              <input
                type={"checkbox"}
                className={"checkbx  mr-3"}
                onClick={(e) => {
                  MatchingJobSelectAllData(e);
                  handleSelectAll(e);
                }}
              />
              <font className={"headingfont pl-2 pr-0"}>ID</font>
              <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                    handleIdSorting();
                    setIdOrder(!idOrder);
                }}
              />
            </div>
            <div className={"col-2  hidebox ml-3"}>
              <font className={"headingfont pl-5 ml-4"}>Resume title</font>
            </div>
            <div className={"col-1 pr-0 pl-2 hidebox"}>
              <div className="pl-4">
                <font className={"headingfont pr-0"}>SourcedBy</font>
              </div>
            </div>
            <div className={"col-2  hidebox"}>
              <div className={"pl-5 ml-1"}>
                <font className={"headingfont pr-1"}>Matching</font>
                <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  onClick={() => {
                      handleMatchSorting();
                      setMatchOrder(!matchOrder);
                  }}
                />
              </div>
            </div>
            <div className={"col-2 pl-5 pr-0 ml-3 hidebox"}>
              <div className={"ml-4"}>
                <font className={"headingfont"}>Experience</font>
                <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  onClick={() => {
                      handleExpSorting();
                      setExpOrder(!expOrder);
                  }}
                />
              </div>
            </div>
            <div className={"col-1  hidebox pl-0"}>
              <font className={"headingfont"}>Rate</font>
              <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                    handleRateSorting();
                    setRateOrder(!rateOrder);
                }}
              />
            </div>
            <div
              className={"col-10 pl-0 showbox pt-0 pb-0"}
              style={{ display: "none" }}
            >
              <div
                className={"pt-1 pb-1 mr-5"}
                style={{
                  background: "#FBFBFB",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className={"row"}>
                  <div className={"col pl-5 ml-5 mt-2"}>
                    <label
                      style={{
                        color: "#FF8A00",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor:"pointer"
                      }}
                      className={"pr-5"}
                      onClick={ShortlistJobs}
                    >
                    {shortlistValue.length !== 0 &&
                      shortlistValue.includes(false)
                        ? "Shortlist"
                        : allshortlistValues.length !== 0 &&
                          allshortlistValues.includes(false)
                        ? "Shortlist"
                        : "Remove from shortlist"}
                    </label>
                   {shortlistValue.length !== 0 &&
                    shortlistValue.includes(false) ? (
                      <label style={{ color: "#FF8A00", fontSize: "18px" }}>
                        &#9733;{" "}
                      </label>
                    ) : allshortlistValues.length !== 0 &&
                      allshortlistValues.includes(false) ? (
                      <label style={{ color: "#FF8A00", fontSize: "18px" }}>
                        &#9733;{" "}
                      </label>
                    ) : (
                      <label style={{ color: "black", fontSize: "18px" }}>
                        &#9734;{" "}
                      </label>
                    )}
                  </div>
                  {
                    cardName === "rejected"
                    ?
                    ""
                    :
                    <div className={"col mt-2 pr-5"}>
                    <span
                      className={"pt-2 pb-2 pl-5 pr-5"}
                      style={{
                        background: "rgba(196, 196, 196, 0.2)",
                        border: "1px solid #FFFFFF",
                      }}
                    >
                      <label
                        style={{
                          color: "#FF0000",
                          fontSize: "14px",
                          fontWeight: "500",
                          cursor:"pointer"
                        }}
                        onClick={rejectAllApplication}
                      >
                        Reject
                      </label>
                    </span>
                  </div>
                  }
                </div>
              </div>
            </div>
            <div className={"col-1 hidebox"}>
              {/* <font className={"headingfont pl-5 ml-5"}>Status</font> */}
              <div className="pl-5 ml-1">
                <select
                  className={"form-select statusdropdown px-4 headingfont"}
                  onChange={handleStatusFilter}
                  value={statusValue}
                >
                  <option value={""}>Status</option>
                  <option value={"scheduled"}>Scheduled</option>
                  <option value={"shortlisted"}>Shortlisted</option>
                  <option value={"offer"}>Offered</option>
                  <option value={"rejected"}>Rejected</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*this is second box of heading data*/}
      <Data
        item={item}
        record={record}
        handleShortlist={handleShortlist}
        handleaddSingleShortlist={handleaddSingleShortlist}
        handleremoveSingleShortlist={handleremoveSingleShortlist}
        handleSingleReject={handleSingleReject}
      />
      {/*last box of data of heading*/}
      {loading ? (
        ""
      ) : (
        <>
          {record !== 0 && (
            <div
              className={"row align-items-center"}
              style={{
                borderRadius: "0 0 20px 20px",
                boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                backgroundColor: "white",
                marginTop: "1px",
              }}
            >
              <div className={"col py-2"}></div>
            </div>
          )}
        </>
      )}
      <div className={"col mt-5"}>
        <div className={"row align-items-center"}>
          <div className={"col-md-6 pl-0"}>
            {/* Showing 6 from {data.length} data */}
          </div>
          <div className={"col-md"}>
            {totalLength > 6 && (
              <ReactPaginate
                previousLabel={"<< Previous"}
                nextLabel={"Next >>"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={page}
              />
            )}
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};
export default React.memo(Applications);
