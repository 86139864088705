import React, { useEffect, useState } from "react";

function MessageBody(props) {
  // return message right box
  const [match, setMatch] = useState([]);
  useEffect(() => {
    async function callUserDetails() {
      try {
        if (props.item.length !== 0) {
          setMatch(props.item);
        }
      } catch {}
    }
    callUserDetails();
  }, [props.item]);
  return (
    <>
      <div className="row">
        <div className="col-10 mt-4 pb-4">
          <label className="message ml-5"></label>
        </div>
      </div>
      {match.map((data, index) => (
        <div key={index}>
          {/* <div className="col-1 mt-3">
          <i
            className="fa fa-window-minimize"
            aria-hidden="true"
            style={{ fontWeight: "500", color: "#92929D" }}
          ></i>
        </div>
        <div className="col-1 mt-2 mb-0">
          <label
            style={{ fontWeight: "500", color: "#92929D", fontSize: "30px" }}
          >
            &times;
          </label>
        </div> */}
          <div className="row mt-3 ml-1 mr-2">
            {data.sentBy === "OTHER" ? (
              <div
                className="col-6 pt-2 pb-2 messagetextborder"
                style={{ backgroundColor: "#F9F9F9", height: "auto" }}
              >
                {data.type === "TEMPLATE" ? (
                  <label className="messagedata ml-3">
                    {data.message.replace(/<[^>]+>/g, "")}
                  </label>
                ) : (
                  <label className="messagedata ml-3">{data.message}</label>
                )}
              </div>
            ) : (
              <div className="col-6 pt-2 pb-2"></div>
            )}
            {data.sentBy === "YOU" ? (
              <div
                className="col-6 pt-2 pb-2 messagetextborder"
                style={{ backgroundColor: "#F9F9F9", height: "auto" }}
              >
                {data.type === "TEMPLATE" ? (
                  <label className="messagedata ml-3">
                    {data.message.replace(/<[^>]+>/g, "")}
                  </label>
                ) : (
                  <label className="messagedata ml-3">{data.message}</label>
                )}
              </div>
            ) : (
              <div className="col-6 pt-2 pb-2"></div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
export default React.memo(MessageBody);
