import { FREELANCECANDIDATEEDUCATION } from "../../ENVIRONMENT";

// api for freelance educationlist
function freeeducationList(token) {
  const response = fetch(FREELANCECANDIDATEEDUCATION, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { freeeducationList };
