import { RECRUITERBUILDREQUIREMENT } from "../../../ENVIRONMENT";

// api for submit recruiter builrequirement description
async function recpostBuildRequirementDescription(token, data, id) {
  return await fetch(RECRUITERBUILDREQUIREMENT + `/${id}/jobdescription`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { recpostBuildRequirementDescription };
