import React, { useState, useEffect, useRef } from "react";
import GoToTop from "../../common/GoToTop";
import { Redirect } from "react-router";
import { Link, useParams,useLocation } from "react-router-dom";
import {
  buildAcademic,
  buildCertificate,
} from "../../../function/candidate/validateField";
import { verifyBuildAcademic } from "../../../function/candidate/checkRequired";
import { useSelector } from "react-redux";
import {
  GetAcademicFromParse,
  parseGetAcademic,
  parsePostAcademic,
} from "../../../function/candidate/parseAcademicData";
import YearOfPassing from "../../common/YearOfPassing";
import { freegetSecurity } from "../../../api/freelance/candidate/buildResume/buildResumeSecurity";
import { freegetAcademic, 
        freepostAcademic } from "../../../api/freelance/candidate/buildResume/buildResumeAcademic";
import { freegetResumeProfileDetails } from "../../../api/freelance/candidate/resumeParsing";
import YearOfValidity from "../../common/yearOfValidity";
import GetValidityMonth from "../../common/GetValidityMonth";

const BuildResume3 = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [loader, setLoader] = useState(false);
  const [information, setInformation] = useState({
    qualification: "",
    specialization: "",
    institute: "",
  });
  const [error, setError] = useState({
    qualification: "",
    specialization: "",
    institute: "",
  });

  const [visibility, setVisibility] = useState({
    zero: true,
    one: false,
    two: false,
    four: true
  });

  const [certificateInformation, setCertificateInformation] = useState({
    certificateName: "",
    institute: "",
    year: 0,
    month: 0,
    lifeTimeValidity: false,
    certificateName0: "",
    institute0: "",
    year0: 0,
    month0: 0,
    lifeTimeValidity0: false,
    certificateName1: "",
    institute1: "",
    year1: 0,
    month1: 0,
    lifeTimeValidity1: false,
  });
  const [certificateError, setCertificateError] = useState({
    certificateName: "",
    institute: "",
    certificateName0: "",
    institute0: "",
    certificateName1: "",
    institute1: "",
  });

  const [certificateVisibility, setCertificateVisibility] = useState({
    one: false,
    two: false,
  });

  const [securityNavigation, setSecurityNavigation] = useState(false);
  const [socialNavigation, setSocialNavigation] = useState(false);

  const token = useSelector((state) => state.token);
  let academicRef = useRef();
  const param = useParams();
  const id = param.id;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let cardName = query.get("data");
  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        qualification1: "",
        specialization1: "",
        institute1: "",
      };
    });
    setError((state) => {
      return {
        ...state,
        qualification1: "",
        specialization1: "",
        institute1: "",
      };
    });
  }, [visibility.one]);

  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        qualification2: "",
        specialization2: "",
        institute2: "",
      };
    });
    setError((state) => {
      return {
        ...state,
        qualification2: "",
        specialization2: "",
        institute2: "",
      };
    });
  }, [visibility.two]);

  useEffect(() => {
    setCertificateInformation((state) => {
      return {
        ...state,
        certificateName0: "",
        institute0: "",
        year0: 0,
        month0: 0,
        lifeTimeValidity0: false
      };
    });
    setCertificateError((state) => {
      return {
        ...state,
        certificateName0: "",
        institute0: "",
      };
    });
  }, [certificateVisibility.one]);

  useEffect(() => {
    setCertificateInformation((state) => {
      return {
        ...state,
        certificateName1: "",
        institute1: "",
        year1: 0,
        month1: 0,
        lifeTimeValidity1: false
      };
    });
    setCertificateError((state) => {
      return {
        ...state,
        certificateName1: "",
        institute1: "",
      };
    });
  }, [certificateVisibility.two]);

  useEffect(() => {
    async function callItSelf(){
      try {
       if(cardName){
        const response = await freegetResumeProfileDetails(token,id);
           if (response.status === 200) {
             let payload = await response.json();
             payload = payload.payload.parsedResume;
             try {
               GetAcademicFromParse(
                 payload,
                 information,
                 setInformation,
                 visibility,
                 setVisibility,
                 certificateInformation,
                 setCertificateInformation,
                 certificateVisibility,
                 setCertificateVisibility
               );
             } catch {}
           }
       }else{
        const response = await freegetAcademic(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload.education[0].academicQualification.length !== 0) {
            setSecurityNavigation(true);
            document.getElementsByClassName("buildhide")[0].style.display =
              "none";
          }
          try {
            parseGetAcademic(
              payload,
              information,
              setInformation,
              visibility,
              setVisibility,
              certificateInformation,
              setCertificateInformation,
              certificateVisibility,
              setCertificateVisibility
            );
          } catch {}
          const socialResponse = await freegetSecurity(token, id);
          if (socialResponse.status === 200) {
            let payload = await socialResponse.json();
            payload = payload.payload;
            if (payload.candidate.securityClearance !== null) {
              setSocialNavigation(true);
            }
          }
        }
       }
      } catch {}
    }
    callItSelf()
  }, []);

  async function handleClick(event) {
    event.preventDefault();
    try {
      if (
        verifyBuildAcademic(
          error,
          setError,
          information,
          visibility,
          certificateVisibility,
          certificateError,
          setCertificateError
        )
      ) {
        if (academicRef.current) {
          academicRef.current.setAttribute("disabled", "disabled");
        }
        setLoader(true);
        const parsedData = parsePostAcademic(
          information,
          visibility,
          certificateInformation,
          certificateVisibility
        );
        const response = await freepostAcademic(token, parsedData, id);
        if (response.status === 200) {
          setRedirect(true);
        }
        if (response.status === 422) {
          academicRef.current.removeAttribute("disabled");
          setLoader(false);
          document.getElementsByClassName("academicerr")[0].innerText =
            "Please Fill Valid Format";
        } else {
          academicRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      }
    } catch {}
  }

  const AddEducation = () => {
    // return education duplicate div
    setVisibility((state) => {
      if (state.one === false) return { ...state, one: true };
      else return { ...state, two: true };
    });
  };
  //new certificate box

  const AddCertificate = () => {
    // return certificate duplicate div
    setCertificateVisibility((state) => {
      if (!state.one) return { ...state, one: true };
      if (!state.two) return { ...state, two: true };
      return state;
    });
  };

  function submitEnabled() {
    try {
      document.getElementsByClassName("buildhide")[0].style.display = "block";
      setSecurityNavigation(false);
      setSocialNavigation(false);
    } catch {}
  }

  return (
    <div className={"col"}>
      <form className={"form-group"} onSubmit={handleClick}>
        <div className={"row pl-5 ml-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5"}>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className={"row mx-5 pl-5  resumealignment"}>
          <div className={"col-lg-1"}></div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link
              to={`/freelance/dashboard/buildresume/resumeexperience/${id}`}
            >
              <div
                className={"pl-3 pb-2 pr-3 pt-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Experience
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <div
              className={"pt-1 pl-2 pr-2"}
              style={{
                width: "140px",
                borderRadius: "10px",
                background: "#FFFFFF",
                textAlign: "center",
              }}
            >
              <div className={"row"}>
                <div className={"col-lg pb-0"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#000000",
                      fontFamily: "poppins",
                    }}
                  >
                    Academics <br />& Certificate
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg"}>
                  <div
                    style={{
                      background: "#3F9AE0",
                      width: "100%",
                      height: "4px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {securityNavigation ? (
              <Link
                to={`/freelance/dashboard/buildresume/resumesecurity/${id}`}
              >
                <div
                  className={"pt-2 pl-2 pr-2"}
                  style={{
                    width: "140px",
                    borderRadius: "10px",
                    background: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#BFBFBF",
                    }}
                  >
                    Security Clearance
                  </label>
                  <br />
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#BFBFBF",
                  }}
                >
                  Security Clearance
                </label>
                <br />
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {socialNavigation ? (
              <Link to={`/freelance/dashboard/buildresume/resumesocial/${id}`}>
                <div
                  className={"pl-3 pb-2 pr-3 pt-3"}
                  style={{
                    width: "140px",
                    borderRadius: "10px",
                    background: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#BFBFBF",
                    }}
                  >
                    Social
                  </label>
                  <br />
                </div>
              </Link>
            ) : (
              <div
                className={"pl-3 pb-2 pr-3 pt-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#BFBFBF",
                  }}
                >
                  Social
                </label>
                <br />
              </div>
            )}
          </div>
        </div>
        {/*second box*/}
        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-4"}>
            <div className={"w-100 pb-4 pt-4 contentholder"}>
              <div className={"row mx-4"}>
                <div className={"col-md"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#000000",
                    }}
                  >
                    Academics (Please provide all academic qualifications)
                  </label>
                </div>
              </div>
              <div className={"row mx-4"}>
                <div className={"col-md-2"}>
                  <label className={"resumeformquerytext"}>US Degree</label>
                </div>
                <div className="col-md-1 form-inline pl-0">
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="degree"
                    value="Yes"
                    onClick={() =>
                      setVisibility((state) => {
                        return { ...state, zero: true };
                      })
                    }
                    checked={visibility.zero}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                </div>
                <div className="col-md-1 form-inline pl-0">
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="degree"
                    value="No"
                    onClick={() =>
                      setVisibility((state) => {
                        return { ...state, zero: false };
                      })
                    }
                    checked={!visibility.zero}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>

              <div id={"academics"}>
                <div className={"row mx-4"}>
                  <div className={"col-md"}>
                    <label htmlFor={"equal"} className={"resumeforminputtext"}>
                      Academics Qualification
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className={
                        "form-control resumeforminputfield form-select inputgray shadow-none"
                      }
                      id={"equal"}
                      name={"qualification"}
                      value={information.qualification}
                      style={{
                        border: `${error.qualification ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        buildAcademic(
                          event,
                          error,
                          setError,
                          information,
                          setInformation
                        );
                        submitEnabled();
                      }}
                    >
                      <option value={""}>Select Highest Degree</option>
                      <option value={"Doctorate"}>Doctorate</option>
                      <option value={"Masters"}>Masters</option>
                      <option value={"Bachelors"}>Bachelors</option>
                      <option value={"Associate"}>Associate</option>
                      <option value={"Vocational"}>Vocational</option>
                      <option value={"HighSchool"}>High School</option>
                    </select>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.qualification}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"specialization"}
                      className={"resumeforminputtext"}
                    >
                      Specialization<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"specialization"}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      value={information.specialization}
                      style={{
                        border: `${
                          error.specialization ? "1px solid red" : ""
                        }`,
                      }}
                      name={"specialization"}
                      onChange={(event) => {
                        buildAcademic(
                          event,
                          error,
                          setError,
                          information,
                          setInformation
                        );
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.specialization}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"institution"}
                      className={"resumeforminputtext"}
                    >
                      Institution
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"institution"}
                      name={"institute"}
                      value={information.institute}
                      style={{
                        border: `${error.institute ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      onChange={(event) => {
                        buildAcademic(
                          event,
                          error,
                          setError,
                          information,
                          setInformation
                        );
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.institute}
                    </font>
                  </div>
                </div>
                <div className={"row mx-4 pt-1"}>
                  <div className={"col-md-4"}>
                    <label htmlFor={"ypass"} className={"resumeforminputtext"}>
                      Year Of Passing
                    </label>
                    <select
                      className={
                        "form-control resumeforminputfield form-select inputgray shadow-none"
                      }
                      id={"ypass"}
                      value={information.yearOfPassing}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          yearOfPassing: event.currentTarget.value,
                        });
                        submitEnabled();
                      }}
                    >
                      <option value={""}></option>
                      <YearOfPassing />
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*duplicate here*/}
        {visibility.one && (
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-3 pb-0 contentholder"}>
                <div id={"addmoreacademics"}>
                  <div className={"row mx-4 pt-2"}>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"equal"}
                        className={"resumeforminputtext"}
                      >
                        Academics Qualification
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        id={"equal"}
                        name={"qualification1"}
                        value={information.qualification1}
                        style={{
                          border: `${
                            error.qualification1 ? "1px solid red" : ""
                          }`,
                        }}
                        onChange={(event) => {
                          buildAcademic(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select Highest Degree</option>
                        <option value={"Doctorate"}>Doctorate</option>
                        <option value={"Masters"}>Masters</option>
                        <option value={"Bachelors"}>Bachelors</option>
                        <option value={"Associate"}>Associate</option>
                        <option value={"Vocational"}>Vocational</option>
                        <option value={"HighSchool"}>High School</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.qualification1}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"specialization"}
                        className={"resumeforminputtext"}
                      >
                        Specialization<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"specialization"}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                        name={"specialization1"}
                        value={information.specialization1}
                        style={{
                          border: `${
                            error.specialization1 ? "1px solid red" : ""
                          }`,
                        }}
                        onChange={(event) => {
                          buildAcademic(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.specialization1}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"institution"}
                        className={"resumeforminputtext"}
                      >
                        Institution
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"institution"}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                        name={"institute1"}
                        value={information.institute1}
                        style={{
                          border: `${error.institute1 ? "1px solid red" : ""}`,
                        }}
                        onChange={(event) => {
                          buildAcademic(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.institute1}
                      </font>
                    </div>
                  </div>
                  <div className={"row mx-4 pt-1"}>
                    <div className={"col-md-4"}>
                      <label
                        htmlFor={"ypass"}
                        className={"resumeforminputtext"}
                      >
                        Year Of Passing
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        id={"ypass"}
                        value={information.yearOfPassing1}
                        onChange={(event) => {
                          setInformation({
                            ...information,
                            yearOfPassing1: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                      >
                        <option value={""}></option>
                        <YearOfPassing />
                      </select>
                    </div>
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                      onClick={() => {
                        setVisibility((state) => {
                          return { ...state, one: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*duplicate herer*/}
        {visibility.two && (
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-3 pb-0 contentholder"}>
                <div id={"addmoreacademics"}>
                  <div className={"row mx-4 pt-2"}>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"equal"}
                        className={"resumeforminputtext"}
                      >
                        Academics Qualification
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        id={"equal"}
                        name={"qualification2"}
                        value={information.qualification2}
                        style={{
                          border: `${
                            error.qualification2 ? "1px solid red" : ""
                          }`,
                        }}
                        onChange={(event) => {
                          buildAcademic(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select Highest Degree</option>
                        <option value={"Doctorate"}>Doctorate</option>
                        <option value={"Masters"}>Masters</option>
                        <option value={"Bachelors"}>Bachelors</option>
                        <option value={"Associate"}>Associate</option>
                        <option value={"Vocational"}>Vocational</option>
                        <option value={"HighSchool"}>High School</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.qualification2}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"specialization"}
                        className={"resumeforminputtext"}
                      >
                        Specialization<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"specialization"}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                        name={"specialization2"}
                        value={information.specialization2}
                        style={{
                          border: `${
                            error.specialization2 ? "1px solid red" : ""
                          }`,
                        }}
                        onChange={(event) => {
                          buildAcademic(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.specialization2}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"institution"}
                        className={"resumeforminputtext"}
                      >
                        Institution
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"institution"}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                        name={"institute2"}
                        value={information.institute2}
                        style={{
                          border: `${error.institute2 ? "1px solid red" : ""}`,
                        }}
                        onChange={(event) => {
                          buildAcademic(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.institute2}
                      </font>
                    </div>
                  </div>
                  <div className={"row mx-4 pt-1"}>
                    <div className={"col-md-4"}>
                      <label
                        htmlFor={"ypass"}
                        className={"resumeforminputtext"}
                      >
                        Year Of Passing
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        id={"ypass"}
                        value={information.yearOfPassing2}
                        onChange={(event) => {
                          setInformation({
                            ...information,
                            yearOfPassing2: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                      >
                        <option value={""}></option>
                        <YearOfPassing />
                      </select>
                    </div>
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                      onClick={() => {
                        setVisibility((state) => {
                          return { ...state, two: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*duplicate here*/}
        {!(visibility.one && visibility.two) && (
          <div className={"row"}>
            <div className={"col-md"}>
              <div
                className={
                  "btn offset-sm-9 badge border-0  newskill bg-transparent"
                }
                onClick={AddEducation}
              >
                + Add More
              </div>
            </div>
          </div>
        )}
          {/*veteran*/}
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-3 pb-2 contentholder"}>
              <div className={"row mx-4"}>
                <div className={"col-md-1"}>
                  <label className={"resumeformquerytext"}>Veteran</label>
                </div>
                <div className="col-md-1 form-inline pl-0">
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="veteran"
                    value="Yes"
                    onClick={() =>
                      setVisibility((state) => {
                        return { ...state, four: true };
                      })
                    }
                    checked={visibility.four}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                </div>
                <div className="col-md-1 form-inline pl-0">
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="veteran"
                    value="No"
                    onClick={() =>
                      setVisibility((state) => {
                        return { ...state, four: false };
                      })
                    }
                    checked={!visibility.four}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>
              </div>
            </div>
          </div>
        {/*Third box*/}
        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-2"}>
            <div className={"w-100 pt-1 pb-3 contentholder"}>
              <div className={"row mx-4 pt-3"}>
                <div className={"col-md"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#000000",
                    }}
                  >
                    Certificates
                  </label>
                </div>
              </div>
              <div className={"form-group"}>
                <div className={"row pt-0 mx-4"}>
                  <div className={"col-md-4"}>
                    <label htmlFor={"cname"} className={"resumeforminputtext"}>
                      Certificate Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cname"}
                      name={"certificateName"}
                      value={certificateInformation.certificateName}
                      style={{
                        border: `${
                          certificateError.certificateName
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onChange={(event) => {
                        buildCertificate(
                          event,
                          setCertificateError,
                          setCertificateInformation
                        );
                        submitEnabled();
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.certificateName}
                    </font>
                  </div>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"institute"}
                      className={"resumeforminputtext"}
                    >
                      Institute
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"institute"}
                      name={"institute"}
                      value={certificateInformation.institute}
                      style={{
                        border: `${
                          certificateError.institute ? "1px solid red" : ""
                        }`,
                      }}
                      onChange={(event) => {
                        buildCertificate(
                          event,
                          setCertificateError,
                          setCertificateInformation
                        );
                        submitEnabled();
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.institute}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2 mx-4"}>
                <div className={"col-md-4"}>
                    <label htmlFor={"valid"} className={"resumeforminputtext"}>
                      Valid Up To
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        value={certificateInformation.month}
                        onChange={(event) => {
                          setCertificateInformation({
                            ...certificateInformation,
                            month: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                      >
                        <option value={0}>Month</option>
                        <GetValidityMonth />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        value={certificateInformation.year}
                        onChange={(event) => {
                          setCertificateInformation({
                            ...certificateInformation,
                            year: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                      >
                        <option value={0}>Year</option>
                        <YearOfValidity />
                      </select>
                    </div>
                  </div>
                  <div className={"col-md-4 form-inline mt-4"}>
                  <input
                      type="checkbox"
                      style={{width: "18px",height: "18px"}}
                      checked={certificateInformation.lifeTimeValidity}
                      onChange={() => {
                        setCertificateInformation((state) => {
                          return { ...state, lifeTimeValidity: !certificateInformation.lifeTimeValidity };
                        });
                      }}
                      />
                    <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*duplicate started*/}
        {certificateVisibility.one && (
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-1 contentholder"}>
                <div className={"row pt-3 mx-4"}>
                  <div className={"col-md-4"}>
                    <label htmlFor={"cname"} className={"resumeforminputtext"}>
                      Certificate Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cname"}
                      name={"certificateName0"}
                      value={certificateInformation.certificateName0}
                      style={{
                        border: `${
                          certificateError.certificateName0
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onChange={(event) => {
                        buildCertificate(
                          event,
                          setCertificateError,
                          setCertificateInformation
                        );
                        submitEnabled();
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.certificateName0}
                    </font>
                  </div>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"institute"}
                      className={"resumeforminputtext"}
                    >
                      Institute
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"institute"}
                      name={"institute0"}
                      value={certificateInformation.institute0}
                      style={{
                        border: `${
                          certificateError.institute0 ? "1px solid red" : ""
                        }`,
                      }}
                      onChange={(event) => {
                        buildCertificate(
                          event,
                          setCertificateError,
                          setCertificateInformation
                        );
                        submitEnabled();
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.institute0}
                    </font>
                  </div>
                </div>
                <div className={"row pt-3 mx-4"}>
                <div className={"col-md-4"}>
                    <label htmlFor={"valid"} className={"resumeforminputtext"}>
                      Valid Up To
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        value={certificateInformation.month0}
                        onChange={(event) => {
                          setCertificateInformation({
                            ...certificateInformation,
                            month0: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                      >
                        <option value={0}>Month</option>
                        <GetValidityMonth />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        value={certificateInformation.year0}
                        onChange={(event) => {
                          setCertificateInformation({
                            ...certificateInformation,
                            year0: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                      >
                        <option value={0}>Year</option>
                        <YearOfValidity />
                      </select>
                    </div>
                  </div>
                  <div className={"col-md-4 form-inline mt-4"}>
                  <input
                      type="checkbox"
                      style={{width: "18px",height: "18px"}}
                      checked={certificateInformation.lifeTimeValidity0}
                      onChange={() => {
                        setCertificateInformation((state) => {
                          return { ...state, lifeTimeValidity0: !certificateInformation.lifeTimeValidity0 };
                        });
                      }}
                      />
                    <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={
                        "btn offset-sm-10 pl-5 badge bg-transparent cancelskill"
                      }
                      onClick={() => {
                        setCertificateVisibility((state) => {
                          return { ...state, one: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*another duplicate*/}
        {certificateVisibility.two && (
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-1 contentholder"}>
                <div className={"row pt-3 mx-4"}>
                  <div className={"col-md-4"}>
                    <label htmlFor={"cname"} className={"resumeforminputtext"}>
                      Certificate Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cname"}
                      name={"certificateName1"}
                      value={certificateInformation.certificateName1}
                      style={{
                        border: `${
                          certificateError.certificateName1
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onChange={(event) => {
                        buildCertificate(
                          event,
                          setCertificateError,
                          setCertificateInformation
                        );
                        submitEnabled();
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.certificateName1}
                    </font>
                  </div>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"institute"}
                      className={"resumeforminputtext"}
                    >
                      Institute
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"institute"}
                      name={"institute1"}
                      value={certificateInformation.institute1}
                      style={{
                        border: `${
                          certificateError.institute1
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onChange={(event) => {
                        buildCertificate(
                          event,
                          setCertificateError,
                          setCertificateInformation
                        );
                        submitEnabled();
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {certificateError.institute1}
                    </font>
                  </div>
                </div>
                <div className={"row pt-3 mx-4"}>
                <div className={"col-md-4"}>
                    <label htmlFor={"valid"} className={"resumeforminputtext"}>
                      Valid Up To
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        value={certificateInformation.month1}
                        onChange={(event) => {
                          setCertificateInformation({
                            ...certificateInformation,
                            month1: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                      >
                        <option value={0}>Month</option>
                        <GetValidityMonth />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        value={certificateInformation.year1}
                        onChange={(event) => {
                          setCertificateInformation({
                            ...certificateInformation,
                            year1: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                      >
                        <option value={0}>Year</option>
                        <YearOfValidity />
                      </select>
                    </div>
                  </div>
                  <div className={"col-md-4 form-inline mt-4"}>
                  <input
                      type="checkbox"
                      style={{width: "18px",height: "18px"}}
                      checked={certificateInformation.lifeTimeValidity1}
                      onChange={() => {
                        setCertificateInformation((state) => {
                          return { ...state, lifeTimeValidity1: !certificateInformation.lifeTimeValidity1 };
                        });
                      }}
                      />
                    <label className={"dashboardforminputtext pr-2 pl-3"}>
                    Lifetime Validity
                  </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={
                        "btn offset-sm-10 pl-5 badge bg-transparent cancelskill"
                      }
                      onClick={() => {
                        setCertificateVisibility((state) => {
                          return { ...state, two: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*end duplicate*/}
        {!(certificateVisibility.one && certificateVisibility.two) && (
          <div className={"row"}>
            <div className={"col-md"}>
              <div
                className={
                  "btn offset-sm-9 badge border-0  newskill bg-transparent"
                }
                onClick={AddCertificate}
              >
                + Add More
              </div>
            </div>
          </div>
        )}
        <div className="row pr-5">
          <div className="col-md offset-sm-9">
            <label
              className={"dashboardforminputtext text-danger academicerr"}
            ></label>
          </div>
        </div>
        <div className={"row hidebox"}>
          <div className={"col pt-2 pb-2 pr-5"}>
            {securityNavigation ? (
              <>
                {redirect ? (
                  <Redirect
                    to={`/freelance/dashboard/buildresume/resumesecurity/${id}`}
                  />
                ) : (
                  <button
                    type="submit"
                    className={"btn save offset-sm-9 buildhide"}
                    ref={academicRef}
                  >
                    {loader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 5px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  <Redirect
                    to={`/freelance/dashboard/buildresume/resumesecurity/${id}`}
                  />
                ) : (
                  <button
                    type="submit"
                    className={"btn save offset-sm-9"}
                    ref={academicRef}
                  >
                    {loader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 5px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};
export default React.memo(BuildResume3);
