import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Data = (props) => {
  const [match, setMatch] = useState([]);
  const [loading, setLoading] = useState();
  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch {}
  }, [props.item]);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    // return application table data in a group which generate by data variable
    <>
      {loading ? (
        <div
          className="row h-100"
          style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
        >
          <div className="col-sm-12 my-auto">
            <img
              src={"/icon/Gif/loader.gif"}
              className={"mx-auto d-block"}
              alt={"loader"}
            />
          </div>
        </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {match.map((match) => (
                <div
                  className={"row pl-3"}
                  style={{
                    boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                    backgroundColor: "white",
                    marginTop: "1px",
                  }}
                  key={match.uuid}
                >
                  {/* <input type={"checkbox"} className={"checkbx  mr-4 mt-4"} /> */}
                  <div className={"col py-3  box"}>
                    {/*heading box data*/}
                    <div className={"row align-items-center"}>
                      <div className={"col-2 pl-5 pr-0"}>
                        <Link
                          to={`/candidate/candidatedetails/${match.uuid}`}
                          className={"matchingjobdata text-primary"}
                        >
                          {match.candidateId}
                        </Link>
                        <br />
                        <font style={{ fontWeight: 500, fontSize: "14px" }}>
                          {new Date(match.createdAt).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </font>
                        <br />
                      </div>
                      <div className={"col-2"}>
                        <label className={"matchingjobdata text-primary ml-4"}>
                          {match.resumeTitle}
                        </label>
                      </div>
                      <div className={"col-md-1"}>
                        <font
                          style={{ fontWeight: "normal", fontSize: "14px" }}
                        >
                          {match.preferredEmployment}
                        </font>
                      </div>
                      <div className={"col-md-2 pl-5 ml-4"}>
                        <font
                          style={{ fontWeight: "normal", fontSize: "14px" }}
                        >
                          {match.CandidateExperiences[0] ? (
                            <>
                              {
                                match.CandidateExperiences[0]
                                  .totalYearOfExperience
                              }{" "}
                              year
                            </>
                          ) : (
                            <font
                              style={{ fontWeight: "normal", fontSize: "14px" }}
                            >
                              0 year
                            </font>
                          )}
                        </font>
                      </div>
                      <div className={"col-1"}>
                        <font
                          className={""}
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                          }}
                        >
                          ${match.rate}
                        </font>
                      </div>
                      <div className={"col-1"}>
                        <font
                          className={""}
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                          }}
                        >
                          {match.Addresses[0] && match.Addresses[0].city}
                        </font>
                      </div>
                      <div className={"col-1"}>
                        <Link to={`/candidate/registration/${match.uuid}`}
                          className="fas fa-edit  editbtn float-right"
                          style={{
                            fontWeight: "500",
                            cursor: "pointer",
                            fontSize: "18px",
                          }}
                          ></Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </>
          ) : (
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Data Found
            </div>
          )}
        </>
      )}
    </>
  );
};

export { Data };
