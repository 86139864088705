import { RECRUITERCANDIDATEPRIMARY } from "../../ENVIRONMENT";

// api for recruiter primary list
function recprimaryList(token) {
  const response = fetch(RECRUITERCANDIDATEPRIMARY, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { recprimaryList };
