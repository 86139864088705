import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import "../../../styles/app.scss";
import { Route, Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ForgotPassword from "../../authentication/ForgotPassword";
import ResetPassword from "../../authentication/ResetPassword";
import { loginAdmin } from "../../../api/admin/authentication/login";

const AdminLogin = () => {
  // return login page
  const dispatch = useDispatch();
  const history = useHistory();
  try {
    dispatch({ type: "newusertrue" });
    dispatch({ type: "buildresumefalse" });
    dispatch({ type: "recruiterfalse" });
  } catch {}

  let btnRef = useRef();

  const AdminLoginHome = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const formRef = useRef();

    const togglePassword = (e) => {
      // toggle the password view to hide or show
      e.preventDefault();
      setPasswordShown(!passwordShown);
    };

    async function submitUser(userData) {
      // submit form for user creation if all validation meet requirement
      const form = formRef.current;

      try {
        const response = await loginAdmin({
          role: "SUPER_ADMIN",
          email: form.email.value,
          password: form.password.value,
        });
        const status = response.status;
        try {
          sessionStorage.setItem("token", "");
          dispatch({ type: "settoken", value: "" });
          if (status === 200) {
            let data = await response.json();
            data = data.payload;
            const token = data.token;
            sessionStorage.setItem("token", token);
            dispatch({ type: "settoken", value: token });
            history.push("/admin/dashboardview");
          } else {
            const errmsg = document.getElementsByClassName("adminerrormsg")[0];
            errmsg.innerText = "email id or password is wrong";
            errmsg.style.cssText = "display:inline;color:red";
          }
        } catch {}
        // setting error msg to ui
        btnRef.current.removeAttribute("disabled");
        const data = await response.json();
        const errmsg = document.getElementsByClassName("errormsg")[0];
        errmsg.innerText = data.message;
        errmsg.style.cssText = "display:inline";
      } catch {}
    }

    return (
      <div className="main-container" style={{ height: "100vh" }}>
        <div className="col-md-12 h-100">
          <div className="row h-100">
            <div className="col-md-7" style={{ background: "#F7F7F7" }}>
              <div className="row pt-5">
                <div className="col-md pt-5">
                  <div className={"pt-5 mt-3 pb-5"}>
                    <img
                      src="../../../icon/common/mainlogo.png"
                      className="img-fluid mx-auto d-block pt-5 mt-5 pb-5"
                      alt="dollar"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5" style={{ background: "#FFFFFF" }}>
              <form
                className="form-group"
                onSubmit={handleSubmit(submitUser)}
                ref={formRef}
              >
                <div className="row mx-4 mt-5">
                  <div className="col-md mt-5">
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "28px",
                        color: "#11142D",
                        marginTop: "60px",
                        fontFamily: "Mulish",
                      }}
                    >
                      Sign in
                    </label>
                  </div>
                </div>
                <div className="row mx-4 my-3">
                  <div className="col-xl-10">
                    <input
                      type={"email"}
                      placeholder={"Email ID"}
                      id={"email"}
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Please Fill The Email",
                        },
                        pattern: {
                          value:
                            /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                          message: "Email Is Invalid",
                        },
                      })}
                      className={
                        "form-control signupforminputfield shadow-none my-2"
                      }
                      name={"email"}
                    />
                    {errors.email && (
                      <font
                        className={"dashboardforminputtext text-danger"}
                        style={{ fontWeight: "600" }}
                      >
                        {errors.email.message}
                      </font>
                    )}
                  </div>
                </div>
                <div className="row mx-4 mb-4">
                  <div className="col-xl-10">
                    <div>
                      <i
                        className={
                          passwordShown ? "fas fa-eye-slash" : "fa fa-eye"
                        }
                        onClick={togglePassword}
                        style={{
                          position: "absolute",
                          right: "27px",
                          top: "20px",
                          color: "#DADADA",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      />
                      <input
                        type={passwordShown ? "text" : "password"}
                        placeholder={"Password"}
                        name={"password"}
                        {...register("password", {
                          required: {
                            value: true,
                            message: "Please Fill The Password",
                          },
                          minLength: {
                            value: 6,
                            message: "Password Must Have At Least 6 Character",
                          },
                        })}
                        className={
                          "form-control forminputfield shadow-none pr-5"
                        }
                      />
                    </div>
                    {errors.password && (
                      <font
                        className={"dashboardforminputtext text-danger"}
                        style={{ fontWeight: "600" }}
                      >
                        {errors.password.message}
                      </font>
                    )}
                  </div>
                </div>
                {/* <div className="row mr-3">
                  <div className="col pr-5 mr-5">
                    <button className="btn float-right">
                      <Link
                        to="/user/forgot"
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: "Mulish",
                          color: "#9A9AB0",
                          textDecoration: "none",
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </button>
                  </div>
                </div> */}
                <div className={"adminerrormsg  pl-5 pt-4"}></div>
                <div className="row mx-4">
                  <div className="col-xl-10">
                    <button
                      type={"submit"}
                      ref={btnRef}
                      className={"btnsign"}
                      id={"divbtndiv"}
                      onClick={handleSubmit(submitUser)}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Route path={"/adminlogin/login"}>
        <AdminLoginHome />
      </Route>
      <Route path={"/adminlogin/forgot"}>
        <ForgotPassword />
      </Route>
      <Route path={"/adminlogin/reset"}>
        <ResetPassword />
      </Route>
    </React.Fragment>
  );
};

export default React.memo(AdminLogin);
