import React from "react";
const GetPrimaryMonth = () => {
  // return a group of options from 0 to 11
  const child = [];
  for (var i = 0; i < 12; i++)
    child.push(
      <option key={i} value={`${i}`}>
        {i} month
      </option>
    );
  return child;
};

export default React.memo(GetPrimaryMonth);