import '../../styles/app.scss';
import React from "react";
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UserRegistrationForm from './UserRegistrationForm';
import UploadRequirment from './UploadRequirement';
import JobRequirment1 from './JobRequirment/JobRequirment1';
import JobRequirment2 from './JobRequirment/JobRequirment2';
import JobRequirment3 from './JobRequirment/JobRequirment3';
import JobRequirment4 from './JobRequirment/JobRequirment4';
import JobRequirment5 from './JobRequirment/JobRequirment5';
import JobRequirmentPreview from './JobRequirment/JobRequirmentPreview';

const DashboardHome = () => {
    // return dashboardhome of client for new user
    const dispatch = useDispatch();
    dispatch({ type: "newusertrue" });
    const tablink = ["/client/dashboard/postjob", "/client/dashboard/postskill", "/client/dashboard/postacademic", "/client/dashboard/postsecurity", "/client/dashboard/postdescription", "/client/dashboard/postjobpreview","/client/dashboard/editjob"]

    try {
        document.getElementById("postjobid").style.display = "none";
    } catch { }

    return (
        <React.Fragment>
            {/* <Redirect to={"/client/dashboard/register"} /> */}
            <Route exact path={"/client/dashboard/register"}>
                <UserRegistrationForm />
            </Route>
            <Route path={"/client/dashboard/uploadresume"}>
                <UploadRequirment jobpreviewlink={"/client/dashboard/postingpreview"} jobreq1link={"/client/dashboard/postjob"} />
            </Route>
            <Route path={["/client/dashboard/postjob/:refId","/client/dashboard/postjob"]}>
                <JobRequirment1 link={tablink} />
            </Route>
            <Route path={"/client/dashboard/editjob/:id"}>
                <JobRequirment1 link={tablink} />
            </Route>
            <Route path={"/client/dashboard/postskill/:id"}>
                <JobRequirment2 link={tablink} />
            </Route>
            <Route path={"/client/dashboard/postacademic/:id"}>
                <JobRequirment3 link={tablink} />
            </Route>
            <Route path={"/client/dashboard/postsecurity/:id"}>
                <JobRequirment4 link={tablink} />
            </Route>
            <Route path={"/client/dashboard/postdescription/:id"}>
                <JobRequirment5 link={tablink} />
            </Route>
            <Route path={["/client/dashboard/postjobpreview/:id","/client/dashboard/postjobpreview"]}>
                <JobRequirmentPreview />
            </Route>
        </React.Fragment>
    )
}
export default React.memo(DashboardHome);