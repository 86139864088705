import React, { useState, useEffect, useRef } from "react";
import GoToTop from "../../../../common/GoToTop";
import { RequirementListData } from "./RequirementListData";

const FreelanceRequirementList = () => {
  return (
    <div className={"col mx-5 pb-5"}>
      <div
        className={"row mt-4 align-items-center pl-2"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row"}>
            <div className={"col-2 pr-0"}>
              <font className={"headingfont pl-5"}>ID</font>
              {/* <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid ml-1"}
                onClick={() => {
                  {
                    handleIdSorting();
                    setIdOrder(!idOrder);
                  }
                }}
              /> */}
            </div>
            <div className={"col-2"}>
              <div className={"pl-5"}>
                <font className={"headingfont ml-4"}>Job title</font>
              </div>
            </div>
            <div className={"col-2 pl-5"}>
              <font className={"headingfont ml-4"}>Duration</font>
            </div>
            <div className={"col-1 pl-4"}>
              <font className={"headingfont"}>
                Rate
                {/* <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid ml-1"}
                  onClick={() => {
                    {
                      handleRateSorting();
                      setOrder(!order);
                    }
                  }}
                /> */}
              </font>
            </div>
            {/* {cardName !== "archive" ? (
              <div className={"col-1 pl-0"}>
                <font className={"headingfont"}>Shortlist</font>
              </div>
            ) : (
              <div className={"col-1 pl-0"}></div>
            )} */}
            <div className={"col-2 pl-0 pr-0"}>
              <font className={"headingfont pl-3"}>Work Type</font>
            </div>
          </div>
        </div>
      </div>
      {/*this is second box of heading data*/}
      <RequirementListData />
      {/*last box of data of heading*/}
      <div
        className={"row align-items-center"}
        style={{
          borderRadius: "0 0 20px 20px",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
          marginTop: "1px",
        }}
      >
        <div className={"col py-2"}></div>
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(FreelanceRequirementList);
