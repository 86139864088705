import { CLIENTPRIMARY } from "../ENVIRONMENT";

// api for client primary list
function primaryList(token) {
    const response = fetch(CLIENTPRIMARY,{
        
        headers: { 'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token?token:sessionStorage.getItem('token')}` },
       
    })
    return response;
}

export { primaryList };


