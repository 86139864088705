function setSkillData(
  payload,
  information,
  setInformation,
  secondaryInfo,
  setSecondaryInfo,
  setSecondaryDate,
  setSecondaryDate0,
  setSecondaryDate1,
  setSecondaryDate2,
  visible,
  setVisible,
  secondaryVisible,
  setSecondaryVisible,
  setDate,
  setDate0,
  setDate1,
  setDate2
) {
  // for primary data
  if (payload.hasOwnProperty("primary")) {
    const { primary } = payload;
    const plength = primary.length;
    //   set first primary data
    setDate(new Date(primary[0].lastUsed || null));
    let visibleObject = {};
    let primaryObject = {
      primarySkill: primary[0].skill,
      month: primary[0].experienceInMonth,
      year: primary[0].experienceInYear,
    };
    if (plength > 0) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill0: primary[1].skill,
          month0: primary[1].experienceInMonth,
          year0: primary[1].experienceInYear,
        };
        setDate0(new Date(primary[1].lastUsed || null));
        visibleObject["one"] = true;
      } catch {}
    }
    if (plength > 1) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill1: primary[2].skill,
          month1: primary[2].experienceInMonth,
          year1: primary[2].experienceInYear,
        };
        setDate1(new Date(primary[2].lastUsed || null));
        visibleObject["two"] = true;
      } catch {}
    }
    if (plength > 2) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill2: primary[3].skill,
          month2: primary[3].experienceInMonth,
          year2: primary[3].experienceInYear,
        };
        setDate2(new Date(primary[3].lastUsed || null));
        visibleObject["three"] = true;
      } catch {}
    }

    setVisible(Object.assign(visible, visibleObject));
    setInformation({
      ...information,
      ...primaryObject,
    });
  }
  // for secondary data
  if (payload.hasOwnProperty("secondary")) {
    const { secondary } = payload;
    //   first box
    let secondaryVisibleObject = {};
    let secondaryObject = {
      secondarySkill: secondary[0].skill,
      secondarySkillMonth: secondary[0].experienceInMonth,
      secondarySkillYear: secondary[0].experienceInYear,
    };
    setSecondaryDate(new Date(secondary[0].lastUsed));
    // second box
    if (secondary.length > 0) {
      try {
        secondaryObject = {
          ...secondaryObject,
          secondarySkill0: secondary[1].skill,
          secondarySkillMonth0: secondary[1].experienceInMonth,
          secondarySkillYear0: secondary[1].experienceInYear,
        };
        setSecondaryDate0(new Date(secondary[1].lastUsed));
        secondaryVisibleObject = {
          ...secondaryVisibleObject,
          one: true,
        };
      } catch {}
    }
    //   third box
    if (secondary.length > 1) {
      try {
        secondaryObject = {
          ...secondaryObject,
          secondarySkill1: secondary[2].skill,
          secondarySkillMonth1: secondary[2].experienceInMonth,
          secondarySkillYear1: secondary[2].experienceInYear,
        };
        setSecondaryDate1(new Date(secondary[2].lastUsed));
        secondaryVisibleObject = {
          ...secondaryVisibleObject,
          two: true,
        };
      } catch {}
    }
    //   fourth box
    if (secondary.length > 2) {
      try {
        secondaryObject = {
          ...secondaryObject,
          secondarySkill2: secondary[3].skill,
          secondarySkillMonth2: secondary[3].experienceInMonth,
          secondarySkillYear2: secondary[3].experienceInYear,
        };
        setSecondaryDate2(new Date(secondary[3].lastUsed));
        secondaryVisibleObject = {
          ...secondaryVisibleObject,
          three: true,
        };
      } catch {}
    }
    setSecondaryVisible(
      Object.assign(secondaryVisible, secondaryVisibleObject)
    );
    setSecondaryInfo({ ...secondaryInfo, ...secondaryObject });
  }
}

function setParsedSkillData(
  payload,
  information,
  setInformation,
  secondaryInfo,
  setSecondaryInfo,
  setSecondaryDate,
  setSecondaryDate0,
  setSecondaryDate1,
  setSecondaryDate2,
  visible,
  setVisible,
  secondaryVisible,
  setSecondaryVisible,
  setDate,
  setDate0,
  setDate1,
  setDate2
) {
  // for primary data
  if (payload.hasOwnProperty("skills")) {
    const { skills } = payload;
    const plength = skills.length;
    //   set first primary data
    setDate(new Date(skills[0].lastUsed || null));
    let visibleObject = {};
    let primaryObject = {
      primarySkill: skills[0].skill,
      month: skills[0].experienceMonth,
      year: skills[0].experienceYear,
    };
    if (plength > 0) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill0: skills[1].skill,
          month0: skills[1].experienceMonth,
          year0: skills[1].experienceYear,
        };
        setDate0(new Date(skills[1].lastUsed || null));
        visibleObject["one"] = true;
      } catch {}
    }
    if (plength > 1) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill1: skills[2].skill,
          month1: skills[2].experienceMonth,
          year1: skills[2].experienceYear,
        };
        setDate1(new Date(skills[2].lastUsed || null));
        visibleObject["two"] = true;
      } catch {}
    }
    if (plength > 2) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill2: skills[3].skill,
          month2: skills[3].experienceMonth,
          year2: skills[3].experienceYear,
        };
        setDate2(new Date(skills[3].lastUsed || null));
        visibleObject["three"] = true;
      } catch {}
    }

    setVisible(Object.assign(visible, visibleObject));
    setInformation({
      ...information,
      ...primaryObject,
    });
  }
  // for secondary data
  // if (payload.hasOwnProperty("secondary")) {
  //   const { secondary } = payload;
  //   //   first box
  //   let secondaryVisibleObject = {};
  //   let secondaryObject = {
  //     secondarySkill: secondary[0].skill,
  //     secondarySkillMonth: secondary[0].experienceInMonth,
  //     secondarySkillYear: secondary[0].experienceInYear,
  //   };
  //   setSecondaryDate(new Date(secondary[0].lastUsed));
  //   // second box
  //   if (secondary.length > 0) {
  //     try {
  //       secondaryObject = {
  //         ...secondaryObject,
  //         secondarySkill0: secondary[1].skill,
  //         secondarySkillMonth0: secondary[1].experienceInMonth,
  //         secondarySkillYear0: secondary[1].experienceInYear,
  //       };
  //       setSecondaryDate0(new Date(secondary[1].lastUsed));
  //       secondaryVisibleObject = {
  //         ...secondaryVisibleObject,
  //         one: true,
  //       };
  //     } catch {}
  //   }
  //   //   third box
  //   if (secondary.length > 1) {
  //     try {
  //       secondaryObject = {
  //         ...secondaryObject,
  //         secondarySkill1: secondary[2].skill,
  //         secondarySkillMonth1: secondary[2].experienceInMonth,
  //         secondarySkillYear1: secondary[2].experienceInYear,
  //       };
  //       setSecondaryDate1(new Date(secondary[2].lastUsed));
  //       secondaryVisibleObject = {
  //         ...secondaryVisibleObject,
  //         two: true,
  //       };
  //     } catch {}
  //   }
  //   //   fourth box
  //   if (secondary.length > 2) {
  //     try {
  //       secondaryObject = {
  //         ...secondaryObject,
  //         secondarySkill2: secondary[3].skill,
  //         secondarySkillMonth2: secondary[3].experienceInMonth,
  //         secondarySkillYear2: secondary[3].experienceInYear,
  //       };
  //       setSecondaryDate2(new Date(secondary[3].lastUsed));
  //       secondaryVisibleObject = {
  //         ...secondaryVisibleObject,
  //         three: true,
  //       };
  //     } catch {}
  //   }
  //   setSecondaryVisible(
  //     Object.assign(secondaryVisible, secondaryVisibleObject)
  //   );
  //   setSecondaryInfo({ ...secondaryInfo, ...secondaryObject });
  // }
}

export { setSkillData,setParsedSkillData };
