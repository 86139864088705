function setSkillData(
  payload,
  information,
  setInformation,
  secondaryInfo,
  setSecondaryInfo,
  experience,
  setExperience,
  primeExperience,
  setPrimeExperience,
  visible,
  setVisible,
  secondaryVisible,
  setSecondaryVisible,
  setMandatoryType,
  setMandatoryType0,
  setMandatoryType1
) {
  // for primary data
  if (payload.hasOwnProperty("ClientPrimarySkills")) {
    const { ClientPrimarySkills } = payload;
    const plength = ClientPrimarySkills.length;
    //   set first primary data
    let visibleObject = {};
    let primaryExperience = {};
    let primaryObject = {
      primarySkill: ClientPrimarySkills[0].ClientPrimarySkill,
    };
    primaryExperience = {
      ...primaryExperience,
      year: ClientPrimarySkills[0].year,
      month: ClientPrimarySkills[0].month,
    };
    if (plength > 0) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill0: ClientPrimarySkills[1].ClientPrimarySkill,
        };
        primaryExperience = {
          ...primaryExperience,
          year0: ClientPrimarySkills[1].year,
          month0: ClientPrimarySkills[1].month,
        };
        visibleObject["one"] = true;
      } catch {}
    }
    if (plength > 1) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill1: ClientPrimarySkills[2].ClientPrimarySkill,
        };
        primaryExperience = {
          ...primaryExperience,
          year1: ClientPrimarySkills[2].year,
          month1: ClientPrimarySkills[2].month,
        };
        visibleObject["two"] = true;
      } catch {}
    }
    if (plength > 2) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill2: ClientPrimarySkills[3].ClientPrimarySkill,
        };
        primaryExperience = {
          ...primaryExperience,
          year2: ClientPrimarySkills[3].year,
          month2: ClientPrimarySkills[3].month,
        };
        visibleObject["three"] = true;
      } catch {}
    }
    if (plength > 3) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill3: ClientPrimarySkills[4].ClientPrimarySkill,
        };
        primaryExperience = {
          ...primaryExperience,
          year3: ClientPrimarySkills[4].year,
          month3: ClientPrimarySkills[4].month,
        };
        visibleObject["four"] = true;
      } catch {}
    }
    setVisible(Object.assign(visible, visibleObject));
    setPrimeExperience({ ...primeExperience, ...primaryExperience });
    setInformation({
      ...information,
      ...primaryObject,
    });
  }
  if (payload.hasOwnProperty("ClientSecondarySkills")) {
    const { ClientSecondarySkills } = payload;
    //   first box
    let secondaryVisibleObject = {};
    let secondaryExperience = {};
    let secondaryObject = {
      secondarySkill: ClientSecondarySkills[0].ClientSecondarySkill,
    };
    secondaryExperience = {
      ...secondaryExperience,
      secondaryYear: ClientSecondarySkills[0].year,
      secondaryMonth: ClientSecondarySkills[0].month,
    };
    if (ClientSecondarySkills[0].type === "MANDATORY") {
      setMandatoryType(true);
    } else {
      setMandatoryType(false);
    }
    if (ClientSecondarySkills.length > 0) {
      try {
        secondaryObject = {
          ...secondaryObject,
          secondarySkill0: ClientSecondarySkills[1].ClientSecondarySkill,
        };
        secondaryExperience = {
          ...secondaryExperience,
          secondaryYear0: ClientSecondarySkills[1].year,
          secondaryMonth0: ClientSecondarySkills[1].month,
        };
        if (ClientSecondarySkills[1].type === "MANDATORY") {
          setMandatoryType0(true);
        } else {
          setMandatoryType0(false);
        }
        secondaryVisibleObject = {
          ...secondaryVisibleObject,
          one: true,
        };
      } catch {}
    }
    if (ClientSecondarySkills.length > 1) {
      try {
        secondaryObject = {
          ...secondaryObject,
          secondarySkill1: ClientSecondarySkills[2].ClientSecondarySkill,
        };
        secondaryExperience = {
          ...secondaryExperience,
          secondaryYear1: ClientSecondarySkills[2].year,
          secondaryMonth1: ClientSecondarySkills[2].month,
        };
        if (ClientSecondarySkills[2].type === "MANDATORY") {
          setMandatoryType1(true);
        } else {
          setMandatoryType1(false);
        }
        secondaryVisibleObject = {
          ...secondaryVisibleObject,
          two: true,
        };
      } catch {}
    }
    setSecondaryVisible(
      Object.assign(secondaryVisible, secondaryVisibleObject)
    );
    setExperience({ ...experience, ...secondaryExperience });
    setSecondaryInfo({ ...secondaryInfo, ...secondaryObject });
  }
}

function setParsedSkillData(
  payload,
  information,
  setInformation,
  secondaryInfo,
  setSecondaryInfo,
  visible,
  setVisible,
  secondaryVisible,
  setSecondaryVisible,
) {
  // for primary data
  if (payload.hasOwnProperty("primarySkills")) {
    const { primarySkills } = payload;
    const plength = primarySkills.length;
    //   set first primary data
    let visibleObject = {};
   let primaryObject = {
      primarySkill: primarySkills[0]
    };
    if (plength > 1) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill0: primarySkills[1]
        };
       visibleObject["one"] = true;
      } catch {}
    }
    if (plength > 2) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill1: primarySkills[2]
        };
       visibleObject["two"] = true;
      } catch {}
    }
    if (plength > 3) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill2: primarySkills[3],
        };
        visibleObject["three"] = true;
      } catch {}
    }
    if (plength > 4) {
      try {
        primaryObject = {
          ...primaryObject,
          primarySkill3: primarySkills[4],
        };
         visibleObject["four"] = true;
      } catch {}
    }
    setVisible(Object.assign(visible, visibleObject));
    setInformation({
      ...information,
      ...primaryObject,
    });
  }
  if (payload.hasOwnProperty("secondarySkills")) {
    const { secondarySkills } = payload;
    //   first box
    let secondaryVisibleObject = {};
    let secondaryObject = {
      secondarySkill: secondarySkills[0],
    };
   if (secondarySkills.length > 0) {
      try {
        secondaryObject = {
          ...secondaryObject,
          secondarySkill0: secondarySkills[1],
        };
       secondaryVisibleObject = {
          ...secondaryVisibleObject,
          one: true,
        };
      } catch {}
    }
    if (secondarySkills.length > 1) {
      try {
        secondaryObject = {
          ...secondaryObject,
          secondarySkill1: secondarySkills[2]
        };
        secondaryVisibleObject = {
          ...secondaryVisibleObject,
          two: true,
        };
      } catch {}
    }
    setSecondaryVisible(
      Object.assign(secondaryVisible, secondaryVisibleObject)
    );
   setSecondaryInfo({ ...secondaryInfo, ...secondaryObject });
  }
}

export { setSkillData,setParsedSkillData };
