import { UPLOADIMAGE } from "../ENVIRONMENT.js";

async function uploadResume(token, information) {
  // api for submit candidate uploadresume
  try {
    const fileObj = information.resume;
    const fileName = fileObj.name;
    const fileExtension = fileName.match(/[a-zA-Z]{2,4}$/)[0];

    const response = await fetch(UPLOADIMAGE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
      body: JSON.stringify({
        for: "CANDIDATE",
        files: [
          {
            extension: fileExtension,
            contentType: fileExtension,
            fileName: fileName,
          },
        ],
      }),
    });

    const result = await response.json();
    const { signedUrl, fileUrl } = result.payload.signedUrls[0];
    const file = await fetch(fileUrl);
    const fileBinary = await file.blob();
    await fetch(signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: fileBinary,
    });
  } catch {}
}

export { uploadResume };
