import { SUPERADMINCLIENTLIST } from "../../ENVIRONMENT";

async function clientListData(token, currentPage) {
  // api for client list
  const response = await fetch(
    SUPERADMINCLIENTLIST + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
export { clientListData };
