import "../../styles/app.scss";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ClientDashcard from "../../common-component/ClientDashcard";
import MatchingjobCard from "../../common-component/clientMatchingjobCard";
import GoToTop from "../common/GoToTop";
import { getPermanentDataClient } from "../../api/client/permanentDataClient";
import { toTitleCase } from "../../function/common/convertToTitleCase";
import { recgetPermanentDataClient } from "../../api/recruiter/client/permanentDataClient";
import { freegetPermanentDataClient } from "../../api/freelance/client/permanentDataClient";

const DashboardView = () => {
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  // permanent data
  const [information, setInformation] = useState({
    clientName: "",
    companyName: "",
    clientId: "",
    corporateEmail: "",
    location: "",
    workPhone: "",
  });

  useEffect(() => {
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await recgetPermanentDataClient(token)
          : isFreelance
          ? await freegetPermanentDataClient(token)
          : await getPermanentDataClient(token);
        if (response.status === 200) {
          const payload = await response.json();
          if (payload.payload.location.length > 20) {
            payload.payload.location = payload.payload.location + "...";
          } else if (
            payload.payload.companyName &&
            payload.payload.companyName.length > 20
          ) {
            payload.payload.companyName = payload.payload.companyName + "...";
          }
          setInformation({ ...information, ...payload.payload });
        }
      } catch {}
    }
    callItSelf();
  }, []);

  // return dashboard view for existing user of client

  try {
    document.getElementById("postjobid").style.display = "inline-block";
    document.getElementById("replacetitleimg").innerText = "Dashboard";
  } catch {}

  return (
    <div className={"col mx-3"}>
      <div className={"col-lg-12 pr-0"}>
        <div className={"row"}>
          <div className={"col-lg-9 p-0"}>
            <div className={"row viewalignment pb-3"}>
              <ClientDashcard />
            </div>
          </div>
          {/*permanent data*/}
          <div className={"col-xl-3 pt-4"}>
            <div
              className={"p-2 pb-3"}
              style={{ background: "#FFFFFF", borderRadius: "20px" }}
            >
              <div className={"row"}>
                <div className={"col-md"}>
                  <div className={"float-right"}>
                    <span
                      className={"pt-1 pb-1 pl-2 pr-2"}
                      style={{
                        background: "rgba(255, 138, 0, 0.2)",
                        borderRadius: "8px",
                      }}
                    >
                      <label style={{ color: "#FF8A00" }}>&#9733; 4.3</label>
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className={"dashboardfontusername"}>
                      {information.firstName &&
                        information.firstName.charAt(0).toUpperCase() +
                          information.firstName.slice(1).toLowerCase()}
                      &nbsp;
                      {information.middleName === "null"
                        ? ""
                        : information.middleName &&
                          information.middleName.charAt(0).toUpperCase() +
                            information.middleName.slice(1).toLowerCase()}
                      &nbsp;
                      {information.lastName &&
                        information.lastName.charAt(0).toUpperCase() +
                          information.lastName.slice(1).toLowerCase()}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label
                      style={{
                        color: "#8743DF",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {information.companyName &&
                        toTitleCase(information.companyName)}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md pt-0"}>
                    <label className="staticdatacommonfont">
                      {information.clientId}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className="dashboardfontusername">
                      Permanent Data
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={"col-md"}
                    style={{
                      color: "#8A8A8A",
                      wordBreak: "break-all",
                      textAlign: "justify",
                      marginRight: "42px",
                      marginLeft: "50px",
                    }}
                  >
                    <label className="staticdatacommonfont">
                      Email:{information.corporateEmail}
                    </label>
                  </div>
                </div>
                <div className={"row mt-3"}>
                  <div className={"col-3 pl-5 ml-3"}>
                    <img
                      src="../icon/candidate/dashboard/whitelocation.png"
                      alt={"whitelocationicon"}
                      className={"pl-2 pr-2 pt-2 pb-2"}
                      style={{
                        backgroundColor: "#FBA556",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className={"col mt-2"}>
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#2E2E2E",
                        overflow: "hidden",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {toTitleCase(information.location)}
                    </div>
                  </div>
                </div>
                <div className={"row mt-3"}>
                  <div className={"col-3 pl-5 ml-3"}>
                    <img
                      src="../icon/candidate/dashboard/call.png"
                      alt={"call"}
                      className={"pl-2 pr-2 pt-2 pb-2"}
                      style={{
                        backgroundColor: "#F4EBFF",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className={"col pt-2 pl-0"}>
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#2E2E2E",
                      }}
                    >
                      {information.workPhone}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"row viewalignment"}>
        <MatchingjobCard />
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(DashboardView);
