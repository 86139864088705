import {COUNTNOTIFICATIONS} from "../ENVIRONMENT";

// api for notification
async function counting(token) {
  const response = await fetch(COUNTNOTIFICATIONS, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
  export {counting}