import { FREELANCEBUILDREQUIREMENT } from "../../../ENVIRONMENT";

// api for freelance client build requirement security
async function freepostBuildRequirementSecurity(token, data, id) {
  return await fetch(FREELANCEBUILDREQUIREMENT + `/${id}/securityclearance`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}
export { freepostBuildRequirementSecurity };
