var prevevent;
const data = [
  {
    title: "Any carol",
    time: "12:48PM",
    subject: "Offer letter",
    text: "Hey Cak, Could you free now? Can you look and read the brief first…",
  },
  {
    title: "Any carol",
    time: "12:48PM",
    subject: "Offer letter",
    text: "Hey Cak, Could you free now? Can you look and read the brief first…",
  },
  {
    title: "Any carol",
    time: "12:48PM",
    subject: "Offer letter",
    text: "Hey Cak, Could you free now? Can you look and read the brief first…",
  },
  {
    title: "Any carol",
    time: "12:48PM",
    subject: "Offer letter",
    text: "Hey Cak, Could you free now? Can you look and read the brief first…",
  },
  {
    title: "Any carol",
    time: "12:48PM",
    subject: "Offer letter",
    text: "Hey Cak, Could you free now? Can you look and read the brief first…",
  },
  {
    title: "Any carol",
    time: "12:48PM",
    subject: "Offer letter",
    text: "Hey Cak, Could you free now? Can you look and read the brief first…",
  },
];

function changeBgText(event) {
  // first message card will appear selected by default if we click on next it reset previous and set appearance to current one
  const default_card =
    event.currentTarget.parentNode.parentNode.firstChild.firstChild;
  if (default_card.parentNode.firstChild.style.backgroundColor.length > 0) {
    default_card.parentNode.firstChild.style.cssText = "background-color:white";
    for (let i of default_card.getElementsByTagName("label")) {
      i.style.color = "black";
    }
  }
  try {
    prevevent.parentNode.firstChild.style.cssText = "";
    for (let i of prevevent.getElementsByTagName("label")) {
      i.style.color = "";
    }
  } catch {}
  try {
    const target = event.currentTarget;
    target.parentNode.firstChild.style.cssText =
      "background-color:#3F9AE0;border-radius:15px";
    for (let i of target.getElementsByTagName("label")) {
      i.style.color = "white";
    }
    try {
      target.parentNode.children[0].children[0].lastChild.childNodes[2].lastChild.children[0].style.display =
        "none";
    } catch {}
    prevevent = target;
  } catch {}
}

export { changeBgText, data };
