import { RECRUITERCANDIDATERESUMEUPLOAD,RECRUITERCANDIDATEPROFILE } from "../../ENVIRONMENT.js";

function recresumeParsing(token,id, data) {
  // api for recruiter candidate resume parsing
  const response = fetch(RECRUITERCANDIDATERESUMEUPLOAD + `/${id}/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

async function recgetResumeProfileDetails(token,id){
  const response = await fetch(
    RECRUITERCANDIDATEPROFILE + `/${id}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { recresumeParsing,recgetResumeProfileDetails };
