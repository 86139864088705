import { FREELANCECLIENTREQUIREMENT } from "../../ENVIRONMENT";

// api for freelance download requirement
async function freedownloadRequirement(token, id) {
  const response = await fetch(FREELANCECLIENTREQUIREMENT + `/download/${id}`, {
    observe: "response",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
      "Content-Type": "text/csv",
    },
  });
  return response;
}

export { freedownloadRequirement };
