function postParseData(information, visibility, securityDocument) {
  const parsedData = [];
  if (visibility.zero) {
    try {
      parsedData.push({
        classification: information.classification,
        category: information.category,
        validYear: information.year,
        validMonth: information.month,
        comment: information.comment,
        lifeTimeValidity: information.lifeTimeValidity,
      });
    } catch {}
  }

  if (visibility.one) {
    try {
      parsedData.push({
        classification: information.classification1,
        category: information.category1,
        validYear: information.year1,
        validMonth: information.month1,
        comment: information.comment1,
        lifeTimeValidity: information.lifeTimeValidity1,
      });
    } catch {}
  }
  if (visibility.two) {
    try {
      parsedData.push({
        classification: information.classification2,
        category: information.category2,
        validYear: information.year2,
        validMonth: information.month2,
        comment: information.comment2,
        lifeTimeValidity: information.lifeTimeValidity2,
      });
    } catch {}
  }

  const temp = Object.keys(parsedData[0]);
  parsedData.map((obj, index) => {
    if (obj.securityClearance === "Yes") {
      temp.map((key) => {
        if (key === "comment" || key === "documentURL")
          if (!obj[key]) {
            delete obj[key];
          }
      });
    } else {
      temp.map((key) => {
        if (obj[key] === "" || obj[key] === null) {
          delete obj[key];
        }
      });
    }
  });
  let securityparseData = {
    haveSecurityClearance: visibility.zeroin ? "Yes" : "No",
  };
  if (visibility.zeroin) {
    securityparseData = {
      ...securityparseData,
      securityClearance: parsedData,
    };
  }
  if (securityDocument.passport !== "") {
    securityparseData = {
      ...securityparseData,
      passport: securityDocument.passport,
    };
  }
  if (securityDocument.workAuth !== "") {
    securityparseData = {
      ...securityparseData,
      workAuthorization: securityDocument.workAuth,
    };
  }
  if (securityDocument.drivingLicense !== "") {
    securityparseData = {
      ...securityparseData,
      drivingLicense: securityDocument.drivingLicense,
    };
  }

  return securityparseData;
}

function getParseData(
  payload,
  visibility,
  setVisibility,
  information,
  setInformation
) {
  //
  const visible = {};
  visible["zeroin"] =
    payload.candidate.securityClearance === "Yes" ? true : false;
  let parsedData = {};

  if (payload.securityClearance[0]) {
    try {
      const tempObject = payload.securityClearance[0];
      parsedData = {
        ...parsedData,
        classification: tempObject.classification,
        category: tempObject.category,
        year: tempObject.validYear,
        month: tempObject.validMonth,
        comment: tempObject.comment,
        lifeTimeValidity: tempObject.lifeTimeValidity,
      };
    } catch {}
  }

  if (payload.securityClearance[1]) {
    try {
      const tempObj = payload.securityClearance[1];
      visible["one"] = true;
      parsedData = {
        ...parsedData,
        classification1: tempObj.classification,
        category1: tempObj.category,
        year1: tempObj.validYear,
        month1: tempObj.validMonth,
        comment1: tempObj.comment,
        lifeTimeValidity1: tempObj.lifeTimeValidity,
      };
    } catch {}
  }
  if (payload.securityClearance[2]) {
    try {
      const tempObj = payload.securityClearance[2];
      visible["two"] = true;
      parsedData = {
        ...parsedData,
        classification2: tempObj.classification,
        category2: tempObj.category,
        year2: tempObj.validYear,
        month2: tempObj.validMonth,
        comment2: tempObj.comment,
        lifeTimeValidity2: tempObj.lifeTimeValidity,
      };
    } catch {}
  }
  setInformation({ ...information, ...parsedData });
  setVisibility({ ...visibility, ...visible });
  return parsedData;
}

export { postParseData, getParseData };
