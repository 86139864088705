const requiredList = [
  "fein",
  "workPhone",
  "zipCode",
  "addressLine1",
  "city",
  "state",
  "country",
  "firstName",
  "lastName",
  "title",
  "email",
];

const errorMsg = [
  "fein",
  "phoneNumber",
  "zipCode",
  "address",
  "city",
  "state",
  "country",
  "firstName",
  "lastName",
  "title",
  "email",
];

function verifyValidate(
  information,
  setError,
  addmore,
  addMoreInformation,
  setAddMoreError
) {
  // check if required empty form will not submit
  let flag = true;
  requiredList.map((value, index) => {
    const val = information[value];
    if (!val)
      setError((state) => {
        flag = false;
        return { ...state, [value]: `Please enter the ${errorMsg[index]}` };
      });
  });
  // check addmore div required validation also
  if (addmore) {
    requiredList.map((value, index) => {
      const val = addMoreInformation[value];
      if (!val)
        setAddMoreError((state) => {
          flag = false;
          return { ...state, [value]: `Please enter the ${errorMsg[index]}` };
        });
    });
  }
  const flagTwo = requiredList
    .map((value) => {
      return information[value] ? true : false;
    })
    .every((value) => {
      return value ? true : false;
    });
  return flag && flagTwo;
}
//job posting requirement info
const requirementInfoRequiredList = [
  "jobTitle",
  "location",
  "domain",
  "minDuration",
  "interviewType",
];

const requirementInfoErrorMsg = [
  "jobTitle",
  "location",
  "domain",
  "minDuration",
  "interviewType",
];

function verifyJobRequirementInfo(error, setError, information) {
  let flag = true;
  requirementInfoRequiredList.map((value, index) => {
    const val = information[value];
    if (!val)
      setError((state) => {
        flag = false;
        return {
          ...state,
          [value]: `Please enter the ${requirementInfoErrorMsg[index]}`,
        };
      });
  });
  const flagTwo = requirementInfoRequiredList
    .map((value) => {
      return information[value] ? true : false;
    })
    .every((value) => {
      return value ? true : false;
    });
  return flag && flagTwo;
}

//job posting skill
function verifyBuildRequirementSkill(error, setError, information, visibility) {
  // check required
  const requiredzero = ["primarySkill"];
  const requiredone = ["primarySkill0"];
  const requiredtwo = ["primarySkill1"];
  const requiredthree = ["primarySkill2"];
  const requiredfour = ["primarySkill3"];

  Object.keys(information).map((key) => {
    setError((state) => {
      if (!information[key] || information[key].trim() === "") {
        state = {
          ...state,
          [key]: `Please enter the ${key.replace(/\d/, "")}`,
        };
      }
      return state;
    });
  });

  const flagone = !requiredzero
    .map((key) => {
      if (!information[key] || information[key].trim() === "") return false;
      return null;
    })
    .concat(
      visibility.one
        ? requiredone.map((key) => {
            if (!information[key] || information[key].trim() === "")
              return false;
            return null;
          })
        : [],
      visibility.two
        ? requiredtwo.map((key) => {
            if (!information[key] || information[key].trim() === "")
              return false;
            return null;
          })
        : [],
      visibility.three
        ? requiredthree.map((key) => {
            if (!information[key] || information[key].trim() === "")
              return false;
            return null;
          })
        : [],
      visibility.four
        ? requiredfour.map((key) => {
            if (!information[key] || information[key].trim() === "")
              return false;
            return null;
          })
        : []
    )
    .includes(false);
  const flagtwo = !requiredzero
    .map((key) => {
      if (error[key]) return false;
      return null;
    })
    .concat(
      visibility.one
        ? requiredone.map((key) => {
            if (error[key]) return false;
            return null;
          })
        : [],
      visibility.two
        ? requiredtwo.map((key) => {
            if (error[key]) return false;
            return null;
          })
        : [],
      visibility.three
        ? requiredthree.map((key) => {
            if (error[key]) return false;
            return null;
          })
        : [],
      visibility.four
        ? requiredfour.map((key) => {
            if (error[key]) return false;
            return null;
          })
        : []
    )
    .includes(false);
  return flagone && flagtwo;
}

//Job posting academics & certificate
const requiredAcademic = ["qualification"];
const academicField = ["qualification"];

function verifyJobPostingAcademic(error, setError, information, visibility) {
  let flag = true;
  requiredAcademic.map((value, index) => {
    const val = information[value];
    if (!val)
      setError((state) => {
        flag = false;
        return { ...state, [value]: "Please enter the qualification" };
      });
  });
  const flagTwo = requiredAcademic
    .map((value) => {
      return information[value] ? true : false;
    })
    .every((value) => {
      return value ? true : false;
    });
  return flag && flagTwo;
}
//job posting security
const requiredSecurity = ["classification", "category"];
const errMsgSecurity = ["classification", "category"];

function verifyJobPostingSecurity(error, setError, information, visibility) {
  let flag = true;
  const securityField = ["classification", "category"];
  requiredSecurity.map((value, index) => {
    const val = information[value];
    if (!val)
      setError((state) => {
        flag = false;
        if (!visibility) {
          flag = true;
          return { ...state, [value]: "" };
        }
        return {
          ...state,
          [value]: `Please enter the ${errMsgSecurity[index]}`,
        };
      });
  });

  const flagTwo = requiredSecurity
    .map((value) => {
      if (!visibility && securityField.includes(value)) return true;
      return information[value] ? true : false;
    })
    .every((value) => {
      return value ? true : false;
    });
  return flag && flagTwo;
}
export {
  verifyValidate,
  verifyJobRequirementInfo,
  verifyBuildRequirementSkill,
  verifyJobPostingAcademic,
  verifyJobPostingSecurity,
};
