import { AUTHENTICATION_URL } from "../ENVIRONMENT";

async function verifyEmail(id) {
  // api for email verification for new user
  const response = await fetch(`${AUTHENTICATION_URL}${id}/verify`, {
    method: "PUT",
  });
  return response;
}
export { verifyEmail };
