import { FREELANCECANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for freelance mtching job sorting
async function freematchingjobSorting(token, currentPage, percentage, order) {
  const response = await fetch(
    FREELANCECANDIDATEMATCHINGJOBS +
      `?limit=6&page=${currentPage}&${percentage}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

// api for freelance matching job card sorting
async function freematchingjobCardSorting(
  token,
  card,
  currentPage,
  percentage,
  order
) {
  const response = await fetch(
    FREELANCECANDIDATEMATCHINGJOBS +
      `?card=${card}&limit=6&page=${currentPage}&${percentage}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freematchingjobSorting, freematchingjobCardSorting };
