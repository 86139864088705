import { RECRUITERCANDIDATEAPPLICATION } from "../../ENVIRONMENT";

// api for recruiter candidate application sorting
async function recapplicationSorting(token, currentPage, name, order) {
  const response = await fetch(
    RECRUITERCANDIDATEAPPLICATION +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { recapplicationSorting };
