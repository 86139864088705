import {
  FREELANCECLIENTREQUIREMENT,
  FREELANCECLIENTACTIVEJOBS,
  FREELANCEARCHIVEREQUIREMENTS,
} from "../../ENVIRONMENT";

// api for freelance requirement sorting
async function freerequirementSorting(token, currentPage, name, order) {
  const response = await fetch(
    FREELANCECLIENTREQUIREMENT +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance requirementcard sorting
async function freerequirementCardSorting(
  token,
  currentPage,
  name,
  order,
  card
) {
  const response = await fetch(
    FREELANCECLIENTREQUIREMENT +
      `?limit=6&page=${currentPage}&${name}=${order}&card=${card}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance activejob  sorting
async function freeactiveJobSorting(token, currentPage, name, order) {
  const response = await fetch(
    FREELANCECLIENTACTIVEJOBS + `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance archive job sorting
async function freearchiveJobSorting(token, currentPage, name, order) {
  const response = await fetch(
    FREELANCEARCHIVEREQUIREMENTS +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  freerequirementSorting,
  freeactiveJobSorting,
  freearchiveJobSorting,
  freerequirementCardSorting,
};
