import { CANDIDATEDOMAIN } from "../ENVIRONMENT";

function domainList(token) {
  // api for candidate domain list
  const response = fetch(CANDIDATEDOMAIN, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
export { domainList };
