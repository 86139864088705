import { CANDIDATEEXPERIENCE } from "../ENVIRONMENT";

async function postExperience(token, data) {
  // api for candidate submit bbuildresume experience
  const response = await fetch(CANDIDATEEXPERIENCE, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

async function getExperience(token) {
  // api for candidate buildresume experience details
  const response = await fetch(CANDIDATEEXPERIENCE, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
export { postExperience, getExperience };
