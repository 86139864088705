import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ApplicationListData = (props) => {
  const [match, setMatch] = useState([
    {
      Candidate: {
        candidateId: "DS2829939",
        preferredLocation: "Yellow spring",
        resumeTitle: "Java Developer",
        rate: "90",
        CandidateExperiences: [
          {
            totalYearOfExperience: "7",
          },
        ],
      },
      JobRequirement: {
        domain: "java",
      },
      matchPercentage: "90",
      status: "scheduled",
    },
    {
      Candidate: {
        candidateId: "DS2829939",
        preferredLocation: "Yellow spring",
        resumeTitle: "Java Developer",
        rate: "90",
        CandidateExperiences: [
          {
            totalYearOfExperience: "7",
          },
        ],
      },
      JobRequirement: {
        domain: "java",
      },
      matchPercentage: "90",
      status: "scheduled",
    },
    {
      Candidate: {
        candidateId: "DS2829939",
        preferredLocation: "Yellow spring",
        resumeTitle: "Java Developer",
        rate: "90",
        CandidateExperiences: [
          {
            totalYearOfExperience: "7",
          },
        ],
      },
      JobRequirement: {
        domain: "java",
      },
      matchPercentage: "90",
      status: "scheduled",
    },
    {
      Candidate: {
        candidateId: "DS2829939",
        preferredLocation: "Yellow spring",
        resumeTitle: "Java Developer",
        rate: "90",
        CandidateExperiences: [
          {
            totalYearOfExperience: "7",
          },
        ],
      },
      JobRequirement: {
        domain: "java",
      },
      matchPercentage: "90",
      status: "scheduled",
    },
    {
      Candidate: {
        candidateId: "DS2829939",
        preferredLocation: "Yellow spring",
        resumeTitle: "Java Developer",
        rate: "90",
        CandidateExperiences: [
          {
            totalYearOfExperience: "7",
          },
        ],
      },
      JobRequirement: {
        domain: "java",
      },
      matchPercentage: "90",
      status: "scheduled",
    },
    
  ]);
  const [loading, setLoading] = useState();
  //   useEffect(() => {
  //     try {
  //       setLoading(true);
  //       if (props.item.length !== 0) {
  //         setMatch(props.item);
  //         setLoading(false);
  //       }
  //       if (props.record === 0) {
  //         setLoading(false);
  //       }
  //     } catch {}
  //   }, [props.item]);

  return (
    // return application table data in a group which generate by data variable
    <>
      {match.map((match) => (
        <div
          className={"row"}
          style={{
            boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
            backgroundColor: "white",
            marginTop: "1px",
          }}
          key={match.Candidate.candidateId}
        >
          <div className={"col py-3 box"}>
            {/*heading box data*/}
            <div className={"row align-items-center"}>
              <div className={"col-2"} style={{ textAlign: "center" }}>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className={"matchingjobdata text-primary"}>
                      {match.Candidate.candidateId}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={"col-md"}
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <font style={{ fontWeight: 500, fontSize: "14px" }}>
                      {match.Candidate.preferredLocation === null
                        ? match.Candidate.Addresses[0].city
                        : match.Candidate.preferredLocation}
                    </font>
                  </div>
                </div>
              </div>
              <div className={"col-2"} style={{ textAlign: "center" }}>
                <div
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <font className="matchingjobdata">
                    {match.Candidate && match.Candidate.resumeTitle}
                  </font>
                </div>
              </div>
              <div className={"col-1"}>
                <font style={{ fontWeight: "normal", fontSize: "14px" }}>
                  Freelance
                </font>
              </div>
              <div className="col-1 ml-4" style={{ textAlign: "center" }}>
                <font style={{ fontWeight: "normal", fontSize: "14px" }}>
                  {match.matchPercentage} %
                </font>
              </div>
              <div className={"col-1"} style={{ textAlign: "center" }}>
                <font style={{ fontWeight: "normal", fontSize: "14px" }}>
                  {match.Candidate.CandidateExperiences.length !== 0
                    ? match.Candidate.CandidateExperiences[0]
                        .totalYearOfExperience
                    : "0"}{" "}
                  year
                </font>
              </div>
              <div className={"col-2"}>
                <div
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    textAlign: "center",
                  }}
                >
                  <font
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                    }}
                  >
                    ${match.Candidate.rate}
                  </font>
                </div>
              </div>
              <div className="col-1">
                <label
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  {match.status}
                </label>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export { ApplicationListData };
