import {
  RECRUITERCLIENTREQUIREMENT,
  RECRUITERCLIENTACTIVEJOBS,
  RECRUITERARCHIVEREQUIREMENTS,
} from "../../ENVIRONMENT";

// api for recruiter requirement sorting
async function recrequirementSorting(token, currentPage, name, order) {
  const response = await fetch(
    RECRUITERCLIENTREQUIREMENT +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter requirementcard sorting
async function recrequirementCardSorting(
  token,
  currentPage,
  name,
  order,
  card
) {
  const response = await fetch(
    RECRUITERCLIENTREQUIREMENT +
      `?limit=6&page=${currentPage}&${name}=${order}&card=${card}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter active job sorting
async function recactiveJobSorting(token, currentPage, name, order) {
  const response = await fetch(
    RECRUITERCLIENTACTIVEJOBS + `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter archive job sorting
async function recarchiveJobSorting(token, currentPage, name, order) {
  const response = await fetch(
    RECRUITERARCHIVEREQUIREMENTS +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  recrequirementSorting,
  recactiveJobSorting,
  recarchiveJobSorting,
  recrequirementCardSorting,
};
