import { CLIENTDASHBOARDREQUIREMENT } from "../ENVIRONMENT";
// api for client archive requirement
async function deleteRequirment(token, id) {
  const response = await fetch(CLIENTDASHBOARDREQUIREMENT + `/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

async function deleteAssociatedRequirment(token, id, data) {
  // api for client archive applied requirement
  const response = await fetch(CLIENTDASHBOARDREQUIREMENT + `/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export { deleteRequirment, deleteAssociatedRequirment };
