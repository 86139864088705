import { FREELANCEBUILDSECURITY } from "../../../ENVIRONMENT";

// api for freelance candidate security
async function freepostSecurity(token, data, id) {
  return await fetch(FREELANCEBUILDSECURITY + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}
// api for freelance candidate security details
async function freegetSecurity(token, id) {
  return await fetch(FREELANCEBUILDSECURITY + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { freepostSecurity, freegetSecurity };
