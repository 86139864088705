import "../../styles/app.scss";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import GoToTop from "../common/GoToTop";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { candidateListData } from "../../api/recruiter/candidate/candidateListData";
import { freecandidateListData } from "../../api/freelance/candidate/candidateListData";
import { freecandidateListSorting } from "../../api/freelance/candidate/candidateListSorting";
import { candidateListSorting } from "../../api/recruiter/candidate/candidateListSorting";
import { Data } from "../../function/recruiter/candidateListData";

const CandidateListDetails = () => {
  // return application page
  try {
    document.getElementById("replacetitleimg").innerText = "Applications";
    document.getElementById("dashboardfont").innerText = "Job Posting";
    document.getElementById("dashboardbtn").style.display = "none";
  } catch {}

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const token = useSelector((state) => state.token);
  const isFreelance = useSelector((state) => state.isFreelance);
  const [item, setItem] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState();
  const [idOrder, setIdOrder] = useState(true);
  const [expOrder, setExpOrder] = useState(true);
  const [rateOrder, setRateOrder] = useState(true);
  const [totalLength, setTotalLength] = useState();
  const [loading, setLoading] = useState();
  const [record, setRecord] = useState();
  

  useEffect(() => {
    async function callUserDetails() {
      try {
        setLoading(true);
        const response = isFreelance
          ? await freecandidateListData(token, 1)
          : await candidateListData(token, 1);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
          setTotalLength(payload[6].totalRecord);
        }
      } catch {}
    }
    callUserDetails();
  }, []);

  //pagination
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPage(data.selected);
    setLoading(true);
    const response = isFreelance
      ? await freecandidateListData(token, currentPage)
      : await candidateListData(token, currentPage);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setItem(payload.slice(0, -1));
      const totalRecord = payload[payload.length - 1].totalRecord;
      setRecord(totalRecord);
      setLoading(false);
    }
  };

  //for id sorting
  const handleIdSorting = async () => {
    let rate = "id";
    let high = "high";
    let low = "low";
    setPage(0);
    if (idOrder) {
      const response = isFreelance
        ? await freecandidateListSorting(token, 1, rate, high)
        : await candidateListSorting(token, 1, rate, high);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else {
      const response = isFreelance
        ? await freecandidateListSorting(token, 1, rate, low)
        : await candidateListSorting(token, 1, rate, low);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    }
  };
  //for rate sorting
  const handleRateSorting = async () => {
    let rate = "rate";
    let high = "high";
    let low = "low";
    setPage(0);
    if (rateOrder) {
      const response = isFreelance
        ? await freecandidateListSorting(token, 1, rate, high)
        : await candidateListSorting(token, 1, rate, high);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else {
      const response = isFreelance
        ? await freecandidateListSorting(token, 1, rate, low)
        : await candidateListSorting(token, 1, rate, low);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    }
  };
  //for experience sorting
  const handleExpSorting = async () => {
    let rate = "experience";
    let high = "high";
    let low = "low";
    setPage(0);
    if (expOrder) {
      const response = isFreelance
        ? await freecandidateListSorting(token, 1, rate, high)
        : await candidateListSorting(token, 1, rate, high);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else {
      const response = isFreelance
        ? await freecandidateListSorting(token, 1, rate, low)
        : await candidateListSorting(token, 1, rate, low);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    }
  };

  
 return (
    <div className={"col mx-4 mt-5 pt-1"}>
      {/*this is first box*/}
      <div
        className={"row mt-3 align-items-center pl-3"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row"}>
            <div className={"col-2 pr-0 pl-0"}>
              {/* <input
                type={"checkbox"}
                className={"checkbx  mr-3"}
                onClick={MatchingJobSelectAllData}
              /> */}
              <font className={"headingfont pl-5 pr-1"}>ID</font>
              <img
                src="/icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                  {
                    handleIdSorting();
                    setIdOrder(!idOrder);
                  }
                }}
              />
            </div>
            <div className={"col-2  hidebox"}>
              <font className={"headingfont pl-3 ml-3"}>Resume title</font>
            </div>
            <div className={"col-1  hidebox"}>
              <div className={""}>
                <font className={"headingfont pr-1"}>Employment</font>
                {/* <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  onClick={()=>{{
                    handleMatchSorting();
                    setMatchOrder(!matchOrder)
                   }}}
                /> */}
              </div>
            </div>
            <div className={"col-2 pr-0 ml-3 hidebox"}>
              <div className={"ml-4"}>
                <font className={"headingfont"}>Experience</font>
                <img
                  src="/icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  onClick={() => {
                    {
                      handleExpSorting();
                      setExpOrder(!expOrder);
                    }
                  }}
                />
              </div>
            </div>
            <div className={"col-1  hidebox"}>
              <font className={"headingfont"}>Rate</font>
              <img
                src="/icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                  {
                    handleRateSorting();
                    setRateOrder(!rateOrder);
                  }
                }}
              />
            </div>
            <div className={"col-1  hidebox"}>
              <font className={"headingfont"}>Location</font>
            </div>
            <div
              className={"col-10 pl-0 showbox pt-0 pb-0"}
              style={{ display: "none" }}
            >
              <div
                className={"pt-1 pb-1 mr-5"}
                style={{
                  background: "#FBFBFB",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className={"row"}>
                  <div className={"col pl-5 ml-5 mt-2"}>
                    <label
                      style={{
                        color: "#FF8A00",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                      className={"pr-5"}
                    >
                      Shortlist
                    </label>
                    <span
                      className={"pt-2 pb-2 pl-3 pr-3"}
                      style={{
                        background: "rgba(255, 138, 0, 0.2)",
                        borderRadius: "8px",
                      }}
                    >
                      <label style={{ color: "#FF8A00", fontSize: "18px" }}>
                        &#9733;{" "}
                      </label>
                    </span>
                  </div>
                  <div className={"col mt-2 pr-5"}>
                    <span
                      className={"pt-2 pb-2 pl-5 pr-5"}
                      style={{
                        background: "rgba(196, 196, 196, 0.2)",
                        border: "1px solid #FFFFFF",
                      }}
                    >
                      <label
                        style={{
                          color: "#FF0000",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Reject
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={"col-1 hidebox"}>
              <font className={"headingfont pl-5 ml-5"}>Status</font>
              <div className="pl-5 ml-4">
                <select
                  className={"form-select statusdropdown px-4 headingfont"}
                  onChange={handleStatusFilter}
                >
                  <option value={""}>Status</option>
                  <option value={"scheduled"}>Scheduled</option>
                  <option value={"shortlisted"}>Shortlisted</option>
                  <option value={"offer"}>Offered</option>
                  <option value={"reject"}>Rejected</option>
                </select>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/*this is second box of heading data*/}
      <Data item={item} record={record} />
      {/*last box of data of heading*/}
      {loading ? (
        ""
      ) : (
        <>
          {record !== 0 && (
            <div
              className={"row align-items-center"}
              style={{
                borderRadius: "0 0 20px 20px",
                boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                backgroundColor: "white",
                marginTop: "1px",
              }}
            >
              <div className={"col py-2"}></div>
            </div>
          )}
        </>
      )}
      <div className={"col mt-5"}>
        <div className={"row align-items-center"}>
          <div className={"col-md-6 pl-0"}>
            {/* Showing 6 from {data.length} data */}
          </div>
          <div className={"col-md"}>
            {totalLength > 6 && (
              <ReactPaginate
                previousLabel={"<< Previous"}
                nextLabel={"Next >>"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={page}
              />
            )}
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};
export default React.memo(CandidateListDetails);
