import { RATECARD } from "../ENVIRONMENT";

async function postRateCard(token, data) {
  // api for candidate ratecard
  return await fetch(RATECARD, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

async function getRateCard(token, data) {
  // api for submit candidate ratecard
  return await fetch(RATECARD, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { postRateCard, getRateCard };
