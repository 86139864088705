import { RECRUITERCLIENTACADEMIC } from "../../ENVIRONMENT";

// api for submit recruiter academic list
function recacademicList(token) {
  const response = fetch(RECRUITERCLIENTACADEMIC, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { recacademicList };
