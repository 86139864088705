import ReCAPTCHA from "react-google-recaptcha";
import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { createUser } from "../../api/authentication/register";
import "../../styles/app.scss";
import { useLinkedIn } from "react-linkedin-login-oauth2";

const Signup = () => {
  // return signup page
  const [passwordShown, setPasswordShown] = useState(false);
  const [resetPasswordShown, setresetPasswordShown] = useState(false);
  const [loginrole, setLoginRole] = useState();
  const [captchaverify, setCaptchaverify] = useState(false);
  const [password, setPassword] = useState();
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const history = useHistory();
  const formRef = useRef();
  let btnRef = useRef();
  const passwordOne = register("passwordone", {
    required: "Please enter a password",
    pattern: {
      //value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
      //value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#^()<>])[A-Za-z\d@$!%*?&_#^()<>]{8,}$/,
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+])[A-Za-z\d><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w+]{8,}$/,
      message:
        "Password must be min. 8 characters with atleast one from (A-Z), (a-z), (0-9), & special characters",
    },
    onChange: (value) =>
      getValues("passwordtwo") !== "" &&
      (getValues("passwordtwo") === getValues("passwordone")
        ? setError("passwordtwo", { type: "validate", message: "" })
        : setError("passwordtwo", {
            type: "validate",
            message: "Passwords do not match",
          })),
  });
  var count = 0;

  const togglePassword = (e) => {
    // toggle the password view to hide or show
    setPasswordShown(!passwordShown);
    e.preventDefault();
  };

  const resetTogglePassword = (e) => {
    setresetPasswordShown(!resetPasswordShown);
    e.preventDefault();
  };

  const resetPasswordField = (event) => {
    passwordOne.onChange(event);
    setPassword(event.currentTarget.value);
    document.getElementsByClassName("resetfield")[0].style.cssText =
      "display:block";
    document.getElementsByClassName("apierrmsg")[0].style.cssText =
      "display:none";
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "77bx0x727z9w6f",
    redirectUri:
      "https://app.dollarstaffing.com/linkedin",
    // redirectUri:
    //   "http://dollar-staffing.s3-website.ap-south-1.amazonaws.com/linkedin",
    scope: "r_liteprofile,r_emailaddress",
    onSuccess: async (code) => {},
    onError: (error) => {},
  });

  const candidateChecked = document.getElementById("signcustomtadio");
  const clientChecked = document.getElementById("signcustomtadio0");
  const recChecked = document.getElementById("signcustomtadio1");
  const freeChecked = document.getElementById("signcustomtadio2");

  const changebgtxt = (event) => {
    // this function is used for change bg and txt of role type of login page
    count += 1;
    if (count === 1) {
      const defaultrole = document.getElementsByClassName("defaultrole")[0];
      defaultrole.nextSibling.style.cssText = "";
      defaultrole.parentNode.style.cssText = "";
    }
    if (event.target.nodeName === "INPUT") {
      if (event.target.nextSibling.innerHTML !== "Candidate") {
        document.getElementById("signlinkedln").style.cssText = "display:none";
        document.getElementById("btwlabel").style.cssText = "display:none";
      } else {
        document.getElementById("signlinkedln").style.cssText = "display:block";
        document.getElementById("btwlabel").style.cssText = "display:block";
      }
      let background;
      if (event.target.value === "client") background = event.target.parentNode;
      else background = event.currentTarget.children[0];
      background.style.cssText = "background-color:#3F9AE0";
      event.target.nextSibling.style.cssText = "color:#FFFFFF";
      setLoginRole({ bg: background, txt: event.target.nextSibling });
    } else if (event.target.nodeName === "SPAN") {
      event.target.children[0].checked = true;
      if (event.target.innerText !== "Candidate") {
        document.getElementById("signlinkedln").style.cssText = "display:none";
        document.getElementById("btwlabel").style.cssText = "display:none";
      } else {
        document.getElementById("signlinkedln").style.cssText = "display:block";
        document.getElementById("btwlabel").style.cssText = "display:block";
      }
      event.target.style.cssText = "background-color:#3F9AE0";
      event.target.children[1].style.cssText = "color:#FFFFFF";
      setLoginRole({ bg: event.target, txt: event.target.children[1] });
    } else if (event.target.nodeName === "LABEL") {
      event.target.previousSibling.checked = true;
      if (event.target.textContent !== "Candidate") {
        document.getElementById("signlinkedln").style.cssText = "display:none";
        document.getElementById("btwlabel").style.cssText = "display:none";
      } else {
        document.getElementById("signlinkedln").style.cssText = "display:block";
        document.getElementById("btwlabel").style.cssText = "display:block";
      }
      event.target.parentNode.style.cssText = "background-color:#3F9AE0";
      event.target.style.cssText = "color:#FFFFFF";
      setLoginRole({ bg: event.target.parentNode, txt: event.target });
    }
    if (loginrole) {
      loginrole.bg.style.cssText = "";
      loginrole.txt.style.cssText = "";
    }
  };

  const selectdefaultrole = (event) => {
    const defaultrole = document.getElementsByClassName("defaultrole")[0];
    defaultrole.checked = true;
    defaultrole.nextSibling.style.cssText = "color:#FFFFFF";
    defaultrole.parentNode.style.cssText = "background-color:#3F9AE0";
  };

  async function submitUser(event) {
    // submit form for user creation if all validation meet requirement
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    const form = formRef.current;
    let role = form.registeropt.value.toUpperCase();
    role = role === "FREELANCE" ? "FREELANCE_RECRUITER" : role;
    const signupval = {
      firstName: event.firstname.replace(/ /g, ""),
      lastName: event.lastname.replace(/ /g, ""),
      role: role,
      email: event.email,
      password: event.passwordone,
    };
    // if (!captchaverify) {
    //   btnRef.current.removeAttribute("disabled");
    //   document.getElementsByClassName("captchaermsg")[0].style.display =
    //     "inline-block";
    //   return;
    // }
    const response = await createUser(signupval);
    const status = response.status;
    if (status === 201) history.push("/user/success");
    if (status === 400) {
      btnRef.current.removeAttribute("disabled");
      const msg = await response.json();
      const cmsg = document.getElementsByClassName("apierrmsg")[0];
      cmsg.innerText = msg.message;
      cmsg.style.display = "inline-block";
    }
    if (status === 422) {
      btnRef.current.removeAttribute("disabled");
      const msg = await response.json();
      try {
        const cmsg = document.getElementsByClassName("apierrmsg")[0];
        cmsg.innerText = msg.payload.password.message;
        cmsg.style.display = "none";
      } catch {}
    }
  }

  const selectedradio = document.querySelector('input[name="registeropt"]:checked');
  
  return (
    <div
      className="main-container"
      style={{ height: "100vh" }}
      onLoad={selectdefaultrole}
    >
      <div className="col-md-12 h-100">
        <div className="row h-100">
          <div className="col-md-7" style={{ background: "#F7F7F7" }}>
            <div className="row pt-5 mt-4">
              <div className="col-md pt-5 pb-5 mt-5">
                <img
                  src="/icon/common/mainlogo.png"
                  className="img-fluid mx-auto d-block pt-5 pb-5 mt-5"
                  alt="dollar"
                />
              </div>
            </div>
          </div>
          <div
            className="col-md-5"
            style={{ background: "#F7F7F7", fontFamily: "Mulish" }}
          >
            <div className="row mx-5 pt-2">
              <div className="col-md">
                <label
                  style={{
                    fontWeight: "bold",
                    fontSize: "28px",
                    color: "#11142D",
                  }}
                >
                  Create your account
                </label>
              </div>
            </div>
            <form
              className="form-group"
              onSubmit={handleSubmit(submitUser)}
              ref={formRef}
            >
              <div className="row pt-1 pl-1 signupcontent">
                <div className="col-xl-2">
                  <label
                    style={{
                      fontWeight: "600",
                      fontSize: "26px",
                      color: "#000000",
                    }}
                  >
                    I am a
                  </label>
                </div>
                <div
                  className="col-xl-4 radiowrapper1 signupcontentchild"
                  onClick={changebgtxt}
                  style={{
                    pointerEvents: `${
                      candidateChecked !== null && candidateChecked.checked
                        ? "none"
                        : "auto"
                    }`,
                  }}
                >
                  <span className="p-2 form-inline roletype" id="span1">
                    <input
                      type="radio"
                      className="loginradio defaultrole"
                      id="signcustomtadio"
                      value="candidate"
                      name="registeropt"
                    />
                    <label
                      className="radiotext"
                      htmlFor="signcustomRadio"
                      onClick={changebgtxt}
                    >
                      Candidate
                    </label>
                  </span>
                </div>
                <div
                  className="col-xl-4 signupcontentchild"
                  onClick={changebgtxt}
                  style={{
                    pointerEvents: `${
                      clientChecked !== null && clientChecked.checked
                        ? "none"
                        : "auto"
                    }`,
                  }}
                >
                  <div className={"signupclient"}>
                    <span className="p-2  form-inline roletype">
                      <input
                        type="radio"
                        className="loginradio"
                        id="signcustomtadio0"
                        value="client"
                        name="registeropt"
                      />
                      <label
                        className="radiotext"
                        htmlFor="signcustomRadio0"
                        onClick={changebgtxt}
                      >
                        Client
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row pt-2 pl-5 ml-5 pb-3">
                <div
                  className="col-xl-4 radiowrapper signupcontent"
                  onClick={changebgtxt}
                  style={{
                    pointerEvents: `${
                      recChecked !== null && recChecked.checked
                        ? "none"
                        : "auto"
                    }`,
                  }}
                >
                  <span className="p-2 form-inline roletype">
                    <input
                      type="radio"
                      className="loginradio"
                      id="signcustomtadio1"
                      value="recruiter"
                      name="registeropt"
                    />
                    <label
                      className="radiotext"
                      htmlFor="signcustomRadio1"
                      onClick={changebgtxt}
                    >
                      Recruiter
                    </label>
                  </span>
                </div>
                <div
                  className="col-xl-5 radiowrapper signupcontent"
                  onClick={changebgtxt}
                  style={{
                    pointerEvents: `${
                      freeChecked !== null && freeChecked.checked
                        ? "none"
                        : "auto"
                    }`,
                  }}
                >
                  <span className="p-2 form-inline roletype freelance">
                    <input
                      type="radio"
                      className="loginradio"
                      id="signcustomtadio2"
                      value="freelance"
                      name="registeropt"
                    />
                    <label
                      className="radiotext recruiter"
                      htmlFor="signcustomRadio2"
                      onClick={changebgtxt}
                    >
                      Freelance Recruiter
                    </label>
                  </span>
                </div>
              </div>
              <div className="row pl-4">
                <div className="col-xl-5 pt-3 namewrapper input-signupName-placeholder">
                  <input
                    type={"text"}
                    required
                    maxLength={20}
                    {...register("firstname", {
                      required: {
                        value: true,
                        message: "Please enter the first name",
                      },
                      pattern: {
                        value: /^(?! )[A-Za-z\s]*$/,
                        message:
                          "Numbers & special characters are not  allowed",
                      },
                      onChange: () => {
                        const cmsg =
                          document.getElementsByClassName("apierrmsg")[0];
                        cmsg.style.display = "none";
                      },
                    })}
                    style={{
                      border: `${errors.firstname ? "1px solid red" : ""}`,
                    }}
                    className={"form-control signupforminputfield shadow-none"}
                    name={"firstname"}
                  />
                  <div className={"signup_name_placeholder"}>
                   First Name<span>*</span>
                    </div>
                  {errors.firstname && (
                    <font
                      className={"dashboardforminputtext text-danger"}
                      style={{ fontWeight: "600" }}
                    >
                      {errors.firstname.message}
                    </font>
                  )}
                </div>
                <div className="col-xl-5 pr-5 pt-3 input-signupName-placeholder">
                  <input
                    type={"text"}
                    required
                    maxLength={20}
                    {...register("lastname", {
                      pattern: {
                        value: /^(?! )[A-Za-z\s]*$/,
                        message:
                          "Numbers & special characters are not  allowed",
                      },
                      required: {
                        value: true,
                        message: "Please enter the last name",
                      },
                      onChange: () => {
                        const cmsg =
                          document.getElementsByClassName("apierrmsg")[0];
                        cmsg.style.display = "none";
                      },
                    })}
                    style={{
                      border: `${errors.lastname ? "1px solid red" : ""}`,
                    }}
                    className={"form-control signupforminputfield shadow-none"}
                    name={"lastname"}
                  />
                  <div className={"signup_name_placeholder"}>
                   Last Name<span>*</span>
                    </div>
                  {errors.lastname && (
                    <font
                      className={"dashboardforminputtext text-danger"}
                      style={{ fontWeight: "600" }}
                    >
                      {errors.lastname.message}
                    </font>
                  )}
                </div>
              </div>
              <div className="row pl-4 pt-2">
                <div className="col-xl-10 pr-5 input-signup-placeholder">
                  <input
                    type={"text"}
                    required
                    id={"email"}
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Please enter valid email ID",
                      },
                      pattern: {
                        value:
                          /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                        message: "Please enter valid email ID",
                      },
                    })}
                    style={{
                      border: `${errors.email ? "1px solid red" : ""}`,
                    }}
                    className={"form-control signupforminputfield shadow-none"}
                    name={"email"}
                  />
                  <div className={"signup_placeholder"}>
                  {
                      selectedradio === null
                        ? "Personal Email ID"
                        : selectedradio.value === "candidate"
                        ? "Personal Email ID"
                        : selectedradio.value === "client" ||
                          selectedradio.value === "recruiter"
                        ? "Business Email ID"
                        : "Email ID"
                    } <span>*</span>
                    </div>
                  {errors.email && (
                    <font
                      className={"dashboardforminputtext text-danger"}
                      style={{ fontWeight: "600" }}
                    >
                      {errors.email.message}
                    </font>
                  )}
                </div>
              </div>
              <div className="row pl-4 pt-2">
                <div className="col-xl-10 pr-5 input-signup-placeholder">
                  <div>
                    <i
                      className={
                        passwordShown ? "fas fa-eye-slash" : "fa fa-eye"
                      }
                      onClick={togglePassword}
                      style={{
                        position: "absolute",
                        right: "60px",
                        top: "18px",
                        color: "#DADADA",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <input
                      type={passwordShown ? "text" : "password"}
                      id={"password"}
                      required
                      {...passwordOne}
                      className={
                        "form-control signupforminputfield shadow-none pr-5"
                      }
                      style={{
                        border: `${errors.passwordone ? "1px solid red" : ""}`,
                      }}
                      onChange={resetPasswordField}
                    />
                    <div className={"signup_placeholder"}>
                    Password<span>*</span>
                    </div>
                    {errors.passwordone &&
                      errors.passwordone.type !== "validate" && (
                        <font
                          className={"dashboardforminputtext text-danger"}
                          style={{ fontWeight: "600" }}
                        >
                          {errors.passwordone.message}
                        </font>
                      )}
                  </div>
                </div>
              </div>
              <div
                className="row pl-4 pt-2 resetfield"
                style={{ display: "none" }}
              >
                <div className="col-xl-10 pr-5 input-signup-placeholder">
                  <div>
                    <i
                      className={
                        resetPasswordShown ? "fas fa-eye-slash" : "fa fa-eye"
                      }
                      onClick={resetTogglePassword}
                      style={{
                        position: "absolute",
                        right: "60px",
                        top: "18px",
                        color: "#DADADA",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <input
                      type={resetPasswordShown ? "text" : "password"}
                      required
                      id={"password1"}
                      {...register("passwordtwo", {
                        required: " Please confirm the password",
                        validate: (value) =>
                          value === password || "Passwords do not match",
                        onChange: () => {
                          const cmsg =
                            document.getElementsByClassName("apierrmsg")[0];
                          cmsg.style.display = "none";
                        },
                      })}
                      style={{
                        border: `${
                          errors.passwordtwo &&
                          errors.passwordtwo.message !== ""
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      className={
                        "form-control signupforminputfield shadow-none pr-5"
                      }
                    />
                    <div className={"signup_placeholder"}>
                    Re-type Password<span>*</span>
                    </div>
                    {errors.passwordtwo && (
                      <font
                        className={"dashboardforminputtext text-danger"}
                        style={{ fontWeight: "600" }}
                      >
                        {errors.passwordtwo.message}
                      </font>
                    )}
                  </div>
                </div>
              </div>
              <font
                className={"pl-4 errormsg confirmpassworderrmsg"}
                id={""}
                style={{ display: "none" }}
              >
                Password not match
              </font>
              <div className="row pl-4 pt-3 pb-0">
                <div className="col-xl form-inline">
                  <input
                    type="checkbox"
                    className="signcheck ml-1"
                    id="customCheck"
                    name="condition"
                    {...register("condition", {
                      required: {
                        value: true,
                        message: "Please accept the Terms of Service",
                      },
                    })}
                  />
                  <label
                    className="ml-2"
                    htmlFor="customCheck"
                    style={{
                      fontSize: "14px",
                      color: "#9A9AB0",
                      fontWeight: "normal",
                    }}
                  >
                    I have read and agree to the &nbsp;
                    <a
                      href={"http://dollarstaffing.com/termsofservices"}
                      target="_blank"
                    >
                      {" "}
                      Terms of Service
                    </a>
                  </label>
                </div>
              </div>
              {errors.condition && (
                <font
                  className={"dashboardforminputtext text-danger ml-4"}
                  style={{ fontWeight: "600" }}
                >
                  {errors.condition.message}
                </font>
              )}
              <div className="row pl-3 pr-5 pt-2">
                <div className="col-xl-10">
                  <div className={"recaptcha-wrap"}>
                    <div id={"g-recaptcha"}>
                      <ReCAPTCHA
                        sitekey="6LfTNcEgAAAAAAsUX_LhtMHMOhoWxq45yV6nZUVF"
                        //sitekey="6LfipUAdAAAAAAGDRiUZYVUMEzVhUgZJMYGxXQhA"
                        onChange={() => {
                          setCaptchaverify(true)
                          document.getElementsByClassName("captchaermsg")[0].style.display = "none"
                        }}
                       />
                    </div>
                  </div>
                 <font
                    className={"px-2 captchaermsg text-danger"}
                    style={{ display: "none" }}
                  >
                    Please select the Captcha
                  </font>
                  <font
                    className={"px-2 apierrmsg text-danger"}
                    style={{ display: "none" }}
                  >
                    select the checkbox again
                  </font>
                </div>
              </div>
              <div className="row pl-3 pr-5">
                <div className="col-xl-10">
                  <button
                    ref={btnRef}
                    type={"submit"}
                    className={"btn signbtn"}
                    id={"divbtndiv"}
                    onClick={handleSubmit(submitUser)}
                  >
                    Register
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-xl">
                  <label className={"text"} id={"btwlabel"}>
                    Or
                  </label>
                </div>
              </div>
              <div className="row pl-3 pr-5">
                <div className="col-xl-10">
                  <button
                    type={"button"}
                    className="btn btnlinkedln"
                    id={"signlinkedln"}
                    onClick={linkedInLogin}
                  >
                    Log in with Linkedln
                  </button>
                </div>
              </div>
              <div className="row mx-5 pb-0 pt-3">
                <div className=" pl-5">
                  <div
                    className={"pl-5"}
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                      color: "#9A9AB0",
                    }}
                  >
                    Have an account?
                  </div>
                </div>
                <div className=" pl-0">
                  <div className={"pl-2"}>
                    <Link
                      to={"/user/login"}
                      style={{
                        textDecoration: "none",
                        color: "#2E2E2E",
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      Log In Now
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Signup);
