import { FREELANCECLIENTREQUIREMENT } from "../../ENVIRONMENT";

// api for freelance delete requirement
async function freedeleteRequirment(token, id) {
  const response = await fetch(FREELANCECLIENTREQUIREMENT + `/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
// api for freelance delete associate requirement
async function freedeleteAssociatedRequirment(token, id, data) {
  const response = await fetch(FREELANCECLIENTREQUIREMENT + `/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export { freedeleteRequirment, freedeleteAssociatedRequirment };
