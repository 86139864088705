import { CLIENTSECONDARY } from "../ENVIRONMENT";

// api for client secondary list
function secondaryList(token) {
  const response = fetch(CLIENTSECONDARY, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { secondaryList };
