import { FREELANCECANDIDATERESUMEUPLOAD,FREELANCECANDIDATEPROFILE } from "../../ENVIRONMENT";

function freeresumeParsing(token,id,data) {
  // api for candidate resume parsing
  const response = fetch(FREELANCECANDIDATERESUMEUPLOAD + `/${id}/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

async function freegetResumeProfileDetails(token,id){
  const response = await fetch(
    FREELANCECANDIDATEPROFILE + `/${id}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freeresumeParsing,freegetResumeProfileDetails };
