import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

const Data = (props) => {
  const [match, setMatch] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch {}
  }, [props.item]);

  const ref = useRef();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    // return application table data in a group which generate by data variable
    <>
      {loading ? (
        <div
          className="row h-100"
          style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
        >
          <div className="col-sm-12 my-auto">
            <img
              src={"/icon/Gif/loader.gif"}
              alt={"loader"}
              className={"mx-auto d-block"}
            />
          </div>
        </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {match.map((match, index) => (
                <div
                  className={"row pl-3"}
                  style={{
                    boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                    backgroundColor: "white",
                    marginTop: "1px",
                  }}
                  key={index}
                >
                  {/* <input type={"checkbox"} className={"checkbx  mr-4 mt-4"} /> */}
                  <div className={"col py-3  box"}>
                    {/*heading box data*/}
                    <div className={"row align-items-center pl-0"}>
                      <div className={"col-2 pr-0"}>
                        <Link
                          to={props.Link}
                          className={"matchingjobdata text-primary"}
                        >
                          {match.candidateId}
                        </Link>
                        <br />
                        <font style={{ fontWeight: 500, fontSize: "14px" }}>
                          {new Date(match.createdAt).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </font>
                        <br />
                        <font className={"matchingjobdata"}>
                          {match.currentLocation}
                        </font>
                      </div>
                      <div className={"col-2 pl-0"}>
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            textAlign: "center",
                          }}
                        >
                          <font className={"matchingjobdata text-primary"}>
                            {match.firstName}&nbsp;&nbsp;{match.lastName}
                          </font>
                        </div>
                      </div>
                      <div className={"col-3"}>
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            textAlign: "center",
                          }}
                        >
                          <font
                            style={{ fontWeight: "normal", fontSize: "14px" }}
                          >
                            {match.User && match.User.email}
                          </font>
                        </div>
                      </div>
                      <div className={"col-2 pr-0"}>
                        <font
                          style={{ fontWeight: "normal", fontSize: "14px" }}
                        >
                          {match.mobile !== null && match.mobile}
                        </font>
                      </div>
                      <div className={"col-1"}>
                        <div className={"row"}>
                          <div
                            style={{
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                              textAlign: "center",
                            }}
                          >
                            <font
                              style={{
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "14px",
                              }}
                            >
                              ${match.rate}
                            </font>
                          </div>
                        </div>
                        <div className={"row"}>
                          <font
                            style={{
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {match.workAuthorization}
                          </font>
                        </div>
                      </div>
                      <div className="col-1">
                        <font
                          className={"ml-5"}
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                          }}
                        >
                          {match.isRegistered ? "ACTIVE" : "INACTIVE"}
                        </font>
                      </div>
                      <div className="col-1">
                        <Popup
                          ref={ref}
                          trigger={
                            <i
                              className="fa fa-ellipsis-v ml-5"
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                            />
                          }
                          position="bottom right"
                          arrow={false}
                          contentStyle={{
                            width: "165px",
                            height: "100px",
                            background: "#F9F9F9",
                            borderRadius: "10px",
                          }}
                        >
                          <label className="popuptext">Archive</label>
                          <br />
                          <label className="popuptext">Delete </label>
                          <br />
                          <label className="popuptext">Block</label>
                        </Popup>
                      </div>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </>
          ) : (
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Data Found
            </div>
          )}
        </>
      )}
    </>
  );
};

export { Data };
