let durationData = [
  { Select: ["Select"] },
  { 3: ["6", "12", "18", "24", "36", "48"] },
  { 6: ["12", "18", "24", "36", "48"] },
  { 12: ["18", "24", "36", "48"] },
  { 18: ["24", "36", "48"] },
  { 24: ["36", "48"] },
  { 36: ["48"] },
  { 48: ["48"] },
];
export { durationData };
