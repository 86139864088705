import { FREELANCECLIENTPERMANENTDATA } from "../../ENVIRONMENT";

// api for freelance client permanent data
async function freegetPermanentDataClient(token) {
  return await fetch(FREELANCECLIENTPERMANENTDATA, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { freegetPermanentDataClient };
