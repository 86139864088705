import { SECONDARYSKILL } from "../ENVIRONMENT";

function secondaryList(token) {
  // api for candidate secondary list
  const response = fetch(SECONDARYSKILL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { secondaryList };
