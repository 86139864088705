import { FREELANCECLIENTMATCHINGJOBS } from "../../ENVIRONMENT";

function freerejectApplication(token,id) {
    // api for reject application
    return fetch(FREELANCECLIENTMATCHINGJOBS + "/reject",{
        
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token?token:sessionStorage.getItem('token')}` },
        body: JSON.stringify(id)

    })
    
}

export { freerejectApplication };

