import { FREELANCECANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for freelance matching job
async function freegetMatchingJob(token, id) {
  let url = FREELANCECANDIDATEMATCHINGJOBS;
  if (id) {
    url = url + "/" + id;
  }
  return await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { freegetMatchingJob };
