import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  handleRemoveShortlistIcon,
  handleShortlistIcon,
  SelectSingle,
} from "../common/SingleSelectMatchingJob";

const Data = (props) => {
  const [match, setMatch] = useState([]);
  const [loading, setLoading] = useState();
  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch {}
  }, [props.item]);

  return (
    // return application table data in a group which generate by data variable
    <>
      {loading ? (
        <div
          className="row h-100"
          style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
        >
          <div className="col-sm-12 my-auto">
            <img
              src={"/icon/Gif/loader.gif"}
              className={"mx-auto d-block"}
              alt={"loader"}
            />
          </div>
        </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {match.map((match) => (
                <div
                  className={"row pl-3"}
                  style={{
                    boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                    backgroundColor: "white",
                    marginTop: "1px",
                  }}
                  key={match.jobRequirementId}
                >
                  <div>
                    {match.clientShortlisted ? (
                      <>
                        <label
                          style={{ color: "#FF8A00", fontSize: "22px" }}
                          className={"mt-3"}
                          onClick={(e) => {
                            handleShortlistIcon(e);
                            props.handleremoveSingleShortlist(match.uuid);
                          }}
                        >
                          &#9733;
                        </label>
                        <label
                          style={{
                            color: "black",
                            fontSize: "24px",
                            fontWeight: "500",
                            display: "none",
                          }}
                          className={"mt-3"}
                          onClick={(e) => {
                            handleRemoveShortlistIcon(e);
                            props.handleaddSingleShortlist(match.uuid);
                          }}
                        >
                          &#9734;
                        </label>
                      </>
                    ) : (
                      <>
                      <label
                          style={{
                            color: "#FF8A00",
                            fontSize: "22px",
                            display: "none",
                          }}
                          className={"mt-3"}
                          onClick={(e) => {
                            handleShortlistIcon(e)
                            props.handleremoveSingleShortlist(match.uuid);
                          }}
                        >
                          &#9733;
                        </label>
                        <label
                          style={{
                            color: "black",
                            fontSize: "24px",
                            fontWeight: "500",
                          }}
                          className={"mt-3"}
                          onClick={(e) => {
                            handleRemoveShortlistIcon(e);
                            props.handleaddSingleShortlist(match.uuid);
                          }}
                        >
                          &#9734;
                        </label>
                      </>
                    )}
                  </div>
                  <input
                    type={"checkbox"}
                    className={"checkbx  mr-4 mt-4 ml-3"}
                    onClick={(e) => {
                      {
                        SelectSingle(e);
                        props.handleShortlist(e, match.clientShortlisted);
                      }
                    }}
                    value={match.uuid}
                  />
                  <div className={"col py-3  box"}>
                    {/*heading box data*/}
                    <div className={"row align-items-center"}>
                      <div className={"col-1 pl-0"}>
                        <div className={"row"}>
                          <div className={"col-md"}>
                            <Link
                              to={`/client/candidatedetails/${match.uuid}`}
                              className={"matchingjobdata text-primary"}
                            >
                              {match.Candidate.candidateId}
                            </Link>
                          </div>
                        </div>
                        <div className={"row"}>
                          <div
                            className={"col-md"}
                            style={{
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            <font style={{ fontWeight: 500, fontSize: "14px" }}>
                              {match.Candidate.preferredLocation === null
                                ? match.Candidate.Addresses[0].city
                                : match.Candidate.preferredLocation}
                            </font>
                          </div>
                        </div>
                      </div>
                      <div className={"col-2"}>
                        <div
                          className="ml-3"
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <Link
                            to={`/client/candidatedetails/${match.uuid}`}
                            className={"matchingjobdata text-primary"}
                          >
                            {match.Candidate && match.Candidate.resumeTitle}
                          </Link>
                        </div>
                      </div>
                      <div className={"col-1"}>
                        <font
                          style={{ fontWeight: "normal", fontSize: "14px" }}
                        >
                          Client
                        </font>
                      </div>
                      <div className="col-3">
                        <div className={"row ml-4 pr-0"}>
                          <div className="col-4 pr-0">
                            <div
                              className={"badge py-3"}
                              style={{
                                backgroundColor: "#D3D3D3",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {match.matchPercentage} %
                            </div>
                          </div>
                          <div className="col-8 pl-0 pr-5">
                            <div className={"row"}>
                              <div
                                className="col-md"
                                style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                <font
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  {match.JobRequirement.domain}
                                </font>
                              </div>
                            </div>
                            <div className={"row"}>
                              <div className={"col-md"}>
                                <font
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                  }}
                                >
                                  {match.Candidate.preferredEmployment}
                                </font>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-1 pl-0"}>
                        <font
                          style={{ fontWeight: "normal", fontSize: "14px" }}
                        >
                          {
                            match.Candidate.CandidateExperiences.length!==0 ?
                            match.Candidate.CandidateExperiences[0]
                              .totalYearOfExperience
                              :"0"
                          }{" "}
                          year
                        </font>
                      </div>
                      <div className={"col-1"}>
                        <div
                          className={"ml-2"}
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <font
                            style={{
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "14px",
                            }}
                          >
                            ${match.Candidate.rate}
                          </font>
                        </div>
                      </div>
                      <div className="col-1 pr-0 pl-0">
                        {match.initiatedBy === "CANDIDATE" && (
                          <font
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Applied
                          </font>
                        )}
                        {match.initiatedBy === "CLIENT" && (
                          <font
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Request
                          </font>
                        )}
                      </div>
                      <div className="col-1 pl-0">
                        {match.JobRequirement.archived ? (
                          <label
                            className={"btn bg-success"}
                            style={{
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              width: "100px",
                              borderRadius: "10px",
                              color: "white",
                            }}
                          >
                            {match.status === null ? "Interview" : match.status}
                          </label>
                        ) : (
                          <>
                            {match.status === null ? (
                              <Link
                                 to={`/client/interview/${match.Candidate.userId}/${match.uuid}`}
                                className={"btn bg-success"}
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  width: "100px",
                                  borderRadius: "10px",
                                  color: "white",
                                }}
                              >
                                Interview
                              </Link>
                            ) : (
                              <label
                                className={"btn bg-success"}
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  width: "100px",
                                  borderRadius: "10px",
                                  color: "white",
                                }}
                              >
                                {match.status}
                              </label>
                            )}
                          </>
                        )}
                      </div>
                      <div className={"col-1 pr-0"}>
                        {
                          match.rejected ?
                          "":
                          <img
                          src="/icon/candidate/applications/Union.png"
                          aria-hidden="true"
                          style={{
                            cursor:"pointer"
                          }}
                          onClick={() => {
                            props.handleSingleReject(match.uuid);
                          }}
                        />
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </>
          ) : (
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Data Found
            </div>
          )}
        </>
      )}
    </>
  );
};

export { Data };
